import { InputMaybe, RecordStatus } from 'valtech-core/common/gql/generated';

import { IAccountFormProps } from './AccountForm';

type SubmitAddAccountParams = {
  data: IAccountFormProps;
};

type AccountMutationVariables = {
  name: string;
  status: InputMaybe<RecordStatus>;
  editAccountId?: number;
};

type SubmitEditAccountParams = {
  data: IAccountFormProps;
};

export const mapToSubmitAddAccount = ({
  data,
}: SubmitAddAccountParams): AccountMutationVariables => {
  return {
    name: data.name,
    status: data.status,
  };
};

export const mapToSubmitEditAccount = ({
  data,
}: SubmitEditAccountParams): AccountMutationVariables => {
  return {
    editAccountId: Number(data.id),
    name: data.name,
    status: data.status,
  };
};
