import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';

import isNumber from 'lodash/isNumber';
import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import {
  setPage,
  setPersistentFilters,
  setRowsPerPage,
} from 'src/common/apolloState/filters/filters.actions';

import { ACTION_FTL } from 'valtech-core/common/ftl';
import { OrderDirection, useGetArrayOfEtitiesQuery } from 'valtech-core/common/gql/generated';
import { IFilters } from 'valtech-core/common/types';

interface IEntity {
  id: string;
  label: string;
  updated_at: string;
  updated_by: string | number;
  status: string;
  order: number;
  userName: string;
}

export interface IUseEntitiesReturn {
  entities: IEntity[];
  activeColumn: string;
  handleSorting: (value: string) => void;
  loading: boolean;
  total: number;
  setPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  page: number;
  rowsPerPage: number;
  error: ApolloError | undefined;
  sortingType: OrderDirection;
  setSortingType: (value: OrderDirection) => void;
  setModel: (value: string) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
  setFilter: (value: IFilters[] | []) => void;
  filter: IFilters[];
  setTableFilters: (value: IFilters[] | []) => void;
  tableFilters: IFilters[] | [];
  setActiveColumn: (value: string) => void;
}

export const useAllEntities = (): IUseEntitiesReturn => {
  const { entitiesPageFilters } = useFiltersState();
  const { persistentFilters, pagination } = entitiesPageFilters;
  const { page, rowsPerPage } = pagination;

  const [activeColumn, setActiveColumn] = useState('');
  const [total, setTotal] = useState(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Asc);
  const [model, setModel] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('label');
  const [filter, setFilter] = useState<IFilters[] | []>(persistentFilters);
  const [tableFilters, setTableFilters] = useState<IFilters[] | []>(persistentFilters);

  const { data, loading, error } = useGetArrayOfEtitiesQuery({
    variables: {
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const entities: IEntity[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data?.getBusinessUnitList?.data || []).map(item => {
      return {
        id: item?.id,
        label: item?.label || '',
        updated_at: item?.updated_at || '',
        updated_by: item?.updated_by || '',
        status: item?.status || '',
        order: item?.order,
        userName: item?.userInfo?.name || '',
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.getBusinessUnitList?.pageInfo?.total;
    const isNumberValue = isNumber(data?.getBusinessUnitList?.pageInfo?.total);
    if (isTotalChanged && isNumberValue) {
      setTotal(data?.getBusinessUnitList?.pageInfo?.total || total);
    }
  }, [data]);

  const handleSorting = useCallback(
    item => {
      if (![ACTION_FTL].includes(item.l10n)) {
        setActiveColumn(item.l10n);
        setModel(item.model);
        setSortBy(item.sortBy);
        setSortingType(OrderDirection.Desc);

        if (item.l10n === activeColumn) {
          setSortingType(
            sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
          );
          return;
        }
      }
    },
    [activeColumn, sortingType],
  );

  useEffect(() => {
    const newFilters: IFilters[] = [];

    if (tableFilters.length) {
      tableFilters.forEach(tableFilter => {
        tableFilter.query && newFilters.push(tableFilter);
      });
    }
    setPersistentFilters(PageTypes.SETTINGS_ENTITIES)(newFilters);
    setFilter(newFilters);
  }, [tableFilters]);

  return {
    page,
    setPage: setPage(PageTypes.SETTINGS_ENTITIES),
    rowsPerPage,
    setRowsPerPage: setRowsPerPage(PageTypes.SETTINGS_ENTITIES),
    loading,
    error,
    entities,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    setFilter,
    filter,
    setTableFilters,
    tableFilters,
    activeColumn,
    handleSorting,
    setActiveColumn,
  };
};
