import React from 'react';

import { useLocalization } from '@fluent/react';
import { hideSystemError, useSystemState } from 'common/apolloState/system';
import Modal from 'components/Modal';

import ErrorIcon from '@mui/icons-material/Error';
import { Stack } from '@mui/material';

import { OK_FTL } from 'valtech-core/common/ftl';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

const GenericError: React.FC = () => {
  const { error } = useSystemState();
  const { l10n } = useLocalization();

  return (
    <Modal openModal={!!error} handleCloseModal={hideSystemError}>
      <Stack
        direction='row'
        spacing={'15px'}
        sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <ErrorIcon fontSize='large' color='error' />

        {error && <Typography>{l10n.getString(error as string)}</Typography>}

        <Button variant='contained' color='error' onClick={hideSystemError}>
          {l10n.getString(OK_FTL)}
        </Button>
      </Stack>
    </Modal>
  );
};

export default GenericError;
