import React, { FC, useCallback, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { CLIENT_DEFAULT } from 'common/constants';
import { IEditBudgetingFormProps } from 'common/types';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import DataPickerField from 'form/DataPickerField';
import SelectFiled from 'form/SelectField';
import TextFiled from 'form/TextField';

import moment, { Moment } from 'moment';

import { Box, Grid, Stack } from '@mui/material';

import {
  notesLimitValidator,
  requiredStringValidator,
  titlePageValidator,
  validateRequiredNumber,
} from 'valtech-core/common/form/validators';
import {
  ADD_FTL,
  CANCEL_FTL,
  CLIENT_FTL,
  COST_FTL,
  DELETE_FTL,
  END_DATE_FTL,
  NOTES_FTL,
  SAVE_FTL,
  START_DATE_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useHandleExpenses } from './ExpenseForm.hooks';

import { checkDates, getDefaultDate, getDefaultDateWithMonth } from '../forms.utils';

import { EFormFieldName } from '../form.types';
import { ADD_TITLE_FTL, EDIT_TITLE_FTL } from './ExpenseForm.ftl';

export interface IExpenseFormProps {
  title: string;
  client: string;
  cost: number;
  startDate: Moment;
  endDate: Moment;
  notes: string;
  isDisabledEditOnBilling?: boolean;
}

const ExpenseForm: FC<IEditBudgetingFormProps<IExpenseFormProps>> = ({
  onClose,
  initialValues,
  assignmentId,
  hasDelete,
  isDisabledEditOnBilling = false,
}) => {
  const { l10n } = useLocalization();
  const { control, handleSubmit, formState, watch, setValue, setError } =
    useForm<IExpenseFormProps>({
      defaultValues: initialValues,
    });
  const { financialYear } = useBudgetingState();

  const { clientsList, createExpenses, updateExpenses, deleteExpenses, loading } =
    useHandleExpenses();

  function fieldWatcher(filed: keyof IExpenseFormProps) {
    const fieldValue = watch(filed);
    return fieldValue;
  }

  useEffect(() => {
    if (!isDisabledEditOnBilling) {
      const generalClientId = clientsList.find(client => client.title === CLIENT_DEFAULT)?.id;
      generalClientId && setValue(EFormFieldName.Client, `${generalClientId}`);
    }
  }, [clientsList]);

  const formSubmit: SubmitHandler<IExpenseFormProps> = useCallback(
    data => {
      const isValidPeriod = checkDates<IExpenseFormProps>({
        data,
        dateKeys: [EFormFieldName.StartDate, EFormFieldName.EndDate],
        setError,
      });

      if (isValidPeriod) {
        if (initialValues) {
          updateExpenses(assignmentId, data).then(success => success && onClose());
        } else {
          createExpenses(data).then(success => success && onClose());
        }
      }
    },
    [onClose],
  );

  const onCancel = () => {
    onClose();
  };

  const onDelete = () => {
    deleteExpenses(assignmentId).then(success => success && onClose());
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {initialValues ? l10n.getString(EDIT_TITLE_FTL) : l10n.getString(ADD_TITLE_FTL)}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextFiled
            label={l10n.getString(TITLE_FTL)}
            name={EFormFieldName.Title}
            disabled={isDisabledEditOnBilling}
            control={control as unknown as FieldControl}
            validate={titlePageValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(CLIENT_FTL)}
            name={EFormFieldName.Client}
            data={clientsList}
            disabled={isDisabledEditOnBilling}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFiled
            label={l10n.getString(COST_FTL)}
            name={EFormFieldName.Cost}
            disabled={isDisabledEditOnBilling}
            control={control as unknown as FieldControl}
            textProps={{
              type: 'number',
            }}
            validate={validateRequiredNumber}
          />
        </Grid>

        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(START_DATE_FTL)}
            name={EFormFieldName.StartDate}
            disabled={isDisabledEditOnBilling}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
            minDate={getCalendarRangeByYear(financialYear).firstDayOfYear}
            maxDate={getCalendarRangeByYear(financialYear).lastDayOfYear}
            defaultCalendarMonth={getDefaultDate(financialYear)}
          />
        </Grid>

        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(END_DATE_FTL)}
            name={EFormFieldName.EndDate}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
            minDate={fieldWatcher(EFormFieldName.StartDate) as Moment}
            maxDate={getCalendarRangeByYear(financialYear).lastDayOfYear}
            defaultCalendarMonth={getDefaultDateWithMonth(
              moment(fieldWatcher(EFormFieldName.StartDate) as Moment).month() + 1,
              financialYear as number,
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFiled
            name={EFormFieldName.Notes}
            control={control as unknown as FieldControl}
            label={l10n.getString(NOTES_FTL)}
            textProps={{
              label: l10n.getString(NOTES_FTL),
              multiline: true,
              rows: 5,
            }}
            validate={notesLimitValidator}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button
          onClick={handleSubmit(formSubmit)}
          disabled={!formState.isDirty || loading}
          variant='contained'>
          {initialValues ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        {initialValues && hasDelete && (
          <Button
            onClick={onDelete}
            disabled={loading}
            variant='outlined'
            type='button'
            color='error'>
            {l10n.getString(DELETE_FTL)}
          </Button>
        )}

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default ExpenseForm;
