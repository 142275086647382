import { ALL_FTL } from 'valtech-core/common/ftl';
import { IFilters } from 'valtech-core/common/types';

export const createFiltersPayload = (
  projectFilter: IFilters,
  tableFilters: IFilters[],
): IFilters[] => {
  const newFilters: IFilters[] = [];

  projectFilter.query &&
    projectFilter?.query?.toLowerCase() !== ALL_FTL &&
    newFilters.push(projectFilter);

  if (tableFilters.length) {
    if (projectFilter.query) {
      tableFilters
        .filter(filter => filter.filterModel !== projectFilter.filterModel)
        .forEach(tableFilter => {
          tableFilter.query && newFilters.push(tableFilter);
        });
    } else {
      tableFilters.forEach(tableFilter => {
        tableFilter.query && newFilters.push(tableFilter);
      });
    }
  }

  return newFilters;
};
