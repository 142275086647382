import React, { FC, memo } from 'react';

import MUISkeleton, { SkeletonProps } from '@mui/material/Skeleton';

const Skeleton: FC<SkeletonProps> = ({ animation, children, sx, variant, ...rest }) => {
  return (
    <MUISkeleton animation={animation} sx={sx} variant={variant} {...rest}>
      {children}
    </MUISkeleton>
  );
};

export default memo(Skeleton);
