import React, { FC } from 'react';

import useGetAccounts from 'src/common/hooks/useGetAccounts';

import AccountsHeader from './AllAccountsHeader';
import AccountsTable from './AllAccountsTable';

const Accounts: FC = () => {
  const allAccountsHookData = useGetAccounts();

  return (
    <>
      <AccountsHeader />
      <AccountsTable {...allAccountsHookData} />
    </>
  );
};
export default Accounts;
