import React, { FC, useMemo } from 'react';

import { Localized } from '@fluent/react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { ITableHeaderItem } from 'src/common/types';
import { v4 as uuidv4 } from 'uuid';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { OrderDirection } from 'valtech-core/common/gql/generated';

import s from './TableRowReusable.module.scss';

interface ITableRowProps {
  headData: ITableHeaderItem[];
  sortingType?: OrderDirection;
  activeColumn?: string;
  handleSorting?: (e) => void;
  disableSorting?: boolean;
}

const TableRowReusable: FC<ITableRowProps> = ({
  headData,
  sortingType,
  activeColumn,
  handleSorting = noop,
  disableSorting = false,
}) => {
  const _headData = useMemo(() => headData.map(item => ({ ...item, _id: uuidv4() })), []);
  return (
    <TableRow className={cn({ 'TableRow--disabled': disableSorting })}>
      {_headData.map(item => {
        const isActive = item.l10n === activeColumn;
        const iconClasses = `${s.icon} ${isActive ? s.active : ''}`;
        return (
          <TableCell key={item._id} onClick={() => handleSorting(item)}>
            <div
              className={cn(s.tableHeader, {
                [s.clickable]: !!item.sortBy,
                [s.active]: isActive,
              })}>
              {item.l10n && <Localized id={item.l10n} />}
              {!!item.sortBy && (
                <span className={s.arrow}>
                  {isActive && sortingType === OrderDirection.Asc ? (
                    <ArrowUpwardIcon className={iconClasses} />
                  ) : (
                    <ArrowDownwardIcon className={iconClasses} />
                  )}
                </span>
              )}
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableRowReusable;
