import {
  CLIENTS_EXPENSES_FTL,
  CONSULTANTS_CLIENTS_FTL,
  COST_FTL,
  ENTITIES_FTL,
  HOURS_FTL,
  MODEL_FTL,
  PERIOD_FTL,
  QTY_FTL,
  RATE_FTL,
  TOTAL_FTL,
} from 'valtech-core/common/ftl';

export interface IHeadData {
  id: number;
  title_l10n: string;
}

export const consultantsHeadData: IHeadData[] = [
  { id: 0, title_l10n: '' },
  { id: 1, title_l10n: CONSULTANTS_CLIENTS_FTL },
  { id: 2, title_l10n: MODEL_FTL },
  { id: 3, title_l10n: PERIOD_FTL },
  { id: 4, title_l10n: RATE_FTL },
  { id: 5, title_l10n: HOURS_FTL },
  { id: 6, title_l10n: TOTAL_FTL },
  { id: 7, title_l10n: '' },
  { id: 8, title_l10n: '' },
];

export const clientsHeadData: IHeadData[] = [
  { id: 0, title_l10n: '' },
  { id: 1, title_l10n: CLIENTS_EXPENSES_FTL },
  { id: 2, title_l10n: '' },
  { id: 3, title_l10n: PERIOD_FTL },
  { id: 4, title_l10n: COST_FTL },
  { id: 5, title_l10n: QTY_FTL },
  { id: 6, title_l10n: TOTAL_FTL },
  { id: 7, title_l10n: '' },
  { id: 8, title_l10n: '' },
];

export const clientsHeadDataByEntities: IHeadData[] = [
  { id: 0, title_l10n: '' },
  { id: 1, title_l10n: ENTITIES_FTL },
  { id: 2, title_l10n: '' },
  { id: 3, title_l10n: '' },
  { id: 4, title_l10n: '' },
  { id: 5, title_l10n: '' },
  { id: 6, title_l10n: '' },
  { id: 7, title_l10n: TOTAL_FTL },
  { id: 8, title_l10n: '' },
];
