import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import s from './TimesheetDetails.module.scss';

type CollapsibleListItemProps = {
  name: string;
  totalHours?: number;
  collapsed: boolean;
  onToggle: () => void;
  children?: ReactNode;
  selected?: boolean;
};

const CollapsibleListItem: FC<CollapsibleListItemProps> = ({
  name,
  totalHours,
  collapsed,
  onToggle,
  selected,
  children,
}) => {
  return (
    <li className={selected ? s.selected : ''}>
      <div
        className={cn(
          s.container,
          s.timesheetListItem,
          !selected && s.timesheetListItemIcon,
          collapsed ? s.collapsed : '',
        )}
        onClick={onToggle}>
        <span className={s.timesheetTitle}>{name}</span>
        <span className={s.timesheetListHours}>{totalHours}</span>
      </div>
      {!collapsed && children}
    </li>
  );
};

export default CollapsibleListItem;
