import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { useAllBusinessUnitList } from 'common/hooks/useAllBusinessUnitList';
import TableRowReusable from 'components/TableRowReusable';
import SelectFiled from 'form/SelectField/SelectField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { ENTITY_FTL, RATES_FTL } from 'valtech-core/common/ftl';
import { Filter } from 'valtech-core/common/gql/generated';
import { FieldControl } from 'valtech-core/common/types';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import AddNewRate from './AddNewRate';
import RateItem from './RateItem';
import { headData } from './RateTable.constants';
import { useRateTable, useUpdRate } from './RateTable.hook';
import s from './RateTable.module.scss';

const RateTable: FC = () => {
  const { id: budgetID } = useParams();
  const [editingItemId, setEditItemId] = useState<string | number>('');
  const { l10n } = useLocalization();
  const methods = useFormContext();
  const updRate = useUpdRate();
  const { control, setValue, getValues, watch } = methods;
  const [ratesEntitiesFilter, setRatesEntitiesFilter] = useState<Filter | null>(null);
  const {
    rate,
    loading,
    sorting: { sortingType, activeColumn, handleSorting },
  } = useRateTable(ratesEntitiesFilter);
  const { isEditing, setState } = useEditingContext();
  const { entities } = useAllBusinessUnitList();
  const entitiesSelectData = [
    {
      id: 0,
      title: 'All',
      value: 'All',
    },
    ...entities,
  ];

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const hasRatesEntitiesFilterChanged = name === 'ratesEntitiesFilter' && type === 'change';

      if (hasRatesEntitiesFilterChanged) {
        const ratesEntitiesFilterValue = value.ratesEntitiesFilter;
        const ratesFilter =
          ratesEntitiesFilterValue === 'All'
            ? null
            : {
                field: 'entity',
                query: value.ratesEntitiesFilter,
              };

        setRatesEntitiesFilter(ratesFilter);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const { fields, append, replace } = useFieldArray({
    control,
    name: 'rate.rates',
  });

  useEffect(() => {
    setValue('rate', rate);
    replace(rate.rates);
  }, [loading]);

  const onSave = index => value => {
    updRate(value).then(() => {
      setValue(`rate.rates[${index}]`, value);
    });
  };

  const onSelect = id => () => {
    if (isEditing) return;
    setEditItemId(id);
    setState(true);
  };

  const onCancel = () => {
    setEditItemId('');
    setState(false);
  };

  return (
    <Box>
      <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(RATES_FTL)}</Box>
      <TableContainer sx={{ pt: 4 }}>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'relative',
                bottom: 0,
                left: 0,
              },
            }}
          />
        )}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <SelectFiled
              label={l10n.getString(ENTITY_FTL)}
              name={`ratesEntitiesFilter`}
              data={entitiesSelectData}
              defaultValue='All'
              control={control as unknown as FieldControl}
            />
          </Grid>
        </Grid>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRowReusable
              disableSorting={Boolean(editingItemId) || !budgetID}
              headData={headData}
              sortingType={sortingType}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
            />
          </TableHead>
          <TableBody className={s.RateTable_tableBody}>
            {fields.map((field, index) => {
              return (
                <RateItem
                  key={field.id}
                  isActive={field.id === editingItemId && editingItemId !== ''}
                  onSave={onSave(index)}
                  onSelect={onSelect(field.id)}
                  onCancel={onCancel}
                  values={getValues(`rate.rates[${index}]`)}
                />
              );
            })}
            <AddNewRate append={append} entities={entities} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RateTable;
