import { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { showGenericError } from 'common/apolloState/system';
import useHandleSorting, { HolidaySorting } from 'common/hooks/useHandleSorting';

import { Moment } from 'moment';

import {
  useDeleteHolidayMutation,
  useGetHolidaysByHolidayCalendarIdLazyQuery,
  useUpdateHolidayCalendarDateMutation,
} from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';

import { HolidayFields, IHoliday } from './Holiday.constants';

interface IUseHolidayReturn {
  holiday: {
    order: number;
    loading: boolean;
    holidays: IHoliday[];
  };
  sorting: HolidaySorting;
}

export const useHoliday = (): IUseHolidayReturn => {
  const { id } = useParams();
  const calendarID = Number(id);
  const { sortBy, handleSorting, setSortBy, setSortingType, sortingType, activeColumn } =
    useHandleSorting({
      modelState: '',
      sortByState: HolidayFields.Date,
      activeColumnState: '',
    });

  const sorting = {
    sortBy,
    setSortBy,
    sortingType,
    activeColumn,
    handleSorting,
    setSortingType,
  };

  const [getHolidays, { data, loading }] = useGetHolidaysByHolidayCalendarIdLazyQuery();

  useEffect(() => {
    if (id) {
      getHolidays({
        onError() {
          showGenericError();
        },
        variables: {
          sortBy,
          sortType: sortingType,
          holidayCalendarId: calendarID,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [id, sortBy, sortingType]);

  const { holidays, order } = useMemo(() => {
    if (data?.sendArrayOfHolidayDatesByHolidayCalendarID?.length) {
      return {
        holidays: data.sendArrayOfHolidayDatesByHolidayCalendarID,
        // FYI: calendar doesn't use the order field,
        // but for DB it's required and needs to be incremented for new date
        order: Math.max(
          0,
          ...data.sendArrayOfHolidayDatesByHolidayCalendarID.map(({ order }) => order),
        ),
      };
    } else
      return {
        holidays: [],
        order: 0,
      };
  }, [data]);

  return {
    holiday: {
      order,
      loading,
      holidays,
    },
    sorting,
  };
};

interface IUseDeleteHolidayReturn {
  loading: boolean;
  onDelete: (id: number) => Promise<unknown>;
}

export const useDeleteHoliday = (): IUseDeleteHolidayReturn => {
  const { id: calendarID } = useParams();
  const [deleteHoliday, { loading }] = useDeleteHolidayMutation();

  const onDelete = useCallback(
    async (id: number) => {
      try {
        if (calendarID) {
          return deleteHoliday({
            variables: {
              holidayDateId: id,
            },
            refetchQueries: ['GetHolidaysByHolidayCalendarID'],
          });
        }
      } catch (error) {
        showGenericError();
        console.log('==>delete holiday error', error);
      }
    },
    [calendarID],
  );

  return {
    loading,
    onDelete,
  };
};

export const useUpdHolidayCalendarDate = (): ((holiday: IHoliday) => Promise<unknown>) => {
  const { id: calendarID } = useParams();
  const [updUpdHolidayDate] = useUpdateHolidayCalendarDateMutation();

  return holiday => {
    const { date, title, id } = holiday;
    return updUpdHolidayDate({
      variables: {
        date: (date as unknown as Moment).format(DateMask.YYYY_MM_DD),
        title,
        holidayDateId: Number(id),
        holidayCalendarId: Number(calendarID),
      },
    });
  };
};
