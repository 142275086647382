import { useCallback, useMemo } from 'react';

import { MonthSettings } from 'common/types';

import { useGetWorkingDaysForTheYearLazyQuery } from 'valtech-core/common/gql/generated';

type UseWorkingDaysAndHolidaysByYearReturn = {
  getWorkingDays: (holidayCalendarId: number, year: number) => Promise<MonthSettings[]>;
  workingDays: { workingDays: number }[] | [];
  loading: boolean;
};

export const useWorkingDaysForTheYear = (): UseWorkingDaysAndHolidaysByYearReturn => {
  const [getDaysApollo, { data, loading }] = useGetWorkingDaysForTheYearLazyQuery();

  const getWorkingDays = useCallback(
    async (holidayCalendarId: number, year: number) => {
      const workingDaysAndHolidays = await getDaysApollo({
        variables: {
          holidayCalendarId,
          year: `${year}`,
        },
      });

      const month = workingDaysAndHolidays.data?.getWorkingDaysAndHolidaysForTheYear.map(
        ({ workingDays, daysInMonth, arrayOfNationalHolidays }) => ({
          workingDays,
          daysInMonth,
          arrayOfNationalHolidays,
        }),
      );

      return month || [];
    },
    [data],
  );

  const workingDays = useMemo(() => {
    if (!data) return [];

    return data.getWorkingDaysAndHolidaysForTheYear.map(item => {
      return {
        workingDays: item.workingDays,
      };
    });
  }, [data]);

  return {
    workingDays,
    getWorkingDays,
    loading,
  };
};
