import { BudgetingState, budgetingInitialState, budgetingVar } from './budgeting';

export const setBudgetingState = (state: Partial<BudgetingState>): void => {
  const currentState = budgetingVar();

  budgetingVar({
    ...currentState,
    ...state,
  });
};

export const clearBudgetingState = (): void => {
  budgetingVar(budgetingInitialState);
};
