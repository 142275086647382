import { ConsultantModelEnum } from 'common/types';
import round from 'lodash/round';
import { TEMP_ICBO_TEMPLATEID } from 'src/common/constants';

import moment from 'moment';

import {
  AddAssignmentMutationVariables,
  EditAssignementMutationVariables,
} from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';

import { transformAllocation } from '../forms.utils';

import { IAssignmentFormProps } from './AssignmentForm';

type SubmitAddAssignmentParams = {
  projectId: number;
  budgetReportId: number;
  budgetingSettingId: number;
  data: IAssignmentFormProps;
};

export type EditAssignementVariables = {
  costOfOverhead?: number;
  endDate: string;
  startDate: string;
  fee: number;
  fromBilling?: boolean;
  holidayCalendarId: number;
  model?: string;
  notes?: string;
  percentage: number;
  rateId?: number;
};

export type SubmitEditAssignmentParams = {
  projectId: number;
  assignmentId: number;
  data: IAssignmentFormProps;
  fromBilling?: boolean;
  budgetingSettingId?: number | null;
  billingReportId?: number;
  budgetReportId?: number | null;
  icboTemplateId?: number;
  ccy?: string;
  status?: string;
  text?: string;
};

export const mapToSubmitAddAssignment = ({
  projectId,
  budgetReportId,
  budgetingSettingId,
  data,
}: SubmitAddAssignmentParams): AddAssignmentMutationVariables => {
  return {
    budgetReportId,
    budgetingSettingId,
    projectId,
    consultantId: data.consultant.id,
    holidayCalendarId: Number(data.holidayCalendarId),
    costOfOverhead: round(data.costOfOverhead, 2),
    //TODO: remove hardcoded, Sept 2024 functionality is not developed
    icboTemplateId: TEMP_ICBO_TEMPLATEID,
    hoursPerMonth: data.model === ConsultantModelEnum.TimeAndMaterial ? Number(data.hours) : null,
    percentage:
      data.model === ConsultantModelEnum.Dedicated
        ? transformAllocation(Number(data.allocation))
        : null,
    clientId: Number(data.client),
    fee: round(data.fee, 2),
    rateId: Number(data.rate),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    model: data.model,
    notes: data.notes,
  };
};

type SubmitAddAssignmentFromBillingParams = SubmitAddAssignmentParams & {
  fromBilling: boolean;
  billingId: number;
};

export const mapToSubmitAddAssignmentFromBilling = ({
  projectId,
  budgetReportId,
  budgetingSettingId,
  billingId,
  fromBilling,
  data,
}: SubmitAddAssignmentFromBillingParams): AddAssignmentMutationVariables => {
  return {
    billingReportId: billingId,
    fromBilling,
    showInBudget: data.showInBudget,
    ...mapToSubmitAddAssignment({ data, projectId, budgetingSettingId, budgetReportId }),
  };
};

export const mapToSubmitEditAssignment = ({
  ...params
}: SubmitEditAssignmentParams): EditAssignementMutationVariables => {
  const {
    projectId,
    assignmentId,
    data,
    fromBilling = false,
    budgetingSettingId,
    billingReportId,
    budgetReportId,
    icboTemplateId,
    ccy,
    status,
    text,
  } = params;
  return {
    projectId,
    assignementId: Number(assignmentId),
    holidayCalendarId: Number(data.holidayCalendarId),
    costOfOverhead: round(data.costOfOverhead, 2),
    hoursPerMonth: data.model === ConsultantModelEnum.TimeAndMaterial ? Number(data.hours) : null,
    percentage:
      data.model === ConsultantModelEnum.Dedicated
        ? transformAllocation(Number(data.allocation))
        : null,
    rateId: Number(data.rate),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    model: data.model,
    notes: data.notes,
    fee: round(data.fee, 2),
    fromBilling,
    consultantId: data?.consultant?.id,
    clientId: Number(data.client),
    billingReportId,
    budgetingSettingId: budgetingSettingId,
    budgetReportId: budgetReportId,
    icboTemplateId,
    ccy,
    status,
    text,
  };
};

export const formattedAssignmentData = (data: IAssignmentFormProps): EditAssignementVariables => {
  return {
    holidayCalendarId: Number(data.holidayCalendarId),
    costOfOverhead: round(data.costOfOverhead, 2),
    percentage: transformAllocation(Number(data.allocation)),
    rateId: Number(data.rate),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    model: data.model,
    notes: data.notes,
    fee: round(data.fee, 2),
  };
};

export const compareAssignmentData = (
  initialValues: EditAssignementVariables,
  updatedValues: EditAssignementVariables,
): boolean => {
  const initialValuesKeys = Object.keys(initialValues);
  const updatedValuesKeys = Object.keys(updatedValues);

  if (initialValuesKeys.length !== updatedValuesKeys.length) {
    return false;
  }

  for (const key of initialValuesKeys) {
    if (updatedValuesKeys.includes(key)) {
      if (key === 'notes') {
        if (initialValues[key] !== updatedValues[key]) {
          return true;
        }
      } else {
        if (initialValues[key] !== updatedValues[key]) {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  return false;
};
