import React, { ReactNode, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'src/common/apolloState/notifications';
import { checkIsAllowedFileFormat } from 'src/common/utils/fileFormat';
import { secondsToMilliSeconds } from 'src/common/utils/secondsToMilliSeconds';

import { AlertsSeverityEnum } from 'valtech-core/common/types';

type DragDropProps = {
  render: (
    dragActive: boolean,
    handleDrop: (
      e: React.DragEvent,
      setTitle: React.Dispatch<React.SetStateAction<string>>,
      setFile: React.Dispatch<React.SetStateAction<File | null>>,
      formats: string[],
      extensionsRegex: RegExp,
      setOriginalFileName?: React.Dispatch<React.SetStateAction<string>>,
    ) => void,
    handleDrag: (e: React.DragEvent) => void,
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      setTitle: React.Dispatch<React.SetStateAction<string>>,
      setFile: React.Dispatch<React.SetStateAction<File | null>>,
      formats: string[],
      extensionsRegex: RegExp,
      setOriginalFileName?: React.Dispatch<React.SetStateAction<string>>,
    ) => void,
  ) => ReactNode;
  handleClearData: () => void;
  message: string;
};

const DragAndDrop: React.FC<DragDropProps> = props => {
  const { render, handleClearData, message } = props;
  const { l10n } = useLocalization();
  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (
    e: React.DragEvent,
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    formats: string[],
    extensionsRegex: RegExp,
    setOriginalFileName?: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    const files = e.dataTransfer.files;
    const hasFiles = files && files[0];
    const isAllowedFileFormat = checkIsAllowedFileFormat(files, formats);

    if (hasFiles) {
      const droppedFile = files[0];
      setTitle(droppedFile.name.replace(extensionsRegex, ''));
      setOriginalFileName && setOriginalFileName(droppedFile.name.replace(extensionsRegex, ''));

      if (!isAllowedFileFormat) {
        showNotification({
          message: l10n.getString(message),
          hideDuration: secondsToMilliSeconds(3),
          severity: AlertsSeverityEnum.Error,
        });

        handleClearData && handleClearData();

        return;
      }

      setFile(droppedFile);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    formats: string[],
    extensionsRegex: RegExp,
    setOriginalFileName?: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    e.preventDefault();

    const files = e.target.files;
    const isAllowedFileFormat = checkIsAllowedFileFormat(files, formats);

    if (!isAllowedFileFormat) {
      showNotification({
        message: l10n.getString(message),
        hideDuration: secondsToMilliSeconds(4),
        severity: AlertsSeverityEnum.Error,
      });

      handleClearData();

      return;
    }

    if (files && files[0]) {
      const selectedFile = files[0];
      setTitle(selectedFile.name.replace(extensionsRegex, ''));
      setOriginalFileName && setOriginalFileName('');
      setFile(selectedFile);
    }
  };

  return <> {render(dragActive, handleDrop, handleDrag, handleChange)}</>;
};

export default DragAndDrop;
