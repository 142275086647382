import { TableSearchFieldsEnum } from 'src/common/types';

import { ACTION_FTL, EMAIL_FTL, NAME_FTL, ROLE_FTL, STATUS_FTL } from 'valtech-core/common/ftl';

export const headData = [
  { l10n: NAME_FTL, model: '', sortBy: TableSearchFieldsEnum.Name },
  { l10n: EMAIL_FTL, model: '', sortBy: TableSearchFieldsEnum.Email },
  { l10n: ROLE_FTL, model: '', sortBy: TableSearchFieldsEnum.Role },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: TableSearchFieldsEnum.Status,
  },
  { l10n: ACTION_FTL, model: '', sortBy: '' },
];
