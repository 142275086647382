import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { showGenericError } from 'common/apolloState/system';
import { useAllBusinessUnitList } from 'common/hooks/useAllBusinessUnitList';
import useHandleSorting from 'common/hooks/useHandleSorting';
import { handleSortingParams } from 'common/hooks/useHandleSorting/useHandleSorting';
import { ConsultantModelEnum } from 'common/types';

import { STATUS_ACTIVE_FTL, STATUS_NOT_ACTIVE_FTL } from 'valtech-core/common/ftl';
import {
  ActivationStatus, //TODO:When query will be ready need to add AccountReportModel
  Filter,
  useGetRatesByBudgetingSettingIdLazyQuery,
  useUpdateRateByIdMutation,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum } from 'valtech-core/common/types';

import { Currency, Entity, IRateTable, ModelStatus, RatesStatus } from './RateTable.constants';

export interface IUseRateTableReturn {
  loading: boolean;
  sorting: handleSortingParams;
  rate: {
    rates: IRateTable[];
  };
}

interface IUseRateSelectsReturn {
  statuses: RatesStatus[];
  models: ModelStatus[];
  ccys: Currency[];
  entities: Entity[];
}

export const useRateTable = (entityFilter?: Filter | null): IUseRateTableReturn => {
  const { id } = useParams();
  const budgetID = Number(id);
  const { sortingType, activeColumn, handleSorting, sortBy } = useHandleSorting({
    modelState: 'client',
    sortByState: 'title',
    activeColumnState: '',
  });

  const [getRates, { data, loading }] = useGetRatesByBudgetingSettingIdLazyQuery();

  useEffect(() => {
    if (id) {
      const filterDedicated: Filter[] = [{ field: 'model', query: ConsultantModelEnum.Dedicated }];
      const filterTnM: Filter[] = [{ field: 'model', query: ConsultantModelEnum.TimeAndMaterial }];

      if (entityFilter && entityFilter.query) {
        filterDedicated.push(entityFilter);
        filterTnM.push(entityFilter);
      }

      getRates({
        variables: {
          budgetSettingsId: Number(budgetID),
          orderBy: {
            sortBy: sortBy,
            sortDirection: sortingType,
          },
          filterDedicated,
          filterTnM,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [id, sortBy, sortingType, entityFilter]);

  const _rate = useMemo(() => {
    return !id || !data || !data?.dedicated || !data?.timeAndMaterial
      ? { rates: [] }
      : { rates: [...data.dedicated, ...data.timeAndMaterial] };
  }, [data]);

  return {
    loading,
    rate: _rate,
    sorting: {
      sortingType,
      activeColumn,
      handleSorting,
    },
  };
};

export const useRateSelects = (): IUseRateSelectsReturn => {
  const { l10n } = useLocalization();
  const statuses = [
    { id: 1, title: l10n.getString(STATUS_ACTIVE_FTL), value: ActivationStatus.Active },
    { id: 2, title: l10n.getString(STATUS_NOT_ACTIVE_FTL), value: ActivationStatus.NotActive },
  ];

  const models = [
    { id: 1, title: 'Dedicated', value: ConsultantModelEnum.Dedicated },
    { id: 2, title: 'T&M', value: ConsultantModelEnum.TimeAndMaterial },
  ];

  const ccys = [
    { id: 1, title: CurrencyEnum.USD, value: CurrencyEnum.USD },
    { id: 2, title: CurrencyEnum.EUR, value: CurrencyEnum.EUR },
  ];

  const { entities } = useAllBusinessUnitList();

  return {
    statuses,
    models,
    ccys,
    entities,
  };
};

export const useUpdRate = (): ((rate: IRateTable) => Promise<unknown>) => {
  const { id: budgetID } = useParams();
  const [updRate] = useUpdateRateByIdMutation();

  return (rate: IRateTable): Promise<unknown> => {
    const { id: rateId, fee, status, ...rest } = rate;
    return updRate({
      variables: {
        budgetingSettingId: Number(budgetID),
        updateRateByIdId: rateId,
        fee: Number(fee),
        status: status as ActivationStatus,
        ...rest,
      },
    }).catch(error => {
      console.log('==>upd rate error', error);
      showGenericError();
    });
  };
};
