import { useEffect, useMemo } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showGenericError } from 'common/apolloState/system';
import { DEFAULT_CLIENTS_SEARCH_PARAMS } from 'src/common/constants';

import {
  ActivationStatus,
  useGetAllRelatedClientsLazyQuery,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

export type Client = {
  id: number;
  name: string;
  projectId: number;
  clientId: number;
  clientManager: Maybe<string>;
  clientManagerEmail: Maybe<string>;
  status: ActivationStatus;
};

type UseClientsReturn = {
  clients: Client[];
  loading: boolean;
};

export const useClients = (projectId?: number): UseClientsReturn => {
  const { projectId: stateAccountId } = useBudgetingState();
  const [getClients, { data, loading }] = useGetAllRelatedClientsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onError() {
      showGenericError();
    },
  });

  useEffect(() => {
    if (projectId || stateAccountId) {
      getClients({
        variables: {
          id: projectId || (stateAccountId as number),
          ...DEFAULT_CLIENTS_SEARCH_PARAMS,
        },
      });
    }
  }, [projectId, stateAccountId]);

  const clients = useMemo((): Client[] => {
    if (!data) {
      return [];
    }

    return data.getArrayOfClients.map(client => ({
      id: client.id,
      projectId: client.project_id,
      name: client.name,
      clientId: client.id,
      clientManager: client.manager_name,
      clientManagerEmail: client.manager_email,
      status: client.status,
    }));
  }, [data]);

  return {
    clients,
    loading,
  };
};
