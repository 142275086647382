import React, { FC, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { Localized, useLocalization } from '@fluent/react';
import { setBudgetingState, useBudgetingState } from 'common/apolloState/budgeting';
import SelectFiled from 'form/SelectField';
import {
  ConsultantSectionItem,
  ExpensesSectionItem,
  VacancySectionItem,
} from 'pages/Budgeting/Budgeting.types';
import { COPY_BTN_FTL } from 'pages/Budgeting/BudgetingHeader/BudgetingHeader.ftl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import { CANCEL_FTL, CONSULTANTS_FTL, EXPENSES_FTL, VACANCIES_FTL } from 'valtech-core/common/ftl';
import { BUDGETING_ROUTE } from 'valtech-core/common/routes';
import { BudgetingGroupsMultipleEnum, FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';
import Typography from 'valtech-core/ui/Typography';

import {
  AssignmentCopyRow,
  ExpenseCopyRow,
  VacancyCopyRow,
  useHandleCopyBudgetingRows,
} from './CopyBudgetingRowsForm.hooks';

import { changeCopyRowsSubmitStatus, getCopyRowErrorMessage } from './CopyBudgetingRowsForm.utils';

import {
  copyAssignmentsTableTitlesCol,
  copyExpensesTableTitlesCol,
  copyVacanciesTableTitlesCol,
} from './CopyBudgetingRowsForm.const';
import { COPY_ROWS_IN_TITLE_FTL } from './CopyBudgetingRowsForm.ftl';
import s from './CopyBudgetingRowsForm.module.scss';
import CopyBudgetingRowsFormTableRecord from './CopyBudgetingRowsFormTable/CopyBudgetingRowsFormTableRecord';

type CopyBudgetingRowsFormProps = {
  onClose: VoidFunction;
  selectedRows: Set<string>;
  consultantsSection: ConsultantSectionItem[];
  vacanciesSection: VacancySectionItem[];
  expensesSection: ExpensesSectionItem[];
};

export type copyRowsStatusType = {
  assignments: number[];
  vacancies: number[];
  expenses: number[];
  errors: { id: number; message: string }[];
};

export enum copyRowSubmitStatusEnum {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export type CopyBudgetingRowsFormSubmitProps = {
  assignments: AssignmentCopyRow[];
  vacancies: VacancyCopyRow[];
  expenses: ExpenseCopyRow[];
  budgetCopyInHolidayCalendarId: string;
  budgetCopyInId: string;
  budgetingSettingId: number;
  projectId: number;
};

const CopyBudgetingRowsForm: FC<CopyBudgetingRowsFormProps> = ({
  onClose,
  selectedRows,
  consultantsSection,
  vacanciesSection,
  expensesSection,
}) => {
  const { l10n } = useLocalization();

  const [budgetingCopyInYear, setBudgetingCopyInYear] = useState<number>(0);
  const [budgetingCopyInId, setBudgetingCopyInId] = useState<number>(0);
  const { projectId, budgetingCopyInSettingsId } = useBudgetingState();

  const methods = useForm<CopyBudgetingRowsFormSubmitProps>();
  const { control, watch, handleSubmit, setValue, getValues } = methods;
  const [fieldsMount, setFieldsMount] = useState({
    [BudgetingGroupsMultipleEnum.Assignments]: false,
    [BudgetingGroupsMultipleEnum.Vacancies]: false,
    [BudgetingGroupsMultipleEnum.Expenses]: false,
  });
  const [copyRowsStatus, setCopyRowsStatus] = useState<copyRowsStatusType>({
    assignments: [],
    vacancies: [],
    expenses: [],
    errors: [],
  });
  const [isCopyBudgetingRowsFormSubmitted, setIsCopyBudgetingRowsFormSubmitted] = useState(false);

  const { fields: assignmentsFields, remove: assignmentsFieldsRemove } = useFieldArray({
    control,
    name: BudgetingGroupsMultipleEnum.Assignments,
  });

  const { fields: vacanciesFields, remove: vacanciesFieldsRemove } = useFieldArray({
    control,
    name: BudgetingGroupsMultipleEnum.Vacancies,
  });

  const { fields: expensesFields, remove: expensesFieldsRemove } = useFieldArray({
    control,
    name: BudgetingGroupsMultipleEnum.Expenses,
  });

  const {
    assignmentsCopyRows,
    vacanciesCopyRows,
    expensesCopyRows,
    budgetingsList,
    budgetings,
    holidayCalendarList,
    loading,
    copyBudgetingRows,
  } = useHandleCopyBudgetingRows({
    selectedRowsIds: selectedRows,
    consultantsSection,
    vacanciesSection,
    expensesSection,
    budgetingCopyInYear,
  });

  useEffect(() => {
    if (
      !assignmentsFields.length &&
      assignmentsCopyRows.length &&
      !fieldsMount[BudgetingGroupsMultipleEnum.Assignments]
    ) {
      setValue(BudgetingGroupsMultipleEnum.Assignments, assignmentsCopyRows, {
        shouldValidate: true,
      });

      setFieldsMount(prevState => ({
        ...prevState,
        [BudgetingGroupsMultipleEnum.Assignments]: true,
      }));
    }
  }, [assignmentsCopyRows]);

  useEffect(() => {
    if (
      !vacanciesFields.length &&
      vacanciesCopyRows.length &&
      !fieldsMount[BudgetingGroupsMultipleEnum.Vacancies]
    ) {
      setValue(BudgetingGroupsMultipleEnum.Vacancies, vacanciesCopyRows, { shouldValidate: true });

      setFieldsMount(prevState => ({
        ...prevState,
        [BudgetingGroupsMultipleEnum.Vacancies]: true,
      }));
    }
  }, [vacanciesCopyRows]);

  useEffect(() => {
    if (
      !expensesFields.length &&
      expensesCopyRows.length &&
      !fieldsMount[BudgetingGroupsMultipleEnum.Expenses]
    ) {
      setValue(BudgetingGroupsMultipleEnum.Expenses, expensesCopyRows, { shouldValidate: true });

      setFieldsMount(prevState => ({
        ...prevState,
        [BudgetingGroupsMultipleEnum.Expenses]: true,
      }));
    }
  }, [expensesCopyRows]);

  const formSubmit: SubmitHandler<CopyBudgetingRowsFormSubmitProps> = useCallback(() => {
    if (projectId && budgetingCopyInSettingsId) {
      copyBudgetingRows(getValues()).then(response => {
        if (response) {
          setCopyRowsStatus(response);
          setBudgetingCopyInId(response.budgetingId);
        }
      });
    }
  }, [onClose]);

  const handleGoToBudgeting = () => {
    window.location.href = BUDGETING_ROUTE.replace(':id', budgetingCopyInId.toString());
  };

  useEffect(() => {
    const isCopyRowsStatusChanged =
      copyRowsStatus.assignments.length ||
      copyRowsStatus.vacancies.length ||
      copyRowsStatus.expenses.length ||
      copyRowsStatus.errors.length;

    if (isCopyRowsStatusChanged) {
      if (copyRowsStatus.assignments.length || copyRowsStatus.errors.length) {
        changeCopyRowsSubmitStatus({
          copyRowsStatus,
          fields: assignmentsFields,
          groupEnum: BudgetingGroupsMultipleEnum.Assignments,
          setValue,
        });
      }

      if (copyRowsStatus.vacancies.length || copyRowsStatus.errors.length) {
        changeCopyRowsSubmitStatus({
          copyRowsStatus,
          fields: vacanciesFields,
          groupEnum: BudgetingGroupsMultipleEnum.Vacancies,
          setValue,
        });
      }

      if (copyRowsStatus.expenses.length || copyRowsStatus.errors.length) {
        changeCopyRowsSubmitStatus({
          copyRowsStatus,
          fields: expensesFields,
          groupEnum: BudgetingGroupsMultipleEnum.Expenses,
          setValue,
        });
      }

      const copyRowsFormSubmittedSuccessfully =
        copyRowsStatus.assignments.length === assignmentsFields.length &&
        copyRowsStatus.vacancies.length === vacanciesFields.length &&
        copyRowsStatus.expenses.length === expensesFields.length &&
        !copyRowsStatus.errors.length;

      setIsCopyBudgetingRowsFormSubmitted(copyRowsFormSubmittedSuccessfully);
    }
  }, [copyRowsStatus]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const hasBudgetChanged = name === 'budgetCopyInId' && type === 'change';
      const selectedBudgetingCopyInSettingsId = Number(value.budgetCopyInId);

      if (hasBudgetChanged && selectedBudgetingCopyInSettingsId && budgetings.length) {
        const selectedBudgeting = budgetings.find(b => b.id === selectedBudgetingCopyInSettingsId);
        setBudgetingCopyInYear(selectedBudgeting.financial_year);
        setBudgetingState({ budgetingCopyInSettingsId: selectedBudgetingCopyInSettingsId });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, budgetings]);

  useEffect(() => {
    return () => {
      setBudgetingState({
        budgetingCopyInSettingsId: null,
      });
    };
  }, []);

  const onCancel = () => {
    setBudgetingState({
      budgetingCopyInSettingsId: null,
    });
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={e => e.preventDefault()}>
        <SpinnerOverlay visible={loading} />

        <Box sx={{ mt: 2, mb: 3 }}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <SelectFiled
              control={control as unknown as FieldControl}
              className={s.headerSelect}
              label={l10n.getString(COPY_ROWS_IN_TITLE_FTL)}
              name='budgetCopyInId'
              data={budgetingsList}
              disabled={false}
              validate={requiredStringValidator}
            />
          </Stack>
        </Box>

        <Box className={!budgetingCopyInYear ? s.disabledArea : null}>
          {assignmentsFields.length ? (
            <Box sx={{ mb: 3 }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
                <Typography variant='h6' component='h4' sx={{ my: 3 }}>
                  {l10n.getString(CONSULTANTS_FTL)}
                </Typography>

                {/* hide 'copy notes' checkbox for now */}
                {/*<Box>*/}
                {/*  <Stack direction="row" spacing={2}>*/}
                {/*    <CheckboxField*/}
                {/*      label={l10n.getString(COPY_NOTES_FTL)}*/}
                {/*      name="copyNotesConsultants"*/}
                {/*      control={control as unknown as FieldControl}*/}
                {/*    />*/}
                {/*  </Stack>*/}
                {/*</Box>*/}
              </Stack>

              <Table className={s.table}>
                <TableHead>
                  <TableRow>
                    {copyAssignmentsTableTitlesCol.map(colTitle => {
                      return (
                        <TableCell key={colTitle.id}>
                          {colTitle.title_l10n && <Localized id={colTitle.title_l10n} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {assignmentsFields &&
                    assignmentsFields.map((assignmentsField, index) => (
                      <CopyBudgetingRowsFormTableRecord
                        key={assignmentsField.id}
                        index={index}
                        record={assignmentsField}
                        group={BudgetingGroupsMultipleEnum.Assignments}
                        holidayCalendarList={holidayCalendarList}
                        onDeleteSelectedRow={assignmentsFieldsRemove}
                        budgetingCopyInYear={budgetingCopyInYear}
                        copyRowStatus={getValues(
                          `${BudgetingGroupsMultipleEnum.Assignments}.${index}.submitStatus`,
                        )}
                        copyRowErrorMessage={getCopyRowErrorMessage(
                          copyRowsStatus.errors,
                          assignmentsField.assignmentId,
                        )}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          ) : null}

          {vacanciesFields.length ? (
            <Box sx={{ mb: 3 }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
                <Typography variant='h6' component='h4' sx={{ my: 3 }}>
                  {l10n.getString(VACANCIES_FTL)}
                </Typography>

                {/*<Box>*/}
                {/*  <Stack direction="row" spacing={2}>*/}
                {/*    <CheckboxField*/}
                {/*      label={l10n.getString(COPY_NOTES_FTL)}*/}
                {/*      name="copyNotesVacancies"*/}
                {/*      control={control as unknown as FieldControl}*/}
                {/*    />*/}
                {/*  </Stack>*/}
                {/*</Box>*/}
              </Stack>

              <Table className={s.table}>
                <TableHead>
                  <TableRow>
                    {copyVacanciesTableTitlesCol.map(colTitle => {
                      return (
                        <TableCell key={colTitle.id}>
                          {colTitle.title_l10n && <Localized id={colTitle.title_l10n} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {vacanciesFields &&
                    vacanciesFields.map((vacanciesField, index) => (
                      <CopyBudgetingRowsFormTableRecord
                        key={vacanciesField.id}
                        index={index}
                        record={vacanciesField}
                        group={BudgetingGroupsMultipleEnum.Vacancies}
                        holidayCalendarList={holidayCalendarList}
                        onDeleteSelectedRow={vacanciesFieldsRemove}
                        budgetingCopyInYear={budgetingCopyInYear}
                        copyRowStatus={getValues(
                          `${BudgetingGroupsMultipleEnum.Vacancies}.${index}.submitStatus`,
                        )}
                        copyRowErrorMessage={getCopyRowErrorMessage(
                          copyRowsStatus.errors,
                          vacanciesField.assignmentId,
                        )}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          ) : null}

          {expensesFields.length ? (
            <Box sx={{ mb: 3 }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
                <Typography variant='h6' component='h4' sx={{ my: 3 }}>
                  {l10n.getString(EXPENSES_FTL)}
                </Typography>

                {/*<Box>*/}
                {/*  <Stack direction="row" spacing={2}>*/}
                {/*    <CheckboxField*/}
                {/*      label={l10n.getString(COPY_NOTES_FTL)}*/}
                {/*      name="copyNotesExpenses"*/}
                {/*      control={control as unknown as FieldControl}*/}
                {/*    />*/}
                {/*  </Stack>*/}
                {/*</Box>*/}
              </Stack>

              <Table className={s.table}>
                <TableHead>
                  <TableRow>
                    {copyExpensesTableTitlesCol.map(colTitle => {
                      return (
                        <TableCell key={colTitle.id}>
                          {colTitle.title_l10n && <Localized id={colTitle.title_l10n} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {expensesFields &&
                    expensesFields.map((expensesField, index) => (
                      <CopyBudgetingRowsFormTableRecord
                        key={expensesField.id}
                        index={index}
                        record={expensesField}
                        group={BudgetingGroupsMultipleEnum.Expenses}
                        holidayCalendarList={holidayCalendarList}
                        onDeleteSelectedRow={expensesFieldsRemove}
                        budgetingCopyInYear={budgetingCopyInYear}
                        copyRowStatus={getValues(
                          `${BudgetingGroupsMultipleEnum.Expenses}.${index}.submitStatus`,
                        )}
                        copyRowErrorMessage={getCopyRowErrorMessage(
                          copyRowsStatus.errors,
                          expensesField.assignmentId,
                        )}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </Box>

        <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
          {isCopyBudgetingRowsFormSubmitted ? (
            <Button variant='contained' type='button' onClick={handleGoToBudgeting}>
              {l10n.getString(`Go to ${budgetingCopyInYear} budget`)}
            </Button>
          ) : (
            <Button
              variant='contained'
              type='button'
              disabled={!budgetingCopyInYear}
              onClick={handleSubmit(formSubmit)}>
              {l10n.getString(COPY_BTN_FTL)}
            </Button>
          )}

          <Button onClick={onCancel} type='button'>
            {l10n.getString(CANCEL_FTL)}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CopyBudgetingRowsForm;
