import React, { FC, useCallback, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { getFormattedDate } from 'common/utils/formatDate';
import Modal from 'components/Modal';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import {
  CANCEL_FTL,
  CONFIRMATION_MESSAGE_FTL,
  DELETE_FTL,
  TIMESHEET_FTL,
} from 'valtech-core/common/ftl';
import { OrderDirection } from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';
import { useToggle } from 'valtech-core/utils/useToggle';

import { useDeleteTimesheetFile } from '../hooks/DeleteTimesheetFile.hook';
import { IUseTimesheetReturn } from '../hooks/Timesheet.hook';
import s from './BudgetingTable.module.scss';
import { headData } from './TimesheetSettingsTable.consts';

const TimesheetSettingsTable: FC<IUseTimesheetReturn> = ({
  page,
  setPage,
  setRowsPerPage,
  timesheet,
  rowsPerPage,
  loading,
  total,
  sortingType,
  setSortingType,
  setModel,
  setSortBy,
}) => {
  const { l10n } = useLocalization();
  const [activeColumn, setActiveColumn] = useState(TIMESHEET_FTL);
  const [isModalOpened, toggleDelete] = useToggle(false);
  const [selectedTimesheetId, setSelectedTimesheetId] = useState<number | null>(null);
  const deleteTimesheet = useDeleteTimesheetFile();
  const isData = !loading && timesheet.length > 0;

  const deleteItem = async () => {
    if (selectedTimesheetId !== null) {
      await deleteTimesheet({ timesheetId: selectedTimesheetId });
      toggleDelete();
    }
  };

  const handleDeleteClick = (timesheetId: number) => {
    setSelectedTimesheetId(timesheetId);
    toggleDelete();
  };

  const handleSorting = useCallback(
    item => {
      setActiveColumn(item.l10n);
      setModel(item.model);
      setSortBy(item.sortBy);
      setSortingType(OrderDirection.Desc);

      if (item.l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [activeColumn, sortingType],
  );

  return (
    <>
      <Modal handleCloseModal={toggleDelete} openModal={isModalOpened}>
        <Stack gap={2} alignItems='center'>
          <Typography component='p' variant='h5'>
            {l10n.getString(CONFIRMATION_MESSAGE_FTL)}
          </Typography>
          <Stack direction='row' spacing={2}>
            <Button onClick={deleteItem} variant='contained' color='error'>
              {l10n.getString(DELETE_FTL)}
            </Button>
            <Button onClick={toggleDelete}>{CANCEL_FTL}</Button>
          </Stack>
        </Stack>
      </Modal>
      <TableContainer component={Paper} className='tableContainer-xKiller'>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'absolute',
                top: '52px',
                left: 0,
              },
            }}
          />
        )}
        <Table aria-label='simple table'>
          <TableHead className={s.tableHead}>
            <TableRowReusable
              headData={headData}
              sortingType={sortingType}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
            />
          </TableHead>

          <TableBody className={s.tableBody}>
            {loading && !timesheet ? (
              <TableSkeleton
                cellsNumber={headData.length}
                rowsNumber={rowsPerPage}
                page={page}
                total={total}
              />
            ) : (
              timesheet?.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.userInfo}</TableCell>
                  <TableCell>
                    {getFormattedDate(row.uploadTime as string, DateMask.DD_MM_YYYY_HH)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left' }}>
                    <IconButton aria-haspopup='true' onClick={() => handleDeleteClick(row.id)}>
                      <DeleteIcon color='primary' fontSize='medium' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
              count={total}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              isData={isData}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default TimesheetSettingsTable;
