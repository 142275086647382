import { useCallback, useEffect, useState } from 'react';

type UseSelectedReturn = {
  selected: Set<string>;
  selectById: (id: string) => void;
  selectAll: () => void;
  unselectAll: VoidFunction;
  countAll: ([]: any) => void;
  allSelectItems: Set<string>;
  setAllSelectItems: (value: any) => void;
  areAllItemsSelected: boolean;
};

export function useSelected(): UseSelectedReturn {
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [allSelectItems, setAllSelectItems] = useState<Set<string>>(new Set());
  const [areAllItemsSelected, setAllItemsSelected] = useState<boolean>(false);

  useEffect(() => {
    setAllItemsSelected(selected.size !== 0 && selected.size === allSelectItems.size);
  }, [selected]);

  const unselectAll = useCallback((): void => {
    setSelected(new Set());
  }, []);

  const countAll = useCallback((items: Set<string>): void => {
    setAllSelectItems(items);
  }, []);

  const selectAll = () => {
    setSelected(new Set(allSelectItems));
  };

  const selectById = useCallback(
    (id: string): void => {
      if (selected.has(id)) {
        selected.delete(id);
        setSelected(new Set(selected));
      } else {
        setSelected(new Set(selected.add(id)));
      }
    },
    [selected],
  );

  return {
    selected,
    selectById,
    selectAll,
    unselectAll,
    countAll,
    allSelectItems,
    setAllSelectItems,
    areAllItemsSelected,
  };
}
