import { sortBy } from 'lodash';

export type TotalData = {
  month: number;
  total: number;
};
type TotalByEntities = {
  data: TotalData[];
  entityName: string;
};

export type TransformedEntitiesTotalData = {
  monthlyTotalPerEntity: MonthlyTotalPerEntity;
  entities: string[];
};
type EntityTotalByMonth = {
  entityName: string;
  total: number;
};

type MonthlyTotalPerEntity = {
  month: number;
  data: EntityTotalByMonth[];
}[];

export const transformEntitiesTotalData = (
  originalTotal: TotalByEntities[],
): MonthlyTotalPerEntity => {
  const transformedData = originalTotal.reduce(
    (acc: MonthlyTotalPerEntity, curr: TotalByEntities) => {
      curr.data.forEach(entry => {
        const existingEntry = acc.find(item => item.month === entry.month);
        if (existingEntry) {
          existingEntry.data.push({ total: entry.total, entityName: curr.entityName });
        } else {
          acc.push({
            month: entry.month,
            data: [{ total: entry.total, entityName: curr.entityName }],
          });
        }
      });
      return acc;
    },
    [],
  );

  return sortBy(transformedData, ['month']);
};
