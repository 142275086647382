import React, { FC, ReactNode, memo } from 'react';

import { Link } from 'react-router-dom';

import { Localized } from '@fluent/react';

import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MUIDrawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { ReactComponent as LogoIcon } from 'valtech-core/assets/logo.svg';
import { LOGOUT_FTL } from 'valtech-core/common/ftl';
import { LOGOUT_ROUTE } from 'valtech-core/common/routes';
import { DrawerTypeEnum } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';

import styles from './Drawer.module.scss';
import { DrawerItemsList } from './Drawer.types';
import DrawerList from './DrawerList';

interface IDrawerProps {
  boxProps?: BoxProps;
  drawerProps?: DrawerProps;
  drawerItemsList: DrawerItemsList[];
  drawerWidth?: number;
  mainContentWrapperProps?: BoxProps;
  children?: ReactNode;
  type?: DrawerTypeEnum;
  activeItemOnLoad?: string;
  clearState?: boolean;
}

const Drawer: FC<IDrawerProps> = ({
  boxProps,
  drawerItemsList,
  drawerWidth,
  mainContentWrapperProps,
  drawerProps,
  children,
  type = DrawerTypeEnum.Button,
  activeItemOnLoad,
  clearState,
}) => {
  return (
    <Box sx={{ display: 'flex' }} {...boxProps}>
      <MUIDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        anchor='left'
        {...drawerProps}
      >
        <Toolbar sx={{ minHeight: '60px !important' }}>
          <LogoIcon className={styles.logo} />
        </Toolbar>
        <Divider
          sx={{
            borderBlockStartWidth: '1px',
          }}
        />
        <List disablePadding>
          <DrawerList
            type={type}
            data={drawerItemsList}
            activeItemOnLoad={activeItemOnLoad}
            clearState={clearState}
          />
        </List>
        <Toolbar
          sx={{
            marginTop: 'auto',
          }}
        >
          <Button component={Link} to={LOGOUT_ROUTE} type='reset' color='inherit'>
            <Stack direction='row' alignItems='center' spacing={'5px'}>
              <LogoutIcon />
              <Typography textTransform='none'>
                <Localized id={LOGOUT_FTL} />
              </Typography>
            </Stack>
          </Button>
        </Toolbar>
      </MUIDrawer>
      {children && (
        <Box
          component='div'
          sx={{ flexGrow: 1, bgcolor: 'background.default' }}
          {...mainContentWrapperProps}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default memo(Drawer);
