import { useCallback } from 'react';

import { showGenericError } from 'common/apolloState/system';
import { DEFAULT_SKIP_QUANTITY, MAX_QUANTITY } from 'src/common/constants';

import {
  BillingStatus,
  InputMaybe,
  useGetArrayOfUsersQuery,
  useUpdateBillingReportMutation,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

type UpdateAssignmentStatus = (
  billingReportId: Maybe<number>,
  status: InputMaybe<BillingStatus>,
) => Promise<boolean | undefined>;

interface IUseSingleBillingHeaderReturn {
  loading: boolean;
  updateAssignmentStatus: UpdateAssignmentStatus;
  getUserName: (userId: number) => Promise<string | undefined>;
}

export const useSingleBillingHeader = (): IUseSingleBillingHeaderReturn => {
  const [updateBillingReportMutation, { loading }] = useUpdateBillingReportMutation({
    onError() {
      showGenericError();
    },
  });

  const { data: usersData, loading: usersLoading } = useGetArrayOfUsersQuery({
    variables: {
      pagination: {
        limit: MAX_QUANTITY,
        skip: DEFAULT_SKIP_QUANTITY,
      },
      filter: [],
    },
    fetchPolicy: 'cache-and-network',
  });

  const updateAssignmentStatus = useCallback(async (billingReportId, status) => {
    try {
      if (billingReportId) {
        const response = await updateBillingReportMutation({
          variables: {
            billingReportId: Number(billingReportId),
            status,
          },
          refetchQueries: ['SingleBillingInfo'],
        });

        return !!response.data?.updateBillingReport;
      }
    } catch (_) {}
  }, []);

  const getUserName = useCallback(
    async (userId: Maybe<number>) => {
      try {
        if (!userId || !usersData?.sendArrayOfUsers?.data?.length) {
          return;
        }

        const userName = usersData?.sendArrayOfUsers?.data?.find(user => user.id === userId)?.name;

        return userName;
      } catch (_) {}
    },
    [usersData, usersLoading],
  );

  return {
    updateAssignmentStatus,
    getUserName,
    loading: loading || usersLoading,
  };
};
