import { SelectedItem } from 'valtech-core/form/Select';

export const getYearsRangeSelect = (): SelectedItem[] => {
  const years: SelectedItem[] = [];
  const year = new Date().getUTCFullYear();
  for (let i = year; i <= year + 2; i++) {
    years.push({ id: i, title: `${i}`, value: `${i}` });
  }

  return years;
};
