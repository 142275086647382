import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showGenericError } from 'common/apolloState/system';

import moment from 'moment';

import {
  useAddOvertimeMutation,
  useDeleteOvertimeByIdMutation,
  useUpdateBillingReportTotalMutation,
} from 'valtech-core/common/gql/generated';

import { FormattedOvertime } from './Ovretimes.utils';

type AddOvertime = (overtimeData: FormattedOvertime) => Promise<number | null | undefined>;
type DeleteOvertime = (id: number, initialTotal: number) => Promise<boolean>;

interface IUseHandleOvertimeReturn {
  addOvertime: AddOvertime;
  deleteOvertime: DeleteOvertime;
  loading: boolean;
}

const useHandleOvertime = (): IUseHandleOvertimeReturn => {
  const { projectId } = useBudgetingState();
  const [addOvertimeMutation, { loading: addingOvertime }] = useAddOvertimeMutation({
    onError() {
      showGenericError();
    },
  });

  const [deleteOvertimeByIdMutation, { loading: deletingOvertime }] = useDeleteOvertimeByIdMutation(
    {
      onError() {
        showGenericError();
      },
    },
  );

  const [updateBillingReportTotalMutation, { loading: updateBillingReportLoading }] =
    useUpdateBillingReportTotalMutation({
      onError() {
        showGenericError();
      },
    });

  const { id: billingId } = useParams();

  const addOvertime: AddOvertime = useCallback(async overtimeData => {
    if (overtimeData.billing_report_row_id && projectId) {
      const overtime = await addOvertimeMutation({
        variables: { ...overtimeData, projectId },
        refetchQueries: ['SingleBillingInfo'],
      });

      const currentDate = moment().format();
      await updateBillingReportTotalMutation({
        variables: {
          billingReportId: Number(billingId),
          modifiedDate: currentDate,
          total: Number(overtimeData.total),
        },
        refetchQueries: ['SingleBillingInfo'],
      });

      return overtime.data?.addOvertime.id;
    }

    return null;
  }, []);

  const deleteOvertime = useCallback(async (id: number, initialTotal: number) => {
    if (id) {
      const deletedOvertime = await deleteOvertimeByIdMutation({
        variables: { overtimeId: id },
        refetchQueries: ['SingleBillingInfo'],
      });

      const currentDate = moment().format();
      await updateBillingReportTotalMutation({
        variables: {
          billingReportId: Number(billingId),
          modifiedDate: currentDate,
          total: initialTotal,
        },
        refetchQueries: ['SingleBillingInfo'],
      });

      return !!deletedOvertime.data;
    }

    return false;
  }, []);

  return {
    addOvertime,
    deleteOvertime,
    loading: addingOvertime || deletingOvertime || updateBillingReportLoading,
  };
};

export default useHandleOvertime;
