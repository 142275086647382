import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { showSystemError } from 'common/apolloState/system';
import useHandleSorting from 'common/hooks/useHandleSorting';
import { handleSortingParams } from 'common/hooks/useHandleSorting/useHandleSorting';

import {
  ActivationStatus,
  useDeleteAssociatedClientItemByIdMutation,
  useGetArrayOfClientsQuery,
  useUpdateAssociatedClientByIdMutation,
} from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { IAssociatedClientsTable } from './AssociatedClientsTable.const';

interface IAssociatedClients {
  project_id: number;
  id: number;
  manager_email?: string;
  manager_name?: string;
  name: string;
  status: string;
}
interface IUseAssociatedClientsReturn {
  associatedClients: {
    loading: boolean;
    clients: IAssociatedClients[];
    sorting: handleSortingParams;
  };
}

interface IDeleteAssociatedClient {
  clientId: number;
}

export const useAssociatedClients = (
  projectName: string | undefined,
): IUseAssociatedClientsReturn => {
  const { id } = useParams();
  const clientsId = Number(id);

  const { sortingType, activeColumn, handleSorting, sortBy } = useHandleSorting({
    modelState: 'associatedClients',
    sortByState: 'name',
    activeColumnState: '',
  });

  if (!clientsId) {
    return {
      associatedClients: {
        loading: false,
        clients: [],
        sorting: {
          sortingType,
          activeColumn,
          handleSorting,
        },
      },
    };
  }

  if (projectName) {
    const { data, loading } = useGetArrayOfClientsQuery({
      variables: {
        getArrayOfClientsId: Number(clientsId),
        sortBy: sortBy,
        sortDirection: sortingType,
      },
      fetchPolicy: 'cache-and-network',
    });

    const clients = useMemo(() => data?.getArrayOfClients || [], [data]);

    return {
      associatedClients: {
        loading,
        clients,
        sorting: {
          sortingType,
          activeColumn,
          handleSorting,
        },
      },
    };
  }

  return {
    associatedClients: {
      loading: false,
      clients: [],
      sorting: {
        sortingType,
        activeColumn,
        handleSorting,
      },
    },
  };
};

export const useUpdateAssociatedClient = (): ((
  associatedClient: IAssociatedClientsTable,
) => Promise<unknown>) => {
  const { id: userId } = useParams();
  const [updateAssociatedClient] = useUpdateAssociatedClientByIdMutation();

  return (associatedClient: IAssociatedClientsTable): Promise<unknown> => {
    const { id: associatedClientId, status, ...rest } = associatedClient;

    return updateAssociatedClient({
      variables: {
        projectId: Number(userId),
        clientId: associatedClientId,
        status: status as ActivationStatus,
        ...rest,
      },
    }).catch(e => {
      showSystemError(getErrorMessage(e.message));
    });
  };
};

export const useDeleteAssociatedClient = (): ((
  associatedClient: IDeleteAssociatedClient,
) => Promise<unknown>) => {
  const [deleteAssociatedClient] = useDeleteAssociatedClientItemByIdMutation();

  return (associatedClient: IDeleteAssociatedClient): Promise<unknown> => {
    const { clientId: clientId } = associatedClient;
    return deleteAssociatedClient({
      variables: {
        clientId: Number(clientId),
      },
      refetchQueries: ['getArrayOfClients'],
    }).catch(e => {
      showSystemError(getErrorMessage(e.message));
    });
  };
};
