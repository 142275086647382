import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { UseFormReturn } from 'react-hook-form';

import { showGenericError } from 'common/apolloState/system';
import round from 'lodash/round';
import {
  IRateTable,
  defaultValues,
} from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/RateTable/RateTable.constants';
import { v4 as uuidv4 } from 'uuid';

import { ActivationStatus, useCreateRateMutation } from 'valtech-core/common/gql/generated';

interface IUseAddRateReturn {
  addRate: VoidFunction;
  loading: boolean;
}

export interface IUseAddRateParams {
  append: (item: IRateTable) => void;
  methods: UseFormReturn<Omit<IRateTable, 'id'>>;
}

export const useAddRate = ({ append, methods }: IUseAddRateParams): IUseAddRateReturn => {
  const { trigger, getValues, reset } = methods;
  const [createRate, { loading }] = useCreateRateMutation();
  const { id: budgetID } = useParams();

  const addRate = useCallback(async () => {
    const values = getValues();

    try {
      const valid = await trigger();
      if (valid) {
        const { title, model, ccy, fee, status, entity } = values;
        const params = {
          title,
          model,
          ccy,
          fee,
          status,
          entity,
        };

        if (budgetID) {
          const res = await createRate({
            variables: {
              data: [
                {
                  budgeting_setting_id: Number(budgetID),
                  title,
                  fee: round(Number(fee), 2),
                  model,
                  ccy,
                  status: status as ActivationStatus,
                  entity,
                },
              ],
            },
          });
          const rateId = res?.data?.createRate[0].id;
          rateId &&
            append({
              id: rateId,
              ...params,
            });
        } else {
          append({
            id: uuidv4(),
            ...params,
          });
        }
        reset({ ...defaultValues });
      }
    } catch (e) {
      showGenericError();
    }
  }, [budgetID]);

  return {
    loading,
    addRate,
  };
};
