import React, { FC, ReactNode, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import get from 'lodash/get';

import { BoxProps } from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import FormError from 'valtech-core/form/FormError';
import TextInput from 'valtech-core/form/TextInput';

import styles from './TextField.module.scss';

export interface ITextFieldProps {
  control: FieldControl;
  name: string;
  label?: string;
  defaultValue?: string;
  boxProps?: BoxProps;
  validate?: (value: string) => Promise<Maybe<string>>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlurCallback?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  className?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  textProps?: TextFieldProps;
  autoComplete?: string;
  readOnly?: boolean;
  disabled?: boolean;
  integerInput?: boolean;
  acceptOnlyPositiveNumbers?: boolean;
  endAdornment?: ReactNode;
  emptyValToZero?: boolean;
  enableEndAdornment?: boolean;
  shrink?: boolean;
  maxLength?: number;
}

const TextFiled: FC<ITextFieldProps> = ({
  name,
  control,
  label,
  defaultValue = '',
  boxProps,
  className,
  validate,
  textProps,
  onFocus,
  variant,
  onBlurCallback,
  autoComplete,
  readOnly,
  onKeyPress,
  disabled,
  endAdornment,
  acceptOnlyPositiveNumbers,
  integerInput,
  emptyValToZero,
  enableEndAdornment,
  shrink,
  maxLength,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <TextInput
          disabled={disabled}
          boxProps={boxProps}
          textProps={{
            ...textProps,
            ...field,
            label,
            error: !!get(errors, name, false),
            onFocus,
            variant,
            onKeyPress,
          }}
          endAdornment={endAdornment}
          readOnly={readOnly}
          onBlurCallback={onBlurCallback}
          className={cn(styles.inputError, className)}
          autoComplete={autoComplete}
          acceptOnlyPositiveNumbers={acceptOnlyPositiveNumbers}
          integerInput={integerInput}
          emptyValToZero={emptyValToZero}
          enableEndAdornment={enableEndAdornment}
          shrink={shrink}
          maxLength={maxLength}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </TextInput>
      )}
      rules={{
        validate,
      }}
    />
  );
};

export default memo(TextFiled);
