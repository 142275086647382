import React, { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import { Variant } from 'common/types';
import Modal from 'components/Modal';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';
import { selectMockStatus } from 'src/common/constants';
import { splitString } from 'src/common/utils/splitAndCapitalize';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {
  emailValidator,
  requiredLongStringValidator,
  requiredMidStringValidator,
  requiredStringValidator,
} from 'valtech-core/common/form/validators';
import { CANCEL_FTL, CONFIRMATION_MESSAGE_FTL, DELETE_FTL } from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';
import { useToggle } from 'valtech-core/utils/useToggle';

import { AssociatedClientItemFields } from './AssociatedClientItem.constants';
import clientItem from './AssociatedClientItem.module.scss';

import s from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/shared.module.scss';

export interface IAssociatedClientItem {
  id: number;
  name: string;
  manager_name: string;
  manager_email: string;
  status: string;
}
export interface IDeleteAssociatedClientItem {
  clientId: number;
}

interface AssociatedClientItemProps {
  isLoading: boolean;
  isActive: boolean;
  rowId: number;
  onSave: (value: IAssociatedClientItem) => void;
  onSelect: () => void;
  onCancel: () => void;
  onRemove: (value: number) => void;
  onDelete: (value: IDeleteAssociatedClientItem) => void;
  values: {
    id: number;
    name: string;
    manager_name: string;
    manager_email: string;
    status: string;
  };
}

const AssociatedClientItem: FC<AssociatedClientItemProps> = ({
  rowId,
  isLoading,
  isActive,
  onRemove,
  onSelect,
  onCancel,
  onSave,
  onDelete,
  values: { id, name, manager_name, manager_email, status },
}) => {
  const defaultValues = {
    [AssociatedClientItemFields.Title]: name,
    [AssociatedClientItemFields.ClientManager]: manager_name,
    [AssociatedClientItemFields.Email]: manager_email,
    [AssociatedClientItemFields.Status]: status,
  };

  const [isModalOpened, toggleDelete] = useToggle(false);
  const { l10n } = useLocalization();
  const { id: clientId } = useParams();
  const { control, setValue, trigger, getValues, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [isActive]);

  const cancel = () => {
    reset(defaultValues);
    onCancel();
  };

  const save = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    onSave({
      id,
      name: values[AssociatedClientItemFields.Title],
      manager_name: values[AssociatedClientItemFields.ClientManager],
      manager_email: values[AssociatedClientItemFields.Email],
      status: values[AssociatedClientItemFields.Status],
    });
    onCancel();
  };

  const deleteItem = async () => {
    const isValid = await trigger();

    if (!clientId) {
      onRemove(rowId);
    }

    if (!isValid) return;
    onDelete({
      clientId: id,
    });

    toggleDelete();
  };

  return (
    <>
      <TableRow
        className={cn({
          [clientItem['AssociatedClientItem--active']]: isActive,
        })}>
        {isActive ? (
          <>
            <TableCell>
              <TextField
                name={AssociatedClientItemFields.Title}
                control={control as unknown as FieldControl}
                textProps={{
                  type: 'text',
                }}
                variant={Variant.Filled}
                validate={requiredLongStringValidator}
                className={s.inputField}
                onBlurCallback={e => {
                  setValue(AssociatedClientItemFields.Title, e.target.value.trim());
                  trigger(AssociatedClientItemFields.Title);
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                name={AssociatedClientItemFields.ClientManager}
                control={control as unknown as FieldControl}
                textProps={{
                  type: 'text',
                }}
                variant={Variant.Filled}
                validate={requiredMidStringValidator}
                className={s.inputField}
                onBlurCallback={e => {
                  setValue(AssociatedClientItemFields.ClientManager, e.target.value.trim());
                  trigger(AssociatedClientItemFields.ClientManager);
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                name={AssociatedClientItemFields.Email}
                control={control as unknown as FieldControl}
                textProps={{
                  type: 'text',
                }}
                variant={Variant.Filled}
                validate={emailValidator}
                className={s.inputField}
                onBlurCallback={e => {
                  setValue(AssociatedClientItemFields.Email, e.target.value.trim());
                  trigger(AssociatedClientItemFields.Email);
                }}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                className={s.selectField}
                name={AssociatedClientItemFields.Status}
                data={selectMockStatus}
                control={control as unknown as FieldControl}
                validate={requiredStringValidator}
                variant={Variant.Filled}
              />
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <IconButton aria-haspopup='true' onClick={save}>
                <SaveIcon color='primary' fontSize='medium' />
              </IconButton>
              <IconButton aria-haspopup='true' onClick={cancel}>
                <ClearIcon color='primary' fontSize='medium' />
              </IconButton>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <Typography>{name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{manager_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{manager_email}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{splitString(status, false)}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <IconButton aria-haspopup='true' onClick={onSelect}>
                <EditIcon color='primary' fontSize='medium' />
              </IconButton>
              <IconButton aria-haspopup='true' onClick={toggleDelete}>
                <DeleteIcon color='primary' fontSize='medium' />
              </IconButton>
              <Modal handleCloseModal={toggleDelete} openModal={isModalOpened}>
                <SpinnerOverlay visible={isLoading} />
                <Stack gap={2} alignItems='center'>
                  <Typography component='p' variant='h5'>
                    {l10n.getString(CONFIRMATION_MESSAGE_FTL)}
                  </Typography>
                  <Stack direction='row' spacing={2}>
                    <Button onClick={deleteItem} variant='contained' color='error'>
                      {l10n.getString(DELETE_FTL)}
                    </Button>
                    <Button onClick={toggleDelete}>{CANCEL_FTL}</Button>
                  </Stack>
                </Stack>
              </Modal>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default AssociatedClientItem;
