import React, { FC, ReactNode, useEffect, useState } from 'react';

import { FluentBundle, FluentResource } from '@fluent/bundle';
import { negotiateLanguages } from '@fluent/langneg';
import { LocalizationProvider, ReactLocalization } from '@fluent/react';

import { Dictionary } from 'valtech-core/common/types';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

const DEFAULT_LOCALE = 'en-US';
const localeMap: Dictionary<string> = {
  'en-US': 'en',
  'uk-UA': 'ua',
};
const matchLocale = (locale: string): string => {
  if (!localeMap[locale]) return 'en-US';
  return localeMap[locale];
};

function withBuildID(fileName: string): string {
  const ID = process.env.REACT_APP_BUILD ? `.${process.env.REACT_APP_BUILD}` : '';
  return `${fileName}${ID}.ftl`;
}

async function fetchMessages(locale: string): Promise<[string, string]> {
  const fileName = withBuildID(`/locales/${matchLocale(locale)}`);

  const response = await fetch(fileName);

  const messages = await response.text();

  return [locale, messages];
}

function* lazilyParsedBundles(fetchedMessages: Array<[string, string]>): Iterable<FluentBundle> {
  for (const [locale, messages] of fetchedMessages) {
    const resource = new FluentResource(messages);
    const bundle = new FluentBundle(locale);
    bundle.addResource(resource);
    yield bundle;
  }
}

export let l10n: ReactLocalization | null = null;

interface IProps {
  children?: ReactNode;
}

export const AppLocalizationProvider: FC<IProps> = ({ children }) => {
  // eslint-disable-next-line
  const [_, setCurrentLocales] = useState<string[]>([DEFAULT_LOCALE]);
  const [l10nInstance, setL10nInstance] = useState<null | ReactLocalization>(null);

  useEffect(() => {
    changeLocales(navigator.languages as Array<string>);
  }, []);

  async function changeLocales(userLocales: Array<string>): Promise<void> {
    const currentLocales = negotiateLanguages(userLocales, Object.keys(localeMap), {
      defaultLocale: DEFAULT_LOCALE,
    });

    setCurrentLocales(currentLocales);

    const fetchedMessages = await Promise.all(currentLocales.map(fetchMessages));

    const bundles = lazilyParsedBundles(fetchedMessages);
    const newLocalization = new ReactLocalization(bundles);
    l10n = newLocalization;
    setL10nInstance(newLocalization);
  }

  if (l10nInstance === null) {
    return <SpinnerOverlay visible={true} />;
  }

  return <LocalizationProvider l10n={l10nInstance}>{children}</LocalizationProvider>;
};
