import React, { FC, useMemo } from 'react';

import Modal from 'components/Modal';
import AccountForm from 'src/components/_forms/AccountForm';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useHandleAccountsTableRowEdit } from './HandleAccountsTableRowEdit.hooks';

import { Account } from '../AllAccounts.types';

interface HandleTableRowEditProps {
  rowRecord: Account;
}

const HandleAccountsTableRowEdit: FC<HandleTableRowEditProps> = ({ rowRecord }) => {
  const { isOpened, toggleForm, getAccountInitialValues } = useHandleAccountsTableRowEdit({
    rowRecord,
  });

  const displayForm = useMemo(() => {
    return (
      <Box
        sx={{
          width: 600,
        }}>
        <AccountForm
          onClose={toggleForm}
          initialValues={getAccountInitialValues(rowRecord as Account)}
          shortName={rowRecord.id}
        />
      </Box>
    );
  }, [rowRecord]);

  return (
    <>
      <IconButton
        aria-haspopup='true'
        onClick={toggleForm}
        sx={{
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}>
        <EditIcon color='primary' fontSize='small' />
      </IconButton>
      <Modal handleCloseModal={toggleForm} openModal={isOpened}>
        {displayForm}
      </Modal>
    </>
  );
};

export default HandleAccountsTableRowEdit;
