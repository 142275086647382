import { ACCESS_LEVEL_FTL, NAME_FTL } from 'valtech-core/common/ftl';

export enum UserCredentialsEnum {
  ID = 'id',
  Name = 'name',
  AccessLevel = 'role',
  UserId = 'user_id',
}

export const headData = [
  { l10n: NAME_FTL, model: '', sortBy: UserCredentialsEnum.Name },
  { l10n: ACCESS_LEVEL_FTL, model: '', sortBy: UserCredentialsEnum.AccessLevel },
];

type User = {
  id: number | undefined;
  label: string;
};
export interface IUserCredentialsTable {
  id?: number;
  user_id: number | undefined;
  role: string;
  name?: User;
}

export const defaultValues: Omit<IUserCredentialsTable, 'id'> = {
  user_id: 0,
  role: '',
};

//TODO: use reusable
export const selectMockAccessLevel = [
  { id: '1', title: 'editing', value: 'editing' },
  { id: '2', title: 'editing & billing', value: 'editing & billing' },
  { id: '3', title: 'view & comment', value: 'view & comment' },
];
