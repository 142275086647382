import { ActivationStatus, BillingStatus, RecordStatus } from 'valtech-core/common/gql/generated';
import { ChipColor, Maybe } from 'valtech-core/common/types';

import { AllTableStatus } from '../types';

export const chipColorPicker = (
  status: Maybe<BillingStatus | ActivationStatus | RecordStatus | AllTableStatus>,
): ChipColor => {
  switch (status) {
    case BillingStatus.Draft:
      return 'warning';

    case AllTableStatus.Draft:
      return 'warning';

    case AllTableStatus.NotActive:
      return 'warning';

    case AllTableStatus.Active:
      return 'success';

    case ActivationStatus.Active:
      return 'success';

    case RecordStatus.Active:
      return 'success';

    case BillingStatus.Invoiced:
      return 'success';

    case ActivationStatus.NotActive:
      return 'default';

    case AllTableStatus.Archived:
      return 'default';

    case RecordStatus.Archived:
      return 'default';

    default:
      return 'default';
  }
};
