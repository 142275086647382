import { DELETE_ERROR_FTL, ERRORS_FTL, GENERIC_ERROR } from 'valtech-core/common/ftl';
import { Maybe } from 'valtech-core/common/types';

export const getErrorMessage = (message: string): string => {
  const errorMsg = parseErrors(message);

  if (ERRORS_FTL[errorMsg || message]) {
    return ERRORS_FTL[errorMsg || message];
  }
  return GENERIC_ERROR;
};

const DELETE_ERROR_MSG = "can't be deleted";

export function parseErrors(message: string): Maybe<string> {
  const isDeleteError = message.includes(DELETE_ERROR_MSG);

  if (isDeleteError) return DELETE_ERROR_FTL;

  return;
}
