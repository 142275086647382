import { FC, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { APPLICATION_TITLE } from 'src/common/constants';

import { parseUrl } from './PageTitle.utils';

const PageTitle: FC = () => {
  const location = useLocation();

  const title = useMemo(
    () => parseUrl(location?.pathname, APPLICATION_TITLE),
    [location?.pathname],
  );

  useEffect(() => {
    document.title = title;
  }, [location?.pathname]);

  return null;
};

export default PageTitle;
