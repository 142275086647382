import React, { FC } from 'react';

import { useAllEntities } from './AllEntities.hook';
import AllEntitiesHeader from './AllEntitiesHeader';
import AllEntitiesTable from './AllEntitiesTable';

const AllEntities: FC = () => {
  const allEntitiesHookData = useAllEntities();

  return (
    <>
      <AllEntitiesHeader />
      <AllEntitiesTable {...allEntitiesHookData} />
    </>
  );
};
export default AllEntities;
