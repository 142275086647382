import React, { FC, useCallback, useEffect, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { setBudgetingState, useBudgetingState } from 'common/apolloState/budgeting';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import { getFormattedDate } from 'common/utils/formatDate';
import Modal from 'components/Modal';
import AssignmentForm, { IAssignmentFormProps } from 'components/_forms/AssignmentForm';
import CopyBudgetingRowsForm from 'components/_forms/CopyBudgetingRowsForm';
import ExpenseForm from 'components/_forms/ExpenseForm';
import VacancyForm from 'components/_forms/VacancyForm';
import { ConsultantModelEnum } from 'src/common/types';
import ForecastForm from 'src/components/_forms/ForecastForm';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import { ADD_FTL } from 'valtech-core/common/ftl';
import {
  BudgetType,
  RecordStatus,
  useGetBudgetTypesQuery,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum, DateMask } from 'valtech-core/common/types';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import Select from 'valtech-core/form/Select';
import authStorage from 'valtech-core/services/auth/storage';
import Back from 'valtech-core/ui/Back';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import Dropdown from 'valtech-core/ui/Dropdown';
import Skeleton from 'valtech-core/ui/Skeleton';
import Typography from 'valtech-core/ui/Typography';
import { useToggle } from 'valtech-core/utils/useToggle';

import { useUpdateBudget } from './BudgetingHeader.hooks';

import {
  IDataBudgetTypes,
  formattedForAutocompleteBudgetTypesData,
  formattedForSelectBudgetTypesData,
} from './BudgetingHeader.utils';

import {
  BudgetingInfo,
  ConsultantSectionItem,
  ExpensesSectionItem,
  VacancySectionItem,
} from '../Budgeting.types';
import {
  ASSIGNMENT_FTL,
  COPY_BTN_FTL,
  CREATE_FORECAST_FTL,
  EXPENSE_FTL,
  OPERATIONAL_FORECAST_CAMEL_CASE_FTL,
  OPERATIONAL_FORECAST_FTL,
  SUBMIT_BUDGET_FTL,
  SUBMIT_BUDGET_MESSAGE_FTL,
  UNDO_SUBMIT,
  VACANCY_FTL,
} from './BudgetingHeader.ftl';
import s from './BudgetingHeader.module.scss';
import SubmitModal from './SubmitModal';

type BudgetingModals = {
  assignmentFormModal: boolean;
  vacancyFormModal: boolean;
  expenseFormModal: boolean;
  copyRowsModal: boolean;
  forecastFormModal: boolean;
};

type existingBudget = {
  id: number;
  title: string;
  value: string;
};

type Props = BudgetingInfo & {
  consultantsSection: ConsultantSectionItem[];
  vacanciesSection: VacancySectionItem[];
  expensesSection: ExpensesSectionItem[];
  loading: boolean;
  onSelectRowsForCopying: VoidFunction;
  areAllRowsSelected: boolean;
  selectedForCopy?: Set<string>;
  readOnly: boolean;
};

const BudgetingHeader: FC<Props> = ({
  title,
  status,
  lastModified,
  modifiedBy,
  loading,
  selectedForCopy,
  consultantsSection,
  vacanciesSection,
  expensesSection,
  areAllRowsSelected,
  onSelectRowsForCopying,
  existingBudgets,
  projectId,
  budgetingSettingId,
  budgetId,
  readOnly,
}) => {
  const { l10n } = useLocalization();
  const [isSubmitOpened, toggleSubmit] = useToggle(false);
  const userRole = authStorage.getUserInfo();
  const { assignmentDefaults, currency, forecastType, accessLevel } = useBudgetingState();
  const userAccessLevel = getAccessLevel(accessLevel, l10n);

  const { data: budgetTypesData, loading: loadingTypes } = useGetBudgetTypesQuery();

  const [budgetTypes, setBudgetTypes] = useState<IDataBudgetTypes[]>([]);

  useEffect(() => {
    if (budgetTypesData) {
      setBudgetTypes(budgetTypesData.sendArrayOfBudgetTypes);
    }
  }, [budgetTypesData]);

  const { updateBudget } = useUpdateBudget();

  const nonExistingBudgetTypes = formattedForAutocompleteBudgetTypesData(budgetTypes).filter(
    budgetType =>
      !existingBudgets?.some(item => item.type === budgetType.label) &&
      budgetType.label !== l10n.getString(OPERATIONAL_FORECAST_CAMEL_CASE_FTL),
  );
  const existingBudgetTypes = formattedForSelectBudgetTypesData(budgetTypes).reduce(
    (result: existingBudget[], budgetType) => {
      const matchedBudget = existingBudgets?.find(item => item.type === budgetType.value);
      if (
        existingBudgets?.some(item => item.type === budgetType.value) ||
        budgetType.value === l10n.getString(OPERATIONAL_FORECAST_FTL)
      ) {
        result.push({
          ...budgetType,
          id:
            budgetType.value === l10n.getString(OPERATIONAL_FORECAST_FTL)
              ? budgetId || budgetType.id
              : matchedBudget?.id || budgetType.id,
        });
      }
      return result;
    },
    [],
  );

  const [openModal, setOpenModal] = React.useState<BudgetingModals>({
    assignmentFormModal: false,
    vacancyFormModal: false,
    expenseFormModal: false,
    copyRowsModal: false,
    forecastFormModal: false,
  });

  const getDefaultCostOfOverhead = useCallback(() => {
    return currency === CurrencyEnum.EUR
      ? assignmentDefaults.costOfOverhead.EUR
      : assignmentDefaults.costOfOverhead.USD;
  }, [assignmentDefaults, currency]);

  //TODO: temporary solution -> all defaults should come from the API
  const defaultAssignmentValues: Partial<IAssignmentFormProps> = {
    allocation: assignmentDefaults.allocation,
    costOfOverhead: getDefaultCostOfOverhead(),

    model: ConsultantModelEnum.Dedicated,
  };
  const defaultVacancyValues: Partial<IAssignmentFormProps> = {
    allocation: assignmentDefaults.allocation,
    costOfOverhead: getDefaultCostOfOverhead(),
    model: ConsultantModelEnum.Dedicated,
  };

  const toggleModal = (key: keyof BudgetingModals) => {
    setOpenModal(prev => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  const submitBudgeting = useCallback(() => {
    updateBudget({
      budgetId,
      columnName: 'submitted',
      value: true,
    });
    setBudgetingState({
      submitted: true,
    });
    toggleSubmit();
  }, [budgetId]);

  const undoBudgetSubmit = () => {
    updateBudget({
      budgetId,
      columnName: 'submitted',
      value: false,
    });
    setBudgetingState({
      submitted: false,
    });
  };

  const toggleBudgetSubmit = useCallback(() => {
    toggleSubmit();
  }, []);

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    const budgetKeys = Object.keys(BudgetType);
    const selectValue = event.target.value;
    const selectedValue = String(selectValue).replaceAll(' ', '');

    const selectedBudgetType = budgetKeys.find(item => item === selectedValue);

    if (selectedBudgetType) {
      const id = existingBudgetTypes.find(item => item.value === selectedValue)?.id;
      setBudgetingState({
        forecastType: {
          id: id || null,
          type: BudgetType[selectedBudgetType],
        },
      });
    }
  };

  if (loading) {
    return (
      <Box className={s.container}>
        <Skeleton variant='rounded' height={56} width={'100vw'} />
      </Box>
    );
  }

  return (
    <Box className={s.container}>
      <Box className={s.information}>
        <Back className={s.informationIcon} />
        <Box className={s.informationTitle}>
          <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
            {title}
          </Typography>
        </Box>
        <Chip
          size={'medium'}
          label={status || ''}
          variant='outlined'
          color={chipColorPicker(status)}
        />
      </Box>
      <Typography variant='subtitle2' weight='regular' component={'p'} className={s.actionTitle}>
        {`last modified ${getFormattedDate(lastModified, DateMask.DD_MM_YYYY_HH)} ${modifiedBy}`}
      </Typography>
      <Stack direction='row' alignItems='center' spacing={2}>
        <Select
          onChange={onSelectChange}
          defaultValue={
            forecastType?.type &&
            forecastType?.type !== l10n.getString(OPERATIONAL_FORECAST_CAMEL_CASE_FTL)
              ? forecastType.type
              : l10n.getString(OPERATIONAL_FORECAST_FTL)
          }
          boxProps={{
            sx: {
              width: '200px',
              '.MuiSelect-select': {
                paddingY: '7px',
              },
            },
          }}
          data={existingBudgetTypes}
        />
        {status !== RecordStatus.Archived && userAccessLevel.full && (
          <Button onClick={() => toggleModal('forecastFormModal')} variant='contained'>
            {l10n.getString(CREATE_FORECAST_FTL)}
          </Button>
        )}
      </Stack>

      <Divider sx={{ width: '100%', marginY: '10px' }} />

      <Box className={s.controlsRow}>
        <>
          {forecastType?.type === BudgetType.OperationalForecast ? (
            <Stack direction='row' alignItems='center' spacing={2}>
              {userAccessLevel.full && (
                <>
                  <Checkbox
                    checked={areAllRowsSelected}
                    onChange={() => onSelectRowsForCopying()}
                  />

                  <Button
                    variant='outlined'
                    disabled={!selectedForCopy?.size}
                    type='button'
                    onClick={() => toggleModal('copyRowsModal')}>
                    {l10n.getString(COPY_BTN_FTL)}
                  </Button>
                </>
              )}
              {selectedForCopy?.size ? (
                <Typography variant='subtitle2' weight='regular' component={'p'}>
                  {`${selectedForCopy?.size} selected rows`}
                </Typography>
              ) : null}
            </Stack>
          ) : (
            <div></div>
          )}

          {status !== RecordStatus.Archived && userAccessLevel.full && (
            <Stack direction='row' alignItems='center' spacing={2}>
              {!readOnly && (
                <>
                  <Dropdown
                    bntTitle={l10n.getString(ADD_FTL)}
                    btnVariant='outlined'
                    menuPositionVertical='bottom'
                    menuPositionHorizontal='right'
                    menuList={[
                      {
                        id: '1',
                        title: l10n.getString(ASSIGNMENT_FTL),
                        callback: () => {
                          toggleModal('assignmentFormModal');
                        },
                      },
                      {
                        id: '2',
                        title: l10n.getString(EXPENSE_FTL),
                        callback: () => {
                          toggleModal('expenseFormModal');
                        },
                      },
                      {
                        id: '3',
                        title: l10n.getString(VACANCY_FTL),
                        callback: () => {
                          toggleModal('vacancyFormModal');
                        },
                      },
                    ]}
                  />
                  {userAccessLevel.full && (
                    <Button onClick={() => toggleBudgetSubmit()} variant='contained'>
                      {l10n.getString(SUBMIT_BUDGET_FTL)}
                    </Button>
                  )}
                </>
              )}
              {userRole?.role.toLowerCase() === 'administrator' && readOnly && (
                <Button onClick={() => undoBudgetSubmit()} variant='contained'>
                  {l10n.getString(UNDO_SUBMIT)}
                </Button>
              )}
            </Stack>
          )}
        </>
      </Box>

      <Modal handleCloseModal={toggleSubmit} openModal={isSubmitOpened}>
        <SubmitModal
          title={l10n.getString(SUBMIT_BUDGET_FTL)}
          text={l10n.getString(SUBMIT_BUDGET_MESSAGE_FTL)}
          onCancel={toggleSubmit}
          onSubmit={submitBudgeting}
        />
      </Modal>

      <Modal
        handleCloseModal={() => toggleModal('forecastFormModal')}
        openModal={openModal.forecastFormModal}>
        <ForecastForm
          onClose={() => toggleModal('forecastFormModal')}
          budgetTypes={nonExistingBudgetTypes}
          loading={loadingTypes}
          projectId={projectId}
          budgetingSettingId={budgetingSettingId}
        />
      </Modal>

      <Modal
        handleCloseModal={() => toggleModal('assignmentFormModal')}
        openModal={openModal.assignmentFormModal}
        size='mid'
        closeBtn>
        <AssignmentForm
          onClose={() => toggleModal('assignmentFormModal')}
          defaultValues={defaultAssignmentValues}
        />
      </Modal>

      <Modal
        handleCloseModal={() => toggleModal('vacancyFormModal')}
        openModal={openModal.vacancyFormModal}
        size='mid'
        closeBtn>
        <VacancyForm
          onClose={() => toggleModal('vacancyFormModal')}
          defaultValues={defaultVacancyValues}
        />
      </Modal>

      <Modal
        handleCloseModal={() => toggleModal('expenseFormModal')}
        openModal={openModal.expenseFormModal}
        size='mid'
        closeBtn>
        <ExpenseForm onClose={() => toggleModal('expenseFormModal')} />
      </Modal>

      <Modal
        handleCloseModal={() => toggleModal('copyRowsModal')}
        openModal={openModal.copyRowsModal}
        size='big'
        scroll
        closeBtn>
        <CopyBudgetingRowsForm
          selectedRows={selectedForCopy as Set<string>}
          consultantsSection={consultantsSection}
          vacanciesSection={vacanciesSection}
          expensesSection={expensesSection}
          onClose={() => toggleModal('copyRowsModal')}
        />
      </Modal>
    </Box>
  );
};

export default BudgetingHeader;
