import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { IWarningData, SingleBillingTableTypeEnum } from 'common/types';

import { Moment } from 'moment';

import { BillingStatus } from 'valtech-core/common/gql/generated';
import { AnyObject, CurrencyEnum, Maybe } from 'valtech-core/common/types';

import { BillingHistoryItem } from './SingleBilling.utils';

import { IAssignmentsData } from './SingleBilling.types';

export interface IBillingContext {
  accessLevel?: string;
  projectData?: AnyObject;
  tableName: Maybe<SingleBillingTableTypeEnum>;
  activeTabName: string;
  consultantId?: number;
  singleBillingWarnings?: IWarningData[];
  isTabOpened?: boolean;
  billingInfo?: {
    status: Maybe<BillingStatus>;
    projectName: string;
    project_id: Maybe<number>;
    financialYear: number | undefined;
    title: string;
    currencySign: Maybe<string>;
    currency: CurrencyEnum;
    billingStartDay: Maybe<Moment>;
    billingEndDay: Maybe<Moment>;
    billingMonth: Maybe<number>;
    workingHoursPerMonth: Maybe<number>;
    assignmentsCurrencyData: IAssignmentsData[];
  };
  billingHistory: BillingHistoryItem[];
}

const BillingStateContext = createContext<{
  state: Partial<IBillingContext>;
  setState: React.Dispatch<React.SetStateAction<IBillingContext>>;
}>({
  state: {} as Partial<IBillingContext>,
  setState: {} as Dispatch<SetStateAction<IBillingContext>>,
});

interface SingleBillingContextProviderProps {
  children?: ReactNode;
}

const SingleBillingContextProvider: FC<SingleBillingContextProviderProps> = ({ children }) => {
  const [state, setState] = useState<IBillingContext>({
    projectData: {},
    tableName: undefined,
    activeTabName: '',
    consultantId: undefined,
    isTabOpened: false,
    billingInfo: undefined,
    billingHistory: [],
  });

  return (
    <BillingStateContext.Provider value={{ state, setState }}>
      {children}
    </BillingStateContext.Provider>
  );
};

type UseBillingStateReturn = {
  state: Partial<IBillingContext>;
  setState: React.Dispatch<React.SetStateAction<IBillingContext>>;
  setStateParam: (stateParam: Partial<IBillingContext>) => void;
};

const useBillingState = (): UseBillingStateReturn => {
  const context = useContext(BillingStateContext);
  if (!context) {
    throw new Error('useBillingState must be used within a BillingStateContext');
  }
  const setStateParam = (stateParam: Partial<IBillingContext>): void => {
    context.setState(prev => ({ ...prev, ...stateParam }));
  };
  return {
    ...context,
    setStateParam,
  };
};

export { SingleBillingContextProvider, useBillingState };
