import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { FilterModelEnum, TableSearchFieldsEnum } from 'common/types';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import { updateTableFilters } from 'common/utils/updateTableFilters';
import SearchInput from 'components/SearchInput';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';
import { IUseBillingsReturn } from 'pages/AllBillings/AllBillings.hook';
import { PROJECT_DEFAULT_OBJ, PageTypes } from 'src/common/apolloState/filters/filters';
import { clearFiltersState } from 'src/common/apolloState/filters/filters.actions';
import { handleSearchInput } from 'src/common/utils/handleSearchModel';

import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { BILL_FTL, CLEAR_ALL_FTL, DELIVERY_MANAGER_FTL } from 'valtech-core/common/ftl';
import { OrderDirection } from 'valtech-core/common/gql/generated';
import { BILLING_ROUTE } from 'valtech-core/common/routes';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear/ProgressLinear';

import { headData } from './AllBillingsTable.const';
import s from './AllBillingsTable.module.scss';

interface IAllBillingsTableProps extends IUseBillingsReturn {
  drawerClearState: VoidFunction;
}

const AllBillingsTable: FC<IAllBillingsTableProps> = ({
  page,
  setPage,
  billings,
  setRowsPerPage,
  rowsPerPage,
  loading,
  total,
  sortingType,
  setSortingType,
  setModel,
  setSortBy,
  tableFilters,
  setTableFilters,
  setFilter,
  setProjectFilter,
  drawerClearState,
}) => {
  const { l10n } = useLocalization();

  const [activeColumn, setActiveColumn] = useState(DELIVERY_MANAGER_FTL);
  const [isInputClear, setIsInputClear] = useState(true);

  const isData = !loading && billings.length > 0;

  const navigate = useNavigate();

  const handleSorting = useCallback(
    ({ l10n, model, sortBy }) => {
      setActiveColumn(l10n);
      setModel(model);
      setSortBy(sortBy);
      setSortingType(OrderDirection.Desc);

      if (l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [sortingType, activeColumn, sortingType],
  );

  const handleSearch = useCallback(data => {
    if (data.fieldName === BILL_FTL) {
      updateTableFilters({
        inputQuery: data.query,
        field: TableSearchFieldsEnum.Title,
        setTableFilters,
      });
    }

    if (data.fieldName === DELIVERY_MANAGER_FTL) {
      updateTableFilters({
        inputQuery: data.query,
        field: TableSearchFieldsEnum.Name,
        filterModel: FilterModelEnum.DeliveryManager,
        setTableFilters,
      });
    }

    return;
  }, []);

  const handleClearFilters = () => {
    setFilter([]);
    setTableFilters([]);
    setProjectFilter(PROJECT_DEFAULT_OBJ);
    clearFiltersState(PageTypes.BILLING)();
    setSortingType(OrderDirection.Desc);
    setModel(FilterModelEnum.Manager);
    setSortBy(TableSearchFieldsEnum.Name);
    setActiveColumn(DELIVERY_MANAGER_FTL);
    setIsInputClear(false);
    drawerClearState();
  };

  const navigateToPage = useCallback((id: number) => {
    navigate(BILLING_ROUTE.replace(':id', id.toString()));
  }, []);

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead className={s.tableHead}>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />
          <TableRow>
            {headData.map(item => {
              const Searchable = item.l10n === DELIVERY_MANAGER_FTL || item.l10n === BILL_FTL;

              return (
                <TableCell key={item.l10n}>
                  {Searchable && (
                    <SearchInput
                      placeholder='Search'
                      fieldName={item.l10n}
                      onSearch={handleSearch}
                      inputDefaultValue={handleSearchInput(tableFilters, item)}
                      boxProps={{ sx: { maxWidth: '350px' } }}
                      size='small'
                      isInputClear={isInputClear}
                      onClear={() => setIsInputClear(true)}
                    />
                  )}
                  {item.l10n === 'total' && (
                    <Button
                      type='button'
                      size='small'
                      variant='contained'
                      onClick={handleClearFilters}>
                      {l10n.getString(CLEAR_ALL_FTL)}
                    </Button>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={s.tableBody}>
          {loading ? (
            <TableSkeleton
              cellsNumber={headData.length}
              rowsNumber={rowsPerPage}
              page={page}
              total={total}
            />
          ) : (
            billings.map(row => (
              <TableRow
                key={row.id}
                onClick={() => navigateToPage(row.id)}
                sx={{ cursor: 'pointer' }}>
                <TableCell>{row.billingsTitle}</TableCell>
                <TableCell>{row.project}</TableCell>
                <TableCell>{row.manager}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    className='text-capitalized'
                    label={row.status}
                    variant='outlined'
                    color={chipColorPicker(row.status)}
                  />
                </TableCell>
                <TableCell>{`${createNumberWithCurrency({
                  number: row.total,
                  currencyDisplay: 'symbol',
                  currency: row.currency,
                })}`}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            count={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            isData={isData}
          />
        </TableFooter>
      </Table>

      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          }}
        />
      )}
    </TableContainer>
  );
};

export default AllBillingsTable;
