import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import Modal from 'components/Modal';
import SettingsUserForm from 'components/_forms/SettingsUserForm';

import Box from '@mui/material/Box';

import { ADD_FTL, USERS_FTL, USER_FTL } from 'valtech-core/common/ftl';
import Back from 'valtech-core/ui/Back';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import s from './AllUsersHeader.module.scss';

type SettingsUserModal = {
  settingsUserModal: boolean;
};

const AllUsersHeader: FC = () => {
  const { l10n } = useLocalization();

  const [openModal, setOpenModal] = React.useState<SettingsUserModal>({
    settingsUserModal: false,
  });

  const toggleModal = (key: keyof SettingsUserModal) => {
    setOpenModal(prev => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  return (
    <Box className={s.container}>
      <Box className={s.information}>
        <Back className={s.informationIcon} />
        <Box className={s.informationTitle}>
          <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
            {l10n.getString(USERS_FTL)}
          </Typography>
        </Box>
      </Box>
      <Box className={s.action}>
        <Button
          variant='contained'
          sx={{ mx: 1 }}
          type='button'
          onClick={() => toggleModal('settingsUserModal')}>
          {`${l10n.getString(ADD_FTL)} ${USER_FTL}`}
        </Button>
        <Modal
          handleCloseModal={() => toggleModal('settingsUserModal')}
          openModal={openModal.settingsUserModal}>
          <Box
            sx={{
              width: 600,
            }}>
            <SettingsUserForm onClose={() => toggleModal('settingsUserModal')} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AllUsersHeader;
