import { IFilters } from 'valtech-core/common/types';

import { FilterModelEnum, ITableHeaderItem } from '../types';

export const handleSearchInput = (
  tableFilters: IFilters[],
  itemField: ITableHeaderItem,
): string => {
  return (
    tableFilters
      .filter(filter => filter.filterModel !== FilterModelEnum.Project)
      .find(filter => filter.field === itemField.sortBy)?.query || ''
  );
};
