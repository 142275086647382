export function getFirstLastNames(userName: string | undefined): {
  firstName: string;
  lastName: string;
} {
  if (!userName) {
    return {
      firstName: 'User',
      lastName: ' ',
    };
  } else {
    const spaceIndex = userName.indexOf(' ');

    return {
      firstName: userName.slice(0, spaceIndex),
      lastName: userName.slice(spaceIndex + 1),
    };
  }
}
