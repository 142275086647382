import React, { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import SelectFiled from 'form/SelectField';
import TextField from 'form/TextField';
import { FinanceInformationFields } from 'pages/ManageSettings/SingleBudgeting/SingleBudget.constants';
import { useBudgetInfo } from 'pages/ManageSettings/SingleBudgeting/SingleBudgeting.hook';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  requiredLongStringValidator,
  titlePageValidator,
  yearValidator,
} from 'valtech-core/common/form/validators';
import {
  BUDGETING_PROCEED_FTL,
  FINANCIAL_YEAR_FTL,
  PROCEED_FTL,
  STATUS_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import OverheadsCost from '../OverheadsCost/OverheadsCost';
import { useFinancialInformation } from './FinancialInformation.hook';
import s from './FinancialInformation.module.scss';

interface IFinancialInformationProps {
  loading: boolean;
  onProceed: VoidFunction;
}

const FinancialInformation: FC<IFinancialInformationProps> = ({ onProceed, loading }) => {
  const { id: budgetID } = useParams();
  const { l10n } = useLocalization();
  const { control, resetField, setValue, trigger } = useFormContext();
  const { budgetData, getTemplate } = useBudgetInfo();
  const { isEditing } = useEditingContext();

  useEffect(() => {
    !budgetData.loading && setValue('budgetData', budgetData);
    return () => {
      resetField('budgetData', { defaultValue: budgetData });
    };
  }, [budgetData.loading]);

  const { statuses } = useFinancialInformation();

  const onBlurCallback = async e => {
    const isValid = await trigger(`budgetData.data.${FinanceInformationFields.Title}`);
    if (!isValid) return;
    resetField(`budgetData.data.${FinanceInformationFields.Title}`, {
      defaultValue: e.target.value.trim(),
    });
  };

  const updTitle = year => {
    const newYear = year || new Date().getUTCFullYear();
    resetField(`budgetData.data.${FinanceInformationFields.Title}`, {
      defaultValue: getTemplate(newYear),
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {/*TODO: return to select when problem of choosing the year will be fixed*/}
          {/*<SelectFiled*/}
          {/*  disabled={isEditing}*/}
          {/*  label={l10n.getString(FINANCIAL_YEAR_FTL)}*/}
          {/*  name={`budgetData.data.${FinanceInformationFields.Year}`}*/}
          {/*  data={years}*/}
          {/*  control={control as unknown as FieldControl}*/}
          {/*  validate={requiredLongStringValidator}*/}
          {/*  onChange={async e => {*/}
          {/*  const isValid = await trigger(`budgetData.data.${FinanceInformationFields.Year}`);*/}
          {/*  if (!isValid) return;*/}
          {/*  updTitle(e.target.value);*/}
          {/*}}*/}
          {/*/>*/}
          <TextField
            disabled={isEditing}
            label={l10n.getString(FINANCIAL_YEAR_FTL)}
            name={`budgetData.data.${FinanceInformationFields.Year}`}
            control={control as unknown as FieldControl}
            textProps={{
              type: 'number',
            }}
            validate={yearValidator}
            onBlurCallback={async e => {
              const isValid = await trigger(`budgetData.data.${FinanceInformationFields.Year}`);
              if (!isValid) return;
              updTitle(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={isEditing}
            label={l10n.getString(TITLE_FTL)}
            name={`budgetData.data.${FinanceInformationFields.Title}`}
            control={control as unknown as FieldControl}
            textProps={{
              size: 'medium',
              type: 'text',
            }}
            validate={titlePageValidator}
            onBlurCallback={onBlurCallback}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectFiled
            disabled={isEditing}
            label={l10n.getString(STATUS_FTL)}
            name={`budgetData.data.${FinanceInformationFields.Status}`}
            data={statuses}
            control={control as unknown as FieldControl}
            validate={requiredLongStringValidator}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 5 }} spacing={3}>
        <OverheadsCost />
      </Grid>
      {!budgetID && (
        <Grid container sx={{ mt: 5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              gap: '10px',
            }}>
            <Typography className={s.FinancialInformation__text}>
              {l10n.getString(BUDGETING_PROCEED_FTL)}
            </Typography>
            <Button type='submit' variant='contained' onClick={onProceed} disabled={loading}>
              {l10n.getString(PROCEED_FTL)}
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default FinancialInformation;
