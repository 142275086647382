import { DateMask } from 'valtech-core/common/types';

export const INPUT_SMALL_LENGTH = 160;
export const INPUT_STR_MID_LIMIT = 45;
export const INPUT_STR_NOTE_LIMIT = 450;
export const INPUT_MIN_LENGTH = 3;

export const INPUT_REGEXP = /^[a-zA-Z0-9\s]+$/;
export const NUMBER_REGEXP = /^\d+(\.\d{1,2})?$/;
export const NO_ZERO_NUMBER_REGEXP = /^(?!0(\.0+)?$)\d+(\.\d{1,2})?$/;
export const INTEGER_NUMBER_REGEXP = /^-?\d+$/;
export const LETTERS_ONLY_REGEXP = /^[a-zA-Z\s]+$/;
export const HOURS_LIMIT_REGEXP = /^\d{1,3}(\.\d{1,2})?$/;
export const AMOUNT_LIMIT_REGEXP = /^\d{1,6}(\.\d{1,2})?$/;
export const INTEGER_OR_DECIMAL_REGEXP = /^([1-9]\d*|0)(?:\.\d+)?$/;

export const DateRegexp = {
  [DateMask.DD_MM_YYYY]: /^(0?[1-9]|[12][0-9]|3[01])\-(0?[1-9]|1[012])\-\d{4}$/,
  [DateMask.YYYY_MM_DD]: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
};
