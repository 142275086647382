import React, { FC, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Variant } from 'common/types';

import Autocomplete from '@mui/material/Autocomplete';
import MUITextFiled from '@mui/material/TextField';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import FormError from 'valtech-core/form/FormError';

export type AutocompleteValue = { label: string; id: number };

export interface IAutocompleteFieldProps {
  control: FieldControl;
  name: string;
  defaultValue?: AutocompleteValue;
  data: AutocompleteValue[];
  label?: string;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  validate?: (value: { label: string; id: number }) => Promise<Maybe<string>>;
  variant?: Variant;
}

const AutocompleteField: FC<IAutocompleteFieldProps> = ({
  name,
  control,
  defaultValue = null,
  data,
  label,
  className,
  validate,
  readOnly = false,
  disabled = false,
  variant,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <>
          <Autocomplete
            {...field}
            disablePortal
            id={name}
            readOnly={readOnly}
            disabled={disabled}
            options={data}
            sx={{
              '& .Mui-error': {
                color: 'var(--text-color-black-54)',
              },
            }}
            value={field.value ? field.value : null}
            isOptionEqualToValue={(option, value) => {
              if (!value && typeof value.label !== 'string') return false;
              return option.label === value.label;
            }}
            onChange={(_, newValue: AutocompleteValue) => {
              if (newValue && newValue.label) {
                field.onChange(newValue);
              } else {
                field.onChange('');
              }
            }}
            renderInput={params => (
              <MUITextFiled
                {...params}
                error={!!errors[name]}
                label={label}
                className={className}
                variant={variant}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </>
      )}
      rules={{ validate }}
    />
  );
};

export default memo(AutocompleteField);
