import { useCallback, useMemo } from 'react';

import { showGenericError, showSystemError } from 'common/apolloState/system';
import { DEFAULT_SKIP_QUANTITY, MAX_QUANTITY } from 'common/constants';

import { OrderDirection, useGetBudgetFormDataQuery } from 'valtech-core/common/gql/generated';
import { useCreateBudgetForProjectMutation } from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import {
  IDataBudgetSettings,
  IDataProjects,
  IFormattedBudgetData,
  IFormattedData,
  formattedBudgetSettingsData,
  formattedProjectsData,
  mapToSubmitNewBudget,
} from './BudgetingForm.utils';

import { EFormFieldName } from '../form.types';

type useSingleProjectReturn = {
  loading: boolean;
  projectsList: IFormattedData[];
  budgetSettingsList: IFormattedData[];
  createBudget: (data: IFormattedBudgetData) => Promise<boolean>;
};

export const useBudgetFormData = (): useSingleProjectReturn => {
  const [createBudgetForProjectMutation] = useCreateBudgetForProjectMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const { data, loading } = useGetBudgetFormDataQuery({
    variables: {
      pagination: {
        limit: MAX_QUANTITY,
        skip: DEFAULT_SKIP_QUANTITY,
      },
      filter: [
        {
          field: EFormFieldName.Name,
          filterModel: EFormFieldName.Name,
          query: '',
        },
      ],
      budgetSettingsOrderBy: {
        model: '',
        sortBy: EFormFieldName.Title,
        sortDirection: OrderDirection.Asc,
      },
      projectsOrderBy: {
        model: '',
        sortBy: EFormFieldName.Name,
        sortDirection: OrderDirection.Asc,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError() {
      showGenericError();
    },
  });

  const { projectsList, budgetSettingsList } = useMemo(() => {
    if (!data) {
      return { projectsList: [], budgetSettingsList: [] };
    }

    const projectsList =
      data && formattedProjectsData(data.getProjectsByDMID.data as IDataProjects[]);
    const budgetSettingsList =
      data &&
      formattedBudgetSettingsData(data.sendArrayOfBudgetSettings.data as IDataBudgetSettings[]);

    return {
      projectsList: projectsList,
      budgetSettingsList: budgetSettingsList,
    };
  }, [data]);

  const createBudget = useCallback(async (data: IFormattedBudgetData) => {
    if (data) {
      const newBudget = await createBudgetForProjectMutation({
        variables: mapToSubmitNewBudget(data),
        refetchQueries: ['getBudgetingReports'],
      });

      return !!newBudget.data;
    }
    return false;
  }, []);

  return {
    loading,
    projectsList,
    budgetSettingsList,
    createBudget,
  };
};
