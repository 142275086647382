import { useEffect, useState } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import * as settingsRoutes from 'common/routes/settings';

import * as coreRoutes from 'valtech-core/common/routes';

import { navLink } from './Header';

export const useCurrentTab = (navLinks: navLink[]): string => {
  const [currentTab, setCurrentTab] = useState<string>(coreRoutes.BUDGETINGS_ROUTE);
  const routeMatch = useRouteMatch(getAppRoutes());

  useEffect(() => {
    const currentPath = `/${routeMatch?.pattern?.path.split('/')[1]}`;
    const currentRoute = navLinks.find(link => link.value.includes(currentPath));

    if (currentRoute?.value) {
      setCurrentTab(currentRoute.value);
    }
  }, [routeMatch]);

  return currentTab;
};

function getAppRoutes(): string[] {
  const commonRoutes = Object.values(coreRoutes).filter(route => !route.includes('*'));
  const settingsNextedRoutesArr = Object.values(settingsRoutes).map(
    route => `${coreRoutes.SETTINGS_ROUTE}/${route}`,
  );
  return [...settingsNextedRoutesArr, ...commonRoutes];
}

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}
