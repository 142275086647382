import { UserInfo, authVar } from './auth';

export const setAuthLoggedIn = (userInfo?: UserInfo): void => {
  authVar({
    isLoggedIn: true,
    userInfo: userInfo ?? null,
  });
};

export const setAuthLoggedOut = (): void => {
  authVar({
    isLoggedIn: false,
    userInfo: null,
  });
};
