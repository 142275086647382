import React, { FC, useMemo } from 'react';

import Modal from 'components/Modal';
import SettingsUserForm from 'components/_forms/SettingsUserForm/SettingsUserForm';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useHandleAllUsersTableRowEdit } from './HandleAllUsersTableRowEdit.hooks';

import { SettingsUser } from '../AllUsers.types';

interface HandleTableRowEditProps {
  settingsUserId: number;
  rowRecord: SettingsUser;
}

const HandleAllUsersTableRowEdit: FC<HandleTableRowEditProps> = ({ settingsUserId, rowRecord }) => {
  const { isOpened, toggleForm, getSettingsUserInitialValues } = useHandleAllUsersTableRowEdit({
    settingsUserId,
    rowRecord,
  });

  const displayForm = useMemo(() => {
    return (
      <Box
        sx={{
          width: 600,
        }}>
        <SettingsUserForm
          onClose={toggleForm}
          initialValues={getSettingsUserInitialValues(rowRecord as SettingsUser)}
          settingsUserId={settingsUserId}
        />
      </Box>
    );
  }, [rowRecord]);

  return (
    <>
      <IconButton
        aria-haspopup='true'
        onClick={toggleForm}
        sx={{
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}>
        <EditIcon color='primary' fontSize='small' />
      </IconButton>
      <Modal handleCloseModal={toggleForm} openModal={isOpened}>
        {displayForm}
      </Modal>
    </>
  );
};

export default HandleAllUsersTableRowEdit;
