import { makeVar } from '@apollo/client';

import { PROJECT_FILTER_DEFAULT_OBJ } from 'src/common/constants';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { ALL_FTL } from 'valtech-core/common/ftl';
import { IFilters } from 'valtech-core/common/types';

export enum PageTypes {
  BILLING = 'billingPageFilters',
  BUDGETING = 'budgetingPageFilters',
  SETTINGS_HOLIDAY_CALENDAR = 'holidayCalendarPageFilters',
  SETTINGS_ENTITIES = 'entitiesPageFilters',
  SETTINGS_PROJECTS = 'projectsPageFilters',
  SETTINGS_ACCOUNTS = 'accountsPageFilters',
  SETTINGS_USERS = 'usersPageFilters',
}

type Filter = {
  persistentFilters: IFilters[] | [];
  projectFilter: IFilters;
  pagination: { page: number; rowsPerPage: number };
};

export type FiltersState = {
  [PageTypes.BILLING]: Filter;
  [PageTypes.BUDGETING]: Filter;
  [PageTypes.SETTINGS_HOLIDAY_CALENDAR]: Omit<Filter, 'projectFilter'>;
  [PageTypes.SETTINGS_ENTITIES]: Omit<Filter, 'projectFilter'>;
  [PageTypes.SETTINGS_PROJECTS]: Omit<Filter, 'projectFilter'>;
  [PageTypes.SETTINGS_ACCOUNTS]: Omit<Filter, 'projectFilter'>;
  [PageTypes.SETTINGS_USERS]: Omit<Filter, 'projectFilter'>;
};

export const PROJECT_DEFAULT_OBJ = { ...PROJECT_FILTER_DEFAULT_OBJ, query: ALL_FTL };

const defaultFiltersState: Filter = {
  persistentFilters: [],
  projectFilter: PROJECT_DEFAULT_OBJ,
  pagination: {
    page: 0,
    rowsPerPage: DEFAULT_REQUEST_LIMIT,
  },
};

export const filtersInitialState: FiltersState = {
  [PageTypes.BILLING]: defaultFiltersState,
  [PageTypes.BUDGETING]: defaultFiltersState,
  [PageTypes.SETTINGS_HOLIDAY_CALENDAR]: defaultFiltersState,
  [PageTypes.SETTINGS_ENTITIES]: defaultFiltersState,
  [PageTypes.SETTINGS_PROJECTS]: defaultFiltersState,
  [PageTypes.SETTINGS_ACCOUNTS]: defaultFiltersState,
  [PageTypes.SETTINGS_USERS]: defaultFiltersState,
};

export const filtersVar = makeVar<FiltersState>(filtersInitialState);
