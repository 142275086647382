import { FC, useEffect } from 'react';

import { GraphQLError } from 'graphql/error/GraphQLError';
import isNil from 'lodash/isNil';
import { setAuthLoggedOut } from 'src/common/apolloState/auth/auth.actions';

import { getLogoutUrl } from 'valtech-core/common/geLogoutUrl';
import { useGetIsValidTokenQuery } from 'valtech-core/common/gql/generated';
import * as routes from 'valtech-core/common/routes';
import { ErrorMessage } from 'valtech-core/services/apollo/errors';
import AuthStorage from 'valtech-core/services/auth';

import { CHECK_TIME_THIRTY_MIN, LOGOUT_TIME_THREE_HOURS } from './LogoutTimer.constants';

const LogoutTimer: FC = () => {
  const { data, refetch } = useGetIsValidTokenQuery();

  useEffect(() => {
    if (isNil(data)) {
      return;
    }
    const logoutAndRedirect = (): void => {
      AuthStorage.clear();
      setAuthLoggedOut();

      AuthStorage.setLastVisitedURL({
        url: window?.location?.pathname || routes.HOME_ROUTE,
        isAuthTokenExpired: true,
      });

      window.location.href = getLogoutUrl();
    };

    const checkAuthTokenValidity = async (): Promise<void> => {
      try {
        const { errors } = await refetch();
        if (
          errors &&
          (errors as GraphQLError[])?.[0]?.extensions?.code === ErrorMessage.InternalServerError
        ) {
          logoutAndRedirect();
        }
      } catch (error) {}
    };

    // Set a timer to logout after 3 hours
    const logoutTimeout = setTimeout(() => checkAuthTokenValidity, LOGOUT_TIME_THREE_HOURS);

    // Periodically check auth token validity every 30 minutes
    const intervalId = setInterval(checkAuthTokenValidity, CHECK_TIME_THIRTY_MIN);

    // Clean up both the interval and timeout if the user logs out manually
    return () => {
      clearTimeout(logoutTimeout);
      clearInterval(intervalId);
    };
  }, [data]);

  return null;
};

export default LogoutTimer;
