import React, { FC, FocusEvent, Fragment, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import { useBudgetingState } from 'common/apolloState/budgeting';
import Comment from 'components/Comment';
import isNull from 'lodash/isNull';
import round from 'lodash/round';

import { Moment } from 'moment';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { BudgetType } from 'valtech-core/common/gql/generated';
import { AnyObject, BudgetingGroupsEnum, CurrencyEnum } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import getCurrencySign from 'valtech-core/common/utils/getCurrencySign';
import NA from 'valtech-core/ui/NA';
import Typography from 'valtech-core/ui/Typography';

import { ConsultantSectionItem, ExpensesSectionItem, VacancySectionItem } from '../Budgeting.types';
import HandleTableRowEdit from '../HandleTableRowEdit';
import EditAllocation from './EditAllocation';
import styles from './EditBudgetingTable.module.scss';

const FULL_ALLOCATION_NUMBER = 100;

interface TableRecordsProps {
  readOnly?: boolean;
  records: ExpensesSectionItem[] | ConsultantSectionItem[] | VacancySectionItem[];
  onSelect: (id: string) => void;
  onFieldKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    item?: AnyObject,
  ) => void;
  group: BudgetingGroupsEnum;
  monthsWithBilling: number[];
  currency: CurrencyEnum;
  selectedForCopy?: Set<string>;
}

const TableRecords: FC<TableRecordsProps> = ({
  onBlur,
  records,
  group,
  monthsWithBilling,
  onFieldKeyPress,
  readOnly = false,
  currency,
  onSelect,
  selectedForCopy,
}) => {
  const { control } = useFormContext();
  const isExpenses = group === BudgetingGroupsEnum.Expense;
  const arrayListKey = !isExpenses ? 'allocationPerMonth' : 'costPerMonth';
  const { forecastType } = useBudgetingState();
  const { l10n } = useLocalization();
  const { accessLevel } = useBudgetingState();
  const userAccessLevel = getAccessLevel(accessLevel, l10n);

  const onFocus = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    e.target.setAttribute('type', 'text');
    setTimeout(function () {
      e.target.selectionStart = e.target.selectionEnd = 10000;
      e.target.setAttribute('type', 'number');
    }, 0);
  }, []);

  const EditableCellCss = useCallback(
    (value: number | string) => {
      const modifier =
        +value === 0 && !isExpenses
          ? ''
          : +value < 1 && !isExpenses
          ? styles.textWarning
          : +value > 1 && !isExpenses
          ? styles.textError
          : +value === 1 && !isExpenses
          ? styles.textSuccess
          : '';
      return cn(styles.input, modifier);
    },
    [isExpenses, control],
  );

  const hasDeleteBtn = useCallback(
    (startDate: Moment, endDate: Moment): boolean => {
      const startMonth = startDate.get('month') + 1;
      const endMonth = endDate.get('month') + 1;
      let addDeleteButton = true;
      monthsWithBilling.forEach(month => {
        if (month >= startMonth && month <= endMonth) {
          addDeleteButton = false;
        }
      });
      return addDeleteButton;
    },
    [monthsWithBilling],
  );

  return (
    <>
      {records.map(consultant => {
        return (
          <Fragment key={consultant.id}>
            <TableRow>
              <TableCell
                component='th'
                scope='row'
                className={cn(styles.stickyCell, styles.colored, styles.generalCellTitle)}>
                <Typography component='p' weight='bold' variant='body2'>
                  {consultant.general.name}
                </Typography>
                {group === BudgetingGroupsEnum.Assignment && (
                  <Typography variant='caption' className={styles.textSecondary}>
                    {consultant.general.position}
                  </Typography>
                )}
              </TableCell>

              {consultant.general.costs.months.map(month => (
                <TableCell
                  key={`${month.monthIndex}::${month.cost}`}
                  align='right'
                  className={styles.colored}>
                  <NA
                    value={createNumberWithCurrency({
                      number: month.cost !== 0 ? month.cost : null,
                      currencyDisplay: 'symbol',
                      currency,
                    })}
                  />
                </TableCell>
              ))}

              <TableCell
                align='right'
                className={cn(
                  styles.totalCell,
                  styles.stickyCell,
                  styles.stickToRight,
                  styles.totalColored,
                )}>
                <NA
                  value={createNumberWithCurrency({
                    number:
                      consultant.general.costs.total !== 0 ? consultant.general.costs.total : null,
                    currencyDisplay: 'symbol',
                    currency,
                  })}
                />
              </TableCell>
            </TableRow>
            {consultant.itemsList.map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell
                    component='th'
                    scope='row'
                    className={cn(styles.stickyCell, styles.colored, styles.dataRowTitleCell)}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      {forecastType?.type === BudgetType.OperationalForecast &&
                        userAccessLevel.full && (
                          <Checkbox
                            checked={selectedForCopy?.has(`type:${group}::assignmentId:${item.id}`)}
                            id={`selectForCopyingCheckbox-${item.id}`}
                            onChange={() => onSelect(`type:${group}::assignmentId:${item.id}`)}
                          />
                        )}
                      <Box flexGrow={1}>
                        <Typography variant='body2'>
                          {item.text ? item.text : item.title}
                        </Typography>
                        <Stack direction='row' justifyContent='space-between'>
                          {group !== BudgetingGroupsEnum.Expense && (
                            <Typography className={styles.textSecondary} variant='body2'>
                              {item.model}
                            </Typography>
                          )}
                          <Typography className={styles.textSecondary} variant='body2'>
                            {item.date}
                          </Typography>
                          <Typography
                            fontSize='12px'
                            variant='body2'
                            weight='bold'
                            sx={{ lineHeight: '20px' }}>
                            {!isExpenses
                              ? item.rate?.value
                              : createNumberWithCurrency({
                                  number: item.cost,
                                  currencyDisplay: 'symbol',
                                  currency: item.currency,
                                })}
                            {item.allocation && item.allocation !== FULL_ALLOCATION_NUMBER && (
                              <Typography
                                fontSize='11px'
                                variant='caption'>{` x ${item.allocation}%`}</Typography>
                            )}
                          </Typography>
                        </Stack>
                      </Box>
                      <Stack direction='row'>
                        <Comment text={item.comment} />
                        {!readOnly && (
                          <HandleTableRowEdit
                            assignmentId={item.id}
                            rowRecord={item}
                            rowTitle={{ name: consultant.general.name, id: consultant.general.id }}
                            group={group}
                            hasDelete={hasDeleteBtn(item.startDate, item.endDate)}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                  {item[arrayListKey] &&
                    item[arrayListKey].map(capacity => (
                      <TableCell
                        key={`${consultant.id}:${item.title}${capacity.monthIndex}${item.allocation}${capacity.value}${capacity.allocationId}`}
                        align='right'>
                        {!isNull(capacity.value) && (
                          <>
                            {group !== BudgetingGroupsEnum.Expense && !readOnly ? (
                              <EditAllocation
                                id={`${consultant.id}:${item.title}::month:${capacity.monthIndex}::id:${capacity.allocationId}`}
                                onKeyPress={onFieldKeyPress}
                                onFocus={e => onFocus(e)}
                                endAdornment={
                                  isExpenses ? getCurrencySign(item.currency) : undefined
                                }
                                readOnly={monthsWithBilling.includes(capacity.monthIndex)}
                                className={EditableCellCss(capacity.value)}
                                control={control}
                                isPartial={capacity.isPartial}
                                onBlur={e => onBlur(e, capacity)}
                              />
                            ) : group !== BudgetingGroupsEnum.Expense && readOnly ? (
                              <Typography
                                variant='caption'
                                className={EditableCellCss(capacity.value)}>
                                {round(capacity.value, 2)}
                              </Typography>
                            ) : (
                              <Typography variant='caption'>
                                {createNumberWithCurrency({
                                  number: capacity.value,
                                  currencyDisplay: 'symbol',
                                  currency,
                                })}
                              </Typography>
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                  <TableCell
                    align='right'
                    className={cn(styles.totalColoredCell, styles.stickyCell, styles.stickToRight)}
                  />
                </TableRow>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default TableRecords;
