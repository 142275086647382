import React, { ReactNode, forwardRef, memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import MUITextField, { TextFieldProps } from '@mui/material/TextField';

interface ITextInputProps {
  boxProps?: BoxProps;
  textProps: TextFieldProps;
  children?: ReactNode;
  className?: string;
  autoComplete?: string;
  readOnly?: boolean;
  disabled?: boolean;
  integerInput?: boolean;
  acceptOnlyPositiveNumbers?: boolean;
  endAdornment?: ReactNode;
  onBlurCallback?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  emptyValToZero?: boolean;
  enableEndAdornment?: boolean;
  shrink?: boolean;
  maxLength?: number;
}

const INPUT_TYPE_NUMBER = 'number';
//TODO: temporary solution
const EXCLUDED_SYMBOLS = [
  "'",
  '"',
  '`',
  '~',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '=',
  '[',
  ']',
  '{',
  '}',
  '|',
  ';',
  ':',
  '‘',
  '“',
  '/',
  '<',
  '>',
  '?',
  '+',
  '-',
  ',',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
const EXCLUDED_SYMBOLS_INTEGER = [...EXCLUDED_SYMBOLS, '.'];

const TextInput = forwardRef<HTMLDivElement, ITextInputProps>(
  (
    {
      boxProps,
      textProps,
      className,
      onBlurCallback,
      autoComplete,
      readOnly = false,
      children,
      endAdornment,
      disabled = false,
      acceptOnlyPositiveNumbers = false,
      integerInput,
      emptyValToZero,
      enableEndAdornment,
      shrink,
      maxLength,
      ...rest
    },
    ref,
  ) => {
    const InputProps = enableEndAdornment
      ? textProps.InputProps
      : {
          endAdornment: (
            <InputAdornment
              position='end'
              disableTypography={true}
              sx={{
                fontSize: '14px',
              }}>
              {endAdornment}
            </InputAdornment>
          ),
          readOnly,
        };

    return (
      <Box {...boxProps}>
        <MUITextField
          {...textProps}
          {...rest}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          disabled={disabled}
          fullWidth
          autoComplete={autoComplete}
          InputLabelProps={{ shrink }}
          InputProps={InputProps}
          inputProps={{ step: 'any', maxLength }}
          onChange={e => {
            const { type, onChange } = textProps;
            const fieldValue = e.target.value;
            if (emptyValToZero) {
              if (fieldValue === '') {
                e.target.value = '0';
              } else if (fieldValue.match(/^0\d+/)) {
                e.target.value = fieldValue.slice(1);
              }
            }
            const shouldAcceptOnlyPositiveNumber =
              type === INPUT_TYPE_NUMBER && onChange && acceptOnlyPositiveNumbers;
            if (shouldAcceptOnlyPositiveNumber) {
              if (Math.sign(Number(fieldValue)) >= 0 || fieldValue === '') {
                onChange(e);
              }
            } else {
              onChange && onChange(e);
            }
          }}
          onKeyDown={e => {
            const { type } = textProps;
            if (integerInput) {
              type === INPUT_TYPE_NUMBER &&
                EXCLUDED_SYMBOLS_INTEGER.includes(e.key) &&
                e.preventDefault();
            } else {
              type === INPUT_TYPE_NUMBER && EXCLUDED_SYMBOLS.includes(e.key) && e.preventDefault();
            }
          }}
          onBlur={e => {
            const { onBlur } = textProps;
            if (onBlur) {
              onBlur(e);
            }
            if (onBlurCallback) {
              onBlurCallback(e);
            }
          }}
          ref={ref}
          className={className}
        />
        {children}
      </Box>
    );
  },
);

TextInput.displayName = 'TextInput'; //name we would see in components toolkit

export default memo(TextInput);
