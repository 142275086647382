import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { UseFormReturn } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'common/apolloState/notifications';
import { showGenericError } from 'common/apolloState/system';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import { v4 as uuidv4 } from 'uuid';

import { ERROR_EMPTY_RATES_FIELDS_FTL } from 'valtech-core/common/ftl';
import { useAddHolidayCalendarDatesMutation } from 'valtech-core/common/gql/generated';
import { AlertsSeverityEnum } from 'valtech-core/common/types';

import { HolidayFields, IHoliday } from '../Holiday.constants';
import { defaultValues } from './AddHoliday.constants';

export interface IUseAddHolidayParams {
  append: (item: Omit<IHoliday, 'holiday_calendar_id'>) => void;
  methods: UseFormReturn<Omit<IHoliday, 'id' | 'holiday_calendar_id'>>;
  order: number;
}

interface IUseHandleHolidayReturn {
  loading: boolean;
  addHolidayDate: () => Promise<void>;
}

export const useAddHolidayDate = ({
  order,
  append,
  methods,
}: IUseAddHolidayParams): IUseHandleHolidayReturn => {
  const { id } = useParams();
  const calendarID = Number(id);
  const { l10n } = useLocalization();

  const [addHolidayDatesMutation, { loading }] = useAddHolidayCalendarDatesMutation();

  const addHolidayDate: () => Promise<void> = useCallback(async () => {
    const { trigger, getValues, reset } = methods;
    const values = getValues();

    if (!values[HolidayFields.Title] && !values[HolidayFields.Date]) {
      showNotification({
        message: l10n.getString(ERROR_EMPTY_RATES_FIELDS_FTL),
        hideDuration: secondsToMilliSeconds(3),
        severity: AlertsSeverityEnum.Error,
      });
      return;
    }

    try {
      const valid = await trigger();
      if (!valid) return;
      const { date, title } = values;
      const _order = order + 1;
      const params = {
        date,
        title,
        order: _order,
      };

      if (calendarID) {
        const resp = await addHolidayDatesMutation({
          variables: {
            data: [
              {
                date: values[HolidayFields.Date] as string,
                order: _order,
                title: values[HolidayFields.Title],
                holiday_calendar_id: Number(calendarID),
              },
            ],
          },
        });

        const holidayID = resp?.data?.addHolidayCalendarDates[0].id;
        if (!holidayID) throw new Error('holidayID undefined');

        holidayID &&
          append({
            id: holidayID,
            ...params,
          });
      } else {
        append({
          id: uuidv4(),
          ...params,
        });
      }

      reset({ ...defaultValues });
    } catch {
      showGenericError();
    }
  }, [calendarID, order]);

  return {
    loading,
    addHolidayDate,
  };
};
