import isNil from 'lodash/isNil';

interface IParams {
  number: number | null;
  shouldDisplayStub?: boolean;
}

const formatGrossMarginWithPercentage = ({
  number,
  shouldDisplayStub = false,
}: IParams): string => {
  if (shouldDisplayStub && isNil(number)) {
    return '-';
  }

  if (isNil(number)) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });

  const formattedNumber = formatter.format(number);

  return `${formattedNumber}%`;
};

export default formatGrossMarginWithPercentage;
