import React, { FC } from 'react';

import cn from 'classnames';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MUIModal from '@mui/material/Modal';

import s from './Modal.module.scss';

interface IModalProps {
  children: React.ReactNode;
  openModal: boolean;
  handleCloseModal: VoidFunction;
  size?: 'big' | 'mid' | undefined;
  closeBtn?: boolean;
  scroll?: boolean;
}

const Modal: FC<IModalProps> = ({
  children,
  openModal,
  handleCloseModal,
  size,
  closeBtn,
  scroll,
}) => {
  return (
    <MUIModal open={openModal} onClose={handleCloseModal}>
      <Box
        className={cn(
          s.modalBody,
          { [s['modalBody--close-btn']]: closeBtn },
          { [s['modalBody--big']]: size === 'big' },
          { [s['modalBody--mid']]: size === 'mid' },
          { [s['modalBody--scroll']]: scroll },
        )}>
        <Box className={s.modalBodyInner}>
          {closeBtn && (
            <IconButton
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </Box>
      </Box>
    </MUIModal>
  );
};

export default Modal;
