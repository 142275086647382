import { STATUS_FTL, TITLE_FTL, UPD_BY_FTL } from 'valtech-core/common/ftl';

export enum HolidayCalendarFields {
  Name = 'name',
  Title = 'title',
  Status = 'status',
  BusinessUnitId = 'business_unit_id',
}

export const HolidayCalendarFilterFields = {
  [TITLE_FTL]: {
    field: HolidayCalendarFields.Title,
    filterModel: '',
  },
};

export const headData = [
  {
    l10n: TITLE_FTL,
    model: '',
    sortBy: HolidayCalendarFields.Title,
    withSearch: true,
  },
  {
    l10n: UPD_BY_FTL,
    model: 'managerInfo',
    sortBy: HolidayCalendarFields.Name,
    withSearch: false,
  },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: HolidayCalendarFields.Status,
    withSearch: false,
  },
];
