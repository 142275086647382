import { FieldValues, Path, UseFormSetError } from 'react-hook-form';

import { ConsultantModelEnum } from 'common/types';
import round from 'lodash/round';
import { RateItem } from 'src/common/hooks/useRateList/useRateList';

import moment, { Moment } from 'moment';

import { ERROR_REQUIRED_FTL, PERIOD_ERROR_FTL } from 'valtech-core/common/ftl';
import { DateMask, Maybe } from 'valtech-core/common/types';

import { EFormFieldName } from './form.types';

export const CUSTOM_TIER = 'Custom Tier';

export const getDefaultDate = (year: number | null): string => {
  return `${moment().year() !== year && year ? year : moment().format('YYYY')}`;
};

export function getDefaultDateWithMonth(month: Maybe<number>, year: Maybe<number>): string {
  const date = month && year ? moment({ month: month - 1, year }) : moment();
  return date.format(DateMask.MMMM_YYYY);
}

export function getDateWithYear(date: Moment, year: Maybe<number>): Moment {
  return moment({ day: date.date(), month: date.month(), year });
}

export function transformAllocation(allocation: number): number {
  return round(allocation / 100, 6);
}

type CheckDatesParams<T extends FieldValues> = {
  data: T;
  dateKeys: string[];
  setError: UseFormSetError<T>;
};

export function checkDates<T extends FieldValues>({
  data,
  dateKeys,
  setError,
}: CheckDatesParams<T>): boolean {
  const isBefore = data[dateKeys[1]].isBefore(data[dateKeys[0]]);

  if (isBefore) {
    setError(dateKeys[1] as Path<T>, {
      type: 'manual',
      message: PERIOD_ERROR_FTL,
    });

    return false;
  }

  return true;
}

export const getRateKey = (model: ConsultantModelEnum | undefined): string => {
  if (!model) {
    return '';
  }
  return model === ConsultantModelEnum.Dedicated ? 'dedicated' : 'timeAndMaterial';
};

export const getRateLabel = (
  rates: { dedicated: RateItem[]; timeAndMaterial: RateItem[] },
  selectedModel: ConsultantModelEnum | undefined,
  selectedRate: string | undefined,
): string => {
  const rateKey = getRateKey(selectedModel);
  const rateArray = rates[rateKey];

  if (!rateArray || !Array.isArray(rateArray)) {
    return '';
  }

  return rateArray.find(rate => rate.id === Number(selectedRate))?.label || '';
};

type CheckFeeValidity<T extends FieldValues> = {
  data: T;
  rates: { dedicated: RateItem[]; timeAndMaterial: RateItem[] };
  setError: UseFormSetError<T>;
};

export function checkFeeValidity<T extends FieldValues>({
  data,
  rates,
  setError,
}: CheckFeeValidity<T>): boolean {
  const rateLabel = getRateLabel(rates, data[EFormFieldName.Model], data[EFormFieldName.Rate]);

  if (rateLabel === CUSTOM_TIER && Number(data[EFormFieldName.Fee]) === 0) {
    setError(EFormFieldName.Fee as Path<T>, {
      type: 'manual',
      message: ERROR_REQUIRED_FTL,
    });

    return true;
  }

  return false;
}
