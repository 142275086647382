import { FilterModelEnum, TableSearchFieldsEnum } from 'src/common/types';

import { DELIVERY_MANAGER_FTL, NAME_FTL, STATUS_FTL, TYPE_FTL } from 'valtech-core/common/ftl';

export const headData = [
  { l10n: NAME_FTL, model: '', sortBy: TableSearchFieldsEnum.Name },
  {
    l10n: DELIVERY_MANAGER_FTL,
    model: FilterModelEnum.DeliveryManager,
    sortBy: TableSearchFieldsEnum.Name,
  },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: TableSearchFieldsEnum.Status,
  },
  { l10n: TYPE_FTL, model: '', sortBy: TableSearchFieldsEnum.Type },
];
