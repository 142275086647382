import React, { FC, useEffect } from 'react';

import { useBudgetingState } from 'src/common/apolloState/budgeting';

import { RecordStatus } from 'valtech-core/common/gql/generated';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';
import { useSelected } from 'valtech-core/utils/useSelected';

import { useSingleBudgeting } from './Budgeting.hooks';

import BudgetingHeader from './BudgetingHeader';
import EditBudgetingTable from './EditBudgetingTable';

const Budgeting: FC = () => {
  const {
    selected,
    selectById,
    selectAll,
    unselectAll,
    countAll,
    allSelectItems,
    areAllItemsSelected,
  } = useSelected();

  const {
    loading,
    budgetingInfo,
    consultantsSection,
    consultantsSectionTotals,
    vacanciesSectionTotals,
    vacanciesSection,
    expensesSection,
    expensesSectionTotals,
    invoicedTotals,
    invoicedTotalsByEntities,
    forecastedTotalsByEntities,
  } = useSingleBudgeting();

  const { submitted } = useBudgetingState();

  const handleSelectAll = () => {
    if (areAllItemsSelected) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      !allSelectItems.size &&
      (consultantsSection.length || vacanciesSection.length || expensesSection.length)
    ) {
      const allSelectedIds = new Set<string>();

      addSelectedIds(consultantsSection, 'assignment');
      addSelectedIds(vacanciesSection, 'vacancy');
      addSelectedIds(expensesSection, 'expense');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function addSelectedIds(group: any, groupName: string) {
        group.forEach(groupItem => {
          groupItem.itemsList?.forEach(row => {
            allSelectedIds.add(`type:${groupName}::assignmentId:${row.id}`);
          });
        });
      }

      countAll(allSelectedIds);
    }
  }, [consultantsSection, vacanciesSection, expensesSection]);

  return (
    <>
      {loading && <ProgressLinear />}
      <BudgetingHeader
        selectedForCopy={selected}
        consultantsSection={consultantsSection}
        vacanciesSection={vacanciesSection}
        expensesSection={expensesSection}
        areAllRowsSelected={areAllItemsSelected}
        onSelectRowsForCopying={handleSelectAll}
        {...budgetingInfo}
        loading={loading}
        readOnly={budgetingInfo.status === RecordStatus.Archived || submitted}
      />
      <EditBudgetingTable
        title={budgetingInfo.title}
        loading={loading}
        readOnly={budgetingInfo.status === RecordStatus.Archived || submitted}
        onSelect={selectById}
        selectedForCopy={selected}
        consultantSection={consultantsSection}
        vacanciesSection={vacanciesSection}
        expensesSection={expensesSection}
        consultantsSectionTotals={consultantsSectionTotals}
        vacanciesSectionTotals={vacanciesSectionTotals}
        expensesSectionTotals={expensesSectionTotals}
        invoicedTotals={invoicedTotals}
        invoicedTotalsByEntities={invoicedTotalsByEntities}
        forecastedTotalsByEntities={forecastedTotalsByEntities}
      />
    </>
  );
};

export default Budgeting;
