import React, { FC, ReactNode, memo } from 'react';

import { NavLink } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Box, { BoxProps } from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MUITabs, { TabsProps } from '@mui/material/Tabs';

import { TabsTypeEnum } from 'valtech-core/common/types';

import styles from './Tabs.module.scss';

type Tabs = { title: string; badgeNotificationsQuantity?: number; value: string };

export interface ITabsProps {
  boxProps?: BoxProps;
  colorSchema?: 'white' | 'blue';
  tabs: Tabs[];
  tabsProps?: TabsProps;
  onChange?: (event: React.SyntheticEvent, value: string) => void;
  value?: string;
  type?: TabsTypeEnum;
}

const Tabs: FC<ITabsProps> = ({
  boxProps,
  colorSchema = 'blue',
  tabs,
  tabsProps,
  onChange,
  value,
  type = TabsTypeEnum.Button,
}) => {
  return (
    <Box sx={{ width: '100%' }} {...boxProps}>
      <MUITabs
        value={value}
        onChange={onChange}
        className={styles.tabs}
        data-theme={colorSchema}
        variant={'fullWidth'}
        {...tabsProps}
      >
        {tabs.map(({ title, badgeNotificationsQuantity, value }) => {
          const label = displayLabel(title, badgeNotificationsQuantity);

          switch (type) {
            case TabsTypeEnum.Link:
              return <Tab key={value} label={label} value={value} to={value} component={NavLink} />;

            case TabsTypeEnum.Button:
              return <Tab key={value} value={value} label={label} />;

            default:
              return null;
          }
        })}
      </MUITabs>
    </Box>
  );
};

function displayLabel(title: string, notificationsQuantity?: number): ReactNode {
  let tabLabel;
  const notification = notificationsQuantity;

  if (notification) {
    const getPaddingRight = () => {
      if (notification > 99) {
        return '25px';
      } else if (notification > 10) {
        return '20px';
      }
      return '18px';
    };

    tabLabel = (
      <Badge
        badgeContent={notificationsQuantity}
        color='error'
        sx={{
          paddingRight: getPaddingRight(),
          '& .MuiBadge-badge': {
            top: '45%',
          },
        }}
      >
        {title}
      </Badge>
    );
  } else tabLabel = title;

  return tabLabel;
}

export default memo(Tabs);
