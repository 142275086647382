import * as routes from 'valtech-core/common/routes';

export const HEADER_NAV_LINKS = [
  // { title: 'HOME', value: routes.HOME_ROUTE },
  { title: 'BILLING', value: routes.BILLINGS_ROUTE },
  { title: 'BUDGETING', value: routes.BUDGETINGS_ROUTE },
  // { id: '4', title: 'ICBOS', value: ICBOS_ROUTE },
];

export const HEADER_NAV_LINKS_ADMIN = [
  ...HEADER_NAV_LINKS,
  { title: 'SETTINGS', value: routes.SETTINGS_ROUTE },
];

export const HEADER_AVATAR_DATA = {
  url: '',
  firstName: 'User',
  lastName: 'White',
};
