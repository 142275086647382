import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import TableSkeleton from 'components/TableSkeleton';
import get from 'lodash/get';
import omit from 'lodash/omit';
import round from 'lodash/round';
import { useBudgetingState } from 'src/common/apolloState/budgeting';
import { MonthAllocation } from 'src/common/types';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { EMPTY_BUDGETING_FTL, MONTH_LIST } from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
//TODO: VREP-484 Elements should be hidden
// import TableScrollNavs from './TableScrollNavs';
import { AnyObject, BudgetingGroupsEnum } from 'valtech-core/common/types';
import Accordion from 'valtech-core/ui/Accordion';

import { useUpdateAllocationByMonth } from './EditBudgetingTable.hooks';

import { COL_SPAN, getMonthStatus, getTotalCost } from '../Budgeting.utils';

import {
  ConsultantSectionItem,
  ExpensesSectionItem,
  InvoicedTotal,
  MonthCost,
  TotalByEntities,
  TotalCosts,
  VacancySectionItem,
} from '../Budgeting.types';
import BudgetingTableFooter from './BudgetingTableFooter';
import BudgetingTableHead from './BudgetingTableHead';
import {
  EDIT_CLIENTS_FTL,
  EDIT_EXPENSES_FTL,
  EDIT_VACANCIES_FTL,
  TOTAL_CONSULTANTS_FTL,
  TOTAL_EXPENSES_FTL,
  TOTAL_VACANCIES_FTL,
} from './EditBudgetingTable.ftl';
import styles from './EditBudgetingTable.module.scss';
import GroupTotalRow from './GroupTotalRow';
import TableRecords from './TableRecords';

interface EditBudgetingTableProps {
  title: string;
  readOnly: boolean;
  loading: boolean;
  onSelect: (id: string) => void;
  consultantSection: ConsultantSectionItem[];
  vacanciesSection: VacancySectionItem[];
  expensesSection: ExpensesSectionItem[];
  consultantsSectionTotals: TotalCosts;
  vacanciesSectionTotals: TotalCosts;
  expensesSectionTotals: TotalCosts;
  invoicedTotals: InvoicedTotal;
  invoicedTotalsByEntities: TotalByEntities;
  forecastedTotalsByEntities: TotalByEntities;
  selectedForCopy?: Set<string>;
}

const EditBudgetingTable: FC<EditBudgetingTableProps> = ({
  title,
  readOnly,
  loading,
  onSelect,
  consultantSection,
  vacanciesSection,
  expensesSection,
  consultantsSectionTotals,
  vacanciesSectionTotals,
  expensesSectionTotals,
  invoicedTotals,
  invoicedTotalsByEntities,
  forecastedTotalsByEntities,
  selectedForCopy,
}) => {
  const { l10n } = useLocalization();
  const { currency, accessLevel } = useBudgetingState();

  const { updateSingleAllocation } = useUpdateAllocationByMonth();

  const initialValues = useMemo(() => {
    const consultants = consultantSection.reduce<Record<string, string>>((acc, value) => {
      value.itemsList.forEach(item => {
        item.allocationPerMonth.forEach(capacity => {
          acc[
            `${value.general.id}:${item.title}::month:${capacity.monthIndex}::id:${capacity.allocationId}`
          ] = `${round(Number(capacity.value), 2)}`;
        });
      });

      return acc;
    }, {});

    const vacancies = vacanciesSection.reduce<Record<string, string>>((acc, value) => {
      value.itemsList.forEach(item => {
        item.allocationPerMonth.forEach(capacity => {
          acc[
            `${value.general.id}:${item.title}::month:${capacity.monthIndex}::id:${capacity.allocationId}`
          ] = `${round(Number(capacity.value), 2)}`;
        });
      });

      return acc;
    }, {});

    return {
      ...consultants,
      ...vacancies,
    };
  }, [consultantSection, vacanciesSection]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
  });
  const tableWrapper = useRef<HTMLDivElement>(null);
  const [tableOffsetTop, setTableOffsetTop] = useState<number>();
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useEffect(() => {
    const isDataExist =
      consultantSection.length > 0 || expensesSection.length > 0 || vacanciesSection.length > 0;
    if (!loading && !mounted && isDataExist) {
      setMounted(true);
    }
  }, [loading, consultantSection, expensesSection, vacanciesSection]);

  useEffect(() => {
    if (tableWrapper.current) {
      setTableOffsetTop(tableWrapper.current.offsetTop + 30);
    }
  }, [tableWrapper.current, tableWrapper.current?.offsetTop]);

  const forecastTotal = useMemo((): TotalCosts => {
    const months = Array.from({ length: MONTH_LIST.length }, (_, k): MonthCost => {
      const monthIndex = k;
      const cost =
        get(consultantsSectionTotals.months[monthIndex], 'cost', 0) +
        get(vacanciesSectionTotals.months[monthIndex], 'cost', 0) +
        get(expensesSectionTotals.months[monthIndex], 'cost', 0);
      return {
        monthIndex: monthIndex + 1,
        cost,
      };
    });

    return {
      months,
      total: getTotalCost(months),
    };
  }, [consultantsSectionTotals, vacanciesSectionTotals, expensesSectionTotals]);

  const tableHeaderMonths = useMemo((): { title: string; status: string }[] => {
    return MONTH_LIST.map((month, index) => {
      const hasZeroIndex = Boolean(invoicedTotals.months.find(DBMonth => DBMonth.monthIndex === 0));
      const localMonthIndex = hasZeroIndex ? index : index + 1;
      const invoicedMonth = invoicedTotals.months.find(
        invoicedMonth => invoicedMonth.monthIndex === localMonthIndex,
      );
      return {
        title: month,
        status: getMonthStatus(invoicedMonth?.status, {
          invoiced: styles.statusDone,
          draft: styles.statusDraft,
          new: styles.statusNew,
        }),
      };
    });
  }, [invoicedTotals]);

  const monthsWithBilling = useMemo(
    () =>
      invoicedTotals.months
        .filter(
          month => month.status === BillingStatus.Draft || month.status === BillingStatus.Invoiced,
        )
        .map(month => month.monthIndex),
    [invoicedTotals],
  );

  const handleBlurSubmit = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, item?: AnyObject) => {
      setTimeout(() => {
        const fieldName = e.target.name;
        const hasError = methods.getFieldState(fieldName).error;

        if (!hasError) {
          const initialValue = initialValues[fieldName];
          const newValue = methods.getValues(fieldName);

          if (initialValue !== newValue) {
            const allocationData = omit(item, 'isPartial') as MonthAllocation;
            const value = +Number(newValue).toFixed(2);

            updateSingleAllocation({
              ...allocationData,
              value,
            });
          }
        }
      }, 200);
    },
    [methods, initialValues],
  );

  const blurOnEnterPress = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.currentTarget.querySelector('input')?.blur();
    }
  }, []);

  if (!mounted) {
    const isEmptyData =
      !consultantSection.length && !expensesSection.length && !vacanciesSection.length && !loading;
    return (
      <>
        {isEmptyData ? (
          l10n.getString(EMPTY_BUDGETING_FTL)
        ) : (
          <Table>
            <TableBody>
              <TableSkeleton rowsNumber={6} cellsNumber={1} total={12} page={1} />
            </TableBody>
          </Table>
        )}
      </>
    );
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      {/*TODO: VREP-484 Elements should be hidden*/}
      {/* <TableScrollNavs element={tableWrapper.current} /> */}
      <FormProvider {...methods}>
        <TableContainer
          ref={tableWrapper}
          component={Paper}
          className={styles.container}
          style={{ height: `calc(100vh - ${tableOffsetTop}px)` }}>
          <Table stickyHeader={true} className={cn(styles.table, styles.scrollableTable)}>
            <BudgetingTableHead months={tableHeaderMonths} title={title} l10n={l10n} />

            <TableBody>
              <TableRow>
                <TableCell colSpan={COL_SPAN} padding='none'>
                  <Accordion
                    title={l10n.getString(EDIT_CLIENTS_FTL)}
                    accordionProps={{ className: `${styles.accordion}` }}
                    isOpened={true}>
                    <Table className={cn(styles.table, styles.innerTable)}>
                      <TableBody>
                        <TableRecords
                          records={consultantSection}
                          readOnly={readOnly}
                          group={BudgetingGroupsEnum.Assignment}
                          onSelect={onSelect}
                          selectedForCopy={selectedForCopy}
                          onBlur={handleBlurSubmit}
                          onFieldKeyPress={blurOnEnterPress}
                          monthsWithBilling={monthsWithBilling}
                          currency={currency}
                        />
                      </TableBody>
                    </Table>
                  </Accordion>
                  <Table className={cn(styles.table, styles.innerTable)}>
                    <TableBody>
                      <GroupTotalRow
                        title={TOTAL_CONSULTANTS_FTL}
                        data={consultantsSectionTotals}
                        l10n={l10n}
                        currency={currency}
                      />
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={COL_SPAN} padding='none'>
                  <Accordion
                    title={l10n.getString(EDIT_VACANCIES_FTL)}
                    accordionProps={{ className: `${styles.accordion}` }}
                    isOpened={true}>
                    <Table className={cn(styles.table, styles.innerTable)}>
                      <TableBody>
                        <TableRecords
                          records={vacanciesSection}
                          readOnly={readOnly}
                          group={BudgetingGroupsEnum.Vacancy}
                          onSelect={onSelect}
                          selectedForCopy={selectedForCopy}
                          onBlur={handleBlurSubmit}
                          onFieldKeyPress={blurOnEnterPress}
                          monthsWithBilling={monthsWithBilling}
                          currency={currency}
                        />
                      </TableBody>
                    </Table>
                  </Accordion>
                  <Table className={cn(styles.table, styles.innerTable)}>
                    <TableBody>
                      <GroupTotalRow
                        title={TOTAL_VACANCIES_FTL}
                        data={vacanciesSectionTotals}
                        l10n={l10n}
                        currency={currency}
                      />
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={COL_SPAN} padding='none'>
                  <Accordion
                    title={l10n.getString(EDIT_EXPENSES_FTL)}
                    accordionProps={{ className: `${styles.accordion}` }}
                    isOpened={true}>
                    <Table className={cn(styles.table, styles.innerTable)}>
                      <TableBody>
                        <TableRecords
                          records={expensesSection}
                          readOnly={readOnly}
                          group={BudgetingGroupsEnum.Expense}
                          onSelect={onSelect}
                          selectedForCopy={selectedForCopy}
                          onBlur={handleBlurSubmit}
                          monthsWithBilling={monthsWithBilling}
                          currency={currency}
                        />
                      </TableBody>
                    </Table>
                  </Accordion>
                  <Table className={cn(styles.table, styles.innerTable)}>
                    <TableBody>
                      <GroupTotalRow
                        title={TOTAL_EXPENSES_FTL}
                        data={expensesSectionTotals}
                        l10n={l10n}
                        currency={currency}
                      />
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </TableBody>

            <BudgetingTableFooter
              l10n={l10n}
              forecastedData={forecastTotal}
              invoicedData={invoicedTotals}
              invoicedTotalsByEntities={invoicedTotalsByEntities}
              forecastedTotalsByEntities={forecastedTotalsByEntities}
              consultantsSectionTotals={consultantsSectionTotals}
              currency={currency}
              accessLevel={accessLevel}
            />
          </Table>
        </TableContainer>
      </FormProvider>
    </form>
  );
};

export default EditBudgetingTable;
