import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showSystemError } from 'common/apolloState/system';

import {
  useDeleteAssignementMutation,
  useUpdateBudgetMutation,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';
import { getErrorMessage, parseErrors } from 'valtech-core/common/utils/getErrorMessage';

type useDeleteAssignmentReturn = {
  loading: boolean;
  deleteAssignment: (assignmentId: Maybe<number>, fromBilling?: boolean) => Promise<boolean>;
};

export const useDeleteAssignment = (): useDeleteAssignmentReturn => {
  const { budgetReportId } = useBudgetingState();
  const { id } = useParams();
  const pageId = Number(id);

  const [deleteAssignmentMutation, { loading }] = useDeleteAssignementMutation({
    onError(e) {
      const hasErrors = parseErrors(e.message);
      hasErrors && showSystemError(getErrorMessage(e.message));
    },
  });

  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const deleteAssignment = useCallback(
    async (assignmentId: Maybe<number>, fromBilling = false) => {
      if (assignmentId) {
        const billingPage = fromBilling && pageId;
        const deletedAssignment = await deleteAssignmentMutation({
          variables: {
            assignmentId: Number(assignmentId),
            fromBilling,
          },
          refetchQueries: [billingPage ? 'SingleBillingInfo' : 'getSingleBudgeting'],
        });

        if (!billingPage) {
          await UpdateBudgetMutation({
            variables: {
              budgetId: Number(budgetReportId),
            },
          });
        }

        return !!deletedAssignment.data?.deleteAssignement;
      }

      return false;
    },
    [budgetReportId],
  );

  return {
    loading,
    deleteAssignment,
  };
};
