import { ConsultantOvertimeEnum } from 'common/types';
import round from 'lodash/round';
import { IOvertime } from 'pages/SingleBilling/SingleBilling.types';

import { Maybe } from 'valtech-core/common/types';

export type FormattedOvertime = {
  billing_report_row_id: number;
  coefficient: number;
  note: string;
  order: number;
  qty: number;
  total: number;
  type: string;
};

type getFormattedOvertimeDataParams = {
  overtimeId?: number;
  overtimesArray: (IOvertime | FormattedOvertime)[];
  billing_report_row_id: number;
  workingHoursPerMonth: Maybe<number>;
  rate: number;
  model: string;
};

const calcFormattedOvertimeTotal = ({
  overtime,
  workingHoursPerMonth,
  rate,
  model,
}): number | null => {
  let formattedOvertimeTotal = 0;

  if (!workingHoursPerMonth || !rate || !model) {
    return null;
  }

  try {
    if (overtime && overtime.type === ConsultantOvertimeEnum.Bill) {
      formattedOvertimeTotal = rate * (Number(overtime.hours) * Number(overtime.multiplier));
    } else {
      // TODO: add calculations for Vacation Overtime
      return null;
    }
    return formattedOvertimeTotal > 0 ? round(formattedOvertimeTotal, 6) : null;
  } catch (e) {
    return null;
  }
};

export const getFormattedOvertimeData = ({
  overtimesArray,
  billing_report_row_id,
  workingHoursPerMonth,
  rate,
  model,
}: getFormattedOvertimeDataParams): FormattedOvertime | null => {
  const overtime = overtimesArray.find(item => !item.hasOwnProperty('overtimeId')) as IOvertime;
  const overtimeIndex = overtimesArray.findIndex(item => !item.hasOwnProperty('overtimeId'));

  const formattedOvertimeTotal = calcFormattedOvertimeTotal({
    overtime,
    workingHoursPerMonth,
    rate,
    model,
  });

  if (overtime && overtimeIndex !== undefined && overtimeIndex !== null) {
    return {
      billing_report_row_id,
      coefficient: Number(overtime.multiplier),
      note: overtime.comment,
      order: overtimeIndex,
      qty: Number(overtime.hours),
      type: overtime.type,
      total: formattedOvertimeTotal,
    } as FormattedOvertime;
  } else return null;
};

export const getFormattedOvertimeOnDelete = ({
  overtimeId,
  overtimesArray,
  workingHoursPerMonth,
  rate,
  model,
}: Omit<getFormattedOvertimeDataParams, 'billing_report_row_id'>): number => {
  const overtime = overtimesArray.find(item => item['overtimeId'] === overtimeId);

  const formattedOvertimeTotal = calcFormattedOvertimeTotal({
    overtime,
    workingHoursPerMonth,
    rate,
    model,
  });

  return formattedOvertimeTotal ?? 0;
};
