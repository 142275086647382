import React, { FC } from 'react';

import { Localized } from '@fluent/react';
import cn from 'classnames';

import Typography from 'valtech-core/ui/Typography';

import styles from './FormError.module.scss';

interface FormErrorProps {
  message?: string;
}

const FormError: FC<FormErrorProps> = ({ message }) => {
  if (!message) return null;

  return (
    <Typography variant='caption' className={cn(styles.error, 'xk-form-error')} component='p'>
      <Localized id={message} />
    </Typography>
  );
};

export default FormError;
