import { SETTINGS_ROUTE } from 'valtech-core/common/routes';

export const SETTINGS_ADD_ROUTE = 'add';
export const SETTINGS_ID_ROUTE = ':id';

//Timesheets routes
export const SETTINGS_TIMESHEETS_ROUTE = 'timesheets';

//Accounts routs
export const SETTINGS_ACCOUNT_ROUTE = 'accounts';
export const SETTINGS_ACCOUNT_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_ACCOUNT_ROUTE}`;
export const SETTINGS_ADD_ACCOUNT_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/add`;
export const SETTINGS_EDIT_ACCOUNT_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/edit/:id`;
export const SETTINGS_EDIT_ACCOUNT_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_EDIT_ACCOUNT_ROUTE}`;

//Projects routs
export const SETTINGS_PROJECT_ROUTE = 'projects';
export const SETTINGS_PROJECT_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_PROJECT_ROUTE}`;
export const SETTINGS_ADD_PROJECT_ROUTE = `${SETTINGS_PROJECT_ROUTE}/add`;
export const SETTINGS_EDIT_PROJECT_ROUTE = `${SETTINGS_PROJECT_ROUTE}/edit/:id`;
export const SETTINGS_EDIT_PROJECT_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_EDIT_PROJECT_ROUTE}`;

//Consultants routs
export const SETTINGS_CONSULTANTS_ROUTE = 'consultants';
export const SETTINGS_CONSULTANTS_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_CONSULTANTS_ROUTE}`;

//Budgeting routs
export const SETTINGS_BUDGETING_ROUTE = 'budgetings';
export const SETTINGS_BUDGETING_ADD_ROUTE = `${SETTINGS_BUDGETING_ROUTE}/${SETTINGS_ADD_ROUTE}`;
export const SETTINGS_BUDGETING_ID_ROUTE = `${SETTINGS_BUDGETING_ROUTE}/${SETTINGS_ID_ROUTE}`;
export const SETTINGS_BUDGETING_ID_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_BUDGETING_ID_ROUTE}`;

//Entities routs
export const SETTINGS_ENTITIES_ROUTE = 'entities';

//Users routs
export const SETTINGS_USERS_ROUTE = 'users';

//Holiday calendar routs
export const SETTINGS_HOLIDAY_CALENDAR_ROUTE = 'holiday_calendar';
export const SETTINGS_HOLIDAY_CALENDAR_PAGE_ROUTE = `${SETTINGS_ROUTE}/${SETTINGS_HOLIDAY_CALENDAR_ROUTE}`;
export const SETTINGS_ADD_HOLIDAY_CALENDAR_ROUTE = `${SETTINGS_HOLIDAY_CALENDAR_ROUTE}/${SETTINGS_ADD_ROUTE}`;
export const SETTINGS_EDIT_HOLIDAY_CALENDAR_ROUTE = `${SETTINGS_HOLIDAY_CALENDAR_ROUTE}/${SETTINGS_ID_ROUTE}`;
export const SETTINGS_HOLIDAY_CALENDAR_EDIT_PAGE_ROUTE = `${SETTINGS_HOLIDAY_CALENDAR_PAGE_ROUTE}/${SETTINGS_ID_ROUTE}`;
