import {
  EditProjectVariables,
  SingleProjectFormProps,
} from 'pages/ManageSettings/SingleProject/SingleProject';
import { IAccount } from 'src/common/hooks/useGetAccounts/useGetAccounts';

import moment from 'moment';

import {
  ActivationStatus,
  CreateUserProjectMutationVariables,
  EditUserProjectByIdMutationVariables,
} from 'valtech-core/common/gql/generated';
import { AnyArray, DateMask } from 'valtech-core/common/types';

export interface IDeliveryManager {
  create_time: string;
  email: string;
  id: string | number;
  name: string;
  role: string;
  status: string;
}

interface IAccountFormattedArray {
  id: string | number;
  title: string;
  value: string;
}

interface IDeliveryManagerFormattedArray {
  id: string | number;
  title: string;
  value: string;
}
export interface IDeliveryManagerRole {
  name: string;
  order: number;
}

export const mapToSubmitAddProject = (
  data: SingleProjectFormProps,
): CreateUserProjectMutationVariables => {
  const dataMSA = {
    accountId: Number(data.accountId),
    buId: String(data.businessUnitId),
    name: String(data.projectName),
    ccy: String(data.currency),
    status: data.status as ActivationStatus,
    deliveryManagerId: Number(data.deliveryManagerId),
    msaNo: String(data.msaNumber),
    type: String(data.msaType),
  };

  return data.msaDate
    ? { ...dataMSA, msaDate: moment(data.msaDate).format(DateMask.YYYY_MM_DD) }
    : { ...dataMSA };
};

export const mapToSubmitEditProject = (
  data: EditProjectVariables,
): EditUserProjectByIdMutationVariables => {
  const dataMSA = {
    accountId: Number(data.accountId),
    id: data.id,
    buId: String(data.businessUnitId),
    name: String(data.projectName),
    ccy: String(data.currency),
    status: data.status as ActivationStatus,
    deliveryManagerId: Number(data.deliveryManagerId),
    msaNo: String(data.msaNumber),
    type: String(data.msaType),
  };

  return data.msaDate
    ? {
        ...dataMSA,
        msaDate: moment(data.msaDate).format(DateMask.YYYY_MM_DD),
      }
    : {
        ...dataMSA,
      };
};

export const formattedArrayOfDeliveryManagers = (
  arr: IDeliveryManager[],
): Array<IDeliveryManagerFormattedArray> => {
  const formattedArrayOfDeliveryManagers: Array<IDeliveryManagerFormattedArray> = [];
  arr
    .filter(el => el.status === ActivationStatus.Active)
    .forEach(el => {
      formattedArrayOfDeliveryManagers.push({
        id: el.id,
        title: el.name,
        value: String(el.id),
      });
    });

  return formattedArrayOfDeliveryManagers;
};

export const formattedArrayOfAllAccounts = (arr: IAccount[]): Array<IAccountFormattedArray> => {
  const formattedArrayOfAccounts: Array<IAccountFormattedArray> = [];
  arr
    .filter(el => el.status === ActivationStatus.Active)
    .forEach(el => {
      formattedArrayOfAccounts.push({
        id: el.id,
        title: el.name,
        value: String(el.id),
      });
    });

  return formattedArrayOfAccounts;
};

export const generateFormFieldData = (
  id: string | number | undefined,
  titleKey: string,
  array?: AnyArray,
): {
  id?: string | number;
  label: string;
} => {
  return {
    id,
    label: array?.find(item => item.id === id)?.[titleKey] || '',
  };
};
