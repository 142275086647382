import React, { FC, useState } from 'react';

import EditingContext from './editing.context';

interface IEditingContextProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

const EditingContextProvider: FC<IEditingContextProviderProps> = ({ children }) => {
  const [isEditing, setState] = useState<boolean>(false);

  return (
    <EditingContext.Provider value={{ isEditing, setState }}>{children}</EditingContext.Provider>
  );
};

export default EditingContextProvider;
