export const DEFAULT_REQUEST_LIMIT = 10;
export const MAX_REQUEST_LIMIT = 50;
export const EURO_SIGN_CODE = 8364;
export const DOLLAR_SIGN_CODE = 36;

const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '4007';
const apiUrlBase = isLocalhost
  ? process.env.REACT_APP_OIDC_CLIENT_REDIRECT_URI_BASE
  : window.location.origin;

export const logOutUrlParts = {
  authority: 'https://login.microsoftonline.com/139f0669-cebb-42df-b9e3-415009ddb690/oauth2/v2.0',
  redirect_uri: `${apiUrlBase}/login`,
};
