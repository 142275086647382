import { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  clearBudgetingState,
  setBudgetingState,
  useBudgetingState,
} from 'common/apolloState/budgeting';
import { showGenericError, showSystemError } from 'common/apolloState/system';
import { COST_OF_OVERHEAD_DEFAULT, TEMP_ICBO_TEMPLATEID } from 'common/constants';
import { useWorkingDaysForTheYear } from 'common/hooks/useWorkingDaysForTheYear';
import { SingleBillingTableTypeEnum } from 'common/types';
import { getFirstAndLastDayOfMonth } from 'common/utils/getFirstAndLastDayOfMonth';
import { isNil } from 'lodash';
import round from 'lodash/round';

import { NO_DATA_FTL } from 'valtech-core/common/ftl';
import {
  AssignementConsultant,
  AssignementExpense,
  BillingStatus,
  Overtime,
  useGetBudgetSettingsLazyQuery,
  useSingleBillingInfoLazyQuery,
  useUpdateBillingReportTotalMutation,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum, DateMask, InputMaybe, Maybe } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import getCurrencyCode from 'valtech-core/common/utils/getCurrencyCode';
import getCurrencySign from 'valtech-core/common/utils/getCurrencySign';

import { getAssignmentOvertimesInfo, getWorkingHoursPerMonth } from './SingleBilling.utils';

import {
  IAssignment,
  IAssignmentsData,
  IBillingPageData,
  IClientsData,
  IConsultantsData,
  IExpense,
  ISingleBillingPageData,
  IUseSingleBillingReturn,
} from './SingleBilling.types';
import { useBillingState } from './SingleBillingContextProvider';

export const useSingleBilling = (): IUseSingleBillingReturn => {
  const { id: billingId } = useParams();
  const { setStateParam } = useBillingState();
  const { assignmentDefaults } = useBudgetingState();
  const [getSingleBilling, { data, loading }] = useSingleBillingInfoLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: newData => {
      const billingData = newData.getBillingReportByID;
      if (
        !billingData.billingReportRowInfo.length &&
        !billingData.assignmentConsultantInfo.length &&
        !billingData.expenseInfo.length
      ) {
        showSystemError(NO_DATA_FTL);
      }
    },
  });
  const [getBudgetSettings, { data: budgetSettingsFromApi }] = useGetBudgetSettingsLazyQuery();
  const { getWorkingDays, workingDays } = useWorkingDaysForTheYear();

  useEffect(() => {
    if (billingId) {
      getSingleBilling({
        variables: {
          getBillingReportByIdId: Number(billingId),
        },
      });
    }
  }, [billingId]);

  useEffect(() => {
    return () => {
      clearBudgetingState();
    };
  }, []);

  useEffect(() => {
    const budgetId = data?.getBillingReportByID.billingReportInfo.budgetReportInfo?.id;

    if (budgetId) {
      getBudgetSettings({
        variables: {
          id: budgetId,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const budgetSettingInfo =
      budgetSettingsFromApi?.V2getBudgetReportByID.budgetInfo?.budgetingSettingInfo;

    setBudgetingState({
      financialYear: budgetSettingInfo?.financial_year,
      assignmentDefaults: {
        ...assignmentDefaults,
        costOfOverhead: {
          EUR: budgetSettingInfo?.cost_of_overhead_eur || COST_OF_OVERHEAD_DEFAULT,
          USD: budgetSettingInfo?.cost_of_overhead_usd || COST_OF_OVERHEAD_DEFAULT,
        },
      },
    });
  }, [budgetSettingsFromApi]);

  const projectId = data?.getBillingReportByID.billingReportInfo.project_id;

  const settingId = data?.getBillingReportByID.billingReportRowInfo.length
    ? data?.getBillingReportByID.billingReportRowInfo?.[0].assignmentInfo.budgetInfo
        ?.budgetingSettingInfo?.id
    : null;

  const budgetReportId = data?.getBillingReportByID.billingReportInfo.budgetReportInfo?.id
    ? data?.getBillingReportByID.billingReportInfo.budgetReportInfo?.id
    : null;

  const holidayCalendarId = data?.getBillingReportByID.assignmentConsultantInfo.length
    ? data?.getBillingReportByID.assignmentConsultantInfo?.[0].holiday_calendar_id
    : null;
  const year = String(data?.getBillingReportByID.billingReportInfo.financial_year) || null;
  const monthNumber = data?.getBillingReportByID.billingReportInfo.month;

  useEffect(() => {
    setBudgetingState({
      projectId,
      settingId,
      budgetReportId,
    });
  }, [projectId, settingId, budgetReportId]);

  useEffect(() => {
    if (holidayCalendarId && year) {
      getWorkingDays(holidayCalendarId, Number(year));
    }
  }, [holidayCalendarId, year]);

  const workingHoursPerMonth = getWorkingHoursPerMonth(workingDays, monthNumber);

  const singleBilling = useMemo((): ISingleBillingPageData => {
    if (!data) {
      return {
        billingPageData: {
          currency: CurrencyEnum.EUR,
          currencySign: '',
          headerTitle: '',
          title: '',
          status: undefined,
          modified_date: '',
          modifiedBy: undefined,
          totalForInvoicing: undefined,
          financialYear: undefined,
          gross_margin: null,
          projectName: '',
          project_id: undefined,
          billingStartDay: undefined,
          billingEndDay: undefined,
          billingMonth: undefined,
        },
        clientsData: [],
        consultantsData: [],
        allConsultantsTotal: undefined,
        allClientsTotal: undefined,
        globalTotal: undefined,
        globalTotalFE: undefined,
        assignmentsCurrencyData: [],
      };
    }

    const accessLevel = data.getBillingReportByID?.access_level;
    const consultantsInfo = data.getBillingReportByID
      .assignmentConsultantInfo as AssignementConsultant[];
    const billingPageInfo = data.getBillingReportByID.billingReportInfo;
    const currency = billingPageInfo?.projectInfo?.ccy;
    const clientsInfo = data.getBillingReportByID.billingReportRowInfo;
    const expensesInfo = data.getBillingReportByID.expenseInfo as AssignementExpense[];
    const overtimeInfo = data.getBillingReportByID.overtimeInfo as Overtime[];

    const consultantsData = consultantsInfo.reduce<IConsultantsData[]>(
      (consultantsDataAcc, consultantsInfoItem) => {
        const consultantId = consultantsInfoItem.consultantInfo?.id;
        const assignmentId = consultantsInfoItem.assignementInfo?.id;
        const fromBilling = consultantsInfoItem.assignementInfo?.from_billing;
        const billingReportRowRes = clientsInfo.find(i => i.assignement_id === assignmentId);

        if (billingReportRowRes && consultantId && assignmentId) {
          const [overtimes, overtimesTotal, overtimesHours] = getAssignmentOvertimesInfo(
            billingReportRowRes.id,
            overtimeInfo,
          );

          const assignment: IAssignment = {
            id: billingReportRowRes.id,
            assignmentId,
            clientId: billingReportRowRes.assignmentInfo.clientInfo?.id,
            billingReportId: billingReportRowRes?.billing_report_id,
            icboTemplateId: TEMP_ICBO_TEMPLATEID, //TODO: remove hardcoded, Sept 2024 functionality is not developed
            ccy: getCurrencyCode(billingPageInfo.projectInfo?.ccy),
            title: billingReportRowRes.assignmentInfo.clientInfo?.name,
            clientExpenses: billingReportRowRes.expense_total || 0,
            invoiced: billingReportRowRes?.billingReportInfo?.status === BillingStatus.Invoiced,
            budgetingSettingId:
              billingReportRowRes?.assignmentInfo?.budgetInfo?.budgetingSettingInfo?.id,
            grossMargin: !isNil(billingReportRowRes?.gross_margin)
              ? billingReportRowRes?.gross_margin
              : null,
            locked:
              billingPageInfo.project_id !==
              billingReportRowRes?.assignmentInfo.clientInfo?.project_id,
            model: consultantsInfoItem.rateInfo?.model,
            allocation: consultantsInfoItem.percentage
              ? round(consultantsInfoItem.percentage * 100, 6)
              : null,
            startDate: billingReportRowRes.assignmentInfo.start_date,
            endDate: billingReportRowRes.assignmentInfo.end_date,
            rate: consultantsInfoItem.rateInfo?.fee || consultantsInfoItem.fee || 0,
            hours: isNil(billingReportRowRes.correction_qty)
              ? billingReportRowRes?.original_qty || 0
              : billingReportRowRes.correction_qty,
            total: isNil(billingReportRowRes.correction_total)
              ? billingReportRowRes.original_total || 0
              : billingReportRowRes.correction_total,
            msg: billingReportRowRes.assignmentInfo.notes,
            warning: '',
            overtimes,
            overtimesTotal,
            overtimesHours,
            holidayCalendarId: consultantsInfoItem.holiday_calendar_id,
            rateData: {
              value: `${createNumberWithCurrency({
                number: consultantsInfoItem.rateInfo?.fee || consultantsInfoItem.fee || 0,
                currencyDisplay: 'symbol',
                currency: getCurrencyCode(currency),
              })}`,
              id: consultantsInfoItem?.rateInfo?.id || 0,
              title: consultantsInfoItem.rateInfo?.title || '',
            },
            costOfOverhead: consultantsInfoItem?.cost_of_overhead || 0,
            tabsValues: {
              consultantsExpensesTabValues: {
                correctionExpense: billingReportRowRes?.expense_total,
                expenseNote: billingReportRowRes?.expense_note,
              },
              reportCorrectionTabValues: {
                reportCorrectionQty: billingReportRowRes.correction_qty ?? undefined,
                reportCorrectionOriginalQty: billingReportRowRes.original_qty,
                reportCorrectionNote: billingReportRowRes.correction_note,
                isDisabled: Boolean(fromBilling),
              },
              overtimesInitialTabValues: overtimes,
            },
            fromBilling,
            showInBudget: billingReportRowRes?.assignmentInfo?.show_in_budget,
          };

          const assignmentsTotal = !assignment.locked
            ? round(assignment.total + assignment.overtimesTotal + assignment.clientExpenses, 6)
            : 0;

          const consultantIndex = consultantsDataAcc.findIndex(c => c.id === consultantId);

          if (consultantIndex > -1) {
            const existingConsultant = consultantsDataAcc[consultantIndex];
            existingConsultant.projects.push(assignment);
            existingConsultant.hoursAllAccounts = round(
              existingConsultant.hoursAllAccounts + assignment.hours,
              6,
            );

            if (!assignment.locked) {
              existingConsultant.hours = round(existingConsultant.hours + assignment.hours, 6);
              existingConsultant.total = round(assignmentsTotal + existingConsultant.total, 6);

              existingConsultant.totalOvertimesHours = round(
                existingConsultant.totalOvertimesHours + assignment.overtimesHours,
                6,
              );
            }

            consultantsDataAcc[consultantIndex] = existingConsultant;
          } else {
            const newConsultant: IConsultantsData = {
              id: consultantId,
              title: `${consultantsInfoItem.consultantInfo?.first_name} ${consultantsInfoItem.consultantInfo?.last_name}`,
              subtitle: consultantsInfoItem.consultantInfo?.role,
              projects: [assignment],
              totalOvertimesHours: round(assignment.overtimesHours, 6),
              hours: !assignment.locked ? round(assignment.hours, 6) : 0,
              hoursAllAccounts: round(assignment.hours, 6),
              total: round(assignmentsTotal, 6),
              tableName: SingleBillingTableTypeEnum.Consultants,
            };

            consultantsDataAcc.push(newConsultant);
          }
        }

        return consultantsDataAcc;
      },
      [],
    );

    const clientsData = expensesInfo.reduce<IClientsData[]>((clientsDataAcc, expensesInfoItem) => {
      const expenseId = expensesInfoItem.id;
      const assignmentId = expensesInfoItem.assignementInfo?.id;
      const billingReportRowRes = clientsInfo.find(i => i.assignement_id === assignmentId);

      if (billingReportRowRes && expenseId && assignmentId) {
        const quantity =
          billingReportRowRes.correction_qty || billingReportRowRes.original_qty || 0;
        const cost = round(
          billingReportRowRes.correction_expense || billingReportRowRes.original_total || 0,
          6,
        );
        const total = round(cost * quantity, 6);
        const clientId = billingReportRowRes.assignmentInfo?.clientInfo?.id;

        const expense: IExpense = {
          id: billingReportRowRes.id,
          assignmentId,
          title: billingReportRowRes.correction_title || billingReportRowRes.original_title,
          startDate: billingReportRowRes.assignmentInfo.start_date,
          endDate: billingReportRowRes.assignmentInfo.end_date,
          cost,
          quantity,
          total,
          msg: billingReportRowRes.assignmentInfo.notes,
          warning: '',
          tabsValues: {
            clientsExpensesTabValues: {
              correctionQty: billingReportRowRes.correction_qty,
              correctionClientsExpense: billingReportRowRes.correction_expense,
              correctionTitle: billingReportRowRes.correction_title,
            },
          },
        };

        const clientIndex = clientsDataAcc.findIndex(c => c.id === clientId);

        if (clientIndex > -1) {
          const existingClient = clientsDataAcc[clientIndex];
          existingClient.projects.push(expense);
          existingClient.quantity = existingClient.quantity + expense.quantity;
          existingClient.total = round(existingClient.total + expense.total, 6);
          clientsDataAcc[clientIndex] = existingClient;
        } else {
          const newClient: IClientsData = {
            id: clientId,
            title: billingReportRowRes.assignmentInfo?.clientInfo?.name,
            projects: [expense],
            quantity: expense.quantity,
            total: round(expense.total, 6),
            tableName: SingleBillingTableTypeEnum.Clients,
          };

          clientsDataAcc.push(newClient);
        }
      }

      return clientsDataAcc;
    }, []);

    const allConsultantsTotal = +consultantsData.reduce(
      (acc, item) => (acc += Number(item.total)),
      0,
    );

    const allClientsTotal = +clientsData.reduce((acc, item) => (acc += Number(item.total)), 0);

    const globalTotal = round(Number(billingPageInfo.total), 2) ?? undefined;

    const globalTotalFE = allConsultantsTotal
      ? round(allClientsTotal + allConsultantsTotal, 2)
      : undefined;

    const billingPageData: IBillingPageData = {
      currency: getCurrencyCode(billingPageInfo.projectInfo?.ccy),
      currencySign: getCurrencySign(billingPageInfo.projectInfo?.ccy) || '',
      headerTitle: billingPageInfo.budgetReportInfo?.budgetingSettingInfo?.title,
      title: billingPageInfo.title || '',
      status: billingPageInfo.status || undefined,
      modified_date: billingPageInfo.modified_date || '',
      modifiedBy: billingPageInfo.modified_by || undefined,
      totalForInvoicing: globalTotal,
      financialYear: billingPageInfo.financial_year || undefined,
      gross_margin: billingPageInfo.gross_margin || null,
      projectName: billingPageInfo.projectInfo?.name || '',
      project_id: billingPageInfo.project_id || undefined,
      billingMonth: billingPageInfo.month || undefined,
      billingStartDay: getFirstAndLastDayOfMonth(billingPageInfo.title, DateMask.MMMM_YYYY)
        .startDay,
      billingEndDay: getFirstAndLastDayOfMonth(billingPageInfo.title, DateMask.MMMM_YYYY).endDay,
    };

    const assignmentsCurrencyData = clientsInfo.reduce<IAssignmentsData[]>(
      (acc, assignmentData) => {
        const assignmentCurrencyData = {
          id: assignmentData?.assignement_id,
          projectCurrency: assignmentData.assignmentInfo?.clientInfo?.projectInfo?.ccy,
        };
        acc.push(assignmentCurrencyData);
        return acc;
      },
      [],
    );
    return {
      accessLevel,
      clientsData,
      consultantsData,
      billingPageData,
      allClientsTotal,
      allConsultantsTotal,
      globalTotalFE,
      globalTotal,
      assignmentsCurrencyData,
    };
  }, [data, workingHoursPerMonth]);

  useEffect(() => {
    setStateParam({
      billingInfo: {
        status: singleBilling?.billingPageData.status,
        projectName: singleBilling?.billingPageData.projectName,
        project_id: singleBilling?.billingPageData.project_id,
        financialYear: singleBilling?.billingPageData.financialYear,
        title: singleBilling.billingPageData.title,
        currencySign: singleBilling.billingPageData.currencySign,
        currency: singleBilling.billingPageData.currency,
        billingStartDay: singleBilling.billingPageData.billingStartDay,
        billingEndDay: singleBilling.billingPageData.billingEndDay,
        billingMonth: singleBilling.billingPageData.billingMonth,
        assignmentsCurrencyData: singleBilling.assignmentsCurrencyData,
        workingHoursPerMonth,
      },
      billingHistory: [],
      accessLevel: singleBilling?.accessLevel,
    });

    setBudgetingState({
      currency: singleBilling?.billingPageData.currency,
    });
  }, [singleBilling, workingHoursPerMonth]);

  return {
    singleBilling,
    loading,
  };
};

type UpdateSingleBillingTotal = (
  billingReportId: Maybe<string>,
  total: Maybe<number>,
  grossMargin: InputMaybe<number>,
) => Promise<boolean | undefined>;

interface IUseUpdateSingleBillingReturn {
  updateSingleBillingTotal: UpdateSingleBillingTotal;
  loading: boolean;
}

export const useUpdateSingleBilling = (): IUseUpdateSingleBillingReturn => {
  const [updateBillingReportTotalMutation, { loading }] = useUpdateBillingReportTotalMutation({
    onError() {
      showGenericError();
    },
  });

  const updateSingleBillingTotal = useCallback(async (billingReportId, total, grossMargin) => {
    try {
      if (billingReportId) {
        const response = await updateBillingReportTotalMutation({
          variables: {
            billingReportId: Number(billingReportId),
            total: round(total, 2),
            grossMargin: grossMargin,
          },
        });

        return !!response.data?.updateBillingReport;
      }
    } catch (_) {}
  }, []);

  return {
    updateSingleBillingTotal,
    loading,
  };
};
