import React, { ElementType, FC } from 'react';

import cn from 'classnames';

import MUIButton, { ButtonProps } from '@mui/material/Button';

import styles from './Button.module.scss';

export interface IButtonProps extends ButtonProps {
  to?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
}

const Button: FC<IButtonProps> = ({ children, className, ...rest }) => {
  return (
    <MUIButton {...rest} className={cn(styles.btn, className)}>
      {children}
    </MUIButton>
  );
};

export default Button;
