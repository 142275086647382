import { useMemo } from 'react';

import { MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { Filter, useGetHolidayCalendarListQuery } from 'valtech-core/common/gql/generated';

type HolidayItem = { id: number; label: string; businessUnit: string; businessUnitId: string };

type UseHolidayCalendarListReturn = {
  holidayCalendar: HolidayItem[];
  loading: boolean;
};

type UseHolidayCalendarListParams = {
  filter?: Filter[];
};

export const useHolidayCalendarList = ({
  filter,
}: UseHolidayCalendarListParams): UseHolidayCalendarListReturn => {
  const { data, loading } = useGetHolidayCalendarListQuery({
    variables: {
      filter,
      pagination: {
        limit: MAX_REQUEST_LIMIT,
      },
    },
  });

  const holidayCalendar = useMemo((): HolidayItem[] => {
    if (!data) {
      return [];
    }

    return (data.sendArrayOfHolidayCalendar.data || []).map(calendar => ({
      id: calendar.id,
      label: calendar.title,
      businessUnit: calendar.businessUnitInfo?.label || '',
      businessUnitId: calendar.businessUnitInfo?.id || '',
    }));
  }, [data]);

  return {
    holidayCalendar,
    loading,
  };
};
