import React, { FC, useEffect, useState } from 'react';

import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Maybe } from 'valtech-core/common/types';

import { DrawerItemsList } from './Drawer.types';

interface IDrawerButtonsListProps {
  data: DrawerItemsList[];
  activeItemOnLoad?: string;
  clearState?: boolean;
}

const DrawerButtonsList: FC<IDrawerButtonsListProps> = ({ data, activeItemOnLoad, clearState }) => {
  const [current, setCurrent] = useState<Maybe<string>>();

  useEffect(() => {
    if (activeItemOnLoad) {
      const activeItemIndex = data.findIndex(el => el.title === activeItemOnLoad);

      if (!current && activeItemIndex >= 0) {
        setCurrent(`${activeItemIndex + activeItemOnLoad}`);
      }
    }
  }, [activeItemOnLoad, data, current]);

  useEffect(() => {
    if (clearState) {
      setCurrent(undefined);
    }
  }, [clearState]);

  return (
    <>
      {data.map(({ title, icon = <AutoAwesomeMosaicIcon />, onClick }, index) => {
        const itemId = `${index + title}`;
        const isCurrent = current === itemId ? 'active' : undefined;

        return (
          <ListItem key={itemId} disablePadding divider onClick={() => setCurrent(itemId)}>
            <ListItemButton onClick={onClick} component='button' className={isCurrent}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default DrawerButtonsList;
