import React, { FC, useCallback, useEffect } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { ConsultantModelEnum, SingleBillingTableTypeEnum } from 'common/types';
import SelectField from 'form/SelectField';
import TextField from 'form/TextField';
import isNil from 'lodash/isNil';
import { Overtimes } from 'pages/SingleBilling/SingleBilling.types';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import {
  useGeneralTab,
  useTMRateForConsultant,
} from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';
import { OVERTIME_SELECT_DATA } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabMockData';
import { useHandleSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';
import { showSystemError } from 'src/common/apolloState/system';

import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {
  conditionalOptionalStringValidator,
  hoursValidator,
  invalidNumberFormatValidator,
  requiredStringValidator,
} from 'valtech-core/common/form/validators';
import {
  ADD_COMMENT_FTL,
  BILL_FTL,
  GENERIC_ERROR,
  HOURS_FTL,
  MULTIPLIER_FTL,
  SAVE_FTL,
  VACATION_FTL,
} from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';

import { getFormattedOvertimeData, getFormattedOvertimeOnDelete } from './Ovretimes.utils';

import useHandleOvertime from './Overtime.hook';
import s from './Overtime.module.scss';

type OvertimeFormProps = {
  initialValues: Overtimes;
};

const Overtime: FC<OvertimeFormProps> = ({ initialValues }) => {
  const { l10n } = useLocalization();
  const { addOvertime, deleteOvertime } = useHandleOvertime();
  const { projectId } = useBudgetingState();
  const { addBillingReportComment } = useHandleSingleBillingTabs();
  const { tableName, projectData, deleteStateOvertimes, updateStateOvertimes, billingStatus } =
    useGeneralTab();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });
  const {
    state: { billingInfo },
  } = useBillingState();

  const tmRateFee = useTMRateForConsultant();

  const { fields, remove } = useFieldArray<Overtimes>({
    control,
    name: 'overtimes',
  });

  useEffect(() => {
    reset({ ...initialValues });
  }, [initialValues]);

  const initialTotal = useCallback(
    (id: number) => {
      return tableName === SingleBillingTableTypeEnum.Consultants &&
        projectData?.id &&
        !isNil(tmRateFee)
        ? getFormattedOvertimeOnDelete({
            overtimeId: id,
            overtimesArray: initialValues.overtimes,
            rate: tmRateFee,
            model: ConsultantModelEnum.TimeAndMaterial,
            workingHoursPerMonth: billingInfo?.workingHoursPerMonth,
          })
        : 0;
    },
    [tableName, tmRateFee],
  );

  const onSave = useCallback(
    data => {
      if (tableName === SingleBillingTableTypeEnum.Consultants && projectData?.id) {
        if (isNil(tmRateFee) || tmRateFee === 0) {
          showSystemError(GENERIC_ERROR);
          return;
        }

        const formattedOvertime = getFormattedOvertimeData({
          overtimesArray: data.overtimes,
          billing_report_row_id: projectData?.id,
          rate: tmRateFee,
          model: ConsultantModelEnum.TimeAndMaterial,
          workingHoursPerMonth: billingInfo?.workingHoursPerMonth,
        });

        if (
          formattedOvertime &&
          formattedOvertime.qty &&
          formattedOvertime.type &&
          formattedOvertime.coefficient
        ) {
          addOvertime(formattedOvertime).then(id => {
            if (id) {
              const stateOvertime = {
                ...data.overtimes[formattedOvertime.order],
                billing_report_row_id: Number(projectData?.id),
                overtimeId: id,
              };
              const hoursTotal = (formattedOvertime.qty * formattedOvertime.coefficient).toFixed(2);
              const comment = `Added ${hoursTotal}h of ${
                formattedOvertime?.type === 'bill' ? 'billable overtime' : 'overtime in vacation'
              } ${formattedOvertime?.note ? `with comment: "${formattedOvertime?.note}"` : ''}`;
              addBillingReportComment(projectData?.id, comment, projectId);
              updateStateOvertimes(stateOvertime);
            }
          });
        }
      }
    },
    [projectData?.id, tableName, tmRateFee],
  );

  const onDelete = useCallback(
    (id, index) => {
      deleteOvertime(Number(id), initialTotal(Number(id))).then(isSuccessful => {
        if (isSuccessful && projectId) {
          const comment = `Has removed added overtime`;
          remove(index);
          deleteStateOvertimes(id);
          addBillingReportComment(projectData?.id, comment, projectId);
        }
      });
    },
    [tableName],
  );

  // TODO: Check, if we need Add More Button, & onAppendOvertime
  // const onAppendOvertime = useCallback(async () => {
  //   const valid = await trigger();
  //
  //   if (valid) {
  //     append(DEFAULT_OVERTIME as IOvertime);
  //   }
  // }, []);

  return (
    <form onSubmit={e => e.preventDefault()}>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          {index !== 0 && <Divider sx={{ my: 3 }} />}

          <Box className={s.fieldsWrapper}>
            <TextField
              label={l10n.getString(HOURS_FTL)}
              name={`overtimes[${index}].hours`}
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              textProps={{
                size: 'small',
                type: 'number',
              }}
              validate={hoursValidator}
              disabled={!!field.overtimeId}
            />
            <div>X</div>
            <TextField
              label={l10n.getString(MULTIPLIER_FTL)}
              name={`overtimes[${index}].multiplier`}
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              textProps={{
                size: 'small',
                type: 'number',
              }}
              validate={invalidNumberFormatValidator}
              disabled={!!field.overtimeId}
            />
          </Box>

          <Box className={s.fieldsWrapper} sx={{ mt: 1 }}>
            <SelectField
              label={`${l10n.getString(BILL_FTL)} / ${l10n.getString(VACATION_FTL)}`}
              name={`overtimes[${index}].type`}
              data={OVERTIME_SELECT_DATA}
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              formControlProps={{
                size: 'small',
              }}
              // TODO VREP-837 temporary solution to disable type selector and set bill as default
              // disabled={!!field.overtimeId}
              disabled={true}
              validate={requiredStringValidator}
            />
            <Box sx={{ color: 'transparent' }}>X</Box>
            <TextField
              label={l10n.getString(ADD_COMMENT_FTL)}
              name={`overtimes[${index}].comment`}
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              textProps={{
                size: 'small',
              }}
              disabled={!!field.overtimeId}
              validate={conditionalOptionalStringValidator(!!field.overtimeId)}
            />
          </Box>

          {field?.overtimeId && (
            <IconButton
              aria-haspopup='true'
              sx={{ mt: '10px', ml: 'auto' }}
              disabled={billingStatus === BillingStatus.Invoiced}
              onClick={() => {
                onDelete(field?.overtimeId, index);
              }}>
              <DeleteIcon color='primary' fontSize='medium' />
            </IconButton>
          )}
        </Box>
      ))}

      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          variant='contained'
          type='submit'
          onClick={handleSubmit(onSave)}
          className={s.button}
          disabled={billingStatus === BillingStatus.Invoiced}>
          {l10n.getString(SAVE_FTL) || ''}
        </Button>
      </Box>
    </form>
  );
};

export default Overtime;
