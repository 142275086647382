import React, { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import Modal from 'components/Modal';
import AutocompleteField from 'form/AutocompleteField';
import SelectFiled from 'form/SelectField/SelectField';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import {
  CANCEL_FTL,
  CONFIRMATION_MESSAGE_FTL,
  DELETE_FTL,
  NAME_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';
import { useToggle } from 'valtech-core/utils/useToggle';

import { selectMockAccessLevel } from '../UserCredentialsTable.const';
import { UserCredentialsItemFieldsEnum } from './UserCredentialsItem.constants';
import clientItem from './UserCredentialsItem.module.scss';

export interface IUserCredentialsItem {
  id: number;
  name: { id: number; label: string };
  role: string;
}
export interface IDeleteUserCredentialsItem {
  clientId: number;
}
interface IUsersList {
  id: number;
  label: string;
}
interface IUserCredentialsItemProps {
  isLoading: boolean;
  isActive: boolean;
  rowId: number;
  onSave: (value: IUserCredentialsItem) => void;
  onSelect: () => void;
  onCancel: () => void;
  onRemove: (value: number) => void;
  onDelete: (value: IDeleteUserCredentialsItem) => void;
  values: {
    id: number;
    role: string;
    userInfo: { name: string; id: number };
  };
  usersList: IUsersList[];
  deliveryManagerId: number | undefined;
}

const UserCredentialsItem: FC<IUserCredentialsItemProps> = ({
  rowId,
  isLoading,
  isActive,
  onRemove,
  onSelect,
  onCancel,
  onSave,
  onDelete,
  values: { id, role, userInfo },
  usersList,
  deliveryManagerId,
}) => {
  const isDeliveryManager = deliveryManagerId === userInfo?.id;
  const defaultValues = {
    [UserCredentialsItemFieldsEnum.Name]: {
      id: userInfo?.id,
      label: userInfo?.name,
    },
    [UserCredentialsItemFieldsEnum.AccessLevel]: role,
  };
  const [isModalOpened, toggleDelete] = useToggle(false);
  const { l10n } = useLocalization();
  const { id: clientId } = useParams();
  const { control, trigger, getValues, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [isActive]);

  const cancel = () => {
    reset(defaultValues);
    onCancel();
  };

  const save = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();

    onSave({
      id,
      name: values[UserCredentialsItemFieldsEnum.Name],
      role: values[UserCredentialsItemFieldsEnum.AccessLevel],
    });
    onCancel();
  };

  const deleteItem = async () => {
    const isValid = await trigger();

    if (!clientId) {
      onRemove(rowId);
    }

    if (!isValid) return;
    onDelete({
      clientId: id,
    });

    toggleDelete();
  };

  return (
    <>
      <TableRow
        className={cn({
          [clientItem['UserCredentialsItem--active']]: isActive,
        })}>
        {isActive ? (
          <>
            <TableCell>
              <AutocompleteField
                label={l10n.getString(NAME_FTL)}
                name={UserCredentialsItemFieldsEnum.Name}
                control={control as unknown as FieldControl}
                data={usersList}
                validate={value => {
                  return requiredStringValidator(value.label);
                }}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                name={UserCredentialsItemFieldsEnum.AccessLevel}
                data={selectMockAccessLevel}
                control={control as unknown as FieldControl}
                validate={requiredStringValidator}
              />
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <IconButton aria-haspopup='true' onClick={save}>
                <SaveIcon color='primary' fontSize='medium' />
              </IconButton>
              <IconButton aria-haspopup='true' onClick={cancel}>
                <ClearIcon color='primary' fontSize='medium' />
              </IconButton>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <Typography color={isDeliveryManager ? 'var(--grey-lighten-6)' : 'inherit'}>
                {userInfo?.name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color={isDeliveryManager ? 'var(--grey-lighten-6)' : 'inherit'}>
                {role}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              {!isDeliveryManager && (
                <>
                  <IconButton aria-haspopup='true' onClick={onSelect}>
                    <EditIcon color='primary' fontSize='medium' />
                  </IconButton>
                  <IconButton aria-haspopup='true' onClick={toggleDelete}>
                    <DeleteIcon color='primary' fontSize='medium' />
                  </IconButton>
                </>
              )}
              <Modal handleCloseModal={toggleDelete} openModal={isModalOpened}>
                <SpinnerOverlay visible={isLoading} />
                <Stack gap={2} alignItems='center'>
                  <Typography component='p' variant='h5'>
                    {l10n.getString(CONFIRMATION_MESSAGE_FTL)}
                  </Typography>
                  <Stack direction='row' spacing={2}>
                    <Button onClick={deleteItem} variant='contained' color='error'>
                      {l10n.getString(DELETE_FTL)}
                    </Button>
                    <Button onClick={toggleDelete}>{CANCEL_FTL}</Button>
                  </Stack>
                </Stack>
              </Modal>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default UserCredentialsItem;
