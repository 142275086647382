import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { isNil } from 'lodash';
import round from 'lodash/round';
import { InvoiceMonth } from 'pages/Budgeting/Budgeting.types';

import { MONTH_LIST } from 'valtech-core/common/ftl';
import { useCreateBillingReportMutation } from 'valtech-core/common/gql/generated';
import { BILLING_ROUTE } from 'valtech-core/common/routes';

import { useSingleBudgeting } from 'pages/Budgeting/Budgeting.hooks';

type UseCreateBillingReturn = {
  createBilling: (month: Omit<InvoiceMonth, 'cost' | 'status'>) => void;
  loading: boolean;
};

export const useCreateBilling = (): UseCreateBillingReturn => {
  const navigate = useNavigate();
  const { id: budgetReportId } = useParams<{ id: string }>();
  const {
    getAssignmentIdsByMonth,
    budgetingInfo,
    consultantsSectionTotals,
    expensesSectionTotals,
    forecastGrossMargin,
  } = useSingleBudgeting();
  const [createReport, { loading }] = useCreateBillingReportMutation({
    onCompleted: newData => {
      if (newData.createBillingReport) {
        navigate(BILLING_ROUTE.replace(':id', newData.createBillingReport.toString()));
      }
    },
  });

  const createBilling = useCallback(
    ({ projectId, monthIndex, userId, hasZeroIndex }: Omit<InvoiceMonth, 'cost' | 'status'>) => {
      const { modifiedById, lastModified, financialYear } = budgetingInfo;
      const arrayOfAssignmentId = getAssignmentIdsByMonth(monthIndex);

      const { grossMarginByMonths } = forecastGrossMargin;
      const forecastedAverageGrossMarginByMonth = grossMarginByMonths.find(
        forecastedGrossMargin => Number(forecastedGrossMargin.month) === monthIndex,
      );

      const gross_margin = !isNil(forecastedAverageGrossMarginByMonth?.averageGrossMarginPerMonth)
        ? forecastedAverageGrossMarginByMonth?.averageGrossMarginPerMonth
        : (null as number | null);

      const monthZeroIndexStart = convertToZeroIndex(monthIndex, !!hasZeroIndex);
      const title = getBillingTitle(monthZeroIndexStart, financialYear);
      const total =
        (consultantsSectionTotals.months[monthZeroIndexStart]?.cost || 0) +
        (expensesSectionTotals.months[monthZeroIndexStart]?.cost || 0);

      if (budgetReportId) {
        createReport({
          variables: {
            budgetReportId: Number(budgetReportId),
            projectId,
            financialYear,
            month: monthIndex,
            arrayOfAssignmentId,
            userId,
            title,
            gross_margin,
            modifiedBy: modifiedById,
            modifiedDate: lastModified,
            total: round(total, 2),
          },
        });
      }
    },
    [getAssignmentIdsByMonth, budgetingInfo],
  );

  return {
    createBilling,
    loading,
  };
};

const MONTH_FTL_PREFIX = 'calendar-month-';

function convertToZeroIndex(monthIndex: number, hasZeroIndex: boolean): number {
  return hasZeroIndex ? monthIndex : monthIndex - 1;
}

function getBillingTitle(monthIndex: number, year: number): string {
  return `${MONTH_LIST[monthIndex].replace(MONTH_FTL_PREFIX, '')} ${year}`;
}
