import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import Modal from 'components/Modal';
import EntityForm from 'components/_forms/EntityForm';

import Box from '@mui/material/Box';

import { ADD_FTL, ENTITIES_FTL, ENTITY_FTL } from 'valtech-core/common/ftl';
import Back from 'valtech-core/ui/Back';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import s from './AllEntitiesHeader.module.scss';

type EntityModalType = {
  entityModal: boolean;
};

const AllEntitiesHeader: FC = () => {
  const { l10n } = useLocalization();

  const [openModal, setOpenModal] = React.useState<EntityModalType>({
    entityModal: false,
  });

  const toggleModal = (key: keyof EntityModalType) => {
    setOpenModal(prev => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  return (
    <Box className={s.container}>
      <Box className={s.information}>
        <Back className={s.informationIcon} />
        <Box className={s.informationTitle}>
          <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
            {l10n.getString(ENTITIES_FTL)}
          </Typography>
        </Box>
      </Box>
      <Box className={s.action}>
        <Button
          variant='contained'
          sx={{ mx: 1 }}
          type='button'
          onClick={() => toggleModal('entityModal')}>
          {`${l10n.getString(ADD_FTL)} ${ENTITY_FTL}`}
        </Button>
        <Modal
          handleCloseModal={() => toggleModal('entityModal')}
          openModal={openModal.entityModal}>
          <Box
            sx={{
              width: 600,
            }}>
            <EntityForm onClose={() => toggleModal('entityModal')} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AllEntitiesHeader;
