import { makeVar } from '@apollo/client';

import {
  ALLOCATION_DEFAULT,
  COST_OF_OVERHEAD_DEFAULT,
  FEE_DEDICATED_DEFAULT,
  FEE_TnM_DEFAULT,
  RATE_DEFAULT,
} from 'common/constants';

import { BudgetType } from 'valtech-core/common/gql/generated';
import { AnyArray, CurrencyEnum, Maybe } from 'valtech-core/common/types';

export type BudgetingState = {
  //TODO: temporary solution -> all defaults should come from the API
  assignmentDefaults: {
    allocation: number;
    costOfOverhead: {
      EUR: number;
      USD: number;
    };
    feeDedicated: number;
    feeTnM: number;
    rate: string;
  };
  settingId: number | null;
  projectId: number | null;
  projectName: string | null;
  budgetingCopyInSettingsId: number | null;
  budgetReportId: number | null;
  financialYear: number | null;
  currency: CurrencyEnum;
  accessLevel?: string;
  forecastType?: {
    id: Maybe<number> | null;
    type: Maybe<BudgetType>;
  };
  existingBudgets: AnyArray;
  submitted: boolean;
};

export const budgetingInitialState: BudgetingState = {
  assignmentDefaults: {
    allocation: ALLOCATION_DEFAULT,
    costOfOverhead: {
      EUR: COST_OF_OVERHEAD_DEFAULT,
      USD: COST_OF_OVERHEAD_DEFAULT,
    },
    feeDedicated: FEE_DEDICATED_DEFAULT,
    feeTnM: FEE_TnM_DEFAULT,
    rate: RATE_DEFAULT,
  },
  settingId: null,
  projectId: null,
  projectName: null,
  budgetingCopyInSettingsId: null,
  budgetReportId: null,
  financialYear: null,
  currency: CurrencyEnum.EUR,
  forecastType: {
    id: null,
    type: BudgetType.OperationalForecast,
  },
  existingBudgets: [],
  submitted: false,
};

export const budgetingVar = makeVar<BudgetingState>(budgetingInitialState);
