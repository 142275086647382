import { UserInfo } from 'xkiller/src/common/apolloState/auth/auth';

interface ILocalStorage {
  setToken(status: string): void;
  setUserInfo(userInfo: UserInfo): void;
  getToken(): string | null;
  getUserInfo(): UserInfo | null;
  clear(): void;
}

interface ILastVisitedURLData {
  url: string;
  isAuthTokenExpired: boolean;
}

const XKILLER_TOKEN_KEY = '_*xkiller_to||ken*_';
const USER_INFO_LS_KEY = '_*xkiller_user||info*_';
export const XKILLER_LAST_VISITED_URL_KEY = '_*xkiller_last_visited||url*_';

class LocalStorage implements ILocalStorage {
  private readonly storage = window.localStorage;

  setToken(status): void {
    this.storage.setItem(XKILLER_TOKEN_KEY, JSON.stringify(status));
  }

  getToken(): string | null {
    const storageValue = this.storage.getItem(XKILLER_TOKEN_KEY);
    if (!storageValue) {
      return null;
    }
    return JSON.parse(storageValue);
  }

  setUserInfo(userInfo: UserInfo): void {
    this.storage.setItem(USER_INFO_LS_KEY, JSON.stringify(userInfo));
  }

  setLastVisitedURL(data: ILastVisitedURLData): void {
    this.storage.setItem(XKILLER_LAST_VISITED_URL_KEY, JSON.stringify(data));
  }

  getUserInfo(): UserInfo | null {
    const storageValue = this.storage.getItem(USER_INFO_LS_KEY);
    if (!storageValue) {
      return null;
    }
    return JSON.parse(storageValue);
  }

  getLastVisitedURLData(): ILastVisitedURLData | null {
    const storageValue = this.storage.getItem(XKILLER_LAST_VISITED_URL_KEY);
    if (!storageValue) {
      return null;
    }
    return JSON.parse(storageValue);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.removeItem(XKILLER_TOKEN_KEY);
    this.storage.removeItem(USER_INFO_LS_KEY);
  }
}

export default new LocalStorage();
