import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import BlurContainer from 'components/BlurContainer';

import Holiday from './Holiday';
import HolidayCalendarDescription from './HolidayCalendarDescription';

interface IHolidayCalendarContentProps {
  loading: boolean;
  onProceed: VoidFunction;
}

const HolidayCalendarContent: FC<IHolidayCalendarContentProps> = ({ loading, onProceed }) => {
  const { id } = useParams();

  return (
    <form onSubmit={e => e.preventDefault()} className='BaseForm'>
      <HolidayCalendarDescription onProceed={onProceed} loading={loading} />
      <BlurContainer disabled={!id}>
        <Holiday />
      </BlurContainer>
    </form>
  );
};

export default HolidayCalendarContent;
