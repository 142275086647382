import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import SearchInput from 'components/SearchInput';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';
import { splitString } from 'src/common/utils/splitAndCapitalize';

import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { CLEAR_ALL_FTL, STATUS_FTL } from 'valtech-core/common/ftl';
import { OrderDirection, RecordStatus } from 'valtech-core/common/gql/generated';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear/ProgressLinear';

import { HolidayCalendarFields, headData } from './HolidayCalendarsTable.constants';
import { useHolidayCalendar } from './HolidayCalendarsTable.hook';
import s from './HolidayCalendarsTable.module.scss';

const HolidayCalendarsTable: FC = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const {
    holidayCalendar: { list, loading },
    sorting: {
      setSortBy,
      sortingType,
      setSortingType,
      handleSorting,
      activeColumn,
      setActiveColumn,
    },
    pagePagination: { page, total, rowsPerPage, setPage, setRowsPerPage },
    filter: { handleSearch, tableFilter, setTableFilters, setFilter },
  } = useHolidayCalendar();
  const [isInputClear, setIsInputClear] = useState<boolean>(true);
  const isData = !loading && list.length > 0;

  const handleClearFilters = useCallback(() => {
    setTableFilters([]);
    setFilter([]);
    setSortingType(OrderDirection.Desc);
    setSortBy(HolidayCalendarFields.Title);
    setPage(0);
    setRowsPerPage(DEFAULT_REQUEST_LIMIT);
    setActiveColumn('');
    setIsInputClear(false);
  }, []);

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead className={s.tableHead}>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />
          <TableRow>
            {headData.map(item => (
              <TableCell key={item.l10n}>
                {item.withSearch && (
                  <SearchInput
                    size='small'
                    placeholder='Search'
                    fieldName={item.l10n}
                    onSearch={handleSearch}
                    inputDefaultValue={tableFilter?.query}
                    isInputClear={isInputClear}
                    boxProps={{ sx: { maxWidth: '350px' } }}
                    onClear={() => setIsInputClear(true)}
                  />
                )}
                {item.l10n === STATUS_FTL && (
                  <Button
                    type='button'
                    size='small'
                    variant='contained'
                    onClick={handleClearFilters}>
                    {l10n.getString(CLEAR_ALL_FTL)}
                  </Button>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableSkeleton
              page={page}
              total={total}
              rowsNumber={rowsPerPage}
              cellsNumber={headData.length}
            />
          ) : (
            list.map(row => (
              <TableRow
                hover
                key={row.id}
                className='TableRow--hover'
                onClick={() => navigate(`${row.id}`)}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.managerInfo?.name}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    className='text-capitalized'
                    label={splitString(row.status)}
                    variant='outlined'
                    color={chipColorPicker(row.status as RecordStatus)}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            page={page}
            count={total}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            isData={isData}
          />
        </TableFooter>
      </Table>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          }}
        />
      )}
    </TableContainer>
  );
};

export default HolidayCalendarsTable;
