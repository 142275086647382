import { useCallback, useState } from 'react';

import { OrderDirection } from 'valtech-core/common/gql/generated';

interface ISortItem {
  l10n: string;
  model: string;
  sortBy: string;
}

export interface IUseHandleSortingReturn {
  model: string;
  sortBy: string;
  sortingType: OrderDirection;
  activeColumn: string;
  setModel: (item: string) => void;
  setSortBy: (item: string) => void;
  setActiveColumn: (item: string) => void;
  handleSorting: (item: ISortItem) => void;
  setSortingType: (item: OrderDirection) => void;
}

export type handleSortingParams = Pick<
  IUseHandleSortingReturn,
  'activeColumn' | 'sortingType' | 'handleSorting'
>;

interface IUseHandleSortingParams {
  modelState?: string;
  sortByState?: string;
  activeColumnState?: string;
  sortingTypeState?: OrderDirection;
}

const useHandleSorting = ({
  modelState = '',
  sortByState = '',
  activeColumnState = '',
  sortingTypeState = OrderDirection.Asc,
}: IUseHandleSortingParams = {}): IUseHandleSortingReturn => {
  const [model, setModel] = useState<string>(modelState);
  const [sortBy, setSortBy] = useState<string>(sortByState);
  const [activeColumn, setActiveColumn] = useState<string>(activeColumnState);
  const [sortingType, setSortingType] = useState<OrderDirection>(sortingTypeState);

  const handleSorting = useCallback(
    item => {
      if (!item.l10n) return;
      setModel(item.model);
      setSortBy(item.sortBy);
      setActiveColumn(item.l10n);
      setSortingType(OrderDirection.Desc);

      if (item.l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [activeColumn, sortingType],
  );

  return {
    model,
    sortBy,
    setModel,
    setSortBy,
    sortingType,
    activeColumn,
    handleSorting,
    setSortingType,
    setActiveColumn,
  };
};

export default useHandleSorting;
