import React, { FC } from 'react';

import { Localized } from '@fluent/react';
import { IHeadData } from 'pages/SingleBilling/EditSingleBillingTable/EditSingleBillingTable.const';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CurrencyEnum } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import Typography from 'valtech-core/ui/Typography';

import s from '../../EditSingleBillingTable.module.scss';

interface IConsultantsTable {
  headData: IHeadData[];
  bodyData: { total: number; entityName: string }[] | undefined;
  currency?: CurrencyEnum;
}

const TotalByEntitiesTable: FC<IConsultantsTable> = ({ bodyData, headData, currency }) => {
  return (
    <TableContainer component={Paper} className={s.tableContainer}>
      <Table className={s.table}>
        <TableHead>
          <TableRow>
            {headData.map(item => {
              return (
                <TableCell key={item.id} sx={item.id === 1 ? { paddingLeft: 0 } : {}}>
                  {item.title_l10n && <Localized id={item.title_l10n} />}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {bodyData?.map(row => (
            <React.Fragment key={row.entityName}>
              <TableRow>
                <TableCell sx={{ paddingX: 1.5 }} width={50} />
                <TableCell sx={{ paddingLeft: 0 }} width={240}>
                  <Typography
                    variant='caption'
                    weight='regular'
                    component={'p'}
                    className={s.title}>
                    {row.entityName}
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell width={240}>
                  <Typography
                    variant='caption'
                    weight='regular'
                    component={'p'}
                    className={s.title}>
                    {`${createNumberWithCurrency({
                      number: row.total,
                      currencyDisplay: 'symbol',
                      currency: currency,
                    })}`}
                  </Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TotalByEntitiesTable;
