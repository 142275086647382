import { useEffect, useMemo, useState } from 'react';

import { useLocalization } from '@fluent/react';
import isNumber from 'lodash/isNumber';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import { setPage, setRowsPerPage } from 'src/common/apolloState/filters/filters.actions';
import { DEFAULT_SKIP_QUANTITY, SETTING_PROJECTS_PAGE_DEFAULT_FILTER } from 'src/common/constants';
import { TableSearchFieldsEnum } from 'src/common/types';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { ALL_FTL } from 'valtech-core/common/ftl';
import {
  ActivationStatus,
  OrderDirection,
  useGetProjectsByUserIdQuery,
} from 'valtech-core/common/gql/generated';
import { IFilters, ISortingAndFiltering, Maybe } from 'valtech-core/common/types';

export type Projects = {
  id: number;
  title: string;
};

export type SettingsProjects = Projects & {
  deliveryManager: Maybe<string>;
  accountManager: string;
  status: ActivationStatus;
  type: Maybe<string>;
};

type UseGetProjectsReturn = Omit<
  ISortingAndFiltering,
  'setProjectFilter' | 'setTableFilters' | 'projectFilter' | 'tableFilters'
> & {
  projects: Projects[];
  settingsProjects: Maybe<SettingsProjects[]>;
  loading: boolean;
};

type UseGetProjectsParams = {
  paramLimit?: number;
  paramPage?: number;
  paramFilter?: IFilters[] | [];
};

export const useGetProjects = ({
  paramLimit = DEFAULT_REQUEST_LIMIT,
  paramPage = DEFAULT_SKIP_QUANTITY,
  paramFilter = [SETTING_PROJECTS_PAGE_DEFAULT_FILTER],
}: UseGetProjectsParams = {}): UseGetProjectsReturn => {
  const { l10n } = useLocalization();
  const [total, setTotal] = useState<number>(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Asc);
  const [model, setModel] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>(TableSearchFieldsEnum.Name);
  const [filter, setFilter] = useState<IFilters[] | []>(paramFilter);

  const { data, loading, error } = useGetProjectsByUserIdQuery({
    variables: {
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: paramLimit,
        skip: paramLimit * paramPage,
      },
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const projects: Projects[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const updatedProjects = [
      {
        id: uuidv4(),
        name: l10n.getString(ALL_FTL),
      },
      ...(data.getProjectsByDMID.data as []),
    ];

    return updatedProjects.map(item => {
      return {
        id: item.id,
        title: item.name,
      };
    });
  }, [data]);

  const settingsProjects = useMemo((): SettingsProjects[] | undefined => {
    if (!data) {
      return [];
    }

    return data.getProjectsByDMID?.data?.map(item => {
      return {
        id: item.id,
        title: item.name,
        accountManager: `${item.manager_first_name} ${item.manager_last_name}`,
        deliveryManager: item.deliveryManagerInfo?.name,
        status: item.status as ActivationStatus,
        type: item.type,
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.getProjectsByDMID?.pageInfo?.total;
    const isNumberValue = isNumber(data?.getProjectsByDMID?.pageInfo?.total);

    if (isTotalChanged && isNumberValue) {
      setTotal(data?.getProjectsByDMID?.pageInfo?.total || total);
    }
  }, [data]);

  return {
    projects,
    settingsProjects,
    loading,
    page: paramPage,
    setPage: setPage(PageTypes.SETTINGS_PROJECTS),
    rowsPerPage: paramLimit,
    setRowsPerPage: setRowsPerPage(PageTypes.SETTINGS_PROJECTS),
    error,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    setFilter,
    filter,
  };
};

export default useGetProjects;
