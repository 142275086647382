import React, { FC, useCallback, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { CLIENT_DEFAULT } from 'common/constants';
import { ConsultantModelEnum, IEditBudgetingFormProps } from 'common/types';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import DataPickerField from 'form/DataPickerField';
import SelectFiled from 'form/SelectField';
import TextField from 'form/TextField';
import moment from 'moment/moment';

import { Moment } from 'moment';

import { Box, Grid, Stack } from '@mui/material';

import {
  notesLimitValidator,
  requiredStringValidator,
  validateRequiredNumber,
} from 'valtech-core/common/form/validators';
import {
  ADD_FTL,
  ALLOCATION_FTL,
  CANCEL_FTL,
  CLIENT_FTL,
  COST_OF_OVERHEAD_FTL,
  DELETE_FTL,
  END_DATE_FTL,
  FEE_FTL,
  HOLIDAY_CALENDAR_FTL,
  HOURS_FTL,
  MODEL_FTL,
  NOTES_FTL,
  RATE_FTL,
  ROLE_FTL,
  ROLE_TITLE_FTL,
  SAVE_FTL,
  START_DATE_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useHandleVacancy } from './VacancyForm.hooks';

import {
  CUSTOM_TIER,
  checkDates,
  getDefaultDate,
  getDefaultDateWithMonth,
  getRateKey,
} from '../forms.utils';

import { EFormFieldName } from '../form.types';
import { ADD_TITLE_FTL, EDIT_TITLE_FTL } from './VacancyForm.ftl';

export interface IVacancyFormProps {
  role: string;
  addText: string;
  holidayCalendarId: number;
  client: string;
  model: string;
  rate: string;
  fee: number;
  startDate: Moment;
  endDate: Moment;
  allocation?: number | null;
  hours?: number;
  notes: string;
  costOfOverhead: number;
}

const VacancyForm: FC<IEditBudgetingFormProps<IVacancyFormProps>> = ({
  onClose,
  initialValues,
  assignmentId,
  hasDelete,
  defaultValues,
}) => {
  const { l10n } = useLocalization();
  const { control, handleSubmit, formState, watch, setValue, setError } =
    useForm<IVacancyFormProps>({
      defaultValues: !!initialValues ? initialValues : defaultValues,
    });
  const { financialYear, currency } = useBudgetingState();

  const dirtyRate = formState.dirtyFields.hasOwnProperty(EFormFieldName.Rate);

  const [selectedModel, setSelectedModel] = useState<ConsultantModelEnum>(
    !!initialValues && initialValues.model
      ? (initialValues.model as ConsultantModelEnum)
      : ConsultantModelEnum.Dedicated,
  );
  const [isRateDisabled, setRateDisabled] = useState<boolean>(true);

  const {
    clientsList,
    roleList,
    rateList,
    rates,
    holidayCalendarList,
    modelList,
    createVacancy,
    updateVacancy,
    deleteVacancy,
    loading,
  } = useHandleVacancy();

  const formSubmit: SubmitHandler<IVacancyFormProps> = useCallback(
    data => {
      const isValidPeriod = checkDates<IVacancyFormProps>({
        data,
        dateKeys: [EFormFieldName.StartDate, EFormFieldName.EndDate],
        setError,
      });

      if (isValidPeriod) {
        if (initialValues) {
          updateVacancy(assignmentId, data).then(success => success && onClose());
        } else {
          createVacancy(data).then(success => success && onClose());
        }
      }
    },
    [onClose],
  );

  const checkRoleTitle = watch([EFormFieldName.Role])[0] === 'Other';

  function fieldWatcher(filed: keyof IVacancyFormProps) {
    const fieldValue = watch(filed);
    return fieldValue;
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const hasModelChanged = name === EFormFieldName.Model && type === 'change';
      const newSelectedModel = value.model as ConsultantModelEnum | undefined;
      const hasRateUpdated = name === EFormFieldName.Rate;
      const selectedRate = value.rate;

      if (hasModelChanged && newSelectedModel) {
        setSelectedModel(newSelectedModel as ConsultantModelEnum);
        setValue(EFormFieldName.Rate, '');
        setValue(EFormFieldName.Fee, 0);
      }

      if (hasRateUpdated && selectedRate) {
        const rateLabel = rates[getRateKey(newSelectedModel)].find(
          rate => rate.id === Number(selectedRate),
        )?.label;

        if (rateLabel === CUSTOM_TIER) {
          setRateDisabled(false);
        } else if (rateLabel !== CUSTOM_TIER && !isRateDisabled) {
          setRateDisabled(true);
        }
        setTimeout(() => {
          setValue(
            'fee',
            rates[getRateKey(newSelectedModel)].find(rate => rate.id === Number(selectedRate))
              ?.fee || 0,
          );
        }, 100);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, rates, isRateDisabled]);

  const isEditMode = Boolean(initialValues);

  useEffect(() => {
    if (!isEditMode) {
      const generalClientId = clientsList.find(client => client.title === CLIENT_DEFAULT)?.id;
      generalClientId && setValue(EFormFieldName.Client, `${generalClientId}`);
    }
  }, [clientsList]);

  const onCancel = () => {
    onClose();
  };

  const onDelete = () => {
    deleteVacancy(assignmentId).then(success => success && onClose());
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {initialValues ? l10n.getString(EDIT_TITLE_FTL) : l10n.getString(ADD_TITLE_FTL)}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={checkRoleTitle ? 4 : 6}>
          <SelectFiled
            label={l10n.getString(ROLE_FTL)}
            name={EFormFieldName.Role}
            data={roleList}
            validate={requiredStringValidator}
            disabled={isEditMode}
            control={control as unknown as FieldControl}
          />
        </Grid>
        {checkRoleTitle && (
          <Grid item xs={4}>
            <TextField
              label={l10n.getString(ROLE_TITLE_FTL)}
              name={EFormFieldName.AddText}
              control={control as unknown as FieldControl}
              validate={requiredStringValidator}
              disabled={isEditMode}
            />
          </Grid>
        )}
        <Grid item xs={checkRoleTitle ? 4 : 6}>
          <SelectFiled
            label={l10n.getString(HOLIDAY_CALENDAR_FTL)}
            name={EFormFieldName.HolidayCalendarId}
            data={holidayCalendarList}
            validate={requiredStringValidator}
            disabled={!hasDelete && isEditMode}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(CLIENT_FTL)}
            name={EFormFieldName.Client}
            data={clientsList}
            validate={requiredStringValidator}
            disabled={isEditMode}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(MODEL_FTL)}
            name={EFormFieldName.Model}
            data={modelList}
            disabled={!hasDelete && isEditMode}
            validate={requiredStringValidator}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(START_DATE_FTL)}
            name={EFormFieldName.StartDate}
            disabled={!hasDelete && isEditMode}
            validate={requiredStringValidator}
            control={control as unknown as FieldControl}
            defaultCalendarMonth={getDefaultDate(financialYear)}
            minDate={getCalendarRangeByYear(financialYear).firstDayOfYear}
            maxDate={getCalendarRangeByYear(financialYear).lastDayOfYear}
          />
        </Grid>
        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(END_DATE_FTL)}
            name={EFormFieldName.EndDate}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
            minDate={fieldWatcher(EFormFieldName.StartDate) as Moment}
            maxDate={getCalendarRangeByYear(financialYear).lastDayOfYear}
            defaultCalendarMonth={getDefaultDateWithMonth(
              moment(fieldWatcher(EFormFieldName.StartDate) as Moment).month() + 1,
              financialYear as number,
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectFiled
            label={l10n.getString(RATE_FTL)}
            name={EFormFieldName.Rate}
            data={rateList[getRateKey(selectedModel)]}
            disabled={hasDelete && isEditMode}
            validate={requiredStringValidator}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={l10n.getString(FEE_FTL)}
            name={EFormFieldName.Fee}
            textProps={{
              type: 'number',
              disabled: !fieldWatcher(EFormFieldName.Rate) || isEditMode,
            }}
            disabled={isRateDisabled}
            validate={dirtyRate ? validateRequiredNumber : undefined}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={
              selectedModel === 'Dedicated'
                ? `${l10n.getString(ALLOCATION_FTL)}, %`
                : l10n.getString(HOURS_FTL)
            }
            textProps={{
              type: 'number',
              disabled: isEditMode,
            }}
            name={selectedModel === 'Dedicated' ? EFormFieldName.Allocation : EFormFieldName.Hours}
            validate={validateRequiredNumber}
            disabled={!hasDelete && isEditMode}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={`${l10n.getString(COST_OF_OVERHEAD_FTL)}, ${currency}`}
            name={EFormFieldName.CostOfOverhead}
            textProps={{
              type: 'number',
            }}
            validate={validateRequiredNumber}
            disabled={!hasDelete && isEditMode}
            control={control as unknown as FieldControl}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={EFormFieldName.Notes}
            control={control as unknown as FieldControl}
            label={l10n.getString(NOTES_FTL)}
            textProps={{
              multiline: true,
              rows: 5,
            }}
            validate={notesLimitValidator}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button
          onClick={handleSubmit(formSubmit)}
          disabled={!formState.isDirty || loading}
          variant='contained'>
          {initialValues ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        {initialValues && hasDelete && (
          <Button
            disabled={loading}
            onClick={onDelete}
            variant='outlined'
            type='button'
            color='error'>
            {l10n.getString(DELETE_FTL)}
          </Button>
        )}

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default VacancyForm;
