import React, { FC, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { showSystemError } from 'common/apolloState/system';
import { ERROR_APOLLO_RESPONSE_MSG_UNAUTHORIZED } from 'common/constants';
import { useAuthState } from 'src/common/apolloState/auth';

import { ERROR_LOGIN_UNAUTHORIZED } from 'valtech-core/common/ftl';
import { useSignInMutation } from 'valtech-core/common/gql/generated';
import { HOME_ROUTE } from 'valtech-core/common/routes';
import { BUDGETINGS_ROUTE } from 'valtech-core/common/routes';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import authStorage from 'valtech-core/services/auth';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

const OauthCallback: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuth } = useAuthState();

  const [signInMutation, {}] = useSignInMutation({
    onError(e) {
      if (e.message === ERROR_APOLLO_RESPONSE_MSG_UNAUTHORIZED) {
        showSystemError(ERROR_LOGIN_UNAUTHORIZED);
      } else {
        showSystemError(getErrorMessage(e.message));
      }
    },
  });

  const getAccessToken = async (code: string) => {
    const signInRes = await signInMutation({
      variables: { code },
    });

    return signInRes.data?.signIn;
  };

  useEffect(() => {
    // Get the parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (!code) return;
    getAccessToken(code).then(signInData => {
      if (signInData && signInData.authToken) {
        const userInfo = { ...signInData.userInfo };
        const lastVisitedURLData = authStorage.getLastVisitedURLData();

        authStorage.setToken(signInData.authToken);
        authStorage.setUserInfo(userInfo);
        setAuth(true, signInData.userInfo);
        navigate(lastVisitedURLData ? lastVisitedURLData?.url : BUDGETINGS_ROUTE);
      } else {
        navigate(HOME_ROUTE);
      }
    });
  }, [location, navigate]);

  return <SpinnerOverlay visible={true} />;
};

export default OauthCallback;
