import React, { FC, useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useBudgetingState } from 'common/apolloState/budgeting';
import TextField from 'form/TextField/TextField';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import { useHandleSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import SendIcon from '@mui/icons-material/Send';
import { BoxProps } from '@mui/material/Box';

import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';

import s from './TextInputWithButton.module.scss';

interface ITextInputWithButtonProps {
  placeholder: string;
  fieldName: string;
  className?: string;
  boxProps?: BoxProps;
  size?: 'medium' | 'small';
}

type TextInputFormProps = {
  comment: string;
};

const initialValues = {
  comment: '',
};

const TextInputWithButton: FC<ITextInputWithButtonProps> = ({
  placeholder,
  fieldName,
  className,
  boxProps,
  size,
}) => {
  const { projectId } = useBudgetingState();

  const { addBillingReportComment, loading } = useHandleSingleBillingTabs();
  const {
    state: { projectData },
  } = useBillingState();
  const { control, handleSubmit, reset, formState } = useForm<TextInputFormProps>({
    defaultValues: initialValues,
  });

  const handleSendComment: SubmitHandler<TextInputFormProps> = useCallback(data => {
    addBillingReportComment(projectData?.id, data.comment, projectId).then(isSuccessful => {
      if (isSuccessful) {
        reset();
      }
    });
  }, []);

  return (
    <form onSubmit={e => e.preventDefault()} className={s.form}>
      <TextField
        label={placeholder}
        name={fieldName}
        control={control as unknown as FieldControl}
        className={className}
        boxProps={boxProps}
        endAdornment={
          formState.isDirty && (
            <Button
              className={s.button}
              type='submit'
              disabled={!formState.isDirty || loading}
              onClick={handleSubmit(handleSendComment)}>
              <SendIcon className={s.icon} />
            </Button>
          )
        }
        textProps={{
          multiline: true,
          rows: 1,
          size: size,
        }}
      />
    </form>
  );
};

export default TextInputWithButton;
