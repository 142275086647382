import { axiosWithAuth } from 'valtech-core/api/interceptors';

class TimesheetsService {
  private BASE_URL = 'upload/timesheet';

  async uploadTimesheetFile(data: FormData) {
    const response = await axiosWithAuth.post(this.BASE_URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }
}

export const timesheetsService = new TimesheetsService();
