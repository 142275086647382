import React, { FC, memo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MUIAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useToggle } from 'valtech-core/utils/useToggle';

interface IAccordionProps {
  boxProps?: BoxProps;
  accordionProps?: Omit<AccordionProps, 'children'>;
  accordionDetailsProps?: AccordionDetailsProps;
  children: JSX.Element | React.ReactNode;
  title: string;
  isOpened?: boolean;
}

const Accordion: FC<IAccordionProps> = ({
  boxProps,
  accordionProps,
  accordionDetailsProps,
  children,
  title,
  isOpened = false,
}) => {
  const accordionID = uuidv4();
  const [isExpanded, toggleExpaned] = useToggle(isOpened);

  return (
    <Box {...boxProps}>
      <MUIAccordion {...accordionProps} expanded={isExpanded} onChange={toggleExpaned}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id={accordionID}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ paddingTop: 2 }} {...accordionDetailsProps}>
          {children}
        </AccordionDetails>
      </MUIAccordion>
    </Box>
  );
};

export default memo(Accordion);
