import { axiosWithAuth } from 'valtech-core/api/interceptors';

class ConsultantsService {
  private BASE_URL = 'upload/consultants';

  async uploadConsultantsFile(data: FormData) {
    return await axiosWithAuth.post(this.BASE_URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export const consultantsService = new ConsultantsService();
