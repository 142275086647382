import React, { forwardRef, memo } from 'react';

import { Moment } from 'moment';

import Box, { BoxProps } from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePickerProps, DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DateMask } from 'valtech-core/common/types';

interface IDataPickerProps extends Omit<DatePickerProps<Moment, Moment>, 'renderInput'> {
  boxProps?: BoxProps;
  label: string | undefined;
  children?: React.ReactElement;
  error: boolean;
}

const DatePicker = forwardRef<HTMLDivElement, IDataPickerProps>(
  ({ boxProps, label, onChange, value = null, children, error, ...rest }, ref) => {
    return (
      <Box {...boxProps}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MUIDatePicker
            {...rest}
            label={label}
            value={value}
            onChange={onChange}
            ref={ref}
            renderInput={params => (
              <TextField
                {...params}
                error={error}
                fullWidth
                sx={{
                  '& .Mui-error': {
                    color: 'var(--text-color-black-54)',
                  },
                }}
                onPaste={e => e.preventDefault()}
                onKeyDown={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
              />
            )}
            inputFormat={DateMask.DD_MM_YYYY}
          />
        </LocalizationProvider>
        {children}
      </Box>
    );
  },
);
export default memo(DatePicker);
