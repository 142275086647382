import { FilterModelEnum, TableSearchFieldsEnum } from 'src/common/types';

import {
  BUDGETS_FTL,
  DELIVERY_MANAGER_FTL,
  LAST_UPDATE_FTL,
  PROJECT_FTL,
  STATUS_FTL,
} from 'valtech-core/common/ftl';

export const headData = [
  { l10n: BUDGETS_FTL, model: FilterModelEnum.Title, sortBy: TableSearchFieldsEnum.Title },
  { l10n: PROJECT_FTL, model: FilterModelEnum.Project, sortBy: TableSearchFieldsEnum.Name },
  {
    l10n: DELIVERY_MANAGER_FTL,
    model: FilterModelEnum.DeliveryManager,
    sortBy: TableSearchFieldsEnum.Name,
  },
  { l10n: LAST_UPDATE_FTL, model: '', sortBy: TableSearchFieldsEnum.UpdateTime },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: 'status',
  },
];
