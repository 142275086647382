import { useCallback, useEffect, useState } from 'react';

import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import { setPersistentFilters } from 'src/common/apolloState/filters/filters.actions';
import useGetProjects from 'src/common/hooks/useGetProjects';
import { SettingsProjects } from 'src/common/hooks/useGetProjects/useGetProjects';
import {
  FilterModelEnum,
  ProjectTableSearchFieldsEnum,
  TableSearchFieldsEnum,
} from 'src/common/types';

import { OrderDirection } from 'valtech-core/common/gql/generated';
import { IFilters, Maybe } from 'valtech-core/common/types';

import { updateTableFilters } from './AllProjects.utils';

export interface IUseEntitiesReturn {
  loading: boolean;
  total: number;
  setPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  page: number;
  rowsPerPage: number;
  sortingType: OrderDirection;
  setSortingType: (value: OrderDirection) => void;
  setModel: (value: string) => void;
  setSortBy: (value: string) => void;
  setFilter: (value: IFilters[] | []) => void;
  setTableFilters: (value: IFilters[] | []) => void;
  tableFilters: IFilters[] | [];
  handleSearch: (data) => void;
  settingsProjects: Maybe<SettingsProjects[]>;
}

export const useAllProjects = (): IUseEntitiesReturn => {
  const { projectsPageFilters } = useFiltersState();
  const { persistentFilters, pagination } = projectsPageFilters;

  const {
    settingsProjects,
    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    loading,
    total,
    sortingType,
    setSortingType,
    setModel,
    setSortBy,
    setFilter,
  } = useGetProjects({
    paramFilter: persistentFilters,
    paramLimit: pagination.rowsPerPage,
    paramPage: pagination.page,
  });

  const [tableFilters, setTableFilters] = useState<IFilters[] | []>(persistentFilters);

  useEffect(() => {
    const newFilters: IFilters[] = [];

    if (tableFilters.length > 0) {
      tableFilters.forEach(tableFilter => {
        tableFilter.query && newFilters.push(tableFilter);
      });
    }

    setFilter(newFilters);
    setPersistentFilters(PageTypes.SETTINGS_PROJECTS)(newFilters);
  }, [tableFilters]);

  const handleSearch = useCallback(data => {
    switch (data.fieldName) {
      case ProjectTableSearchFieldsEnum.Name:
        updateTableFilters({ field: TableSearchFieldsEnum.Name }, data, setTableFilters);
        break;
      case ProjectTableSearchFieldsEnum.DeliveryManager:
        updateTableFilters(
          {
            field: TableSearchFieldsEnum.Name,
            filterModel: FilterModelEnum.DeliveryManager,
          },
          data,
          setTableFilters,
        );
        break;
    }

    return;
  }, []);

  return {
    settingsProjects,
    handleSearch,
    tableFilters,
    setTableFilters,
    setFilter,
    setSortBy,
    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    loading,
    total,
    sortingType,
    setSortingType,
    setModel,
  };
};
