import { makeVar } from '@apollo/client';

export type SystemState = {
  loading: boolean;
  error: string | null;
};

export const systemInitialState: SystemState = {
  error: null,
  loading: false,
};

export const systemVar = makeVar<SystemState>(systemInitialState);
