import { useEffect, useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import useHandleSorting, { HolidayCalendarSorting } from 'common/hooks/useHandleSorting';
import usePageFilters, { HolidayCalendarFilter } from 'common/hooks/usePageFilters';
import usePagePagination from 'common/hooks/usePagePagination';
import { pagePaginationParams } from 'common/hooks/usePagePagination/usePagePagination';
import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import {
  setPage,
  setPersistentFilters,
  setRowsPerPage,
} from 'src/common/apolloState/filters/filters.actions';

import {
  OrderDirection,
  RecordStatus,
  useGetHolidayCalendarListLazyQuery,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

import {
  HolidayCalendarFields,
  HolidayCalendarFilterFields,
} from './HolidayCalendarsTable.constants';

export type HolidayCalendars = {
  id: Maybe<number>;
  title: Maybe<string>;
  business_unit_id: string;
  modified_by?: Maybe<number>;
  status: RecordStatus | string;
  managerInfo: {
    id: number;
    name: string;
  };
};

interface IUseHolidayCalendarReturn {
  pagePagination: pagePaginationParams;
  sorting: HolidayCalendarSorting;
  holidayCalendar: {
    list: HolidayCalendars[];
    loading: boolean;
    error: ApolloError | undefined;
  };
  filter: HolidayCalendarFilter;
}

export const useHolidayCalendar = (): IUseHolidayCalendarReturn => {
  const {
    model,
    sortBy,
    sortingType,
    setSortingType,
    setSortBy,
    handleSorting,
    activeColumn,
    setActiveColumn,
  } = useHandleSorting({
    sortByState: HolidayCalendarFields.Title,
    sortingTypeState: OrderDirection.Desc,
  });

  const { holidayCalendarPageFilters } = useFiltersState();
  const { persistentFilters, pagination } = holidayCalendarPageFilters;
  const { rowsPerPage, page } = pagination;

  const [getHolidayCalendarList, { data, loading, error }] = useGetHolidayCalendarListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { total, setTotal } = usePagePagination({
    currentTotal: data?.sendArrayOfHolidayCalendar?.pageInfo?.total,
  });

  const { filter, handleSearch, tableFilter, setTableFilters, setFilter } = usePageFilters({
    fieldMap: HolidayCalendarFilterFields,
    setPage: setPage(PageTypes.SETTINGS_HOLIDAY_CALENDAR),
    tableFilter: persistentFilters[0],
    filterState: persistentFilters,
    setTableFilters: setPersistentFilters(PageTypes.SETTINGS_HOLIDAY_CALENDAR),
  });

  useEffect(() => {
    getHolidayCalendarList({
      variables: {
        orderBy: {
          sortDirection: sortingType,
          model: model,
          sortBy: sortBy,
        },
        pagination: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
        filter,
      },
    });
  }, [sortingType, sortBy, rowsPerPage, page, filter, model]);

  const list = useMemo(
    () =>
      data?.sendArrayOfHolidayCalendar?.data?.length ? data.sendArrayOfHolidayCalendar.data : [],
    [data],
  );

  return {
    holidayCalendar: {
      list: list as HolidayCalendars[],
      error,
      loading,
    },
    sorting: {
      setSortBy,
      sortingType,
      activeColumn,
      setSortingType,
      setActiveColumn,
      handleSorting,
    },
    pagePagination: {
      page: pagination.page,
      total,
      rowsPerPage,
      setPage: setPage(PageTypes.SETTINGS_HOLIDAY_CALENDAR),
      setTotal,
      setRowsPerPage: setRowsPerPage(PageTypes.SETTINGS_HOLIDAY_CALENDAR),
    },
    filter: {
      handleSearch,
      setFilter,
      tableFilter,
      setTableFilters,
    },
  };
};
