import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import cn from 'classnames';
import { Variant } from 'common/types';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';
import {
  IRateTable,
  feeValidator,
  requiredStringValidator,
  titleValidator,
} from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/RateTable/RateTable.constants';
import { useRateSelects } from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/RateTable/RateTable.hook';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { requiredLongStringValidator } from 'valtech-core/common/form/validators';
import { FieldControl } from 'valtech-core/common/types';

import { RateItemFields } from './RateItem.constants';

import s from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/shared.module.scss';

interface IRateItemProps {
  isActive: boolean;
  onSave: (value: IRateTable) => void;
  onSelect: VoidFunction;
  onCancel: VoidFunction;
  values: {
    id: number;
    title: string;
    model: string;
    status: string;
    ccy: string;
    fee: number;
    entity: string;
  };
}

const RateItem: FC<IRateItemProps> = ({
  isActive,
  onSelect,
  onCancel,
  onSave,
  values: { id, title, model, status, ccy, fee, entity },
}) => {
  const { statuses, models, ccys, entities } = useRateSelects();
  const defaultValues = {
    [RateItemFields.Title]: title,
    [RateItemFields.Model]: model,
    [RateItemFields.Ccy]: ccy,
    [RateItemFields.Fee]: fee,
    [RateItemFields.Status]: status,
    [RateItemFields.Entity]: entity,
  };
  const methods = useForm({ defaultValues });
  const { control, setValue, trigger, getValues, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [isActive]);

  const cancel = () => {
    reset(defaultValues);
    onCancel();
  };

  const save = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    onSave({
      id,
      title: values[RateItemFields.Title],
      model: values[RateItemFields.Model],
      status: values[RateItemFields.Status],
      ccy: values[RateItemFields.Ccy],
      fee: values[RateItemFields.Fee],
      entity: values[RateItemFields.Entity],
    });
    onCancel();
  };

  return (
    <>
      <TableRow
        className={cn({
          ['TableRow--active']: isActive,
        })}>
        {isActive ? (
          <>
            <TableCell>
              <TextField
                name={RateItemFields.Title}
                control={control as unknown as FieldControl}
                textProps={{
                  type: 'text',
                }}
                variant={Variant.Filled}
                validate={titleValidator}
                className={s.inputField}
                onBlurCallback={e => {
                  setValue(RateItemFields.Title, e.target.value.trim());
                  trigger(RateItemFields.Title);
                }}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                className={s.selectField}
                name={RateItemFields.Model}
                data={models}
                control={control as unknown as FieldControl}
                validate={requiredStringValidator}
                variant={Variant.Filled}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                className={s.selectField}
                name={RateItemFields.Ccy}
                data={ccys}
                control={control as unknown as FieldControl}
                validate={requiredLongStringValidator}
                variant={Variant.Filled}
              />
            </TableCell>
            <TableCell>
              <TextField
                name={RateItemFields.Fee}
                control={control as unknown as FieldControl}
                textProps={{
                  type: 'number',
                }}
                variant={Variant.Filled}
                validate={feeValidator}
                className={s.inputField}
                onBlurCallback={() => trigger(RateItemFields.Fee)}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                className={s.selectField}
                name={RateItemFields.Entity}
                data={entities}
                control={control as unknown as FieldControl}
                validate={requiredStringValidator}
                variant={Variant.Filled}
              />
            </TableCell>
            <TableCell>
              <SelectFiled
                className={s.selectField}
                name={RateItemFields.Status}
                data={statuses}
                control={control as unknown as FieldControl}
                validate={requiredStringValidator}
                variant={Variant.Filled}
              />
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <IconButton aria-haspopup='true' onClick={save}>
                <SaveIcon color='primary' fontSize='medium' />
              </IconButton>
              <IconButton aria-haspopup='true' onClick={cancel}>
                <ClearIcon color='primary' fontSize='medium' />
              </IconButton>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{model}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{ccy}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fee}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{entity}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{status}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <IconButton aria-haspopup='true' onClick={onSelect}>
                <EditIcon color='primary' fontSize='medium' />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default RateItem;
