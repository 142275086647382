import axios, { CreateAxiosDefaults } from 'axios';

import authStorage from 'valtech-core/services/auth';

const baseURL =
  window.location.hostname === 'localhost' && window.location.port === '4007'
    ? `${process.env.REACT_APP_API_URL}`
    : `${
        (window as Window & typeof globalThis & { env: { [key: string]: string } }).env
          .REACT_APP_API_URL
      }`;

const options: CreateAxiosDefaults = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const axiosWithAuth = axios.create(options);

axiosWithAuth.interceptors.request.use(config => {
  const accessToken = authStorage.getToken();

  if (config?.headers && accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

export { axiosWithAuth };
