import { useLocalization } from '@fluent/react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { showSystemError } from 'common/apolloState/system';
import { useConsultantsLatestUpdatedFile } from 'src/pages/ManageSettings/ConsultantsSettings/hooks/LastUpdatedConsultantsFile.hook';

import { ERROR_CONSULTANTS_UPLOAD_MESSAGE } from 'valtech-core/common/ftl';
import { consultantsService } from 'valtech-core/services/rest/consultants';

import { CONSULTANTS_UPLOAD_FILE_KEY } from './UploadConsultantsFile.constants';

interface UploadConsultantsFileVariables {
  file: File;
}
interface UseUploadConsultantsFileReturn {
  uploadConsultantsFile: UseMutateFunction<void, unknown, UploadConsultantsFileVariables, unknown>;
  status: string;
}

export const useUploadConsultantsFile = (): UseUploadConsultantsFileReturn => {
  const { l10n } = useLocalization();
  const { refetch } = useConsultantsLatestUpdatedFile();

  const { mutateAsync: uploadConsultantsFile, status } = useMutation<
    void,
    AxiosError,
    UploadConsultantsFileVariables
  >({
    mutationKey: [CONSULTANTS_UPLOAD_FILE_KEY],
    mutationFn: async ({ file }: UploadConsultantsFileVariables) => {
      const formData = new FormData();
      formData.append('file', file);

      await consultantsService.uploadConsultantsFile(formData);
    },
    onSuccess() {
      if (refetch) {
        refetch();
      }
    },
    onError: () => {
      showSystemError(l10n.getString(ERROR_CONSULTANTS_UPLOAD_MESSAGE));
    },
  });

  return { uploadConsultantsFile, status };
};
