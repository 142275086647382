import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';

import isNumber from 'lodash/isNumber';
import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import {
  setPage,
  setPersistentFilters,
  setRowsPerPage,
} from 'src/common/apolloState/filters/filters.actions';
import { TableSearchFieldsEnum } from 'src/common/types';

import { ACTION_FTL } from 'valtech-core/common/ftl';
import { OrderDirection, useGetArrayOfUsersQuery } from 'valtech-core/common/gql/generated';
import { IFilters } from 'valtech-core/common/types';

interface IUsers {
  id: number;
  name: string;
  email: string;
  role: string;
  status: string;
}

export interface IUseUsersReturn {
  users: IUsers[];
  activeColumn: string;
  handleSorting: (value: string) => void;
  loading: boolean;
  total: number;
  setPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  page: number;
  rowsPerPage: number;
  error: ApolloError | undefined;
  sortingType: OrderDirection;
  setSortingType: (value: OrderDirection) => void;
  setModel: (value: string) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
  setFilter: (value: IFilters[] | []) => void;
  filter: IFilters[];
  setTableFilters: (value: IFilters[]) => void;
  tableFilters: IFilters[] | [];
  setActiveColumn: (value: string) => void;
}

export const useAllUsers = (): IUseUsersReturn => {
  const { usersPageFilters } = useFiltersState();
  const { persistentFilters, pagination } = usersPageFilters;
  const { page, rowsPerPage } = pagination;

  const [activeColumn, setActiveColumn] = useState('');
  const [total, setTotal] = useState(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Asc);
  const [model, setModel] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>(TableSearchFieldsEnum.Name);
  const [tableFilters, setTableFilters] = useState<IFilters[] | []>(persistentFilters);
  const [filter, setFilter] = useState<IFilters[] | []>(persistentFilters);

  const { data, loading, error } = useGetArrayOfUsersQuery({
    variables: {
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const users: IUsers[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data?.sendArrayOfUsers?.data || []).map(item => {
      return {
        id: item?.id,
        name: item?.name || '',
        email: item?.email || '',
        role: item?.role || '',
        status: item?.status || '',
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.sendArrayOfUsers?.pageInfo?.total;
    const isNumberValue = isNumber(data?.sendArrayOfUsers?.pageInfo?.total);
    if (isTotalChanged && isNumberValue) {
      setTotal(data?.sendArrayOfUsers?.pageInfo?.total || total);
    }
  }, [data]);

  const handleSorting = useCallback(
    item => {
      if (![ACTION_FTL].includes(item.l10n)) {
        setActiveColumn(item.l10n);
        setModel(item.model);
        setSortBy(item.sortBy);
        setSortingType(OrderDirection.Desc);

        if (item.l10n === activeColumn) {
          setSortingType(
            sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
          );
          return;
        }
      }
    },
    [activeColumn, sortingType],
  );

  useEffect(() => {
    const newFilters: IFilters[] = [];
    if (tableFilters.length > 0) {
      tableFilters.forEach(tableFilter => {
        tableFilter.query && newFilters.push(tableFilter);
      });
    }

    setPersistentFilters(PageTypes.SETTINGS_USERS)(newFilters);
    setFilter(newFilters);
  }, [tableFilters]);

  return {
    page,
    setPage: setPage(PageTypes.SETTINGS_USERS),
    rowsPerPage,
    setRowsPerPage: setRowsPerPage(PageTypes.SETTINGS_USERS),
    loading,
    error,
    users,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    setFilter,
    filter,
    setTableFilters,
    tableFilters,
    activeColumn,
    handleSorting,
    setActiveColumn,
  };
};
