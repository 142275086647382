import { useCallback, useMemo } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showSystemError } from 'common/apolloState/system';
import { useHolidayCalendarList } from 'common/hooks/useHolidayCalendarList';
import moment, { Moment } from 'moment/moment';
import {
  ConsultantSectionItem,
  ExpensesSectionItem,
  VacancySectionItem,
} from 'pages/Budgeting/Budgeting.types';

import {
  OrderDirection,
  RecordStatus,
  useCopyRowsFromBudgetMutation,
  useGetArrayOfBudgetSettingsQuery,
} from 'valtech-core/common/gql/generated';
import { AnyArray } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import { SelectedItem } from 'valtech-core/form/Select';

import { getFeeInitialValue } from 'pages/Budgeting/HandleTableRowEdit/HandleTableRowEdit.hooks';

import {
  getSelectedBudgetingRowsIds,
  mapToSubmitCopyBudgetingRows,
} from './CopyBudgetingRowsForm.utils';

import {
  CopyBudgetingRowsFormSubmitProps,
  copyRowSubmitStatusEnum,
  copyRowsStatusType,
} from './CopyBudgetingRowsForm';

export type AssignmentCopyRow = {
  assignmentId: number;
  clientId: number | null;
  consultant: {
    id: number;
    label: string;
    businessUnit: string;
  };
  model: string;
  rate: {
    title: string;
    id: number;
    value: string;
  };
  fee: number;
  startDate: Moment;
  endDate: Moment | undefined;
  allocation?: null | number;
  hours?: number;
  notes?: string;
  holidayCalendarId: number;
  costOfOverhead: number;
  vacationCoeff?: number;
  vacationBalance?: number;
  submitStatus: copyRowSubmitStatusEnum;
};

export type VacancyCopyRow = {
  assignmentId: number;
  clientId: number | null;
  model: string;
  role: string;
  rate: {
    title: string;
    id: number;
    value: string;
  };
  fee: number;
  startDate: Moment;
  endDate: Moment | undefined;
  allocation?: null | number;
  hours?: number;
  notes?: string;
  holidayCalendarId: number;
  submitStatus: copyRowSubmitStatusEnum;
};

export type ExpenseCopyRow = {
  assignmentId: number;
  title: string;
  clientId: number | null;
  cost: number;
  startDate: Moment;
  endDate: Moment | undefined;
  notes?: string;
  submitStatus: copyRowSubmitStatusEnum;
};

type copyBudgetingRowsResponse = copyRowsStatusType & { budgetingId: number };

type UseHandleCopyBudgetingRowsReturn = {
  loading: boolean;
  assignmentsCopyRows: AssignmentCopyRow[];
  vacanciesCopyRows: VacancyCopyRow[];
  expensesCopyRows: ExpenseCopyRow[];
  holidayCalendarList: SelectedItem[];
  budgetingsList: SelectedItem[];
  budgetings: AnyArray;
  copyBudgetingRows: (
    data: CopyBudgetingRowsFormSubmitProps,
  ) => Promise<false | copyBudgetingRowsResponse>;
};

type UseHandleCopyBudgetingRowsProps = {
  selectedRowsIds: Set<string>;
  consultantsSection: ConsultantSectionItem[];
  vacanciesSection: VacancySectionItem[];
  expensesSection: ExpensesSectionItem[];
  budgetingCopyInYear: number;
};

export const useHandleCopyBudgetingRows = ({
  selectedRowsIds,
  consultantsSection,
  vacanciesSection,
  expensesSection,
  budgetingCopyInYear,
}: UseHandleCopyBudgetingRowsProps): UseHandleCopyBudgetingRowsReturn => {
  const { projectId, settingId, budgetingCopyInSettingsId, currency } = useBudgetingState();

  const { holidayCalendar } = useHolidayCalendarList({
    filter: [
      { field: 'status', query: RecordStatus.Active },
      { field: 'year', query: String(budgetingCopyInYear) },
    ],
  });

  const [copyRowsFromBudget, { loading: copyingLoading }] = useCopyRowsFromBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const copyBudgetingRows = useCallback(
    async data => {
      if (projectId && budgetingCopyInSettingsId) {
        const variables = mapToSubmitCopyBudgetingRows({
          data,
          projectId,
          budgetingCopyInSettingsId,
          currency,
        });

        const copyBudgetingRowsRes = await copyRowsFromBudget({
          variables,
        });

        const copyRowsData = copyBudgetingRowsRes.data?.copyRowsFromBudget;

        if (copyRowsData) {
          const { assignementInfo, vacancyInfo, expenseInfo, errors, budget_id } = copyRowsData;

          return {
            assignments:
              assignementInfo && assignementInfo?.length
                ? assignementInfo?.map(item => Number(item.parentID))
                : [],
            expenses:
              expenseInfo && expenseInfo.length
                ? expenseInfo?.map(item => Number(item.parentID))
                : [],
            vacancies:
              vacancyInfo && vacancyInfo.length
                ? vacancyInfo?.map(item => Number(item.parentID))
                : [],
            errors:
              errors && errors.length
                ? errors.map(item => {
                    return {
                      id: Number(item.ID),
                      message: item.error?.message ?? '',
                    };
                  })
                : [],
            budgetingId: budget_id,
          };
        }
      }

      return false;
    },
    [budgetingCopyInSettingsId],
  );

  const { data: budgetingsData, loading: getBudgetingsLoading } = useGetArrayOfBudgetSettingsQuery({
    variables: {
      filter: {
        field: 'status',
        filterModel: '',
        query: 'active',
      },
      pagination: {},
      orderBy: {
        sortBy: 'title',
        sortDirection: OrderDirection.Desc,
        model: '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const holidayCalendarList = useMemo((): SelectedItem[] => {
    if (!holidayCalendar) {
      return [];
    }

    return holidayCalendar.map(calendar => ({
      id: calendar.id,
      title: calendar.businessUnit,
      value: `${calendar.id}`,
    }));
  }, [holidayCalendar]);

  const { consultantsIds, vacanciesIds, expensesIds } = getSelectedBudgetingRowsIds({
    selectedRowsIds,
  });

  const assignmentsCopyRows: AssignmentCopyRow[] = useMemo(() => {
    const assignmentsCopyRowsResult: AssignmentCopyRow[] = [];

    consultantsSection.forEach(consultantSectionItem => {
      consultantSectionItem.itemsList.forEach(assignmentInfo => {
        const assignmentId = parseInt(assignmentInfo.id);

        if (consultantsIds.includes(assignmentId)) {
          const assignmentsCopyRow: AssignmentCopyRow = {
            assignmentId,
            clientId: assignmentInfo.clientId,
            consultant: {
              id: parseInt(consultantSectionItem.id),
              label: consultantSectionItem.general.name,
              businessUnit: consultantSectionItem.general.businessUnit,
            },
            model: assignmentInfo.model,
            rate: { ...assignmentInfo.rate },
            fee: getFeeInitialValue(assignmentInfo.rate.value),
            startDate: assignmentInfo.startDate,
            endDate: undefined,
            allocation: assignmentInfo.allocation,
            hours: assignmentInfo.hours,
            notes: assignmentInfo.comment,
            holidayCalendarId: assignmentInfo.holidayCalendarId,
            costOfOverhead: assignmentInfo.costOfOverhead,
            submitStatus: copyRowSubmitStatusEnum.Pending,
            // vacationCoeff?:;
            // vacationBalance?:;
          };

          assignmentsCopyRowsResult.push(assignmentsCopyRow);
        }
      });
    });

    return assignmentsCopyRowsResult;
  }, [consultantsIds]);

  const vacanciesCopyRows: VacancyCopyRow[] = useMemo(() => {
    const vacanciesCopyRowsResult: VacancyCopyRow[] = [];

    vacanciesSection.forEach(vacanciesSectionItem => {
      vacanciesSectionItem.itemsList.forEach(vacancyInfo => {
        const vacancyId = parseInt(vacancyInfo.id);

        if (vacanciesIds.includes(vacancyId)) {
          const vacanciesCopyRow: VacancyCopyRow = {
            assignmentId: vacancyId,
            clientId: vacancyInfo.clientId,
            model: vacancyInfo.model,
            role: vacancyInfo.role,
            rate: { ...vacancyInfo.rate },
            fee: getFeeInitialValue(vacancyInfo.rate.value),
            startDate: vacancyInfo.startDate,
            endDate: undefined,
            allocation: vacancyInfo.allocation,
            hours: vacancyInfo.hours,
            notes: vacancyInfo.comment,
            holidayCalendarId: vacancyInfo.holidayCalendarId,
            submitStatus: copyRowSubmitStatusEnum.Pending,
          };

          vacanciesCopyRowsResult.push(vacanciesCopyRow);
        }
      });
    });

    return vacanciesCopyRowsResult;
  }, [vacanciesIds]);

  const expensesCopyRows: ExpenseCopyRow[] = useMemo(() => {
    const expensesCopyRowsResult: ExpenseCopyRow[] = [];

    expensesSection.forEach(expensesSectionItem => {
      expensesSectionItem.itemsList.forEach(expenseInfo => {
        const expenseId = parseInt(expenseInfo.id);

        if (expensesIds.includes(expenseId)) {
          const expensesCopyRow: ExpenseCopyRow = {
            assignmentId: expenseId,
            title: expenseInfo.title,
            clientId: expenseInfo.clientId,
            cost: expenseInfo.cost,
            startDate: expenseInfo.startDate,
            endDate: undefined,
            notes: expenseInfo.comment,
            submitStatus: copyRowSubmitStatusEnum.Pending,
          };

          expensesCopyRowsResult.push(expensesCopyRow);
        }
      });
    });

    return expensesCopyRowsResult;
  }, [expensesIds]);

  const budgetingsList = useMemo((): SelectedItem[] => {
    if (!budgetingsData || !budgetingsData.sendArrayOfBudgetSettings.data) {
      return [];
    }

    return budgetingsData.sendArrayOfBudgetSettings.data
      .filter(
        budgetsArrItem =>
          budgetsArrItem.id !== settingId && budgetsArrItem.financial_year >= moment().year(),
      )
      .map(budgetsArrItem => {
        return {
          id: budgetsArrItem.id,
          title: budgetsArrItem?.title || '',
          value: String(budgetsArrItem.id),
        };
      });
  }, [budgetingsData]);

  const budgetings = useMemo(() => {
    if (!budgetingsData || !budgetingsData.sendArrayOfBudgetSettings.data) {
      return [];
    }

    return budgetingsData.sendArrayOfBudgetSettings.data;
  }, [budgetingsData]);

  return {
    copyBudgetingRows,
    budgetingsList,
    budgetings,
    holidayCalendarList,
    assignmentsCopyRows,
    vacanciesCopyRows,
    expensesCopyRows,
    loading: getBudgetingsLoading || copyingLoading,
  };
};
