import { FieldMergeFunction } from '@apollo/client';

import get from 'lodash/get';

import * as routes from 'valtech-core/common/routes';
import { AnyObject } from 'valtech-core/common/types';
import authStorage from 'valtech-core/services/auth';

export const mergeConnectionPolicy: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge?: boolean | FieldMergeFunction<any, any> | undefined;
} = {
  merge(existing, incoming, { args }) {
    return {
      ...incoming,
      edges: mergeEdges({ existing, incoming, args }),
    };
  },
};

type MergeOptions = {
  existing: AnyObject[];
  incoming: AnyObject[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args?: Record<string, any> | null;
};

export function mergeValues(options: MergeOptions): AnyObject[] {
  const { existing, incoming, args } = options;

  const skip = get(args, 'skip', 0);
  const limit = Math.max(incoming.length, existing?.length || 0);
  const merged = existing && existing.length ? existing.slice(0) : [];

  for (let i = 0; i < limit; ++i) {
    merged[skip + i] = incoming[i];
  }

  return merged.filter(item => item);
}

function mergeEdges(options: MergeOptions) {
  const { existing, incoming, args } = options;

  const existingEdges = get(existing, 'edges', []);
  const incomingEdges = get(incoming, 'edges', []);
  return mergeValues({
    existing: existingEdges,
    incoming: incomingEdges,
    args,
  });
}

export function clearLocalStorageExceptLastVisitedUrl(): void {
  localStorage.clear();

  authStorage.setLastVisitedURL({
    url:
      !window?.location?.pathname?.includes(routes.OAUTH_CALLBACK) && window?.location?.pathname
        ? window?.location?.pathname
        : routes.HOME_ROUTE,
    isAuthTokenExpired: true,
  });
}
