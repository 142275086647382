import { IFilters } from 'valtech-core/common/types';

import { FiltersState, PageTypes, filtersInitialState, filtersVar } from './filters';

export const setFiltersState = (state: Partial<FiltersState>): void => {
  const currentState = filtersVar();

  filtersVar({
    ...currentState,
    ...state,
  });
};

export const clearFiltersState = (pageType: PageTypes): (() => void) => {
  return function (): void {
    const currentState = filtersVar();

    filtersVar({
      ...currentState,
      [pageType]: { ...filtersInitialState[pageType] },
    });
  };
};

export const setPage = (pageType: PageTypes): ((page: number) => void) => {
  return function (page: number): void {
    const currentState = filtersVar();

    filtersVar({
      ...currentState,
      [pageType]: {
        ...currentState[pageType],
        pagination: {
          ...currentState[pageType].pagination,
          page,
        },
      },
    });
  };
};

export const setRowsPerPage = (pageType: PageTypes): ((rowsPerPage: number) => void) => {
  return function (rowsPerPage: number): void {
    const currentState = filtersVar();

    filtersVar({
      ...currentState,
      [pageType]: {
        ...currentState[pageType],
        pagination: {
          ...currentState[pageType].pagination,
          rowsPerPage,
        },
      },
    });
  };
};

export const setPersistentFilters = (pageType: PageTypes): ((tableFilters: IFilters[]) => void) => {
  return function (persistentFilters: IFilters[]): void {
    const currentState = filtersVar();

    filtersVar({
      ...currentState,
      [pageType]: {
        ...currentState[pageType],
        persistentFilters,
      },
    });
  };
};

export const setProjectFilter = (pageType: PageTypes): ((projectFilter: IFilters) => void) => {
  return function (projectFilter: IFilters): void {
    const currentState = filtersVar();

    filtersVar({
      ...currentState,
      [pageType]: {
        ...currentState[pageType],
        projectFilter,
      },
    });
  };
};
