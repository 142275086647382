import React, { FC, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import CheckboxInput from 'valtech-core/form/Checkbox';
import FormError from 'valtech-core/form/FormError';

export interface ICheckboxFieldProps {
  control: FieldControl;
  name: string;
  label?: string;
  defaultValue?: boolean;
  validate?: (value: boolean) => Promise<Maybe<string>>;
}

const CheckboxFiled: FC<ICheckboxFieldProps> = ({
  name,
  control,
  label,
  defaultValue = false,
  validate,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <CheckboxInput
          {...field}
          label={label}
          id={`filed-${name}`}
          hasError={!!errors[name]}
          checked={field.value}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </CheckboxInput>
      )}
      rules={{
        validate,
      }}
    />
  );
};

export default memo(CheckboxFiled);
