import React, { FC, useMemo } from 'react';

import { useLocalization } from '@fluent/react';
import Modal from 'components/Modal';
import AssignmentForm from 'src/components/_forms/AssignmentForm';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';

import {
  CANCEL_FTL,
  CONFIRMATION_MESSAGE_FTL,
  DELETE_FTL,
  EDIT_FTL,
} from 'valtech-core/common/ftl';
import { CurrencyEnum } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Dropdown from 'valtech-core/ui/Dropdown';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';
import Typography from 'valtech-core/ui/Typography';
import { useToggle } from 'valtech-core/utils/useToggle';

import { EditBillingAssignmentData } from '../../EditSingleBillingTable.utils';

import { useHandleTableRowEdit } from './SingleBillingTableRowEditAssignment.hook';

interface SingleBillingTableRowEditAssignmentProps {
  assignmentId: number;
  billingReportId: number;
  ccy: CurrencyEnum;
  clientId: number;
  icboTemplateId: number;
  rowRecord: EditBillingAssignmentData;
  constantData: { name: string; id: number };
  hasDelete: boolean;
  invoiced: boolean;
}

const SingleBillingTableRowEditAssignment: FC<SingleBillingTableRowEditAssignmentProps> = ({
  assignmentId,
  rowRecord,
  constantData,
  billingReportId,
  ccy = CurrencyEnum.EUR,
  clientId,
  icboTemplateId,
  hasDelete,
  invoiced,
}) => {
  const [isModalOpened, toggleDelete] = useToggle(false);
  const { l10n } = useLocalization();

  const { isLoading, isOpened, toggleForm, onDelete, getAssignmentInitialValues } =
    useHandleTableRowEdit({ assignmentId, rowRecord });

  const displayForm = useMemo(() => {
    return (
      <AssignmentForm
        hasDelete={hasDelete}
        onClose={toggleForm}
        initialValues={getAssignmentInitialValues(constantData, rowRecord)}
        assignmentId={assignmentId}
        billingReportId={billingReportId}
        ccy={ccy}
        clientId={clientId}
        icboTemplateId={icboTemplateId}
        fromBilling
        isDisabledEditOnBilling={false}
      />
    );
  }, [rowRecord]);

  const menuList = useMemo(
    () => [
      {
        id: '1',
        title: EDIT_FTL,
        callback: toggleForm,
        icon: <EditIcon fontSize='small' />,
      },
      {
        id: '2',
        title: DELETE_FTL,
        callback: toggleDelete,
        icon: <DeleteIcon fontSize='small' />,
      },
    ],
    [],
  );

  return (
    <>
      <Dropdown
        bntTitle={''}
        menuList={menuList}
        buttonIcon={<MoreVertIcon />}
        menuPositionVertical='bottom'
        menuPositionHorizontal='right'
        disabled={invoiced}
      />
      <Modal
        handleCloseModal={toggleForm}
        openModal={isOpened}
        size='mid'
        closeBtn
        data-modal='modal'>
        {<>{displayForm}</>}
      </Modal>
      <Modal handleCloseModal={toggleDelete} openModal={isModalOpened} data-modal>
        <SpinnerOverlay visible={isLoading} />
        <Stack gap={2} alignItems='center'>
          <Typography component='p' variant='h5'>
            {l10n.getString(CONFIRMATION_MESSAGE_FTL)}
          </Typography>
          <Stack direction='row' spacing={2}>
            <Button onClick={onDelete} variant='contained' color='error'>
              {l10n.getString(DELETE_FTL)}
            </Button>
            <Button onClick={toggleDelete}>{l10n.getString(CANCEL_FTL)}</Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default SingleBillingTableRowEditAssignment;
