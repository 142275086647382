import { useCallback } from 'react';

import { showGenericError, showSystemError } from 'common/apolloState/system';
import { setBudgetingState, useBudgetingState } from 'src/common/apolloState/budgeting';

import {
  BudgetType,
  useCreateForecastMutation,
  useGetSingleBudgetingLazyQuery,
} from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { IFormattedForecastData, mapToSubmitNewBudget } from './ForecastForm.utils';

type useForecastFormDataReturn = {
  createBudget: (data: IFormattedForecastData) => Promise<boolean>;
};

export const useForecastFormData = (): useForecastFormDataReturn => {
  const [createForecastMutation] = useCreateForecastMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const { existingBudgets, budgetReportId } = useBudgetingState();
  const [getBudgeting] = useGetSingleBudgetingLazyQuery({
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    onError(_) {
      showGenericError();
    },
  });

  const createBudget = useCallback(async (data: IFormattedForecastData) => {
    if (data) {
      const newBudget = await createForecastMutation({
        variables: mapToSubmitNewBudget(data),
        refetchQueries: ['getBudgetingReports'],
      });

      const budgetingData = await getBudgeting({
        variables: {
          id: Number(budgetReportId),
        },
      });
      const newBudgetList = budgetingData.data?.V2getBudgetReportByID.existingBudgets;
      const addedBudgetLabel = newBudget.data?.createBudgetForProject.budget_type;
      const newForecastId = newBudgetList?.find(budget => budget.type === addedBudgetLabel)?.id;

      setBudgetingState({
        existingBudgets: [
          ...existingBudgets,
          { id: newForecastId, type: data.forecastToCreate.label },
        ],
        forecastType: {
          id: newForecastId,
          type: BudgetType[data.forecastToCreate.label],
        },
      });

      return !!newBudget.data;
    }
    return false;
  }, []);

  return { createBudget };
};
