import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import BlurContainer from 'components/BlurContainer';

import ExchangeRateTable from './ExchangeRateTable';
import FinancialInformation from './FinancialInformation';
import RateTable from './RateTable';

interface ISingleBudgetingContentProps {
  loading: boolean;
  onProceed: VoidFunction;
}

const SingleBudgetingContent: FC<ISingleBudgetingContentProps> = ({ onProceed, loading }) => {
  const { id: budgetID } = useParams();
  return (
    <form onSubmit={e => e.preventDefault()} className='BaseForm'>
      <FinancialInformation onProceed={onProceed} loading={loading} />
      <BlurContainer disabled={!budgetID}>
        <RateTable />
        <ExchangeRateTable />
      </BlurContainer>
    </form>
  );
};

export default SingleBudgetingContent;
