import React, { FC } from 'react';

import { FieldValues, UseFieldArrayAppend, useForm } from 'react-hook-form';

import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { Variant } from 'common/types';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { FieldControl } from 'valtech-core/common/types';

import {
  Entity,
  Rate,
  defaultValues,
  feeValidator,
  requiredStringValidator,
  titleValidator,
} from '../RateTable.constants';
import { useRateSelects } from '../RateTable.hook';
import { IUseAddRateParams, useAddRate } from './AddNewRate.hook';

import s from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/shared.module.scss';

export interface IAddNewRateProps {
  append: UseFieldArrayAppend<FieldValues, 'rate.rates'>;
  entities: Entity[];
}

const AddNewRate: FC<IAddNewRateProps> = ({ append, entities }) => {
  const { statuses, models, ccys } = useRateSelects();
  const methods = useForm({ defaultValues });
  const { control, setValue } = methods;
  const { addRate, loading } = useAddRate({ append, methods } as unknown as IUseAddRateParams);
  const { isEditing } = useEditingContext();

  return (
    <TableRow>
      <TableCell>
        <TextField
          name={Rate.Title}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'text',
          }}
          variant={Variant.Filled}
          validate={titleValidator}
          className={s.inputField}
          onBlurCallback={e => {
            setValue(`${Rate.Title}`, e.target.value.trim());
          }}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          className={s.selectField}
          name={Rate.Model}
          data={models}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
          variant={Variant.Filled}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          className={s.selectField}
          name={Rate.Currency}
          data={ccys}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
          variant={Variant.Filled}
        />
      </TableCell>
      <TableCell>
        <TextField
          name={Rate.Fee}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'number',
          }}
          variant={Variant.Filled}
          validate={feeValidator}
          className={s.inputField}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          className={s.selectField}
          name={Rate.Entity}
          data={entities}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
          variant={Variant.Filled}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          className={s.selectField}
          name={Rate.Status}
          data={statuses}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
          variant={Variant.Filled}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton aria-haspopup='true' onClick={addRate} disabled={loading || isEditing}>
          <AddCircleOutlineIcon color='primary' fontSize='medium' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AddNewRate;
