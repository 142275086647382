import { useLocalization } from '@fluent/react';
import { getYearsRangeSelect } from 'common/utils/getYearsRangeSelect';

import {
  STATUS_ACTIVE_FTL,
  STATUS_ARCHIVED_FTL,
  STATUS_NOT_ACTIVE_FTL,
} from 'valtech-core/common/ftl';
import { RecordStatus } from 'valtech-core/common/gql/generated';
import { SelectedItem } from 'valtech-core/form/Select';

type UseFinancialInformationReturn = {
  years: SelectedItem[];
  statuses: SelectedItem[];
};

export const useFinancialInformation = (): UseFinancialInformationReturn => {
  const { l10n } = useLocalization();

  const years = getYearsRangeSelect();

  const statuses: SelectedItem[] = [
    { id: 1, title: l10n.getString(STATUS_NOT_ACTIVE_FTL), value: RecordStatus.NotActive },
    { id: 2, title: l10n.getString(STATUS_ACTIVE_FTL), value: RecordStatus.Active },
    { id: 3, title: l10n.getString(STATUS_ARCHIVED_FTL), value: RecordStatus.Archived },
  ];

  return {
    years,
    statuses,
  };
};
