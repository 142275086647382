import { CurrencyEnum, Maybe } from 'valtech-core/common/types';

const getCurrencyCode = (currency: Maybe<string>): CurrencyEnum => {
  if (!currency) {
    return CurrencyEnum.EUR;
  }
  return currency === CurrencyEnum.EUR ? CurrencyEnum.EUR : CurrencyEnum.USD;
};

export default getCurrencyCode;
