import React, { FC, FocusEvent, ReactNode, memo } from 'react';

import { Localized } from '@fluent/react';
import cn from 'classnames';
import TextField from 'form/TextField';

import { Popper } from '@mui/material';
import Box from '@mui/material/Box';

import { requiredStringValidatorWithShortMessage } from 'valtech-core/common/form/validators';
import { FieldControl } from 'valtech-core/common/types';
import Typography from 'valtech-core/ui/Typography';

import styles from '../EditBudgetingTable.module.scss';
import { PARTIAL_ALLOCATION_WARNING } from './EditAllocation.ftl';

interface EditAllocationProps {
  control: FieldControl;
  id: string;
  readOnly: boolean;
  isPartial: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  className?: string;
  endAdornment?: ReactNode;
  children?: ReactNode;
}

const EditAllocation: FC<EditAllocationProps> = ({
  id,
  className,
  onFocus,
  onBlur,
  onKeyPress,
  endAdornment,
  control,
  readOnly,
  isPartial,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleTouch = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur(e);
    if (!e.target.value) {
      e.target.classList.add(cn(styles.emptyInput));
    } else {
      e.target.classList.remove(cn(styles.emptyInput));
    }
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {isPartial && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement='top'
          sx={{ padding: '6px', zIndex: 1 }}>
          <Box width='300px' bgcolor='white' p='5px' border='1px solid' borderRadius='5px'>
            <Typography variant='caption'>
              <Localized id={PARTIAL_ALLOCATION_WARNING} />
            </Typography>
          </Box>
        </Popper>
      )}
      <TextField
        name={id}
        control={control}
        validate={requiredStringValidatorWithShortMessage}
        variant='standard'
        autoComplete='off'
        readOnly={readOnly}
        className={className}
        onBlurCallback={e => handleTouch(e)}
        onKeyPress={onKeyPress}
        onFocus={e => handleFocus(e)}
        endAdornment={endAdornment}
        textProps={{
          type: 'number',
        }}
        acceptOnlyPositiveNumbers={true}
      />
    </>
  );
};

export default memo(EditAllocation);
