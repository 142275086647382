import { useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import {
  setPage,
  setPersistentFilters,
  setRowsPerPage,
} from 'src/common/apolloState/filters/filters.actions';
import { FilterModelEnum, TableSearchFieldsEnum } from 'src/common/types';
import { createFiltersPayload } from 'src/common/utils/createFiltersPayload';
import { removeProjectFromFilters } from 'src/common/utils/handleProjectsData';

import {
  BudgetReportModel,
  OrderDirection,
  RecordStatus,
  useGetBudgetingReportsQuery,
} from 'valtech-core/common/gql/generated';
import { IFilters, ISortingAndFiltering } from 'valtech-core/common/types';

interface IBudgeting {
  id: number;
  manager: string;
  budgetsTitle: string;
  project: string;
  lastUpdated: string;
  status: RecordStatus;
}

export interface IUseBudgetingReturn extends ISortingAndFiltering {
  budgetings: IBudgeting[];
}

export const useBudgetings = (): IUseBudgetingReturn => {
  const { budgetingPageFilters } = useFiltersState();
  const {
    persistentFilters,
    projectFilter: budgetingProjectFilter,
    pagination,
  } = budgetingPageFilters;
  const { page, rowsPerPage } = pagination;

  const [total, setTotal] = useState(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Desc);
  const [model, setModel] = useState<string>(FilterModelEnum.Budgets);
  const [sortBy, setSortBy] = useState<string>(TableSearchFieldsEnum.Title);
  const [filter, setFilter] = useState<IFilters[] | []>(persistentFilters);
  const [projectFilter, setProjectFilter] = useState<IFilters>(budgetingProjectFilter);
  const [tableFilters, setTableFilters] = useState<IFilters[] | []>(
    removeProjectFromFilters(persistentFilters),
  );

  const { data, loading, error } = useGetBudgetingReportsQuery({
    variables: {
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const budgetings: IBudgeting[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const budgetingsArr = get(data, 'sendArrayOfBudgets.data', []) as BudgetReportModel[];

    return budgetingsArr.map(item => {
      return {
        id: item.id,
        manager: item.projectInfo?.deliveryManagerInfo?.name || '',
        budgetsTitle: item.budgetingSettingInfo?.title || '',
        project: item.projectInfo?.name || '',
        status: item.status,
        lastUpdated: item.update_time,
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.sendArrayOfBudgets?.pageInfo?.total;
    const isNumberValue = isNumber(data?.sendArrayOfBudgets?.pageInfo?.total);
    if (isTotalChanged && isNumberValue) {
      setTotal(data?.sendArrayOfBudgets?.pageInfo?.total || total);
    }
  }, [data]);

  useEffect(() => {
    const newFilters = createFiltersPayload(projectFilter, tableFilters);

    setPersistentFilters(PageTypes.BUDGETING)(newFilters);
    setFilter(newFilters);
  }, [projectFilter, tableFilters]);

  return {
    page,
    setPage: setPage(PageTypes.BUDGETING),
    rowsPerPage,
    setRowsPerPage: setRowsPerPage(PageTypes.BUDGETING),
    loading,
    error,
    budgetings,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    setFilter,
    filter,
    setTableFilters,
    setProjectFilter,
    tableFilters,
    projectFilter,
  };
};
