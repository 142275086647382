import React, { FC } from 'react';

import { FieldValues, UseFieldArrayAppend, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import AutocompleteField from 'form/AutocompleteField';
import SelectFiled from 'form/SelectField/SelectField';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import { ACCESS_LEVEL_FTL, NAME_FTL } from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';

import {
  UserCredentialsEnum,
  defaultValues,
  selectMockAccessLevel,
} from '../UserCredentialsTable.const';
import {
  IAddNewUserCredentialsParams,
  useAddNewUserCredentials,
} from './AddNewUserCredentials.hook';

interface IUsersList {
  id: number;
  label: string;
}
export interface IAddNewUserCredentialsProps {
  append: UseFieldArrayAppend<FieldValues, 'userCredentials.credentials'>;
  usersList: IUsersList[];
}

const AddNewUserCredentials: FC<IAddNewUserCredentialsProps> = ({ append, usersList }) => {
  const methods = useForm({ defaultValues });
  const { control } = methods;
  const { l10n } = useLocalization();
  const { addNewUserCredentials, loading } = useAddNewUserCredentials({
    append,
    methods,
  } as unknown as IAddNewUserCredentialsParams);
  const { isEditing } = useEditingContext();

  return (
    <TableRow>
      <TableCell>
        <AutocompleteField
          label={l10n.getString(NAME_FTL)}
          name={UserCredentialsEnum.Name}
          control={control as unknown as FieldControl}
          data={usersList}
          validate={value => requiredStringValidator(value.label)}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          label={l10n.getString(ACCESS_LEVEL_FTL)}
          name={UserCredentialsEnum.AccessLevel}
          data={selectMockAccessLevel}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton
          aria-haspopup='true'
          onClick={addNewUserCredentials}
          disabled={loading || isEditing}>
          <AddCircleOutlineIcon color='primary' fontSize='medium' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AddNewUserCredentials;
