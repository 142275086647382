import React, { ElementType, FC } from 'react';

import cn from 'classnames';

import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

import styles from './Typography.module.scss';

type TypographyProps = MuiTypographyProps & {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold';
  transform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
  textStyle?: 'normal' | 'italic' | 'oblique';
  decoration?: 'none' | 'line-through' | 'overline' | 'underline';
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
};

const Typography: FC<TypographyProps> = ({
  variant,
  children,
  weight,
  transform,
  align,
  textStyle,
  decoration,
  className,
  component = 'span',
  ...restMui
}) => {
  return (
    <MuiTypography
      variant={variant}
      align={align}
      className={cn(
        styles.text,
        styles[`text-${weight}`],
        styles[`text-${transform}`],
        styles[`text-${textStyle}`],
        styles[`text-${decoration}`],
        className,
      )}
      component={component}
      {...restMui}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
