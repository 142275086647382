import { DEFAULT_OVERTIME_COEFFICIENT } from 'common/constants';

export const GENERAL_FEEDBACK_ALERTS = [
  { id: '1', title: 'No timesheets found', severity: 'warning' },
  { id: '2', title: 'Some timesheets are not yet approved', severity: 'warning' },
  { id: '3', title: 'Timesheet does not match the forecast', severity: 'warning' },
  { id: '4', title: 'The vacation balance is not cashed out', severity: 'warning' },
];

export const GENERAL_TREE_DATA = [
  {
    id: '1',
    title: 'ELoza, Taisiia',
    value: '160',
    children: [
      { id: '1-1', title: 'Test 1-1', value: '52', children: [] },
      { id: '1-2', title: 'Test 1-2', value: '28', children: [] },
      {
        id: '1-3',
        title: 'Test 1-3',
        value: '17',
        children: [
          {
            id: '1-3-1',
            title: 'Test 1-3-1',
            value: '77',
            children: [
              { id: '1-3-1-1', title: 'Test 1-3-1-1', value: '52', children: [] },
              {
                id: '1-3-1-2',
                title: 'Test 1-3-1-2',
                value: '52',
                children: [{ id: '1-3-1-2-1', title: 'Test 1-3-1-2-1', value: '52', children: [] }],
              },
            ],
          },
          { id: '1-3-2', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-3', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-4', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-5', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-6', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-7', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-8', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-9', title: 'Test 1-3-2', value: '778' },
          { id: '1-3-10', title: 'Test 1-3-2', value: '778' },
        ],
      },
    ],
  },
];
export const OVERTIME_SELECT_DATA = [
  { id: '1', title: 'bill', value: 'bill' },
  { id: '2', title: 'vacation', value: 'vacation' },
];

// TODO VREP-837  temporary solution to block type selector and set bill as default
export const DEFAULT_OVERTIME = {
  hours: '',
  multiplier: String(DEFAULT_OVERTIME_COEFFICIENT),
  type: OVERTIME_SELECT_DATA[0].value,
  comment: '',
};
