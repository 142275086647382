import { useEffect, useMemo, useState } from 'react';

import isNumber from 'lodash/isNumber';
import { useFiltersState } from 'src/common/apolloState/filters';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import {
  setPage,
  setPersistentFilters,
  setRowsPerPage,
} from 'src/common/apolloState/filters/filters.actions';
import { FilterModelEnum, TableSearchFieldsEnum } from 'src/common/types';
import { createFiltersPayload } from 'src/common/utils/createFiltersPayload';
import { removeProjectFromFilters } from 'src/common/utils/handleProjectsData';

import {
  BillingReportModel,
  BillingStatus,
  OrderDirection,
  useGetBillingReportsQuery,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum, IFilters, ISortingAndFiltering, Maybe } from 'valtech-core/common/types';
import getCurrencyCode from 'valtech-core/common/utils/getCurrencyCode';

interface IBilling {
  id: number;
  manager: string;
  billingsTitle: string;
  project: string;
  total: Maybe<number>;
  status: BillingStatus;
  financial_year: Maybe<number>;
  currency: CurrencyEnum;
}

export interface IUseBillingsReturn extends ISortingAndFiltering {
  billings: IBilling[];
}

export const useBillings = (): IUseBillingsReturn => {
  const { billingPageFilters } = useFiltersState();
  const { persistentFilters, projectFilter: billingProjectFilter, pagination } = billingPageFilters;
  const { page, rowsPerPage } = pagination;

  const [total, setTotal] = useState<number>(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Desc);
  const [model, setModel] = useState<string>(FilterModelEnum.Manager);
  const [sortBy, setSortBy] = useState<string>(TableSearchFieldsEnum.Name);
  const [projectFilter, setProjectFilter] = useState<IFilters>(billingProjectFilter);
  const [filter, setFilter] = useState<IFilters[] | []>(persistentFilters);
  const [tableFilters, setTableFilters] = useState<IFilters[] | []>(
    removeProjectFromFilters(persistentFilters),
  );

  const { data, loading, error } = useGetBillingReportsQuery({
    variables: {
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });
  const billings: IBilling[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const billingsArr = data.sendAnArrayOfBillingReports.data as BillingReportModel[];

    return billingsArr.map(item => {
      return {
        id: item.id,
        manager: item.projectInfo?.deliveryManagerInfo?.name || '',
        project: item.projectInfo?.name || '',
        billingsTitle: item.title || '',
        status: item.status || '',
        total: item.total || undefined,
        financial_year: item.financial_year || undefined,
        currency: getCurrencyCode(item.projectInfo?.ccy),
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.sendAnArrayOfBillingReports?.pageInfo?.total;
    const isNumberValue = isNumber(data?.sendAnArrayOfBillingReports?.pageInfo?.total);

    if (isTotalChanged && isNumberValue) {
      setTotal(data?.sendAnArrayOfBillingReports?.pageInfo?.total || total);
    }
  }, [data]);

  useEffect(() => {
    const newFilters = createFiltersPayload(projectFilter, tableFilters);

    setPersistentFilters(PageTypes.BILLING)(newFilters);
    setFilter(newFilters);
  }, [projectFilter, tableFilters]);

  return {
    page,
    setPage: setPage(PageTypes.BILLING),
    rowsPerPage,
    setRowsPerPage: setRowsPerPage(PageTypes.BILLING),
    loading,
    error,
    billings,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    filter,
    setTableFilters,
    setProjectFilter,
    setFilter,
    tableFilters,
    projectFilter,
  };
};
