import { useCallback } from 'react';

import { showSystemError } from 'common/apolloState/system';

import {
  useCreateAccountMutation,
  useEditAccountMutation,
} from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { mapToSubmitAddAccount, mapToSubmitEditAccount } from './AccountForm.utils';

import { IAccountFormProps } from './AccountForm';

type UseHandleAccountReturn = {
  createAccount: (data: IAccountFormProps) => Promise<boolean>;
  updateAccount: (data: IAccountFormProps) => Promise<boolean>;
  loading: boolean;
};

export const useHandleAccount = (): UseHandleAccountReturn => {
  const [addAccount, { loading: adding }] = useCreateAccountMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [editAccountMutation, { loading: updating }] = useEditAccountMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const createAccount = useCallback(async (data: IAccountFormProps) => {
    const newAccount = await addAccount({
      variables: mapToSubmitAddAccount({ data }),
      refetchQueries: ['getArrayOfAccounts'],
    });

    return !!newAccount.data?.createAccount.id;
  }, []);

  const updateAccount = useCallback(async (data: IAccountFormProps) => {
    const updateAccount = await editAccountMutation({
      variables: mapToSubmitEditAccount({ data }),
      refetchQueries: ['getArrayOfAccounts'],
    });

    return !!updateAccount.data;
  }, []);

  return {
    createAccount,
    updateAccount,
    loading: adding || updating,
  };
};
