import { useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';

import { UserInfo, authVar } from './auth';
import { setAuthLoggedIn, setAuthLoggedOut } from './auth.actions';

type UseAuthStateReturn = {
  isLoggedIn: boolean;
  setAuth: (loggedIn: boolean, userInfo?: UserInfo) => void;
  userInfo: UserInfo | null;
};

export const useAuthState = (): UseAuthStateReturn => {
  const { isLoggedIn, userInfo } = useReactiveVar(authVar);

  const setAuth = useCallback((loggedIn: boolean, userInfo?: UserInfo) => {
    if (loggedIn && userInfo) {
      setAuthLoggedIn(userInfo);
    } else {
      setAuthLoggedOut();
    }
  }, []);

  return {
    isLoggedIn,
    setAuth,
    userInfo,
  };
};
