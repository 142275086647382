export const EDIT_CLIENTS_FTL = 'budgeting-edit-clients';
export const EDIT_VACANCIES_FTL = 'budgeting-edit-vacancies';
export const EDIT_EXPENSES_FTL = 'budgeting-edit-expenses';

export const TOTAL_CONSULTANTS_FTL = 'budgeting-edit-total-consultants';
export const TOTAL_VACANCIES_FTL = 'budgeting-edit-total-vacancies';
export const TOTAL_EXPENSES_FTL = 'budgeting-edit-total-expenses';
export const TOTAL_INVOICED_FTL = 'budgeting-edit-total-invoiced';
export const FORECASTED_TOTAL_BY_ENTITIES_FTL = 'budgeting-total-forecasted_by_entities';
export const TOTAL_FORECASTED_FTL = 'budgeting-edit-total-forecasted';
export const FORECASTED_GM = 'budgeting-edit-forecasted-gm';
export const ACTUAL_GM = 'budgeting-edit-actual-gm';
