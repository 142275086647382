import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { UseFormReturn } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'common/apolloState/notifications';
import { showSystemError } from 'common/apolloState/system';
import { SETTINGS_BUDGETING_ID_PAGE_ROUTE } from 'common/routes';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import round from 'lodash/round';

import {
  BUDGET_WAS_CREATED_FTL,
  CHANGES_WERE_SAVED_FTL,
  DEC_SHORT_FTL,
  ERROR_BUDGETING_YEAR_EXIST,
  FINANCIAL_YEAR_LABEL_FTL,
  GENERIC_ERROR,
  JAN_SHORT_FTL,
} from 'valtech-core/common/ftl';
import {
  RecordStatus,
  useCreateBudgetPerYearMutation,
  useGetBudgetSettingByIdLazyQuery,
  useUpdateBudgetPerYearMutation,
} from 'valtech-core/common/gql/generated';
import { SETTINGS_BUDGETINGS_ROUTE } from 'valtech-core/common/routes';
import { AlertsSeverityEnum } from 'valtech-core/common/types';

interface IBudget {
  title: string;
  year: number;
  status: RecordStatus;
  user_id?: number;
  costOfOverheadUsd: number;
  costOfOverheadEur: number;
}

export type UseBudgetInfoQueryReturn = {
  budgetData: {
    loading: boolean;
    data: IBudget;
  };
  getTemplate: (year: string | number) => string;
};

export const useBudgetInfo = (): UseBudgetInfoQueryReturn => {
  const { id } = useParams();
  const budgetID = Number(id);
  const { l10n } = useLocalization();

  const [getBudgetSettings, { data, loading }] = useGetBudgetSettingByIdLazyQuery();

  useEffect(() => {
    if (id) {
      getBudgetSettings({
        variables: {
          budgetSettingId: budgetID,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [id]);

  const getTemplate = useCallback(
    year =>
      `${l10n.getString(FINANCIAL_YEAR_LABEL_FTL)}${year} (${l10n.getString(
        JAN_SHORT_FTL,
      )} ${year} - ${l10n.getString(DEC_SHORT_FTL)} ${year})`,
    [],
  );

  const _data = useMemo(() => {
    if (!data || !budgetID) {
      const year = new Date().getUTCFullYear();
      return {
        title: getTemplate(year),
        year,
        status: RecordStatus.NotActive,
        costOfOverheadUsd: 0,
        costOfOverheadEur: 0,
      };
    } else {
      const {
        financial_year: year,
        cost_of_overhead_usd,
        cost_of_overhead_eur,
        status,
        title,
        user_id,
      } = data?.getBudgetSettingByID || {};

      return {
        year,
        title,
        status,
        user_id,
        costOfOverheadUsd: cost_of_overhead_usd ?? 0,
        costOfOverheadEur: cost_of_overhead_eur ?? 0,
      } as IBudget;
    }
  }, [data]);

  return {
    budgetData: {
      loading,
      data: _data,
    },
    getTemplate,
  };
};

interface IUseAddBudgetReturn {
  onSubmit: (proceed?: boolean) => Promise<void>;
  loading: boolean;
}

export const useAddBudget = (methods: UseFormReturn): IUseAddBudgetReturn => {
  const { l10n } = useLocalization();
  const [addBudget, { loading: addBudgetLoading }] = useCreateBudgetPerYearMutation();
  const [useUpdate, { loading: updateBudgetLoading }] = useUpdateBudgetPerYearMutation();

  const navigate = useNavigate();

  const { id: budgetID } = useParams();

  const onSubmit = useCallback(
    async (proceed?: boolean) => {
      const { budgetData } = methods.getValues();

      const {
        year: financialYear,
        title,
        status,
        user_id,
        costOfOverheadUsd,
        costOfOverheadEur,
      } = budgetData.data;

      const variables = {
        title,
        status,
        financialYear: Number(financialYear),
        modifiedDate: Date.now(),
        costOfOverheadUsd: round(Number(costOfOverheadUsd), 2),
        costOfOverheadEur: round(Number(costOfOverheadEur), 2),
      };

      try {
        const isValid = await methods.trigger();
        if (isValid) {
          if (!budgetID) {
            const res = await addBudget({
              variables: {
                ...variables,
              },
            });

            const id = res.data?.createBudgetPerYear?.id;

            if (!id) throw new Error('id undefined');

            showNotification({
              message: l10n.getString(BUDGET_WAS_CREATED_FTL),
              hideDuration: secondsToMilliSeconds(3),
              severity: AlertsSeverityEnum.Success,
            });

            if (!budgetID && !proceed) navigate(SETTINGS_BUDGETINGS_ROUTE);
            else if (proceed) {
              navigate(SETTINGS_BUDGETING_ID_PAGE_ROUTE.replace(':id', id.toString()), {
                replace: true,
              });
            }
          } else {
            await useUpdate({
              variables: {
                budgetSettingId: Number(budgetID),
                ...(user_id ? { userId: user_id } : {}),
                ...variables,
              },
            });

            showNotification({
              message: l10n.getString(CHANGES_WERE_SAVED_FTL),
              hideDuration: secondsToMilliSeconds(3),
              severity: AlertsSeverityEnum.Success,
            });

            // Need to reset touched state (state when the user tried to modify some input's value) from fields
            methods.resetField('budgetData', { defaultValue: budgetData });
          }
        }
      } catch (e: any) {
        if (
          e.message.includes('Budget with financial year') &&
          e.message.includes('already exists')
        ) {
          showSystemError(ERROR_BUDGETING_YEAR_EXIST);
        } else {
          showSystemError(GENERIC_ERROR);
        }
      }
    },
    [budgetID],
  );

  return {
    loading: addBudgetLoading || updateBudgetLoading,
    onSubmit,
  };
};
