import { useReactiveVar } from '@apollo/client';

import { systemVar } from './system';

type UseSystemStateReturn = {
  isLoading: boolean;
  error: string | null;
};

export const useSystemState = (): UseSystemStateReturn => {
  const { loading: isLoading, error } = useReactiveVar(systemVar);

  return {
    isLoading,
    error,
  };
};
