import { ApolloClient, NormalizedCacheObject, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import authStorage from 'valtech-core/services/auth';
import { getApiUrl } from 'valtech-core/utils/getApiUrl';

import { cache } from './cache';
import { errorLink } from './errors';

const API = `${getApiUrl()}/graphql`;

const httpLink = createHttpLink({
  uri: API,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = authStorage.getToken();
  let headerParams = {};

  if (token) headerParams = { authorization: `Bearer ${token}` };

  return {
    headers: {
      ...headers,
      ...headerParams,
    },
  };
});

const clientLink = authLink.concat(httpLink);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([errorLink, clientLink]),
  cache,
});

export default client;
