import React, { FC, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { FormProvider, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import {
  SETTINGS_EDIT_PROJECT_PAGE_ROUTE,
  SETTINGS_PROJECT_PAGE_ROUTE,
} from 'common/routes/settings';
import {
  EditProjectVariables,
  SingleProjectFormProps,
  UpdatedSingleProjectFormProps,
} from 'pages/ManageSettings/SingleProject/SingleProject';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';
import { EFormFieldName } from 'src/components/_forms/form.types';

import { ADD_FTL, CREATE_NEW_PROJECT_FTL, SAVE_FTL } from 'valtech-core/common/ftl';

import { generateFormFieldData } from '../SingleProject.utils';

import { useHandleSingleProject } from '../SinglePrject.hook';
import SingleProjectContent from './SingleProjectContent';

type ISingleProjectFormWrapperProps = Partial<SingleProjectFormProps>;

const SingleAccountFormWrapper: FC<ISingleProjectFormWrapperProps> = props => {
  const { id: projectId } = useParams();
  const { l10n } = useLocalization();
  const { createProject, editProject } = useHandleSingleProject();
  const navigate = useNavigate();

  const methods = useForm<UpdatedSingleProjectFormProps>({
    defaultValues: {
      ...props,
      [EFormFieldName.DeliveryManagerId]: generateFormFieldData(
        props?.deliveryManagerId,
        EFormFieldName.Title,
        props?.arrayOfDeliveryManagers,
      ),
      [EFormFieldName.AccountId]: generateFormFieldData(
        props?.accountId,
        EFormFieldName.Title,
        props?.accounts,
      ),
    },
  });

  const projectTitle = !!projectId
    ? props.projectName || ''
    : l10n.getString(CREATE_NEW_PROJECT_FTL);

  const navigateToPage = useCallback((id: number) => {
    navigate(`${SETTINGS_EDIT_PROJECT_PAGE_ROUTE.replace(':id', id.toString())}`);
  }, []);

  const onSubmitProceed = async () => {
    try {
      const isValid = await methods.trigger();
      if (isValid) {
        const restData = {
          ...methods.getValues(),
          deliveryManagerId: methods.getValues()[EFormFieldName.DeliveryManagerId].id as number,
          accountId: methods.getValues().accountId.id as number,
        };

        if (projectId) {
          const data: EditProjectVariables = { ...restData, id: Number(projectId) };
          editProject(data).then(() => navigate(SETTINGS_PROJECT_PAGE_ROUTE));
        } else {
          createProject(restData).then(id => {
            if (id) {
              navigateToPage(id);
            }
          });
        }
      }
    } catch (_) {}
  };

  const onSubmitSave = async () => {
    try {
      const isValid = await methods.trigger();
      if (isValid) {
        const restData = {
          ...methods.getValues(),
          deliveryManagerId: methods.getValues().deliveryManagerId.id as number,
          accountId: methods.getValues().accountId.id as number,
        };

        if (projectId) {
          const data: EditProjectVariables = { ...restData, id: Number(projectId) };
          editProject(data).then(res => res && navigate(SETTINGS_PROJECT_PAGE_ROUTE));
        } else {
          createProject(restData).then(id => {
            if (id) {
              navigate(SETTINGS_PROJECT_PAGE_ROUTE);
            }
          });
        }
      }
    } catch (_) {}
  };

  return (
    <FormProvider {...methods}>
      <SettingsHeaderReusable
        title={projectTitle}
        btnTitle={!!projectId ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        callback={onSubmitSave}
      />
      <SingleProjectContent {...props} callback={onSubmitProceed} />
    </FormProvider>
  );
};

export default SingleAccountFormWrapper;
