import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { UseFormReturn } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'common/apolloState/notifications';
import { showSystemError } from 'common/apolloState/system';
import {
  SETTINGS_HOLIDAY_CALENDAR_EDIT_PAGE_ROUTE,
  SETTINGS_HOLIDAY_CALENDAR_PAGE_ROUTE,
} from 'common/routes';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import { EFormFieldName } from 'src/components/_forms/form.types';

import {
  CALENDAR_WAS_CREATED_FTL,
  CHANGES_WERE_SAVED_FTL,
  ERROR_HOLIDAY_CALENDAR_EXIST,
} from 'valtech-core/common/ftl';
import {
  useAddHolidayCalendarMutation,
  useUpdateHolidayCalendarByIdMutation,
} from 'valtech-core/common/gql/generated';
import { AlertsSeverityEnum } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

interface IUseAddUpdHolidayCalendarReturn {
  loading: boolean;
  onSubmit: (proceed?: boolean) => Promise<void>;
}

export const useAddUpdHolidayCalendar = (
  methods: UseFormReturn,
): IUseAddUpdHolidayCalendarReturn => {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const { id: calendarID } = useParams();
  const [addHolidayCalendar, { loading: addHolidayCalendarLoading }] =
    useAddHolidayCalendarMutation();
  const [updHolidayCalendar, { loading: updHolidayCalendarLoading }] =
    useUpdateHolidayCalendarByIdMutation();

  const loading = addHolidayCalendarLoading || updHolidayCalendarLoading;
  const onSubmit = useCallback(
    async (proceed?: boolean) => {
      const { calendar } = methods.getValues();

      const { year, title, status, businessUnit } = calendar.data;

      const variables = {
        year: Number(year),
        title,
        status,
        businessUnitId: businessUnit,
      };

      try {
        const isValid = await methods.trigger();
        if (!isValid) return;

        if (!calendarID) {
          const resp = await addHolidayCalendar({
            variables: {
              ...variables,
            },
          });

          const id = resp?.data?.addHolidayCalendar?.id;
          if (!id) throw new Error('calendar id undefined');

          showNotification({
            message: l10n.getString(CALENDAR_WAS_CREATED_FTL),
            hideDuration: secondsToMilliSeconds(3),
            severity: AlertsSeverityEnum.Success,
          });

          if (!calendarID && !proceed) navigate(SETTINGS_HOLIDAY_CALENDAR_PAGE_ROUTE);
          else if (proceed) {
            navigate(SETTINGS_HOLIDAY_CALENDAR_EDIT_PAGE_ROUTE.replace(':id', id.toString()), {
              replace: true,
            });
          }
        } else {
          await updHolidayCalendar({
            variables: {
              ...variables,
              holidayCalendarId: Number(calendarID),
            },
          });

          showNotification({
            message: l10n.getString(CHANGES_WERE_SAVED_FTL),
            hideDuration: secondsToMilliSeconds(3),
            severity: AlertsSeverityEnum.Success,
          });

          // Need to reset touched state (state when the user tried to modify some input's value) from fields
          methods.resetField(EFormFieldName.Calendar, { defaultValue: calendar });
        }
      } catch (e: any) {
        if (e.message.includes('Holiday Calendar') && e.message.includes('already exists')) {
          showSystemError(ERROR_HOLIDAY_CALENDAR_EXIST);
        } else {
          showSystemError(getErrorMessage(e.message));
        }
      }
    },
    [calendarID],
  );

  return {
    loading,
    onSubmit,
  };
};
