import React, { FC, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { SingleBillingTableTypeEnum, TabsActionEnum } from 'common/types';
import TextField from 'form/TextField/TextField';
import round from 'lodash/round';
import {
  useGeneralTab,
  useHandleUpdateAssignment,
} from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';
import { useHandleSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import { Divider } from '@mui/material';

import {
  amountValidator,
  conditionalNumberValidator,
  conditionalOptionalStringValidator,
  titleOptionalValidator,
} from 'valtech-core/common/form/validators';
import {
  ADD_COMMENT_FTL,
  AMOUNT_FTL,
  QTY_FTL,
  SAVE_FTL,
  TITLE_FTL,
  UNDO_FTL,
} from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import { FieldControl, Maybe } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import s from '../shared.module.scss';

type FormProps = {
  amount: string;
  qty?: Maybe<string>;
  title?: Maybe<string>;
  comment?: Maybe<string>;
};

type ExpensesFormProps = {
  initialValues: FormProps;
};

const Expenses: FC<ExpensesFormProps> = ({ initialValues }) => {
  const { l10n } = useLocalization();
  const { updateAssignmentExpenses, loading, updateClientsExpenses } = useHandleUpdateAssignment();
  const {
    tableName,
    currencySign,
    projectData,
    updateStateConsultantsExpenses,
    updateStateClientsExpenses,
    billingStatus,
  } = useGeneralTab();
  const { addBillingReportComment } = useHandleSingleBillingTabs();
  const { projectId } = useBudgetingState();

  const [disabled, setDisabled] = useState(false);

  const { control, handleSubmit, reset } = useForm<FormProps>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset({ ...initialValues });
    if (disabled && !initialValues.amount) setDisabled(false);
    else if (!disabled && initialValues.amount) setDisabled(true);
  }, [projectData?.id]);

  const onSave = useCallback(
    (data: FormProps, type: TabsActionEnum) => {
      if (!projectData?.id) return;

      const { amount, comment, qty, title } = data;
      const { id, total, quantity, title: projectTitle } = projectData;

      const assignmentId = Number(projectData?.id);

      const handleSuccessConsultants = (
        expenseAmount: string,
        expenseComment: string,
        disabled: boolean,
      ): void => {
        reset({ amount: expenseAmount, comment: expenseComment });
        updateStateConsultantsExpenses(expenseAmount, expenseComment);
        setDisabled(disabled);
      };

      const handleSuccessClients = (
        expenseData: { amount: string; qty?: string; title?: string },
        disabled: boolean,
      ): void => {
        reset(expenseData);
        updateStateClientsExpenses({
          expensesClientsAmount: expenseData.amount,
          expensesQty: expenseData.qty,
          expensesTitle: expenseData.title,
        });
        setDisabled(disabled);
      };

      switch (tableName) {
        case SingleBillingTableTypeEnum.Consultants:
          switch (type) {
            case TabsActionEnum.Save:
              const commentText = `Added expense in amount ${data.amount} ${currencySign} ${
                data.comment ? `with comment: "${data.comment}"` : ''
              }`;

              updateAssignmentExpenses({
                assignmentId,
                expenseNote: String(comment),
                expenseTotal: round(Number(amount), 2),
                projectTotal: total,
              }).then(isSuccessful => {
                if (isSuccessful) {
                  addBillingReportComment(id, commentText, projectId);
                  handleSuccessConsultants(amount, commentText, true);
                }
              });
              break;

            case TabsActionEnum.Undo:
              const undoComment = 'Has undone added expenses';

              updateAssignmentExpenses({
                assignmentId,
                expenseNote: null,
                expenseTotal: null,
                projectTotal: total,
              }).then(isSuccessful => {
                if (isSuccessful) {
                  addBillingReportComment(id, undoComment, projectId);
                  handleSuccessConsultants('', '', false);
                }
              });
              break;

            default:
              break;
          }

          break;

        case SingleBillingTableTypeEnum.Clients:
          switch (type) {
            case TabsActionEnum.Save:
              updateClientsExpenses({
                id: assignmentId,
                correctionExpense: round(Number(amount), 2),
                correctionQty: Number(qty),
                correctionTitle: String(title),
              }).then(isSuccessful => {
                if (isSuccessful) {
                  handleSuccessClients({ amount, qty, title }, true);
                }
              });

              break;

            case TabsActionEnum.Undo:
              updateClientsExpenses({
                id: assignmentId,
                correctionExpense: round(Number(total), 2),
                correctionQty: Number(quantity),
                correctionTitle: String(projectTitle),
              }).then(isSuccessful => {
                if (isSuccessful) {
                  handleSuccessClients({ amount: '', qty: '', title: '' }, false);
                }
              });

              break;

            default:
              break;
          }

          break;

        default:
          break;
      }
    },
    [projectData?.id, projectData?.total, projectData?.quantity, projectData?.title, tableName],
  );

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <SpinnerOverlay visible={loading} />
        <div className={s.fieldsWrapper}>
          <TextField
            label={`${l10n.getString(AMOUNT_FTL)}, ${currencySign}`}
            name='amount'
            control={control as unknown as FieldControl}
            boxProps={{
              className: s.input,
            }}
            textProps={{
              size: 'small',
              type: 'number',
            }}
            validate={amountValidator}
            disabled={disabled}
          />
          {tableName === SingleBillingTableTypeEnum.Consultants ? (
            <TextField
              label={l10n.getString(ADD_COMMENT_FTL)}
              name='comment'
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              textProps={{
                size: 'small',
              }}
              validate={conditionalOptionalStringValidator(disabled)}
              disabled={disabled}
            />
          ) : (
            <TextField
              label={l10n.getString(QTY_FTL)}
              name='qty'
              control={control as unknown as FieldControl}
              boxProps={{
                sx: { width: '12%' },
              }}
              textProps={{
                size: 'small',
                type: 'number',
              }}
              validate={conditionalNumberValidator(disabled)}
              disabled={disabled}
              integerInput={true}
            />
          )}

          {tableName === SingleBillingTableTypeEnum.Clients && (
            <TextField
              label={l10n.getString(TITLE_FTL)}
              name='title'
              control={control as unknown as FieldControl}
              boxProps={{
                className: s.input,
              }}
              textProps={{
                size: 'small',
                disabled,
              }}
              validate={titleOptionalValidator}
              disabled={disabled}
            />
          )}
        </div>
        <Divider sx={{ my: 2 }} />
        <Button
          variant='contained'
          type='submit'
          disabled={billingStatus === BillingStatus.Invoiced}
          onClick={handleSubmit(data => {
            if (disabled) {
              return onSave(data, TabsActionEnum.Undo);
            }
            return onSave(data, TabsActionEnum.Save);
          })}
          className={s.button__left_margin}>
          {l10n.getString(disabled ? UNDO_FTL : SAVE_FTL)}
        </Button>
      </form>
    </>
  );
};

export default Expenses;
