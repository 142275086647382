import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { SETTINGS_ADD_ROUTE } from 'common/routes';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';

import { ADD_FTL, CALENDAR_FTL, HOLIDAY_CALENDAR_FTL } from 'valtech-core/common/ftl';

import HolidayCalendarsTable from './HolidayCalendarsTable';

const HolidayCalendars: FC = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();

  const handleAdd = () => navigate(SETTINGS_ADD_ROUTE);

  return (
    <>
      <SettingsHeaderReusable
        title={l10n.getString(HOLIDAY_CALENDAR_FTL)}
        btnTitle={`${l10n.getString(ADD_FTL)} ${l10n.getString(CALENDAR_FTL)}`}
        callback={handleAdd}
      />
      <HolidayCalendarsTable />
    </>
  );
};

export default HolidayCalendars;
