import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import TableRowReusable from 'components/TableRowReusable';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { PROJECT_NAME_IN_OPEN_AIR } from 'valtech-core/common/ftl';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import AddNewAssociatedClient from './AddNewAssociatedClient';
import AssociatedClientItem from './AssociatedClientItem';
import { headData } from './AssociatedClientsTable.const';
import {
  useAssociatedClients,
  useDeleteAssociatedClient,
  useUpdateAssociatedClient,
} from './AssociatedClientsTable.hook';

interface IAssociatedClientsTableProps {
  projectName: string | undefined;
}

const AssociatedClientsTable: FC<IAssociatedClientsTableProps> = props => {
  const { projectName } = props;

  const { id: clientId } = useParams();
  const [editingItemId, setEditItemId] = useState<string | number>('');
  const { l10n } = useLocalization();
  const methods = useFormContext();
  const { control, setValue, getValues } = methods;
  const { isEditing, setState } = useEditingContext();
  const { associatedClients } = useAssociatedClients(projectName);
  const updateAssociatedClient = useUpdateAssociatedClient();
  const deleteAssociatedClient = useDeleteAssociatedClient();

  const {
    loading,
    sorting: { sortingType, activeColumn, handleSorting },
  } = associatedClients;

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'associatedClients.clients',
  });

  useEffect(() => {
    setValue('clients', associatedClients);
    replace(associatedClients.clients);
  }, [associatedClients.loading]);

  const onSave = index => value => {
    if (clientId) {
      updateAssociatedClient(value).then(() => {
        setValue(`associatedClients.clients[${index}]`, value);
      });
    } else setValue(`associatedClients.clients[${index}]`, value);
  };

  const onSelect = id => () => {
    if (isEditing) return;
    setEditItemId(id);
    setState(true);
  };

  const onCancel = () => {
    setEditItemId('');
    setState(false);
  };

  const onDelete = index => value => {
    if (clientId) {
      deleteAssociatedClient(value).then(() => {
        setValue(`associatedClients.clients[${index}]`, value);
        setState(false);
      });
    } else {
      setValue(`associatedClients.clients[${index}]`, value);
      setState(false);
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(PROJECT_NAME_IN_OPEN_AIR)}</Box>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'relative',
                bottom: 0,
                left: 0,
              },
            }}
          />
        )}
        <Table aria-label='simple table'>
          <TableHead>
            <TableRowReusable
              disableSorting={Boolean(editingItemId) || !clientId}
              headData={headData}
              sortingType={sortingType}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
            />
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <AssociatedClientItem
                  key={field.id}
                  rowId={index}
                  onRemove={remove}
                  isActive={field.id === editingItemId && editingItemId !== ''}
                  onSave={onSave(index)}
                  onSelect={onSelect(field.id)}
                  onCancel={onCancel}
                  onDelete={onDelete(index)}
                  values={getValues(`associatedClients.clients[${index}]`)}
                  isLoading={loading}
                />
              );
            })}
            <AddNewAssociatedClient append={append} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default AssociatedClientsTable;
