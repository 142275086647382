import React, { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  requiredStringValidator,
  titlePageValidator,
  yearValidatorRange,
} from 'valtech-core/common/form/validators';
import {
  BUSINESS_UNIT_FTL,
  DEC_SHORT_FTL,
  FINANCIAL_YEAR_FTL,
  HOLIDAY_CALENDAR_PROCEED_FTL,
  JAN_SHORT_FTL,
  PROCEED_FTL,
  STATUS_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import { HolidayDescriptionFields } from './HolidayCalendarDescription.constants';
import {
  useHolidayCalendarDescription,
  useHolidayCalendarSelects,
} from './HolidayCalendarDescription.hook';
import s from './HolidayCalendarDescription.module.scss';

interface IHolidayCalendarDescriptionProps {
  loading: boolean;
  onProceed: VoidFunction;
}

const HolidayCalendarDescription: FC<IHolidayCalendarDescriptionProps> = ({
  loading,
  onProceed,
}) => {
  const { id } = useParams();
  const { l10n } = useLocalization();
  const { control, resetField, setValue, trigger, getValues } = useFormContext();
  const { isEditing } = useEditingContext();

  const { calendar } = useHolidayCalendarDescription();

  useEffect(() => {
    !calendar.loading && setValue('calendar', calendar);
    return () => {
      resetField('calendar', { defaultValue: calendar });
    };
  }, [calendar.loading]);

  const { statuses, businessUnits } = useHolidayCalendarSelects();

  const onBlurCallback = async e => {
    const isValid = await trigger(`calendar.data.${HolidayDescriptionFields.Title}`);
    if (!isValid) return;
    resetField(`calendar.data.${HolidayDescriptionFields.Title}`, {
      defaultValue: e.target.value.trim(),
    });
  };

  const getTemplate = ({ year = new Date().getUTCFullYear(), unit = '' }) =>
    `${unit} (${l10n.getString(JAN_SHORT_FTL)} ${year} - ${l10n.getString(DEC_SHORT_FTL)} ${year})`;

  const updTitle = ({ year, unit }: { year?: number | string; unit?: string }) => {
    resetField(`calendar.data.${HolidayDescriptionFields.Title}`, {
      defaultValue: getTemplate({
        ...(year
          ? { year }
          : { year: getValues(`calendar.data.${HolidayDescriptionFields.Year}`) }),
        ...(unit
          ? { unit }
          : { unit: getValues(`calendar.data.${HolidayDescriptionFields.BusinessUnit}`) }),
      }),
    });
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ md: 3 }}>
      <Grid item xs={4}>
        <TextField
          label={l10n.getString(TITLE_FTL)}
          name={`calendar.data.${HolidayDescriptionFields.Title}`}
          control={control as unknown as FieldControl}
          disabled={isEditing}
          validate={titlePageValidator}
          onBlurCallback={onBlurCallback}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectFiled
          label={l10n.getString(BUSINESS_UNIT_FTL)}
          name={`calendar.data.${HolidayDescriptionFields.BusinessUnit}`}
          data={businessUnits}
          disabled={isEditing}
          validate={requiredStringValidator}
          control={control as unknown as FieldControl}
          onChange={e => updTitle({ unit: e.target.value as string })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          disabled={isEditing}
          label={l10n.getString(FINANCIAL_YEAR_FTL)}
          name={`calendar.data.${HolidayDescriptionFields.Year}`}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'number',
          }}
          validate={yearValidatorRange}
          onBlurCallback={async e => {
            const isValid = await trigger(`calendar.data.${HolidayDescriptionFields.Year}`);
            if (!isValid) return;
            updTitle({ year: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectFiled
          label={l10n.getString(STATUS_FTL)}
          name={`calendar.data.${HolidayDescriptionFields.Status}`}
          data={statuses}
          disabled={isEditing}
          validate={requiredStringValidator}
          control={control as unknown as FieldControl}
        />
      </Grid>
      {!id && (
        <Grid container sx={{ mt: 5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              gap: '10px',
            }}>
            <Typography className={s.HolidayCalendarDescription__text}>
              {l10n.getString(HOLIDAY_CALENDAR_PROCEED_FTL)}
            </Typography>
            <Button type='submit' variant='contained' onClick={onProceed} disabled={loading}>
              {l10n.getString(PROCEED_FTL)}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default HolidayCalendarDescription;
