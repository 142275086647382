import isNil from 'lodash/isNil';
import round from 'lodash/round';

import { IAssignment, IConsultantsData } from '../SingleBilling.types';

export interface IFooterItem {
  cellNumber: number;
  title: string | number;
}

const TITLE_CELL_INDEX = 1;
const TOTAL_CELL_INDEX = 6;

export const makeFooterData = (title: string, data: number | string): IFooterItem[] => {
  return new Array(9).fill(0).map((item, idx) => {
    switch (idx) {
      case TITLE_CELL_INDEX:
        return { cellNumber: idx, title: title };

      case TOTAL_CELL_INDEX:
        return { cellNumber: idx, title: data };

      default:
        return { cellNumber: idx, title: '' };
    }
  });
};

export const countAverageGrossMarginPerBilling = (
  data: IConsultantsData[] | undefined,
): number | null => {
  if (data !== undefined && Array.isArray(data) && data.length > 0) {
    const accumulatedGM = {
      totalAssignmentsCount: 0,
      grossMargin: 0,
    };

    data.forEach(item => {
      item.projects.forEach(item => {
        if (!item.locked && !isNil(item.grossMargin)) {
          accumulatedGM.totalAssignmentsCount++;
          accumulatedGM.grossMargin += item.grossMargin;
        }
      });
    });

    return accumulatedGM.totalAssignmentsCount > 0
      ? round(accumulatedGM.grossMargin / accumulatedGM.totalAssignmentsCount, 2)
      : null;
  }

  return null;
};

export type EditBillingAssignmentData = Partial<Omit<IAssignment, 'rate'>> & {
  notes: string;
  datePickerMinStartDate?: string;
  datePickerMinEndDate?: string;
  rate: {
    id: number;
    value: string;
    title: string;
  };
  showInBudget?: boolean;
};

export const transformEditBillingAssignmentData = ({
  ...data
}: Partial<IAssignment>): EditBillingAssignmentData => {
  return {
    clientId: data.clientId,
    model: data.model,
    allocation: data.allocation,
    hours: data.hours,
    rate: {
      id: data?.rateData?.id as unknown as number,
      value: data?.rateData?.value as unknown as string,
      title: data?.rateData?.title as unknown as string,
    },
    startDate: data.startDate,
    endDate: data.endDate,
    notes: data?.msg || '',
    holidayCalendarId: data.holidayCalendarId,
    costOfOverhead: data.costOfOverhead,
    datePickerMinStartDate: data?.startDate,
    datePickerMinEndDate: data?.endDate,
    showInBudget: data?.showInBudget,
  };
};
