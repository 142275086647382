import React, { useCallback, useContext, useEffect } from 'react';

import { UNSAFE_NavigationContext as NavigationContext, Navigator } from 'react-router-dom';

import Modal from 'components/Modal';
import { Blocker, History } from 'history';
import { createRoot } from 'react-dom/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from 'valtech-core/ui/Button';

// TODO: works only with react-router-dom <= 6.3.*, find another solution
const useBlocker = (blocker: Blocker, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as Navigator & Pick<History, 'block'>).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

interface IUsePromptArgs {
  title: string;
  confirm: string;
  cancel: string;
  message: string;
  when: boolean;
}

const usePrompt = ({ title, message, confirm, cancel, when = true }: IUsePromptArgs): void => {
  const blocker = useCallback(
    tx => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');
      document.body.appendChild(element);
      const _root = createRoot(element);

      const closePrompt = (state: boolean): void => {
        if (!state) document.body.removeChild(element);
        else tx.retry();
        if (element) _root.unmount();
      };

      _root.render(
        <Modal openModal handleCloseModal={() => closePrompt(false)}>
          <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center' }}>
            {title}
          </Typography>
          <Typography sx={{ mt: 2 }}>{message}</Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={() => closePrompt(false)}>{cancel}</Button>
            <Button onClick={() => closePrompt(true)}>{confirm}</Button>
          </Box>
        </Modal>,
      );
    },
    [message, title, confirm, cancel],
  );

  useBlocker(blocker, when);
};

export default usePrompt;
