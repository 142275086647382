import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';

import isNumber from 'lodash/isNumber';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import {
  OrderDirection,
  useGetArrayOfBudgetSettingsQuery,
} from 'valtech-core/common/gql/generated';

interface IBudgetings {
  id: number;
  budgets: string;
  updatedBy: string;
  lastUpdated: string;
  status: string;
}
interface IUseBudgetingsReturn {
  loading: boolean;
  error: ApolloError | undefined;
  budgetings: IBudgetings[];
  total: number;
  page: number;
  rowsPerPage: number;
  sortingType: OrderDirection;
  handleSorting: (value: string) => void;
  activeColumn: string;
  setRowsPerPage: (page: number) => void;
  setPage: (page: number) => void;
}

export const useSettingsBudgetings = (): IUseBudgetingsReturn => {
  const [activeColumn, setActiveColumn] = useState('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_REQUEST_LIMIT);
  const [total, setTotal] = useState(0);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Desc);
  const [model, setModel] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('title');

  const { data, loading, error } = useGetArrayOfBudgetSettingsQuery({
    variables: {
      filter: null,
      orderBy: {
        sortDirection: sortingType,
        model: model,
        sortBy: sortBy,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const budgetings: IBudgetings[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data?.sendArrayOfBudgetSettings?.data || []).map(item => {
      return {
        id: item?.id,
        budgets: item?.title || '',
        updatedBy: item?.managerInfo.name || '',
        lastUpdated: item?.modified_date || '',
        status: item?.status || '',
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.sendArrayOfBudgetSettings?.pageInfo?.total;
    const isNumberValue = isNumber(data?.sendArrayOfBudgetSettings?.pageInfo?.total);
    if (isTotalChanged && isNumberValue) {
      setTotal(data?.sendArrayOfBudgetSettings?.pageInfo?.total || total);
    }
  }, [data]);

  const handleSorting = useCallback(
    item => {
      setActiveColumn(item.l10n);
      setModel(item.model);
      setSortBy(item.sortBy);
      setSortingType(OrderDirection.Desc);

      if (item.l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [activeColumn, sortingType],
  );

  return {
    loading,
    error,
    budgetings,
    sortingType,
    activeColumn,
    handleSorting,
    rowsPerPage,
    page,
    total,
    setRowsPerPage,
    setPage,
  };
};
