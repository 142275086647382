import React, { FC, memo } from 'react';

import MUIAvatar, { AvatarProps } from '@mui/material/Avatar';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { Maybe } from 'valtech-core/common/types';
import { makeUserData } from 'valtech-core/ui/Avatar/Avatar.utils';

import s from './Avatar.module.scss';

export type AvatarData = {
  firstName: string;
  lastName: string;
  url: string;
};

interface IAvatarProps {
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
  avatarProps?: AvatarProps;
  withName?: boolean;
  avatarData: AvatarData;
  isReversed?: boolean;
  nameOnly?: boolean;
  avatarBgColor?: Maybe<string>;
}

const Avatar: FC<IAvatarProps> = ({
  boxProps,
  avatarProps,
  withName = true,
  avatarData,
  isReversed = false,
  nameOnly = true,
  typographyProps,
  avatarBgColor = null,
}) => {
  const { initials, fullName } = makeUserData(avatarData);
  const reversed = isReversed ? 'reversed' : '';
  const classesBox = `${s.box} ${s[reversed]} `;
  const classesName = `${s.name} ${s[reversed]} `;

  return (
    <Box className={classesBox} {...boxProps}>
      <MUIAvatar
        sx={{ bgcolor: `${avatarBgColor}` }}
        {...avatarProps}
        alt={avatarData?.firstName}
        src={avatarData?.url}
      >
        {!avatarData?.url && initials}
      </MUIAvatar>
      {withName && (
        <Typography variant='h6' paragraph className={classesName} {...typographyProps}>
          {nameOnly ? `Hi, ${avatarData?.firstName}` : fullName}
        </Typography>
      )}
    </Box>
  );
};

export default memo(Avatar);
