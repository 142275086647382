import { useEffect, useMemo, useState } from 'react';

import isNumber from 'lodash/isNumber';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { OrderDirection, useGetTimesheetListQuery } from 'valtech-core/common/gql/generated';
import { ISortingAndFiltering } from 'valtech-core/common/types';

interface ITimesheet {
  id: number;
  title: string;
  uploadTime: string;
  userInfo: string;
}

export type IUseTimesheetReturn = Omit<
  ISortingAndFiltering,
  'setFilter' | 'filter' | 'setTableFilters' | 'setProjectFilter' | 'tableFilters' | 'projectFilter'
> & {
  timesheet: ITimesheet[];
};

export const useTimesheet = (): IUseTimesheetReturn => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_REQUEST_LIMIT);
  const [sortingType, setSortingType] = useState<OrderDirection>(OrderDirection.Desc);
  const [model, setModel] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('title');

  const { data, loading, error, refetch } = useGetTimesheetListQuery({
    variables: {
      orderBy: {
        model,
        sortBy,
        sortDirection: sortingType,
      },
      pagination: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const timesheet: ITimesheet[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data?.getTimesheetList?.data || []).map(item => {
      const { id, title, upload_time, userInfo } = item;
      return {
        id,
        title,
        uploadTime: upload_time,
        userInfo: userInfo.name,
      };
    });
  }, [data]);

  useEffect(() => {
    const isTotalChanged = total !== data?.getTimesheetList?.pageInfo?.total;
    const isNumberValue = isNumber(data?.getTimesheetList?.pageInfo?.total);
    if (isTotalChanged && isNumberValue) {
      setTotal(data?.getTimesheetList?.pageInfo?.total || total);
    }
  }, [data]);

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    loading,
    error,
    timesheet,
    total,
    sortingType,
    setSortingType,
    setModel,
    sortBy,
    setSortBy,
    refetch,
  };
};
