import React, { ReactNode, forwardRef, memo, useMemo } from 'react';

import { FieldValues } from 'react-hook-form/dist/types/fields';

import { v4 as uuidv4 } from 'uuid';

import Box, { BoxProps } from '@mui/material/Box';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

export type SelectedItem = { id: string | number; title: string; value: string };
export type onChange = ((event: SelectChangeEvent<unknown>, child: ReactNode) => void) | undefined;

interface ISelectProps extends SelectProps {
  data: SelectedItem[];
  selectedValue?: string | null;
  onChange?: onChange;
  boxProps?: BoxProps;
  formControlProps?: FormControlProps;
  children?: ReactNode;
  defaultValue?: string;
  field?: FieldValues;
  className?: string;
}

const Select = forwardRef<HTMLDivElement, ISelectProps>(
  ({ onChange, label, data, boxProps, formControlProps, children, field, ...rest }, ref) => {
    const labelID = useMemo(() => uuidv4(), []);

    const change = (...args: [event: SelectChangeEvent<unknown>, child: ReactNode]) => {
      if (onChange) onChange(...args);
      if (field?.onChange) field.onChange(...args);
    };

    return (
      <Box {...boxProps}>
        <FormControl fullWidth {...formControlProps}>
          {label && (
            <InputLabel
              id={labelID}
              sx={{
                '&.Mui-focused, &.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                  paddingLeft: '6px !important',
                  paddingRight: '6px !important',
                },
              }}
            >
              {label}
            </InputLabel>
          )}
          <MUISelect labelId={labelID} ref={ref} {...rest} {...field} onChange={change}>
            {data.map(({ title, value, id }) => {
              return (
                <MenuItem key={id} value={value}>
                  {title}
                </MenuItem>
              );
            })}
          </MUISelect>
        </FormControl>
        {children}
      </Box>
    );
  },
);

export default memo(Select);
