import React, { FC, useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { BoxProps } from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';

import TextInput from 'valtech-core/form/TextInput';
import Button from 'valtech-core/ui/Button';
import useDebounce from 'valtech-core/utils/useDebounce';

import s from './SearchInput.module.scss';

interface ISearchData {
  query: string;
  fieldName: string;
}

interface ISearchInputProps {
  placeholder: string;
  onSearch: (data: ISearchData) => void;
  fieldName: string;
  className?: string;
  boxProps?: BoxProps;
  size?: 'medium' | 'small';
  isInputClear?: boolean;
  onClear?: VoidFunction;
  inputDefaultValue?: string;
}

const SearchInput: FC<ISearchInputProps> = ({
  placeholder,
  fieldName,
  onSearch,
  className,
  boxProps,
  size,
  isInputClear,
  onClear,
  inputDefaultValue = '',
}) => {
  const [inputValue, setInputValue] = useState<string>(inputDefaultValue);
  const [searchTouched, setSearchTouched] = useState(false);
  //TODO: Check unused code dealt with endAdornment & check styles

  const handleClear = () => {
    setInputValue('');
    if (onClear) {
      onClear();
    }
  };

  const debouncedSearchTerm = useDebounce(inputValue, 500);
  useEffect(() => {
    if (debouncedSearchTerm) {
      const SearchData = {
        query: inputValue,
        fieldName,
      };
      onSearch(SearchData);
    }
    if (!debouncedSearchTerm && searchTouched) {
      const SearchData = {
        query: '',
        fieldName,
      };

      onSearch(SearchData);
    }
  }, [debouncedSearchTerm, searchTouched]);

  useEffect(() => {
    return () => {
      setSearchTouched(false);
    };
  }, []);

  useEffect(() => {
    !isInputClear && handleClear();
  }, [isInputClear, handleClear]);

  return (
    <TextInput
      boxProps={boxProps}
      textProps={{
        label: placeholder,
        onChange: event => {
          setInputValue(event?.target?.value);
          setSearchTouched(true);
        },
        value: inputValue,
        InputProps: {
          sx: { paddingRight: 0 },
          endAdornment: (
            <InputAdornment position='end'>
              <Button className={s.button} type='button' onClick={handleClear}>
                <ClearIcon className={s.icon} />
              </Button>
            </InputAdornment>
          ),
        },
        size: size,
      }}
      className={className}
    />
  );
};

export default SearchInput;
