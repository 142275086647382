import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { showSystemError } from 'common/apolloState/system';
import { ErrorResponse, errorCatch } from 'common/utils/error';

import { timesheetsService } from 'valtech-core/services/rest/timesheet';

import { useTimesheet } from './Timesheet.hook';

interface UploadTimesheetFileVariables {
  file: File;
  title: string;
  replace: boolean;
  date: string;
}
interface UseUploadTimesheetFileReturn {
  uploadTimesheetFile: UseMutateFunction<void, unknown, UploadTimesheetFileVariables, unknown>;
  isLoading: boolean;
}

export const useUploadTimesheetFile = (): UseUploadTimesheetFileReturn => {
  const { refetch } = useTimesheet();

  const { mutateAsync: uploadTimesheetFile, status } = useMutation<
    void,
    unknown,
    UploadTimesheetFileVariables
  >({
    mutationKey: ['uploadTimesheetFile'],
    mutationFn: async ({ file, title, replace, date }: UploadTimesheetFileVariables) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      formData.append('replace', replace.toString());
      formData.append('date', date);

      await timesheetsService.uploadTimesheetFile(formData);
    },
    onSuccess() {
      if (refetch) {
        refetch();
      }
    },
    onError(error: unknown) {
      const errorMessage = errorCatch(error as ErrorResponse);
      showSystemError(errorMessage as string);
    },
  });

  const isLoading = status === 'pending';

  return { uploadTimesheetFile, isLoading };
};
