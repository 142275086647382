import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import DataPickerField from 'form/DataPickerField/DatePickerField';
import RadioField from 'form/RadioField';
import TextField from 'form/TextField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  midStringOptionalValidator,
  requiredOptionValidator,
} from 'valtech-core/common/form/validators';
import { MSA_DETAILS_FTL, SIGNATURE_DATE_FTL } from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';

export enum MSAType {
  External = 'External',
  Intracompany = 'Intracompany',
}

type MSADetailsProps = {
  control: FieldControl;
};

const selectMockRadio = [
  { id: '1', label: 'Intracompany', value: MSAType.Intracompany },
  { id: '2', label: 'External', value: MSAType.External },
];

const MSADetails: FC<MSADetailsProps> = ({ control }) => {
  const { l10n } = useLocalization();

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(MSA_DETAILS_FTL)}</Box>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='#'
          name='msaNumber'
          control={control as unknown as FieldControl}
          textProps={{
            size: 'medium',
          }}
          validate={midStringOptionalValidator}
        />
      </Grid>
      <Grid item xs={6}>
        <DataPickerField
          label={l10n.getString(SIGNATURE_DATE_FTL)}
          name='msaDate'
          control={control as unknown as FieldControl}
        />
      </Grid>
      <Grid item xs={6}>
        <RadioField
          data={selectMockRadio}
          name='msaType'
          control={control as unknown as FieldControl}
          boxProps={{ sx: { color: 'var(--inactive-filter-field)' } }}
          radioGroupProps={{ row: true }}
          validate={requiredOptionValidator}
        />
      </Grid>
    </>
  );
};

export default MSADetails;
