import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import { getFormattedDate } from 'common/utils/formatDate';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import DataPickerField from 'form/DataPickerField/DatePickerField';
import TextField from 'form/TextField/TextField';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { DATE_FTL, TITLE_FTL } from 'valtech-core/common/ftl';
import { DateMask, FieldControl } from 'valtech-core/common/types';

import {
  HolidayFields,
  IHoliday,
  dateAddHolidayValidator,
  titleAddHolidayValidator,
} from '../Holiday.constants';

interface IHolidayItemProps {
  isActive: boolean;
  onSelect: VoidFunction;
  onCancel: VoidFunction;
  onDelete: (id: number) => void;
  onSave: (value: IHoliday) => void;
  currentYear: number | string | undefined;
  values: {
    id: number;
    date: string;
    order: number;
    title: string;
    holiday_calendar_id: number;
  };
}

const HolidayItem: FC<IHolidayItemProps> = ({
  isActive,
  onDelete,
  onSave,
  onSelect,
  onCancel,
  values,
  currentYear,
}) => {
  const { l10n } = useLocalization();
  const defaultValues = {
    [HolidayFields.Date]: values[HolidayFields.Date],
    [HolidayFields.Title]: values[HolidayFields.Title],
  };
  const methods = useForm({ defaultValues });
  const { control, setValue, trigger, getValues, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [isActive]);

  const cancel = () => {
    reset(defaultValues);
    onCancel();
  };

  const save = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const formValues = getValues();
    onSave({
      ...values,
      date: formValues[HolidayFields.Date],
      title: formValues[HolidayFields.Title],
    });
    onCancel();
  };

  const deleteHoliday = () => onDelete(values.id);

  return (
    <TableRow
      className={cn({
        ['RateItem--active']: isActive,
      })}>
      {isActive ? (
        <>
          <TableCell>
            <TextField
              label={l10n.getString(TITLE_FTL)}
              name={HolidayFields.Title}
              control={control as unknown as FieldControl}
              validate={titleAddHolidayValidator}
              onBlurCallback={e => {
                setValue(HolidayFields.Title, e.target.value.trim());
              }}
            />
          </TableCell>
          <TableCell>
            <DataPickerField
              label={l10n.getString(DATE_FTL)}
              name={HolidayFields.Date}
              control={control as unknown as FieldControl}
              validate={dateAddHolidayValidator}
              minDate={getCalendarRangeByYear(Number(currentYear)).firstDayOfYear}
              maxDate={getCalendarRangeByYear(Number(currentYear)).lastDayOfYear}
            />
          </TableCell>
          <TableCell>
            <IconButton aria-haspopup='true' onClick={save}>
              <SaveIcon color='primary' fontSize='medium' />
            </IconButton>
            <IconButton aria-haspopup='true' onClick={cancel}>
              <ClearIcon color='primary' fontSize='medium' />
            </IconButton>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>{values[HolidayFields.Title]}</TableCell>
          <TableCell>{getFormattedDate(values[HolidayFields.Date], DateMask.DD_MM_YYYY)}</TableCell>
          <TableCell>
            <IconButton onClick={onSelect}>
              <EditIcon color='primary' />
            </IconButton>
            <IconButton onClick={deleteHoliday}>
              <DeleteIcon color='primary' />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default HolidayItem;
