import { v4 as uuidv4 } from 'uuid';

import { Notification, notificationsVar } from './notifications';

export const showNotification = (notification: Omit<Notification, 'id'>): void => {
  const currentState = notificationsVar();

  notificationsVar({
    messages: [{ ...notification, id: uuidv4() }, ...currentState.messages],
  });
};

export const hideNotification = (notificationId: string): void => {
  const currentState = notificationsVar();

  notificationsVar({
    messages: currentState.messages.filter(notification => notification.id !== notificationId),
  });
};
