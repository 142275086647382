import { StringSchema, number, string } from 'yup';

import {
  ERROR_AMOUNT_LIMIT_FTL,
  ERROR_EMAIL_FTL,
  ERROR_HOURS_LIMIT_FTL,
  ERROR_INVALID_TITLE_FTL,
  ERROR_LETTERS_AND_NUMBERS_ONLY_FTL,
  ERROR_LETTERS_ONLY_FTL,
  ERROR_LONG_STR_FTL,
  ERROR_MID_STR_LIMIT_FTL,
  ERROR_NOTE_STR_LIMIT_FTL,
  ERROR_NUMBER_FORMAT_FTL,
  ERROR_REQUIRED_FTL,
  ERROR_REQUIRED_OPTION_FTL,
  ERROR_REQUIRED_SHORT_FTL,
  ERROR_REQUIRED_WITH_CONDITIONS_FTL,
  ERROR_SHORT_STR_FTL,
} from 'valtech-core/common/ftl';
import { DateMask, Maybe } from 'valtech-core/common/types';

import { INPUT_STR_MID_LIMIT, INPUT_STR_NOTE_LIMIT } from './const';
import {
  AMOUNT_LIMIT_REGEXP,
  DateRegexp,
  HOURS_LIMIT_REGEXP,
  INPUT_REGEXP,
  LETTERS_ONLY_REGEXP,
  NUMBER_REGEXP,
} from './const';
import {
  emailSchema,
  longStrSchema,
  requiredBooleanSchema,
  requiredNumberSchema,
  requiredStringSchema,
  shortStrSchema,
  validInputSymbolsDateObjectSchema,
  validInputSymbolsStrSchema,
  validNumberStrSchema,
  validate,
} from './schemas';

export const requiredBooleanValidator = validate<boolean>(
  requiredBooleanSchema({ required: ERROR_REQUIRED_FTL }),
);

export const requiredStringValidator = validate<string>(
  requiredStringSchema({ required: ERROR_REQUIRED_FTL }),
);

export const requiredStringValidatorWithShortMessage = validate<string>(
  requiredStringSchema({ required: ERROR_REQUIRED_SHORT_FTL }),
);

export const requiredOptionValidator = validate<string>(
  requiredStringSchema({ required: ERROR_REQUIRED_OPTION_FTL }),
);

export const longStringValidator = validate<string>(
  string()
    .concat(shortStrSchema({ shortStr: ERROR_SHORT_STR_FTL }) as StringSchema)
    .concat(longStrSchema({ longStr: ERROR_LONG_STR_FTL }) as StringSchema),
);

export const requiredMidStringValidator = validate<string>(
  string()
    .trim()
    .concat(requiredStringSchema({ required: ERROR_REQUIRED_FTL }) as StringSchema)
    .concat(
      longStrSchema({
        longStr: ERROR_MID_STR_LIMIT_FTL,
        limit: INPUT_STR_MID_LIMIT,
      }) as StringSchema,
    ),
);

export const requiredLongStringValidator = validate<string>(
  string()
    .trim()
    .concat(requiredStringSchema({ required: ERROR_SHORT_STR_FTL }) as StringSchema)
    .concat(shortStrSchema({ shortStr: ERROR_SHORT_STR_FTL }) as StringSchema)
    .concat(longStrSchema({ longStr: ERROR_LONG_STR_FTL }) as StringSchema),
);

export const requiredNumberValidator = validate<Maybe<string>>(
  requiredNumberSchema({
    required: ERROR_REQUIRED_FTL,
    invalid: ERROR_NUMBER_FORMAT_FTL,
  }),
);

export const validateRequiredNumber = (value: Maybe<string>): Promise<Maybe<string>> => {
  const valueToValidate = value !== '' ? value : undefined;
  return requiredNumberValidator(valueToValidate);
};

export const validateNumber = (value: Maybe<string>): Promise<Maybe<string>> => {
  const valueToValidate = Number(value) !== 0 ? value : undefined;
  return requiredNumberValidator(valueToValidate);
};

export const emailValidator = validate<string>(
  string()
    .concat(emailSchema({ email: ERROR_EMAIL_FTL }) as StringSchema)
    .concat(longStrSchema({ longStr: ERROR_LONG_STR_FTL }) as StringSchema),
);

type schemaFuncReturn = (value: string) => Promise<Maybe<string>>;

interface IUseCommonStringValidatorParams {
  longStr?: string;
  longStrLimit?: number;
  required?: string;
  shortStr?: string;
  invalid?: string;
  regexp?: RegExp;
}

export const useCommonStringValidator = ({
  longStr,
  longStrLimit,
  required,
  shortStr,
  invalid,
  regexp,
}: IUseCommonStringValidatorParams = {}): schemaFuncReturn =>
  validate<string>(
    (() => {
      let str = string().trim();
      if (required) str = str.concat(requiredStringSchema({ required }) as StringSchema);
      if (shortStr) str = str.concat(shortStrSchema({ shortStr }) as StringSchema);
      if (longStr)
        str = str.concat(longStrSchema({ longStr, limit: longStrLimit }) as StringSchema);
      if (invalid) {
        str = str.concat(validInputSymbolsStrSchema({ invalid, regexp }) as StringSchema);
      }
      return str;
    })(),
  );

export const lettersAndNumbersOnlyValidator = useCommonStringValidator({
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_LETTERS_AND_NUMBERS_ONLY_FTL,
  regexp: INPUT_REGEXP,
  longStr: ERROR_MID_STR_LIMIT_FTL,
  longStrLimit: INPUT_STR_MID_LIMIT,
});

export const lettersOnlyValidator = useCommonStringValidator({
  regexp: LETTERS_ONLY_REGEXP,
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_LETTERS_ONLY_FTL,
});

export const lettersOnlyLimitValidator = useCommonStringValidator({
  regexp: LETTERS_ONLY_REGEXP,
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_LETTERS_ONLY_FTL,
  longStr: ERROR_MID_STR_LIMIT_FTL,
  longStrLimit: INPUT_STR_MID_LIMIT,
});

export const notesLimitValidator = useCommonStringValidator({
  longStr: ERROR_NOTE_STR_LIMIT_FTL,
  longStrLimit: INPUT_STR_NOTE_LIMIT,
});

export const invalidNumberValidator = ({
  invalid,
  regexp,
}: {
  invalid: string;
  regexp?: RegExp;
}): schemaFuncReturn =>
  validate<Maybe<string>>(
    string().concat(validNumberStrSchema({ invalid, regexp }) as StringSchema),
  );

export const validateLettersOnly = (value: Maybe<string>): Promise<Maybe<string>> => {
  const valueToValidate = String(value) ? value : '';
  return lettersOnlyValidator(valueToValidate as string);
};

export const dateValidator = ({
  invalid,
  regexp,
  format = DateMask.DD_MM_YYYY,
}: {
  invalid: string;
  regexp?: RegExp;
  format?: DateMask;
}): schemaFuncReturn =>
  validate<Maybe<string>>(
    string().concat(
      validInputSymbolsDateObjectSchema({
        invalid,
        regexp: regexp || DateRegexp[format],
        format,
      }) as StringSchema,
    ),
  );

export const conditionalNumberValidator =
  (condition: boolean) =>
  (value: string): Promise<Maybe<string>> => {
    if (condition && !value) {
      return validate<number>(number())(Number(value));
    } else {
      return validateRequiredNumber(value);
    }
  };

export const conditionalOptionalStringValidator =
  (condition: boolean) =>
  (value: string): Promise<Maybe<string>> => {
    if (condition || value === '') return validate<string>(string().trim())(value);
    else return notesLimitValidator(value);
  };

export const titlePageValidator = useCommonStringValidator({
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_INVALID_TITLE_FTL,
  regexp: /^[a-zA-Z0-9\s\-._()]+$/,
  longStr: ERROR_MID_STR_LIMIT_FTL,
  longStrLimit: INPUT_STR_MID_LIMIT,
});

export const titleOptionalValidator = (value: string): Promise<Maybe<string>> => {
  if (value !== '') {
    return titlePageValidator(value);
  } else return validate<string>(string().trim())(value);
};

export const businessUnitOptionalValidator = (value: string): Promise<Maybe<string>> => {
  if (value !== '') {
    return lettersAndNumbersOnlyValidator(value);
  } else return validate<string>(string().trim())(value);
};

export const midStringOptionalValidator = (value: string): Promise<Maybe<string>> => {
  if (value !== '') {
    return requiredMidStringValidator(value);
  } else return validate<string>(string().trim())(value);
};

const yearRegexp = /^\d{4}$/;
export const yearValidator = invalidNumberValidator({
  regexp: yearRegexp,
  invalid: ERROR_REQUIRED_FTL,
});

const yearRangeRegexp = /^(202[2-9]|203[0-2])$/;
export const yearValidatorRange = invalidNumberValidator({
  regexp: yearRangeRegexp,
  invalid: ERROR_REQUIRED_WITH_CONDITIONS_FTL,
});

export const invalidNumberFormatValidator = invalidNumberValidator({
  regexp: NUMBER_REGEXP,
  invalid: ERROR_NUMBER_FORMAT_FTL,
});

export const hoursValidator = invalidNumberValidator({
  regexp: HOURS_LIMIT_REGEXP,
  invalid: ERROR_HOURS_LIMIT_FTL,
});

export const amountValidator = invalidNumberValidator({
  regexp: AMOUNT_LIMIT_REGEXP,
  invalid: ERROR_AMOUNT_LIMIT_FTL,
});
