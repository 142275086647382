import { SetStateAction } from 'react';

import { IFilters } from 'valtech-core/common/types';

type DefaultSearchParams = {
  [key: string]: string;
};

export const updateTableFilters = (
  { field, filterModel = '' }: DefaultSearchParams,
  data: DefaultSearchParams,
  setTableFilters: (value: SetStateAction<[] | IFilters[]>) => void,
): void => {
  setTableFilters(prevTableFilters => {
    const newTableFilters = prevTableFilters.filter(f => f.query.length > 0);
    const foundFilterIndex = newTableFilters.findIndex(
      f => f.field === field && f.filterModel === filterModel,
    );

    if (foundFilterIndex > -1) {
      if (data.query !== '') {
        newTableFilters[foundFilterIndex] = {
          ...newTableFilters[foundFilterIndex],
          query: data.query,
        };
      } else {
        newTableFilters.splice(foundFilterIndex, 1);
      }
    } else {
      data.query &&
        newTableFilters.push({
          query: data.query,
          field,
          filterModel,
        });
    }

    return newTableFilters;
  });
};
