import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { Variant } from 'common/types';
import TextField from 'form/TextField/TextField';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { FieldControl } from 'valtech-core/common/types';

import { exchangeValidator } from '../ExchangeRateTable.constants';

import s from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/shared.module.scss';

interface IExchangeRateItemProps {
  isActive: boolean;
  onSave: (value: string | null | number) => void;
  onSelect: VoidFunction;
  onCancel: VoidFunction;
  value: string | null | number;
  disabled: boolean;
  emptyValToZero?: boolean;
}

const ExchangeRateItem: FC<IExchangeRateItemProps> = ({
  value,
  onCancel,
  onSave,
  onSelect,
  isActive,
  disabled,
  emptyValToZero,
}) => {
  const defaultValue = value ?? 0;
  const defaultValues = { ExchangeRateValue: defaultValue };
  const methods = useForm({ defaultValues });
  const { control, setValue, trigger, getValues, reset } = methods;
  const { isEditing } = useEditingContext();

  useEffect(() => {
    reset(defaultValues);
  }, [isActive]);

  const cancel = () => {
    reset(defaultValues);
    onCancel();
  };

  const save = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    onSave(getValues('ExchangeRateValue'));
    onCancel();
  };

  return (
    <>
      {isActive ? (
        <TableCell sx={{ maxWidth: '100px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <TextField
              name='ExchangeRateValue'
              control={control as unknown as FieldControl}
              textProps={{
                type: 'number',
              }}
              variant={Variant.Standard}
              validate={exchangeValidator}
              className={s.inputField}
              onBlurCallback={e => {
                setValue('ExchangeRateValue', e.target.value.trim());
                trigger('ExchangeRateValue');
              }}
            />
            <IconButton aria-haspopup='true' onClick={save}>
              <SaveIcon color='primary' fontSize='medium' />
            </IconButton>
            <IconButton aria-haspopup='true' onClick={cancel}>
              <ClearIcon color='primary' fontSize='medium' />
            </IconButton>
          </Box>
        </TableCell>
      ) : (
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography sx={{ minWidth: '190px' }}>{defaultValue}</Typography>
            <IconButton aria-haspopup='true' onClick={onSelect} disabled={isEditing || disabled}>
              <EditIcon color='primary' fontSize='medium' />
            </IconButton>
          </Box>
        </TableCell>
      )}
    </>
  );
};

export default ExchangeRateItem;
