import { useReactiveVar } from '@apollo/client';

import { BudgetingState, budgetingVar } from './budgeting';

type UseBudgetingStateReturn = BudgetingState;

export const useBudgetingState = (): UseBudgetingStateReturn => {
  const state = useReactiveVar(budgetingVar);

  return state;
};
