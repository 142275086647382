import { useCallback } from 'react';

import { showSystemError } from 'common/apolloState/system';

import { useUpdateBudgetMutation } from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { IUpdateBudgetData } from './BudgetingHeader.utils';

export type useUpdateBudgetReturn = {
  updateBudget: (data: IUpdateBudgetData) => Promise<boolean>;
};

export const useUpdateBudget = (): useUpdateBudgetReturn => {
  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const updateBudget = useCallback(async (data: IUpdateBudgetData) => {
    if (data) {
      const newBudget = await UpdateBudgetMutation({
        variables: data,
      });

      return !!newBudget.data?.updateBudgetReportByID;
    }
    return false;
  }, []);

  return { updateBudget };
};
