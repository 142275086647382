import React, { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import * as routes from 'common/routes/settings';
import CustomRoute from 'components/CustomRoute';

import {
  ACCOUNTS_FTL,
  BUDGETING_FTL,
  CONSULTANTS_FTL,
  ENTITIES_FTL,
  HOLIDAY_CALENDAR_FTL,
  PROJECTS_FTL,
  TIMESHEET_FTL,
  USERS_FTL,
} from 'valtech-core/common/ftl';
import { DrawerTypeEnum, GuardTypesEnum } from 'valtech-core/common/types';
import Drawer, { DrawerItemsList } from 'valtech-core/ui/Drawer';

import TimesheetSettings from '../TimesheetSettings/TimesheetSettings';
import Accounts from './AllAccounts';
import AllBudgetings from './AllBudgetings';
import AllEntities from './AllEntities';
import AllProjects from './AllProjects/AllProjects';
import AllUsers from './AllUsers';
import ConsultantsSettings from './ConsultantsSettings/ConsultantsSettings';
import HolidayCalendars from './HolidayCalendars';
import HolidayCalendar from './HolidayCalendars/HolidayCalendar';
import SingleBudgeting from './SingleBudgeting';
import SingleProject from './SingleProject';

const ManageSettings: FC = () => {
  const { l10n } = useLocalization();

  const drawerItemsList: DrawerItemsList[] = [
    {
      title: l10n.getString(USERS_FTL),
      href: routes.SETTINGS_USERS_ROUTE,
    },
    {
      title: l10n.getString(ENTITIES_FTL),
      href: routes.SETTINGS_ENTITIES_ROUTE,
    },
    {
      title: l10n.getString(ACCOUNTS_FTL),
      href: routes.SETTINGS_ACCOUNT_ROUTE,
    },
    {
      title: l10n.getString(BUDGETING_FTL),
      href: routes.SETTINGS_BUDGETING_ROUTE,
    },
    {
      title: l10n.getString(PROJECTS_FTL),
      href: routes.SETTINGS_PROJECT_ROUTE,
    },
    {
      title: l10n.getString(HOLIDAY_CALENDAR_FTL),
      href: routes.SETTINGS_HOLIDAY_CALENDAR_ROUTE,
    },
    {
      title: l10n.getString(TIMESHEET_FTL),
      href: routes.SETTINGS_TIMESHEETS_ROUTE,
    },
    {
      title: l10n.getString(CONSULTANTS_FTL),
      href: routes.SETTINGS_CONSULTANTS_PAGE_ROUTE,
    },
  ];

  return (
    <Drawer drawerItemsList={drawerItemsList} drawerWidth={256} type={DrawerTypeEnum.Link}>
      <Routes>
        <Route index element={<Navigate to={routes.SETTINGS_USERS_ROUTE} />} />
        <Route
          path={routes.SETTINGS_TIMESHEETS_ROUTE}
          element={
            <CustomRoute type={GuardTypesEnum.Protected}>
              <TimesheetSettings />
            </CustomRoute>
          }
        />
        <Route
          path={routes.SETTINGS_PROJECT_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<AllProjects />}</CustomRoute>}
        />
        <Route path={routes.SETTINGS_EDIT_PROJECT_ROUTE} element={<SingleProject />} />
        <Route path={routes.SETTINGS_ADD_PROJECT_ROUTE} element={<SingleProject />} />
        <Route
          path={routes.SETTINGS_BUDGETING_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<AllBudgetings />}</CustomRoute>}
        />
        <Route path={routes.SETTINGS_BUDGETING_ID_ROUTE} element={<SingleBudgeting />} />
        <Route path={routes.SETTINGS_BUDGETING_ADD_ROUTE} element={<SingleBudgeting />} />
        <Route
          path={routes.SETTINGS_HOLIDAY_CALENDAR_ROUTE}
          element={
            <CustomRoute type={GuardTypesEnum.Protected}>{<HolidayCalendars />}</CustomRoute>
          }
        />
        <Route
          path={routes.SETTINGS_ADD_HOLIDAY_CALENDAR_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<HolidayCalendar />}</CustomRoute>}
        />
        <Route
          path={routes.SETTINGS_EDIT_HOLIDAY_CALENDAR_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<HolidayCalendar />}</CustomRoute>}
        />
        <Route
          path={routes.SETTINGS_ENTITIES_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<AllEntities />}</CustomRoute>}
        />
        <Route
          path={routes.SETTINGS_ACCOUNT_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<Accounts />}</CustomRoute>}
        />
        <Route
          path={routes.SETTINGS_USERS_ROUTE}
          element={<CustomRoute type={GuardTypesEnum.Protected}>{<AllUsers />}</CustomRoute>}
        />
        <Route
          path={routes.SETTINGS_CONSULTANTS_ROUTE}
          element={
            <CustomRoute type={GuardTypesEnum.Protected}>{<ConsultantsSettings />}</CustomRoute>
          }
        />
      </Routes>
    </Drawer>
  );
};

export default ManageSettings;
