import React, { FC, useCallback, useMemo } from 'react';

import { Localized, useLocalization } from '@fluent/react';
import {
  ActiveTabsEnum,
  ConsultantModelEnum,
  IWarningData,
  SingleBillingTableTypeEnum,
} from 'common/types';
import { getFormattedDate } from 'common/utils/formatDate';
import Comment from 'components/Comment';
import { IHeadData } from 'pages/SingleBilling/EditSingleBillingTable/EditSingleBillingTable.const';
import { IClientsData, IConsultantsData } from 'pages/SingleBilling/SingleBilling.types';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import { useGeneralTab } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';

import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateMask, Maybe } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import NA from 'valtech-core/ui/NA';
import Typography from 'valtech-core/ui/Typography';

import {
  IFooterItem,
  transformEditBillingAssignmentData,
} from 'pages/SingleBilling/EditSingleBillingTable/EditSingleBillingTable.utils';

import SingleBillingTableRowEditAssignment from '../../components/SingleBillingTableRowEditAssignment.tsx/SingleBillingTableRowEditAssignment';
import s from './MainTable.module.scss';

interface IConsultantsTable {
  headData: IHeadData[];
  bodyData: IConsultantsData[] | IClientsData[] | undefined;
  footerData?: Maybe<IFooterItem>[];
  currencySign: Maybe<string>;
  singleBillingWarnings?: IWarningData[];
}

type IModifiedBodyData = IConsultantsData[] | IClientsData[] | undefined;

const MainTable: FC<IConsultantsTable> = ({ bodyData, headData, footerData }) => {
  const {
    setStateParam,
    state: { projectData, billingInfo, accessLevel },
  } = useBillingState();

  const { l10n } = useLocalization();
  const { workingHoursPerMonth } = useGeneralTab();

  const modifiedBodyData = useMemo((): IModifiedBodyData => {
    return bodyData?.map(item => {
      const modifiedProjects = item.projects.map(project => {
        billingInfo?.assignmentsCurrencyData.forEach(assignment => {
          if (assignment.id === project.assignmentId) {
            project.projectCurrency = assignment.projectCurrency;
          }
        });
        return project;
      });
      item.projects = modifiedProjects;
      return item;
    });
  }, [bodyData, billingInfo?.assignmentsCurrencyData]);

  const hoursColorHighlighter = (amount, tableType) => {
    if (tableType !== SingleBillingTableTypeEnum.Consultants) return;
    if (amount < Number(workingHoursPerMonth)) return s.markedOrange;
    else if (amount > Number(workingHoursPerMonth)) return s.markedRed;
    else return '';
  };

  const assignmentsHighlighter = (tableType, id, locked): string => {
    const inactiveStyle = locked ? s.inactive : '';

    if (tableType === SingleBillingTableTypeEnum.Consultants) {
      if (id === projectData?.id) {
        return `${s.activeRow} ${inactiveStyle}`;
      } else {
        return `${s.tableRowHighlighted} ${inactiveStyle}`;
      }
    } else {
      if (id === projectData?.id) {
        return `${s.activeRow}`;
      } else {
        return `${s.tableRowHighlighted}`;
      }
    }
  };

  const onRowClick = useCallback(
    (
      projectData: Record<string, unknown>,
      tableName: SingleBillingTableTypeEnum,
      accessLevel?: string,
      consultantId?: number,
    ) => {
      const userAccessLevel = getAccessLevel(accessLevel, l10n);

      setStateParam({
        isTabOpened: true,
        projectData,
        tableName,
        activeTabName:
          projectData.locked || userAccessLevel.edit || userAccessLevel.viewAndComment
            ? ActiveTabsEnum.Comments
            : ActiveTabsEnum.General,
        consultantId: consultantId,
      });
    },
    [],
  );

  return (
    <TableContainer component={Paper}>
      <Table className={s.table}>
        <TableHead>
          <TableRow>
            {headData.map(item => {
              return (
                <TableCell key={item.id} sx={item.id === 1 ? { paddingLeft: 0 } : {}}>
                  {item.title_l10n && <Localized id={item.title_l10n} />}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {modifiedBodyData?.map(row => (
            <React.Fragment key={row.id}>
              <TableRow className={s.tableHeader}>
                <TableCell sx={{ paddingX: 1.5 }} width={50}></TableCell>
                <TableCell sx={{ paddingLeft: 0 }} width={240}>
                  <Typography
                    variant='caption'
                    weight='regular'
                    component={'p'}
                    className={s.tableHeader__title}>
                    {row.title}
                  </Typography>
                  {row?.subtitle && (
                    <Typography
                      variant='body2'
                      weight='regular'
                      component={'p'}
                      className={s.tableHeader__subtitle}>
                      {row.subtitle}
                    </Typography>
                  )}
                </TableCell>
                <TableCell width={160}>{row.model}</TableCell>
                <TableCell>
                  {(row.startDate || row.endDate) &&
                    `${getFormattedDate(row.startDate, DateMask.DD_MM_YYYY)} - ${getFormattedDate(
                      row.endDate,
                      DateMask.DD_MM_YYYY,
                    )}`}
                </TableCell>
                <TableCell>{row.rate}</TableCell>
                <TableCell
                  className={`${s.tableBody__dateCell} ${hoursColorHighlighter(
                    row.hoursAllAccounts,
                    row.tableName,
                  )}`}>
                  <NA
                    value={
                      row.tableName === SingleBillingTableTypeEnum.Clients
                        ? row.quantity
                        : row.hours.toFixed(2)
                    }
                  />
                  <Typography
                    style={{ display: 'inline-block', fontSize: `0.875rem`, paddingLeft: '3px' }}>
                    {!!row.totalOvertimesHours && `(${row.totalOvertimesHours.toFixed(2)})`}
                  </Typography>
                  {row.hoursAllAccounts + row.totalOvertimesHours >
                    Number(workingHoursPerMonth) && (
                    <Comment
                      text={`${(row.hoursAllAccounts + row.totalOvertimesHours).toFixed(
                        2,
                      )} hours for all projects`}
                      type={'alert'}
                    />
                  )}
                </TableCell>
                <TableCell className={hoursColorHighlighter(row.hoursAllAccounts, row.tableName)}>
                  {`${createNumberWithCurrency({
                    number: row.total,
                    currencyDisplay: 'symbol',
                    currency: billingInfo?.currency,
                  })}`}
                </TableCell>
                <TableCell sx={{ paddingRight: 0 }}>{row.msg}</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.warning}</TableCell>
              </TableRow>
              {row.projects.map(project => (
                <TableRow
                  key={project.id}
                  className={assignmentsHighlighter(row.tableName, project.id, project.locked)}>
                  <TableCell
                    width={50}
                    sx={{ paddingX: 1.5 }}
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {project.locked && project.invoiced && <CheckIcon color='success' />}
                      <Box sx={{ width: 2 }} />
                      {project.locked && <LockIcon color='success' />}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ paddingLeft: 0 }}
                    width={240}
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    <Typography variant='body2' weight='regular' component={'p'}>
                      {project.title}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    {project.model && (
                      <>
                        {project.model}
                        {project.allocation !== 100 &&
                          project.model === ConsultantModelEnum.Dedicated &&
                          ` x ${project.allocation}%`}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    className={s.tableBody__dateCell}
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    {(project.startDate || project.endDate) && (
                      <>
                        <span>{`${getFormattedDate(
                          project.startDate,
                          DateMask.DD_MM_YYYY,
                        )} - `}</span>
                        <span>{`${getFormattedDate(project.endDate, DateMask.DD_MM_YYYY)}`}</span>
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    <Typography>
                      {`${createNumberWithCurrency({
                        number: project.rate || project.cost,
                        currencyDisplay: 'symbol',
                        currency: project.projectCurrency,
                      })}`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={s.tableBody__hoursCell}
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    <NA
                      value={
                        row.tableName === SingleBillingTableTypeEnum.Clients
                          ? project.quantity
                          : project.hours.toFixed(2)
                      }
                    />
                    <Typography
                      style={{
                        display: 'inline-block',
                        fontSize: `0.875rem`,
                        paddingLeft: '3px',
                      }}>
                      {project.overtimesHours > 0 && `(${project.overtimesHours.toFixed(2)})`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {project?.overtimes?.length > 0 &&
                        project.overtimes.map(item => (
                          <Typography key={item.overtimeId} className={s.markedAdditionalExpenses}>
                            {item.total && `+${item.total.toFixed(2)}`}
                          </Typography>
                        ))}
                      {project.clientExpenses !== 0 && project.clientExpenses && (
                        <Typography sx={{ color: 'blue !important', fontSize: '0.8rem' }}>
                          {`+${project.clientExpenses}`}
                        </Typography>
                      )}
                      <Typography>
                        {`${createNumberWithCurrency({
                          number: project.total,
                          currencyDisplay: 'symbol',
                          currency: project.projectCurrency,
                        })}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ paddingRight: 0 }}
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    {<Comment text={project.msg} />}
                  </TableCell>
                  <TableCell>
                    {project.fromBilling && (
                      <SingleBillingTableRowEditAssignment
                        assignmentId={project.assignmentId}
                        billingReportId={project.billingReportId}
                        clientId={project.clientId}
                        ccy={project.ccy}
                        icboTemplateId={project.icboTemplateId}
                        rowRecord={transformEditBillingAssignmentData(project)}
                        constantData={{ name: row.title, id: Number(row.id) }}
                        hasDelete={false}
                        invoiced={project.invoiced || project.locked}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => onRowClick(project, row.tableName, accessLevel, row.id)}>
                    {project.warning ? <WarningAmberIcon color='warning' /> : null}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
        {footerData && (
          <TableFooter>
            <TableRow>
              {footerData.map(item => (
                <TableCell
                  key={item?.cellNumber}
                  sx={item?.cellNumber === 1 ? { paddingLeft: 0 } : {}}>
                  <Typography className={s.tableFooter__tableCell}>{item?.title}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default MainTable;
