import { Moment } from 'moment';

import { CurrencyEnum, Maybe } from 'valtech-core/common/types';

export interface IEditBudgetingFormProps<T> {
  onClose: VoidFunction;
  initialValues?: Partial<T>;
  defaultValues?: Partial<T>;
  onSubmit?: (data: T) => void;
  assignmentId?: number;
  hasDelete?: boolean;
  fromBilling?: boolean;
  isDisabledEditOnBilling?: boolean;
  billingReportId?: number;
  ccy?: CurrencyEnum;
  clientId?: number;
  icboTemplateId?: number;
  status?: string;
  createFromWarning?: boolean;
}

export enum ConsultantModelEnum {
  Dedicated = 'Dedicated',
  TimeAndMaterial = 'T&M',
}

export enum ConsultantOvertimeEnum {
  Vacation = 'vacation',
  Bill = 'bill',
}

export type MonthSettings = {
  daysInMonth: number;
  workingDays: number;
  arrayOfNationalHolidays: string[];
};

export enum ActiveTabsEnum {
  Comments = 'comments',
  General = 'general',
}

export enum TabsActionEnum {
  Save = 'save',
  Undo = 'undo',
}

export enum Variant {
  Standard = 'standard',
  Filled = 'filled',
  Outlined = 'outlined',
}

export enum SingleBillingTableTypeEnum {
  Consultants = 'Consultants',
  Clients = 'Clients',
}

export enum RecordStatus {
  Active = 'Active',
  NotActive = 'Not active',
  Archived = 'Archived',
}

export enum AllTableStatus {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft',
  NotActive = 'notActive',
}
export interface IWarningData {
  id: number;
  title: string;
}

export enum ProjectTableSearchFieldsEnum {
  Name = 'name',
  DeliveryManager = 'delivery-manager',
}

export enum FilterModelEnum {
  Title = '',
  DeliveryManager = 'deliveryManagerInfo',
  Budgets = 'budgetingSettingInfo',
  Project = 'projectInfo',
  Manager = 'managerInfo',
}

export enum TableSearchFieldsEnum {
  Name = 'name',
  Email = 'email',
  Label = 'label',
  Role = 'role',
  Title = 'title',
  UpdateTime = 'update_time',
  UpdateAt = 'updated_at',
  UpdateBy = 'updated_by',
  Status = 'status',
  Type = 'type',
}

export type ExchangeRate = {
  id: number | string;
  l10n: string;
  month: number;
  usd_to_eur?: number | undefined;
  eur_to_usd?: number | undefined;
};

export enum RequestStatus {
  Error = 'error',
  Success = 'success',
  Pending = 'pending',
  Idle = 'idle',
}

export type ITableHeaderItem = {
  l10n: string;
  model: string;
  sortBy: string;
};

export enum ERoles {
  Administrator = 'Administrator',
  User = 'User',
}

export type AssignmentProject = {
  id: string;
  clientId: number | null;
  title: string;
  model: string;
  date: string;
  text: string;
  startDate: Moment;
  endDate: Moment;
  rate: {
    title: string;
    id: number;
    value: string;
  };
  currency: string;
  holidayCalendarId: number;
  costOfOverhead: number;
  comment?: string;
  allocation?: number | null;
  hours?: number;
  allocationPerMonth: MonthAllocation[];
};

export type MonthAllocation = {
  monthIndex: number;
  value: number | null;
  cost: Maybe<number>;
  isPartial?: boolean;
  rateValue?: number | null;
  allocationId?: number;
  assignementId?: number;
};
