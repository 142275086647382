import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'common/apolloState/notifications';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';

import { TableContainer } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';

import { ERROR_EXISTING_DATE_FTL, HOLIDAY_FTL } from 'valtech-core/common/ftl';
import { AlertsSeverityEnum } from 'valtech-core/common/types';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';
import Typography from 'valtech-core/ui/Typography';

import { checkIfDateExisting } from './Holiday.utils';

import { HolidayDescriptionFields } from '../HolidayCalendarDescription/HolidayCalendarDescription.constants';
import AddHoliday from './AddHoliday';
import { HolidayFields, headData } from './Holiday.constants';
import { useDeleteHoliday, useHoliday, useUpdHolidayCalendarDate } from './Holiday.hook';
import HolidayItem from './HolidayItem';

const Holiday: FC = () => {
  const { id: calendarID } = useParams();
  const { l10n } = useLocalization();
  const methods = useFormContext();
  const {
    holiday,
    sorting: { sortingType, handleSorting, activeColumn },
  } = useHoliday();
  const { isEditing, setState } = useEditingContext();
  const [editingItemId, setEditItemId] = useState<string | number>('');
  const { onDelete, loading: deleting } = useDeleteHoliday();
  const updHolidayCalendarDate = useUpdHolidayCalendarDate();

  const { control, setValue, getValues } = methods;
  const { loading, holidays } = holiday;

  const order = getValues('holiday.order');
  const year = getValues(`calendar.data.${HolidayDescriptionFields.Year}`);
  const [newOrder, setOrder] = useState<number | undefined>(order);

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'holiday.holidays',
  });

  useEffect(() => {
    if (newOrder === undefined || order !== newOrder) {
      setOrder(order);
    }
  }, [order]);

  useEffect(() => {
    setValue('holiday', holiday);
    replace(holidays);
  }, [loading]);

  const onSelect = id => () => {
    if (isEditing || deleting) return;
    setEditItemId(id);
    setState(true);
  };

  const onCancel = () => {
    setEditItemId('');
    setState(false);
  };

  const deleteHoliday = index => id => {
    if (isEditing || deleting) return;
    onDelete(id).then(() => {
      remove(index);
    });
  };

  const updOrder = (order: number) => {
    setOrder(order);
    setValue('holiday.order', order);
  };

  const onCheckBeforeAdd = ({ date, index }: { date: string; index?: number }) => {
    const isDataExist = checkIfDateExisting({
      date,
      index,
      list: getValues('holiday.holidays'),
    });

    if (isDataExist) {
      showNotification({
        message: l10n.getString(ERROR_EXISTING_DATE_FTL),
        hideDuration: secondsToMilliSeconds(3),
        severity: AlertsSeverityEnum.Error,
      });
    }

    return isDataExist;
  };

  const onSave = index => value => {
    if (onCheckBeforeAdd({ date: value[HolidayFields.Date], index })) return;
    updHolidayCalendarDate(value).then(() => {
      setValue(`holiday.holidays[${index}]`, value);
    });
  };

  return (
    <Box sx={{ mt: 5, fontSize: 20 }}>
      <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
        {l10n.getString(HOLIDAY_FTL)}
      </Typography>
      <TableContainer sx={{ mt: 2 }}>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'absolute',
                top: '52px',
                left: 0,
              },
            }}
          />
        )}
        <Table aria-label='simple table'>
          <TableHead>
            <TableRowReusable
              headData={headData}
              sortingType={sortingType}
              disableSorting={isEditing || !calendarID}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
            />
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton
                page={0}
                total={0}
                rowsNumber={holidays.length}
                cellsNumber={headData.length}
              />
            ) : (
              fields.map((field, index) => (
                <HolidayItem
                  key={field.id}
                  currentYear={year}
                  onCancel={onCancel}
                  onSave={onSave(index)}
                  onSelect={onSelect(field.id)}
                  onDelete={deleteHoliday(index)}
                  values={getValues(`holiday.holidays[${index}]`)}
                  isActive={field.id === editingItemId && editingItemId !== ''}
                />
              ))
            )}
          </TableBody>
          <TableFooter>
            <AddHoliday
              order={newOrder || 0}
              currentYear={year}
              append={append}
              updOrder={updOrder}
              onCheckBeforeAdd={onCheckBeforeAdd}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Holiday;
