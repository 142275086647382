import { useCallback, useState } from 'react';

import { IAssignmentFormProps } from 'components/_forms/AssignmentForm';
import { ConsultantModelEnum } from 'src/common/types';

import moment from 'moment';

import { useToggle } from 'valtech-core/utils/useToggle';

import { useDeleteAssignment } from 'components/_forms/forms.hooks';

import { EditBillingAssignmentData } from '../../EditSingleBillingTable.utils';

type Consultant = { name: string; id: string | number };

type UseHandleTableRowEditReturn = {
  isOpened: boolean;
  toggleForm: VoidFunction;
  onDelete: VoidFunction;
  getAssignmentInitialValues: (
    consultant: Consultant,
    item: EditBillingAssignmentData,
  ) => IAssignmentFormProps;
  isLoading: boolean;
};

type UseHandleTableRowEditParams = {
  rowRecord: EditBillingAssignmentData;
  assignmentId: number;
};

export const useHandleTableRowEdit = ({
  assignmentId,

  rowRecord,
}: UseHandleTableRowEditParams): UseHandleTableRowEditReturn => {
  const [isLoading, setLoading] = useState(false);
  const [isOpened, toggleForm] = useToggle(false);
  const { deleteAssignment } = useDeleteAssignment();

  const getAssignmentInitialValues = useCallback(
    (consultant: Consultant, item: EditBillingAssignmentData): IAssignmentFormProps => {
      return {
        consultant: {
          label: consultant.name,
          id: Number(consultant.id),
        },
        client: String(item.clientId) as string,
        model: item.model || ConsultantModelEnum.Dedicated,
        allocation: item.allocation,
        hours: item.hours,
        fee: getFeeInitialValue(item.rate.value),
        rate: `${item.rate.id}`,
        startDate: moment(item.startDate),
        endDate: moment(item.endDate),
        holidayCalendarId: item.holidayCalendarId as number,
        costOfOverhead: item.costOfOverhead as number,
        notes: item.notes || '',
        showInBudget: Boolean(item.showInBudget),
      };
    },
    [rowRecord],
  );

  const onDelete = useCallback(async () => {
    try {
      setLoading(true);

      await deleteAssignment(assignmentId, true);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isOpened,
    toggleForm,
    onDelete,
    isLoading,
    getAssignmentInitialValues,
  };
};

export function getFeeInitialValue(fee: string): number {
  return fee ? Number(fee.slice(1).replace(',', '')) : 0;
}
