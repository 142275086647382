import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { FieldValues, UseFieldArrayAppend, UseFormReturn } from 'react-hook-form';

import { showGenericError, showSystemError } from 'common/apolloState/system';
import { v4 as uuidv4 } from 'uuid';

import { ERROR_CREDENTIALS_USER_EXIST } from 'valtech-core/common/ftl';
import { useCreateUserCredentialsMutation } from 'valtech-core/common/gql/generated';

import { IUserCredentialsTable, defaultValues } from '../UserCredentialsTable.const';

interface IAddNewUserCredentialsReturn {
  addNewUserCredentials: () => void;
  loading: boolean;
}

export interface IAddNewUserCredentialsParams {
  append: UseFieldArrayAppend<FieldValues, 'userCredentials.credentials'>;
  methods: UseFormReturn<Omit<IUserCredentialsTable, 'id'>>;
}

export const useAddNewUserCredentials = ({
  append,
  methods,
}: IAddNewUserCredentialsParams): IAddNewUserCredentialsReturn => {
  const { trigger, getValues, reset } = methods;
  const [createNewUserCredentials, { loading }] = useCreateUserCredentialsMutation();
  const { id: clientId } = useParams();

  const addNewUserCredentials = useCallback(async () => {
    const values = getValues();

    try {
      const valid = await trigger();
      if (valid) {
        const { role, name } = values;
        const user_id = name?.id as number;
        const params = {
          role,
          user_id,
        };

        if (clientId) {
          const res = await createNewUserCredentials({
            variables: {
              data: [
                {
                  project_id: Number(clientId),
                  role,
                  user_id,
                },
              ],
            },
            refetchQueries: ['getArrayOfUserCredentials'],
          });
          const userCredentialsId = res?.data?.addUserCredentials[0].id;
          userCredentialsId &&
            append({
              id: userCredentialsId,
              ...params,
            });
        } else {
          append({
            id: uuidv4(),
            ...params,
          });
        }
        reset({ ...defaultValues });
      }
    } catch (e: any) {
      if (e.message === 'Validation error') {
        showSystemError(ERROR_CREDENTIALS_USER_EXIST);
      } else {
        showGenericError();
      }
    }
  }, [clientId]);

  return {
    loading,
    addNewUserCredentials,
  };
};
