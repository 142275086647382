import React, { FC, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { TabsActionEnum } from 'common/types';
import TextField from 'form/TextField';
import { isNil } from 'lodash';
import {
  useGeneralTab,
  useHandleUpdateAssignment,
} from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';
import { useHandleSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import Divider from '@mui/material/Divider';

import {
  conditionalOptionalStringValidator,
  hoursValidator,
} from 'valtech-core/common/form/validators';
import { ADD_COMMENT_FTL, HOURS_FTL, SAVE_FTL, UNDO_FTL } from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import { FieldControl, InputMaybe } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { countSingleBillingTotal } from 'pages/SingleBilling/SingleBilling.utils';

import s from '../shared.module.scss';

type ReportCorrectionFormProps = {
  hours: InputMaybe<string>;
  comment?: InputMaybe<string>;
};

type ReportCorrectionProps = {
  initialValues: ReportCorrectionFormProps;
};

const ReportCorrection: FC<ReportCorrectionProps> = ({ initialValues }) => {
  const { l10n } = useLocalization();
  const { projectId } = useBudgetingState();
  const {
    projectData,
    updateStateReportCorrection,
    workingHoursPerMonth,
    billingStatus,
    reportCorrectionInitialValues,
  } = useGeneralTab();
  const { isDisabled: isDisabledFromBilling } = reportCorrectionInitialValues;

  const { updateAssignmentReportCorrection, loading } = useHandleUpdateAssignment();
  const { addBillingReportComment } = useHandleSingleBillingTabs();
  const { control, handleSubmit, reset } = useForm<ReportCorrectionFormProps>({
    defaultValues: initialValues,
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    reset({ ...initialValues });
    if (disabled && !initialValues.hours) setDisabled(false);
    else if (!disabled && initialValues.hours) setDisabled(true);
  }, [projectData?.id]);

  const onSave = useCallback(
    (data: ReportCorrectionFormProps, type: TabsActionEnum) => {
      const isSaveAction = type === TabsActionEnum.Save;
      const correctionQty = isSaveAction ? Number(data.hours) : null;
      const correctionNote = isSaveAction ? data.comment : null;
      const projectHours =
        projectData?.tabsValues?.reportCorrectionTabValues?.reportCorrectionOriginalQty;
      const correctionTotal = countSingleBillingTotal({
        rate: projectData?.rate,
        model: projectData?.model,
        hours: isSaveAction ? correctionQty : projectHours,
        workingHoursPerMonth,
      });

      if (projectData?.id) {
        updateAssignmentReportCorrection({
          assignmentId: projectData.id,
          correctionNote,
          correctionQty,
          correctionTotal: correctionTotal,
        }).then(isSuccessful => {
          if (isSuccessful) {
            const comment = `Has
            ${isSaveAction ? `adjusted allocation to ${correctionQty}` : 'undone added allocation'}
            ${isSaveAction && data.comment ? ` with comment: "${data.comment}"` : ''}`;
            addBillingReportComment(projectData.id, comment, projectId);
            reset({
              hours: isSaveAction ? data.hours : '',
              comment: isSaveAction ? correctionNote : '',
            });

            updateStateReportCorrection(
              isSaveAction ? String(correctionQty) : null,
              isNil(correctionNote) ? '' : String(correctionNote),
            );
            setDisabled(isSaveAction);
          }
        });
      }
    },
    [projectData?.id],
  );

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <div className={s.fieldsWrapper}>
        <TextField
          label={l10n.getString(HOURS_FTL)}
          name='hours'
          control={control as unknown as FieldControl}
          boxProps={{
            className: s.input,
          }}
          textProps={{
            size: 'small',
            type: 'number',
          }}
          validate={hoursValidator}
          disabled={disabled || Boolean(isDisabledFromBilling)}
        />
        <TextField
          label={l10n.getString(ADD_COMMENT_FTL)}
          name='comment'
          control={control as unknown as FieldControl}
          boxProps={{
            className: s.input,
          }}
          textProps={{
            size: 'small',
          }}
          validate={conditionalOptionalStringValidator(disabled)}
          disabled={disabled || Boolean(isDisabledFromBilling)}
        />
      </div>
      <Divider sx={{ my: 2 }} />
      <Button
        variant='contained'
        type='submit'
        disabled={billingStatus === BillingStatus.Invoiced || Boolean(isDisabledFromBilling)}
        onClick={handleSubmit(data => {
          if (disabled) {
            return onSave(data, TabsActionEnum.Undo);
          }
          return onSave(data, TabsActionEnum.Save);
        })}
        className={s.button__left_margin}>
        {l10n.getString(disabled ? UNDO_FTL : SAVE_FTL)}
      </Button>
    </form>
  );
};

export default ReportCorrection;
