import React, { FC } from 'react';

import { useAllUsers } from './AllUsers.hook';
import AllUsersHeader from './AllUsersHeader';
import AllUsersTable from './AllUsersTable';

const AllUsers: FC = () => {
  const allUsersHookData = useAllUsers();

  return (
    <>
      <AllUsersHeader />
      <AllUsersTable {...allUsersHookData} />
    </>
  );
};
export default AllUsers;
