import React, { FC, useCallback } from 'react';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { ConsultantModelEnum } from 'common/types';
import Modal from 'components/Modal';
import AssignmentForm, { IAssignmentFormProps } from 'components/_forms/AssignmentForm';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';

import { ADD_ASSIGNMENT_FTL } from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import { CurrencyEnum, Maybe } from 'valtech-core/common/types';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import s from './TableHeader.module.scss';

type BillingModals = {
  assignmentFormModal: boolean;
};

interface IEditBillingTableHeaderProps {
  title: string | undefined;
  status: Maybe<BillingStatus>;
}

const TableHeader: FC<IEditBillingTableHeaderProps> = ({ title, status }) => {
  const { l10n } = useLocalization();

  const [openModal, setOpenModal] = React.useState<BillingModals>({
    assignmentFormModal: false,
  });

  const { assignmentDefaults, currency } = useBudgetingState();

  const getDefaultCostOfOverhead = useCallback(() => {
    return currency === CurrencyEnum.EUR
      ? assignmentDefaults.costOfOverhead.EUR
      : assignmentDefaults.costOfOverhead.USD;
  }, [assignmentDefaults, currency]);

  const {
    state: { billingInfo, accessLevel },
  } = useBillingState();

  //TODO: Remove after getting defaults from the API
  const defaultAssignmentValues: Partial<IAssignmentFormProps> = {
    allocation: assignmentDefaults.allocation,
    costOfOverhead: getDefaultCostOfOverhead(),
    model: ConsultantModelEnum.Dedicated,
    datePickerMinStartDate: billingInfo?.billingStartDay,
    datePickerMinEndDate: billingInfo?.billingEndDay,
    defaultCalendarDate: {
      month: billingInfo?.billingMonth,
      year: billingInfo?.financialYear,
    },
  };

  const toggleModal = (key: keyof BillingModals) => {
    if (status === BillingStatus.Draft) {
      setOpenModal(prev => {
        return {
          ...prev,
          [key]: !prev[key],
        };
      });
    }
  };

  const userAccessLevel = getAccessLevel(accessLevel, l10n);

  return (
    <>
      <div className={s.wrapper}>
        <Typography variant='caption' weight='bold' transform='uppercase'>
          {title}
        </Typography>
        {userAccessLevel.full && (
          <Button
            type='button'
            size='small'
            variant='outlined'
            color='inherit'
            onClick={() => toggleModal('assignmentFormModal')}
            disabled={status === BillingStatus.Invoiced}>
            {l10n.getString(ADD_ASSIGNMENT_FTL).toUpperCase()}
          </Button>
        )}
      </div>

      <Modal
        handleCloseModal={() => toggleModal('assignmentFormModal')}
        openModal={openModal.assignmentFormModal}
        size='mid'
        closeBtn>
        <AssignmentForm
          onClose={() => toggleModal('assignmentFormModal')}
          defaultValues={defaultAssignmentValues}
          fromBilling={true}
        />
      </Modal>
    </>
  );
};

export default TableHeader;
