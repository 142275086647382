import _capitalize from 'lodash/capitalize';
import _map from 'lodash/map';
import _toLower from 'lodash/toLower';

export const splitString = (inputStr: string, shouldCapitalize = true): string => {
  const splitStr = inputStr.match(/([A-Z]?[^A-Z]*)/g);
  let transformedStr: string[] = [];

  if (splitStr) {
    transformedStr = splitStr.filter(Boolean);
  }

  return shouldCapitalize
    ? _map(transformedStr, _capitalize).join(' ')
    : _map(transformedStr, _toLower).join(' ');
};
