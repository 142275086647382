import React, { FC, memo, useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type MenuItem = {
  id: string;
  title: string;
  callback: (event: Event | React.SyntheticEvent) => void;
  icon?: JSX.Element;
};

export interface IDropdownProps {
  boxProps?: BoxProps;
  btnVariant?: 'text' | 'contained' | 'outlined' | undefined;
  bntTitle: string;
  buttonIcon?: JSX.Element;
  menuList: MenuItem[];
  menuPositionVertical?: number | 'top' | 'bottom' | 'center';
  menuPositionHorizontal?: number | 'right' | 'left' | 'center';
  disabled?: boolean;
  shouldStopPropagation?: boolean;
}

const Dropdown: FC<IDropdownProps> = ({
  boxProps,
  btnVariant = 'contained',
  bntTitle,
  menuList,
  menuPositionVertical = 'top',
  menuPositionHorizontal = 'center',
  buttonIcon,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const idBtn = useMemo(() => uuidv4(), []);
  const idMenu = useMemo(() => uuidv4(), []);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  return (
    <Box {...boxProps}>
      {!buttonIcon ? (
        <Button
          id={idBtn}
          aria-controls={open ? idMenu : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          variant={btnVariant}
          onClick={handleClick}
          disabled={disabled}
          fullWidth>
          {bntTitle}
        </Button>
      ) : (
        <IconButton
          id={idBtn}
          aria-controls={open ? idMenu : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          disabled={disabled}
          onClick={handleClick}>
          {buttonIcon}
        </IconButton>
      )}
      <Menu
        id={idMenu}
        aria-labelledby={idBtn}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: menuPositionVertical,
          horizontal: menuPositionHorizontal,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {menuList.map(({ id, title, callback, icon }) => {
          const clickHandler = e => {
            handleCloseModal();
            callback(e);
          };
          return (
            <MenuItem key={id} onClick={clickHandler} data-list-item>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default memo(Dropdown);
