import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import Modal from 'components/Modal';
import BudgetingForm from 'components/_forms/BudgetingForm';

import Box from '@mui/material/Box';

import { ADD_FTL, BUDGET_FTL } from 'valtech-core/common/ftl';
import Button from 'valtech-core/ui/Button';

import s from './BudgetingHeader.module.scss';

type BudgetingModal = {
  budgetingFormModal: boolean;
};

const BudgetingHeader: FC = () => {
  const { l10n } = useLocalization();

  const [openModal, setOpenModal] = React.useState<BudgetingModal>({
    budgetingFormModal: false,
  });

  const toggleModal = (key: keyof BudgetingModal) => {
    setOpenModal(prev => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  return (
    <Box className={s.container}>
      <Button
        variant='contained'
        sx={{ mx: 1 }}
        type='button'
        onClick={() => toggleModal('budgetingFormModal')}>
        {`${l10n.getString(ADD_FTL)} ${BUDGET_FTL}`}
      </Button>
      <Modal
        handleCloseModal={() => toggleModal('budgetingFormModal')}
        openModal={openModal.budgetingFormModal}>
        <Box
          sx={{
            width: 600,
          }}>
          <BudgetingForm onClose={() => toggleModal('budgetingFormModal')} />
        </Box>
      </Modal>
    </Box>
  );
};

export default BudgetingHeader;
