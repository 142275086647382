import React, { FC } from 'react';

import { DrawerTypeEnum } from 'valtech-core/common/types';

import { DrawerItemsList } from './Drawer.types';
import DrawerButtonsList from './DrawerButtonsList';
import DrawerLinksList from './DrawerLinksList';

interface IDrawerListProps {
  data: DrawerItemsList[];
  type: DrawerTypeEnum;
  activeItemOnLoad?: string;
  clearState?: boolean;
}

const DrawerList: FC<IDrawerListProps> = ({ data, type, activeItemOnLoad, clearState }) => {
  switch (type) {
    case DrawerTypeEnum.Button:
      return (
        <DrawerButtonsList
          data={data}
          activeItemOnLoad={activeItemOnLoad}
          clearState={clearState}
        />
      );

    case DrawerTypeEnum.Link:
      return <DrawerLinksList data={data} />;

    default:
      return null;
  }
};

export default DrawerList;
