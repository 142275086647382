import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { FieldValues, UseFieldArrayReplace, UseFormReturn } from 'react-hook-form';

import { showGenericError } from 'common/apolloState/system';
import useHandleSorting from 'common/hooks/useHandleSorting';
import { handleSortingParams } from 'common/hooks/useHandleSorting/useHandleSorting';
import { ExchangeRate } from 'common/types';
import round from 'lodash/round';

import { MONTH_LIST } from 'valtech-core/common/ftl';
import {
  useCreateExchangeRatesMutation,
  useGetExchangeRateByBudgetSettingsIdLazyQuery,
  useUpdateExchangeRateByIdMutation,
} from 'valtech-core/common/gql/generated';

import { getMonthsList } from './ExchangeRateTable.utils';

import { EXCHANGE_RATE_ID_PREFIX, ExchangeRateParams } from './ExchangeRateTable.constants';

export interface IUseExchangeRateHookReturn {
  exchangeRate: {
    loading: boolean;
    exchangeRates: ExchangeRate[];
    sorting: handleSortingParams;
  };
}

export const exchangeRateHook = (): IUseExchangeRateHookReturn => {
  const { id } = useParams();
  const budgetID = Number(id);
  const { sortingType, activeColumn, handleSorting, sortBy } = useHandleSorting({
    sortByState: ExchangeRateParams.Month,
  });

  const [getExchangeRates, { data, loading }] = useGetExchangeRateByBudgetSettingsIdLazyQuery();

  useEffect(() => {
    if (id) {
      getExchangeRates({
        variables: {
          sortBy: sortBy,
          budgetID: Number(budgetID),
          sortDirection: sortingType,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [id, sortBy, sortingType]);

  const defaultMontList = useMemo(
    () =>
      MONTH_LIST.map((m, i) => ({
        id: `${EXCHANGE_RATE_ID_PREFIX}${i + 1}`,
        l10n: m,
        month: i + 1,
        eur_to_usd: 1,
        usd_to_eur: 1,
      })),
    [],
  );

  const exchangeRates = useMemo(() => {
    if (!data || !budgetID || !data.getExchangeRateByBudgetSettingsID.length) {
      return defaultMontList;
    }

    return getMonthsList(data.getExchangeRateByBudgetSettingsID || []);
  }, [data]);

  return {
    exchangeRate: {
      loading,
      exchangeRates: exchangeRates as ExchangeRate[],
      sorting: {
        sortingType,
        activeColumn,
        handleSorting,
      },
    },
  };
};

interface IUseUpdateExchangeRateParams {
  index: number;
  key: string;
  value: string | number | null;
}

interface IUseUpdateExchangeRateMethods {
  methods: UseFormReturn;
  replace: UseFieldArrayReplace<FieldValues, 'exchangeRate.exchangeRates'>;
}

interface useUpdateExchangeRateReturn {
  loading: boolean;
  onModify: ({ index, key, value }: IUseUpdateExchangeRateParams) => Promise<void>;
}

export const useUpdateExchangeRate = ({
  methods,
  replace,
}: IUseUpdateExchangeRateMethods): useUpdateExchangeRateReturn => {
  const { id: budgetID } = useParams();

  const [updateRate, { loading: updExchangeRateLoading }] = useUpdateExchangeRateByIdMutation();
  const [createExchangeRates, { loading: addExchangeRateLoading }] =
    useCreateExchangeRatesMutation();

  const onModify = async ({ index, key, value }) => {
    try {
      const exchangeRate = methods.getValues('exchangeRate');
      const { exchangeRates } = exchangeRate;
      const id = exchangeRates[index].id;
      const cond = value === null || Number.isNaN(Number(value));

      if (id?.includes?.(EXCHANGE_RATE_ID_PREFIX)) {
        const res = await createExchangeRates({
          variables: {
            data: exchangeRate.exchangeRates.map(({ month, eur_to_usd, usd_to_eur }, i) => {
              const cond1 = eur_to_usd === null || Number.isNaN(Number(eur_to_usd));
              const cond2 = usd_to_eur === null || Number.isNaN(Number(usd_to_eur));
              return {
                budgeting_setting_id: Number(budgetID),
                month,
                ...(cond1 ? { eur_to_usd: 0 } : { eur_to_usd: Number(eur_to_usd) }),
                ...(cond2 ? { usd_to_eur: 0 } : { usd_to_eur: Number(usd_to_eur) }),
                ...(!cond && index === i ? { [key]: Number(value) } : {}),
              };
            }),
          },
          refetchQueries: ['getExchangeRateByBudgetSettingsID'],
        });
        if (res?.data?.createExchangeRates.length) {
          replace(getMonthsList(res?.data.createExchangeRates));
        } else throw new Error('sth wend wrong');
      } else {
        const { month, eur_to_usd, usd_to_eur, id } = methods.getValues(
          `exchangeRate.exchangeRates[${index}]`,
        );

        const cond1 = eur_to_usd === null || Number.isNaN(Number(eur_to_usd));
        const cond2 = usd_to_eur === null || Number.isNaN(Number(usd_to_eur));
        const cond3 = value === null || Number.isNaN(Number(value));

        await updateRate({
          variables: {
            ...(cond1 ? { eur_to_usd: 0 } : { eur_to_usd: round(Number(eur_to_usd), 2) }),
            ...(cond2 ? { usd_to_eur: 0 } : { usd_to_eur: round(Number(usd_to_eur), 2) }),
            ...(cond3 ? { [key]: 0 } : { [key]: Number(value) }),
            updateExchangeRateByIdId: id,
            month,
            budgetingSettingId: Number(budgetID),
          },
        });
      }
    } catch (e) {
      showGenericError();
      console.log('==>exchange rate error', e);
    }
  };

  return {
    loading: addExchangeRateLoading || updExchangeRateLoading,
    onModify,
  };
};
