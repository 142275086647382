import { useMemo } from 'react';

import { MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { useGetAllBusinessUnitListQuery } from 'valtech-core/common/gql/generated';

type EntityItem = { id: string; title: string; value: string };

type UseHolidayCalendarListReturn = {
  entities: EntityItem[];
  loading: boolean;
};

export const useAllBusinessUnitList = (): UseHolidayCalendarListReturn => {
  const { data, loading } = useGetAllBusinessUnitListQuery({
    variables: {
      pagination: {
        limit: MAX_REQUEST_LIMIT,
      },
    },
  });

  const entities = useMemo((): EntityItem[] => {
    if (!data) {
      return [];
    }

    return (data?.getBusinessUnitList?.data || []).map(entity => ({
      id: entity.id,
      title: entity.label,
      value: entity.label,
    }));
  }, [data]);

  return {
    entities,
    loading,
  };
};
