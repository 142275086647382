import { INPUT_STR_MID_LIMIT } from 'valtech-core/common/form/const';
import { dateValidator, useCommonStringValidator } from 'valtech-core/common/form/validators';
import {
  DATE_FTL,
  ERROR_INVALID_TITLE_FTL,
  ERROR_MID_STR_LIMIT_FTL,
  ERROR_REQUIRED_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';

export enum HolidayFields {
  Date = 'date',
  Title = 'title',
}

export interface IHoliday {
  id: number | string;
  date: string | null;
  order: number;
  title: string;
  holiday_calendar_id: number;
}

export const headData = [
  { l10n: TITLE_FTL, model: '', sortBy: HolidayFields.Title },
  { l10n: DATE_FTL, model: '', sortBy: HolidayFields.Date },
  { l10n: '', model: '', sortBy: '' },
];

export const dateAddHolidayValidator = dateValidator({ invalid: ERROR_REQUIRED_FTL });

export const titleAddHolidayValidator = useCommonStringValidator({
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_INVALID_TITLE_FTL,
  regexp: /^[a-zA-Z0-9\s\-`'&\/()]+$/,
  longStr: ERROR_MID_STR_LIMIT_FTL,
  longStrLimit: INPUT_STR_MID_LIMIT,
});
