import {
  ActivationStatus,
  AddSettingsUserMutationVariables,
} from 'valtech-core/common/gql/generated';

import { ISettingsUserFormProps } from './SettingsUserForm';

type SubmitAddSettingsUserParams = {
  data: ISettingsUserFormProps;
};

export const mapToSubmitAddSettingsUser = ({
  data,
}: SubmitAddSettingsUserParams): AddSettingsUserMutationVariables => {
  const consultantName = data.consultant.label;

  return {
    consultantId: data.consultant.id,
    name: consultantName,
    email: data.email,
    role: data.role,
    status: data.status as ActivationStatus,
  };
};
