import { ActivationStatus, OrderDirection } from 'valtech-core/common/gql/generated';

import { FilterModelEnum, TableSearchFieldsEnum } from './types';

export const ALLOCATION_DEFAULT = 100;
export const COST_OF_OVERHEAD_DEFAULT = 0;
export const FEE_DEDICATED_DEFAULT = 4000;
export const FEE_TnM_DEFAULT = 43;
export const RATE_DEFAULT = 'Tier 3';
export const CLIENT_DEFAULT = 'General';

export const MONTHS_IN_A_YEAR = 12;
export const WORKING_HOURS_PER_DAY = 8;
export const WORKING_HOURS_PER_MONTH = 160;
export const DEFAULT_OVERTIME_COEFFICIENT = 1;
export const MAX_QUANTITY = 10000;
export const DEFAULT_SKIP_QUANTITY = 0;
export const DEFAULT_GROSS_MARGIN = null;
export const TEMP_ICBO_TEMPLATEID = 2; //TODO: remove hardcoded, Sept 2024 functionality is not developed

export enum ProjectFields {
  Name = 'name',
  ProjectInfo = 'projectInfo',
}

export const PROJECT_FILTER_DEFAULT_OBJ = {
  query: '',
  field: ProjectFields.Name,
  filterModel: ProjectFields.ProjectInfo,
};

export const TABLE_FILTER_DEFAULT_OBJ = {
  query: '',
  field: '',
  filterModel: '',
};

export const SETTING_PROJECTS_PAGE_DEFAULT_FILTER = {
  query: '',
  field: TableSearchFieldsEnum.Name,
  filterModel: FilterModelEnum.DeliveryManager,
};

export const ERROR_APOLLO_RESPONSE_MSG_UNAUTHORIZED = 'Unauthorized';
export const APPLICATION_TITLE = 'V_Reports';

export const DEFAULT_CLIENTS_SEARCH_PARAMS = {
  sortDirection: OrderDirection.Asc,
  sortBy: TableSearchFieldsEnum.Name,
  filterBy: TableSearchFieldsEnum.Status,
  filterQuery: ActivationStatus.Active,
};

export const StatusTitleEnum = {
  Active: 'Active',
  NotActive: ' Not Active',
};

export const selectMockStatus = [
  { id: '1', title: StatusTitleEnum.NotActive, value: ActivationStatus.NotActive },
  { id: '2', title: StatusTitleEnum.Active, value: ActivationStatus.Active },
];
