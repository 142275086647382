import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import styles from './Drawer.module.scss';
import { DrawerItemsList } from './Drawer.types';

interface IDrawerLinksListProps {
  data: DrawerItemsList[];
}

const DrawerLinksList: FC<IDrawerLinksListProps> = ({ data }) => {
  return (
    <>
      {data.map(({ title, icon = <AutoAwesomeMosaicIcon />, href }, index) => (
        <ListItem key={`${index + title}`} disablePadding divider>
          <NavLink
            to={href || ''}
            className={cn(styles.link, ({ isActive }) => (isActive ? [styles.active] : undefined))}
          >
            <ListItemButton component='div'>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
    </>
  );
};

export default DrawerLinksList;
