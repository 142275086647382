import React, { FC, useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import SelectFiled from 'form/SelectField';
import TextFiled from 'form/TextField';
import { selectMockStatus } from 'src/common/constants';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import {
  ADD_ENTITY_FTL,
  ADD_FTL,
  CANCEL_FTL,
  EDIT_ENTITY_FTL,
  NAME_FTL,
  SAVE_FTL,
  SHORT_NAME_FLT,
  STATUS_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useHandleEntity } from './EntityForm.hooks';

import { EFormFieldName } from '../form.types';

export interface IEntityFormProps {
  order: number;
  label: string;
  status: string;
  id: string;
  updatedBy: number | string;
}

export interface IEditEntityFormProps<T> {
  onClose: VoidFunction;
  initialValues?: Partial<T>;
  defaultValues?: Partial<T>;
  onSubmit?: (data: T) => void;
  order?: number;
  shortName?: string;
  hasDelete?: boolean;
}

const defaultInitialValues = {
  order: 0,
  label: '',
  status: '',
  shortName: '',
};

const EntityForm: FC<IEditEntityFormProps<IEntityFormProps>> = ({
  onClose,
  initialValues = defaultInitialValues,
}) => {
  const { l10n } = useLocalization();
  const { control, handleSubmit } = useForm<IEntityFormProps>({
    defaultValues: initialValues,
  });
  const { createEntity, updateEntity, loading } = useHandleEntity();

  const formSubmit: SubmitHandler<IEntityFormProps> = useCallback(
    data => {
      // If Entity doesn't exist data.order will be 0
      if (data.order) {
        const dataUpdated = { order: data.order };

        for (const key in data) {
          if (data[key] !== initialValues[key]) {
            dataUpdated[key] = data[key];
          }
        }
        updateEntity(data, dataUpdated).then(success => success && onClose());
      } else {
        createEntity(data).then(success => success && onClose());
      }
    },
    [onClose],
  );

  const onCancel = () => {
    onClose();
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {initialValues.order ? l10n.getString(EDIT_ENTITY_FTL) : l10n.getString(ADD_ENTITY_FTL)}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextFiled
            label={l10n.getString(NAME_FTL)}
            name={EFormFieldName.Label}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFiled
            label={l10n.getString(SHORT_NAME_FLT)}
            name={EFormFieldName.Id}
            control={control as unknown as FieldControl}
            maxLength={2}
            validate={requiredStringValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(STATUS_FTL)}
            name={EFormFieldName.Status}
            data={selectMockStatus}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button onClick={handleSubmit(formSubmit)} variant='contained'>
          {initialValues.order ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default EntityForm;
