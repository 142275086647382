import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import {
  FilterModelEnum,
  ITableHeaderItem,
  ProjectTableSearchFieldsEnum,
  TableSearchFieldsEnum,
} from 'common/types';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import SearchInput from 'components/SearchInput';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';
import { splitString } from 'src/common/utils/splitAndCapitalize';

import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { CLEAR_ALL_FTL, DELIVERY_MANAGER_FTL, NAME_FTL, TYPE_FTL } from 'valtech-core/common/ftl';
import { OrderDirection } from 'valtech-core/common/gql/generated';
import { IFilters } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { useAllProjects } from '../AllProjects.hook';
import { headData } from './AllProjectsTable.const';
import s from './AllProjectsTable.module.scss';

const AllProjectsTable: FC = () => {
  const { l10n } = useLocalization();
  const {
    setTableFilters,
    setSortingType,
    setModel,
    setSortBy,
    setPage,
    setRowsPerPage,
    loading,
    handleSearch,
    tableFilters,
    rowsPerPage,
    page,
    total,
    sortingType,
    setFilter,
    settingsProjects,
  } = useAllProjects();

  const [activeColumn, setActiveColumn] = useState('');
  const [isInputClear, setIsInputClear] = useState(true);

  const navigate = useNavigate();

  const isData = !loading && !!settingsProjects && settingsProjects?.length > 0;

  const getDefaultInput = useCallback(
    (tableFilters: IFilters[], item: ITableHeaderItem): string => {
      let initialValue = '';

      if (item.l10n === ProjectTableSearchFieldsEnum.Name) {
        return (initialValue =
          tableFilters.find(
            item =>
              item.field === TableSearchFieldsEnum.Name &&
              item.filterModel === FilterModelEnum.Title,
          )?.query || initialValue);
      }

      if (item.l10n === ProjectTableSearchFieldsEnum.DeliveryManager) {
        return (initialValue =
          tableFilters.find(
            item =>
              item.field === TableSearchFieldsEnum.Name &&
              item.filterModel === FilterModelEnum.DeliveryManager,
          )?.query || initialValue);
      } else {
        return initialValue;
      }
    },
    [tableFilters],
  );

  const handleClearFilters = useCallback(() => {
    setTableFilters([]);
    setFilter([]);
    setSortingType(OrderDirection.Desc);
    setModel('');
    setSortBy(TableSearchFieldsEnum.Name);
    setPage(0);
    setRowsPerPage(DEFAULT_REQUEST_LIMIT);
    setActiveColumn(TableSearchFieldsEnum.Name);
    setIsInputClear(false);
  }, []);

  const handleSorting = useCallback(
    item => {
      setActiveColumn(item.l10n);
      setModel(item.model);
      setSortBy(item.sortBy);
      setSortingType(OrderDirection.Desc);

      if (item.l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [activeColumn, sortingType],
  );

  const navigateToPage = useCallback((id: number) => {
    navigate(`edit/${id.toString()}`);
  }, []);

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead className={s.tableHead}>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />

          <TableRow>
            {headData.map(item => {
              const Searchable = item.l10n === NAME_FTL || item.l10n === DELIVERY_MANAGER_FTL;
              return (
                <TableCell key={item.l10n}>
                  {Searchable && (
                    <SearchInput
                      placeholder='Search'
                      fieldName={item.l10n}
                      onSearch={handleSearch}
                      inputDefaultValue={getDefaultInput(tableFilters, item)}
                      boxProps={{ sx: { maxWidth: '350px' } }}
                      size='small'
                      isInputClear={isInputClear}
                      onClear={() => setIsInputClear(true)}
                    />
                  )}
                  {item.l10n === TYPE_FTL && (
                    <Button
                      type='button'
                      size='small'
                      variant='contained'
                      onClick={handleClearFilters}>
                      {l10n.getString(CLEAR_ALL_FTL)}
                    </Button>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={s.tableBody}>
          {loading ? (
            <TableSkeleton
              cellsNumber={headData.length}
              rowsNumber={rowsPerPage}
              page={page}
              total={total}
            />
          ) : (
            settingsProjects?.map(row => (
              <TableRow
                key={row.id}
                onClick={() => navigateToPage(row.id)}
                sx={{ cursor: 'pointer' }}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.deliveryManager}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    label={splitString(row.status)}
                    variant='outlined'
                    color={chipColorPicker(row.status)}
                    className='text-capitalized'
                  />
                </TableCell>
                <TableCell>{row.type}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            count={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            isData={isData}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default AllProjectsTable;
