import { useCallback } from 'react';

import { showGenericError } from 'common/apolloState/system';

import { useEditBillingReportErrorsByIdMutation } from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

type EditBillingReportErrorsByID = (
  errorId: number,
  billingReportId: number,
  isIgnored: Maybe<boolean>,
  isProcessed: Maybe<boolean>,
) => Promise<boolean | undefined>;

type UseEditBillingReportErrorsByID = {
  editBillingReportErrorsByID: EditBillingReportErrorsByID;
  loading: boolean;
};

export const useEditBillingReportErrorsByID = (): UseEditBillingReportErrorsByID => {
  const [editBillingReportErrorsByIDMutation, { loading }] = useEditBillingReportErrorsByIdMutation(
    {
      onError() {
        showGenericError();
      },
    },
  );

  const editBillingReportErrorsByID = useCallback(
    async (errorId, billingReportId, isIgnored, isProcessed) => {
      try {
        if (billingReportId) {
          const response = await editBillingReportErrorsByIDMutation({
            variables: {
              errorId,
              billingReportId,
              isIgnored,
              isProcessed,
            },
            refetchQueries: ['GetSingleBillingWarnings', 'SingleBillingInfo'],
          });

          return !!response.data;
        }
      } catch (_) {}
    },
    [],
  );

  return {
    editBillingReportErrorsByID,
    loading,
  };
};
