import { DOLLAR_SIGN_CODE, EURO_SIGN_CODE } from 'valtech-core/common/constants';

type GetCurrencySign = (currency: string | undefined) => string;

const getCurrencySign: GetCurrencySign = currency => {
  switch (currency) {
    case 'EUR':
      return String.fromCodePoint(EURO_SIGN_CODE);

    case 'USD':
      return String.fromCodePoint(DOLLAR_SIGN_CODE);

    default:
      return String.fromCodePoint(EURO_SIGN_CODE);
  }
};

export default getCurrencySign;
