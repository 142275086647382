import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';

import Stack from '@mui/material/Stack';

import { CANCEL_FTL, SUBMIT_FTL } from 'valtech-core/common/ftl';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import s from './BudgetingHeader.module.scss';

interface SubmitModalProps {
  title: string;
  text: string;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  submitText?: string;
}

const SubmitModal: FC<SubmitModalProps> = ({
  title,
  text,
  onSubmit,
  onCancel,
  submitText = SUBMIT_FTL,
}) => {
  const { l10n } = useLocalization();

  return (
    <>
      <Typography component='h2' variant='h5' className={s.submitBudget__title}>
        {title}
      </Typography>
      <Typography component='p'>{text}</Typography>
      <Stack marginTop={'48px'} color={`var(----text-color-main)`} direction='row'>
        <Button onClick={onSubmit} variant='contained' sx={{ mx: 1 }}>
          {l10n.getString(submitText)}
        </Button>
        <Button onClick={onCancel} type='button' color='primary'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </>
  );
};

export default SubmitModal;
