import { ReactLocalization } from '@fluent/react';

import {
  ACCESS_LEVEL_EDIT_AND_BILLING_FTL,
  ACCESS_LEVEL_EDIT_FTL,
  ACCESS_LEVEL_VIEW_AND_COMMENT_FTL,
} from 'valtech-core/common/ftl';
import { Maybe } from 'valtech-core/common/types';

interface IgetAccessLevelReturn {
  full: Maybe<boolean>;
  edit: Maybe<boolean>;
  viewAndComment: Maybe<boolean>;
}

const getAccessLevel = (
  accessLevel: Maybe<string>,
  l10n: ReactLocalization,
): IgetAccessLevelReturn => {
  const accessLevelObj: IgetAccessLevelReturn = {
    full: false,
    edit: false,
    viewAndComment: false,
  };

  switch (accessLevel) {
    case l10n.getString(ACCESS_LEVEL_VIEW_AND_COMMENT_FTL):
      accessLevelObj.viewAndComment = true;
      break;
    case l10n.getString(ACCESS_LEVEL_EDIT_FTL):
      accessLevelObj.edit = true;
      break;
    case l10n.getString(ACCESS_LEVEL_EDIT_AND_BILLING_FTL):
      accessLevelObj.full = true;
      break;
  }

  return accessLevelObj;
};

export default getAccessLevel;
