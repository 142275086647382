import { showGenericError } from 'common/apolloState/system';

import { useDeleteTimesheetByIdMutation } from 'valtech-core/common/gql/generated';

interface IDeleteTimesheet {
  timesheetId: number;
}

export const useDeleteTimesheetFile = (): ((timesheet: IDeleteTimesheet) => Promise<unknown>) => {
  const [deleteTimesheetFile] = useDeleteTimesheetByIdMutation();

  return (timesheet: IDeleteTimesheet): Promise<unknown> => {
    const { timesheetId } = timesheet;

    return deleteTimesheetFile({
      variables: {
        deleteTimesheetByIdId: Number(timesheetId),
      },
      refetchQueries: ['GetTimesheetList'],
    }).catch(() => {
      showGenericError();
    });
  };
};
