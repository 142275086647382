import React, { FC, useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { ConsultantModelEnum, Variant } from 'common/types';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import { useHandleAssignment } from 'components/_forms/AssignmentForm';
import { useHandleVacancy } from 'components/_forms/VacancyForm';
import AutocompleteField from 'form/AutocompleteField/AutocompleteField';
import DataPickerField from 'form/DataPickerField/DatePickerField';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';
import moment, { Moment } from 'moment/moment';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';

import {
  requiredStringValidator,
  validateNumber,
  validateRequiredNumber,
} from 'valtech-core/common/form/validators';
import { useGetRateForConsultantLazyQuery } from 'valtech-core/common/gql/generated';
import { AnyObject, BudgetingGroupsMultipleEnum, FieldControl } from 'valtech-core/common/types';
import { SelectedItem } from 'valtech-core/form/Select';

import { CUSTOM_TIER, getDefaultDate, getRateKey } from 'components/_forms/forms.utils';

import { EFormFieldName } from '../../form.types';
import s from '../CopyBudgetingRowsForm.module.scss';
import { copyRowSubmitStatusEnum } from './../CopyBudgetingRowsForm';

type CopyBudgetingRowsFormTableRecordProps = {
  record: AnyObject;
  group: BudgetingGroupsMultipleEnum;
  onDeleteSelectedRow: (id: number) => void;
  budgetingCopyInYear: number;
  holidayCalendarList: SelectedItem[];
  index: number;
  copyRowStatus?: copyRowSubmitStatusEnum | null;
  copyRowErrorMessage: string;
};

const CopyBudgetingRowsFormTableRecord: FC<CopyBudgetingRowsFormTableRecordProps> = ({
  record,
  group,
  onDeleteSelectedRow,
  budgetingCopyInYear,
  holidayCalendarList,
  index,
  copyRowStatus,
  copyRowErrorMessage,
}) => {
  const { control, watch, setValue } = useFormContext();
  const inputNamePrefix = group;

  const updatedRateModel = useWatch({
    control,
    name: `${inputNamePrefix}.${index}.${EFormFieldName.Model}`,
  });

  const [isRateDisabled, setRateDisabled] = useState<boolean>(true);
  const [selectedConsultantEntity, setSelectedConsultantEntity] = useState<string | undefined>('');
  const [selectedModel, setSelectedModel] = useState<ConsultantModelEnum>(
    !!record && record.model
      ? (record.model as ConsultantModelEnum)
      : ConsultantModelEnum.Dedicated,
  );

  const [getRateForConsultant] = useGetRateForConsultantLazyQuery();
  const { currency, budgetingCopyInSettingsId } = useBudgetingState();

  const { clientsList, consultantsList, rateList, modelList, rates } = useHandleAssignment({
    selectedConsultantEntity,
  });

  const { roleList } = useHandleVacancy();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (inputNamePrefix && value[inputNamePrefix]) {
        const hasModelChanged =
          name === `${inputNamePrefix}.${index}.${EFormFieldName.Model}` && type === 'change';
        const newSelectedModel = value[inputNamePrefix][index]?.model;
        const hasConsultantChanged =
          name === `${inputNamePrefix}.${index}.${EFormFieldName.Consultant}` && type === 'change';
        const selectedConsultant = value[inputNamePrefix][index]?.consultant;
        const hasRateChanged = name === `${inputNamePrefix}.${index}.${EFormFieldName.Rate}`;
        const hasStartDateChanged =
          name === `${inputNamePrefix}.${index}.${EFormFieldName.StartDate}` && type === 'change';
        const selectedRate = value[inputNamePrefix][index]?.rate;
        const selectedStartDate = value[inputNamePrefix][index]?.startDate;

        if (hasConsultantChanged && selectedConsultant) {
          setSelectedConsultantEntity(selectedConsultant.entity);
        }

        if (hasModelChanged && newSelectedModel) {
          setSelectedModel(newSelectedModel as ConsultantModelEnum);
        }

        const rateLabel = rates[getRateKey(newSelectedModel ?? selectedModel)]?.find(
          rate => rate.id === Number(selectedRate),
        )?.label;

        const pickRateBasedOnModel = (model: ConsultantModelEnum): void => {
          let rateMonth = Number(
            selectedStartDate
              ? moment(selectedStartDate as Moment).month() + 1
              : record.startDate?.month() + 1,
          );

          if (rateMonth < 1 || rateMonth > 12) rateMonth = 1;

          getRateForConsultant({
            variables: {
              projectCcy: currency,
              budgetingSettingId: Number(budgetingCopyInSettingsId),
              consultantId: Number(selectedConsultant?.id),
              costOfOverhead: record.costOfOverhead,
              numberOfMonth: rateMonth,
              rateModel: model,
            },
          }).then(res => {
            const consultantRateRes = res.data?.getRateForConsultant;

            if (consultantRateRes) {
              setValue(
                `${inputNamePrefix}.${index}.${EFormFieldName.Rate}`,
                `${consultantRateRes.id}`,
              );
            }
          });
        };

        const hasFormChanged = hasConsultantChanged || hasModelChanged || hasStartDateChanged;
        const hasAllRequiredValues = selectedConsultant?.id && newSelectedModel;

        if (hasFormChanged && hasAllRequiredValues) {
          pickRateBasedOnModel(newSelectedModel as ConsultantModelEnum);
        }

        if (hasRateChanged && selectedRate) {
          if (rateLabel === CUSTOM_TIER) {
            setRateDisabled(false);
          } else if (rateLabel !== CUSTOM_TIER && !isRateDisabled) {
            setRateDisabled(true);
          }

          setValue(
            `${inputNamePrefix}.${index}.${EFormFieldName.Fee}`,
            rates[getRateKey(newSelectedModel)]?.find(rate => rate.id === Number(selectedRate))
              ?.fee || 0,
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, rateList, rates, isRateDisabled, consultantsList]);

  function fieldWatcher(filed) {
    const fieldValue = watch(filed);
    return fieldValue;
  }

  useEffect(() => {
    if (
      !selectedConsultantEntity &&
      group === BudgetingGroupsMultipleEnum.Assignments &&
      consultantsList.length &&
      record.consultant?.businessUnit
    ) {
      setSelectedConsultantEntity(record.consultant.businessUnit);
    }
  }, [record, consultantsList]);

  useEffect(() => {
    if (budgetingCopyInYear && record) {
      setValue(
        `${inputNamePrefix}.${index}.${EFormFieldName.StartDate}`,
        moment({ day: 1, month: 0, year: budgetingCopyInYear }),
      );

      if (group === BudgetingGroupsMultipleEnum.Assignments) {
        getRateForConsultant({
          variables: {
            projectCcy: currency,
            budgetingSettingId: Number(budgetingCopyInSettingsId),
            consultantId: Number(record.consultant?.id),
            costOfOverhead: record.costOfOverhead,
            numberOfMonth: 1,
            rateModel: updatedRateModel,
          },
        }).then(res => {
          const consultantRateRes = res.data?.getRateForConsultant;
          if (consultantRateRes) {
            setTimeout(() => {
              setValue(
                `${inputNamePrefix}.${index}.${EFormFieldName.Rate}`,
                `${consultantRateRes.id}`,
              );
              setValue(
                `${inputNamePrefix}.${index}.${EFormFieldName.Fee}`,
                rates[getRateKey(selectedModel)].find(
                  rate => rate.id === Number(consultantRateRes.id),
                )?.fee || 0,
              );
              consultantRateRes.title === CUSTOM_TIER && setRateDisabled(false);
            }, 100);
          }
        });
      }
    }
  }, [record, budgetingCopyInYear, rates]);

  useEffect(() => {
    if (group === BudgetingGroupsMultipleEnum.Vacancies) {
      setValue(
        `${inputNamePrefix}.${index}.${EFormFieldName.Rate}`,
        rates[getRateKey(selectedModel)]?.find(rate => rate.label === CUSTOM_TIER)?.id,
      );
    }
  }, [selectedModel, rates]);

  useEffect(() => {
    if (
      (group === BudgetingGroupsMultipleEnum.Assignments ||
        group === BudgetingGroupsMultipleEnum.Vacancies) &&
      holidayCalendarList.length &&
      record
    ) {
      let newHolidayCalendarValue = '';

      if (group === BudgetingGroupsMultipleEnum.Assignments) {
        const holidayCalendarForConsultant = holidayCalendarList.find(
          h => h.title === record.consultant?.businessUnit,
        );
        holidayCalendarForConsultant &&
          (newHolidayCalendarValue = holidayCalendarForConsultant.value);
      }

      !newHolidayCalendarValue && (newHolidayCalendarValue = holidayCalendarList[0]?.value);

      newHolidayCalendarValue &&
        setValue(
          `${inputNamePrefix}.${index}.${EFormFieldName.HolidayCalendarId}`,
          newHolidayCalendarValue,
        );
    }
  }, [holidayCalendarList, record]);

  return (
    <TableRow className={copyRowStatus === copyRowSubmitStatusEnum.Success ? s.rowSubmitted : null}>
      {group === BudgetingGroupsMultipleEnum.Expenses && (
        <TableCell>
          <TextField
            control={control as unknown as FieldControl}
            defaultValue={record.title}
            name={`${inputNamePrefix}.${index}.${EFormFieldName.Title}`}
            validate={requiredStringValidator}
            variant={Variant.Standard}
          />
        </TableCell>
      )}

      <TableCell>
        <SelectFiled
          control={control as unknown as FieldControl}
          data={clientsList}
          defaultValue={record.clientId?.toString()}
          name={`${inputNamePrefix}.${index}.${EFormFieldName.ClientId}`}
          validate={requiredStringValidator}
          variant={Variant.Standard}
        />
      </TableCell>

      {group === BudgetingGroupsMultipleEnum.Assignments && (
        <TableCell>
          <AutocompleteField
            control={control as unknown as FieldControl}
            data={consultantsList}
            defaultValue={record.consultant}
            name={`${inputNamePrefix}.${index}.${EFormFieldName.Consultant}`}
            validate={value => requiredStringValidator(value.label)}
            variant={Variant.Standard}
            className={s.consultantNameCol}
          />
        </TableCell>
      )}

      {group === BudgetingGroupsMultipleEnum.Vacancies && (
        <TableCell>
          <SelectFiled
            control={control as unknown as FieldControl}
            data={roleList}
            defaultValue={record.role}
            name={`${inputNamePrefix}.${index}.${EFormFieldName.Role}`}
            validate={requiredStringValidator}
            variant={Variant.Standard}
          />
        </TableCell>
      )}

      {(group === BudgetingGroupsMultipleEnum.Assignments ||
        group === BudgetingGroupsMultipleEnum.Vacancies) && (
        <>
          <TableCell>
            <SelectFiled
              control={control as unknown as FieldControl}
              data={modelList}
              defaultValue={record.model}
              name={`${inputNamePrefix}.${index}.${EFormFieldName.Model}`}
              validate={requiredStringValidator}
              variant={Variant.Standard}
            />
          </TableCell>

          <TableCell>
            <SelectFiled
              control={control as unknown as FieldControl}
              data={holidayCalendarList}
              name={`${inputNamePrefix}.${index}.${EFormFieldName.HolidayCalendarId}`}
              validate={requiredStringValidator}
              variant={Variant.Standard}
            />
          </TableCell>
        </>
      )}

      {group === BudgetingGroupsMultipleEnum.Expenses && (
        <TableCell>
          <TextField
            control={control}
            defaultValue={record.cost}
            name={`${inputNamePrefix}.${index}.${EFormFieldName.Cost}`}
            textProps={{
              type: 'number',
            }}
            validate={validateRequiredNumber}
            variant={Variant.Standard}
          />
        </TableCell>
      )}

      <TableCell>
        <DataPickerField
          control={control as unknown as FieldControl}
          className={s.datePicker}
          defaultValue={record.startDate}
          name={`${inputNamePrefix}.${index}.${EFormFieldName.StartDate}`}
          minDate={getCalendarRangeByYear(budgetingCopyInYear).firstDayOfYear}
          maxDate={getCalendarRangeByYear(budgetingCopyInYear).lastDayOfYear}
          validate={requiredStringValidator}
        />
      </TableCell>

      <TableCell>
        <DataPickerField
          control={control as unknown as FieldControl}
          className={s.datePicker}
          defaultCalendarMonth={getDefaultDate(budgetingCopyInYear)}
          name={`${inputNamePrefix}.${index}.${EFormFieldName.EndDate}`}
          minDate={
            fieldWatcher(`${inputNamePrefix}.${index}.${EFormFieldName.StartDate}`) as Moment
          }
          maxDate={getCalendarRangeByYear(budgetingCopyInYear).lastDayOfYear}
          validate={requiredStringValidator}
        />
      </TableCell>

      {(group === BudgetingGroupsMultipleEnum.Assignments ||
        group === BudgetingGroupsMultipleEnum.Vacancies) && (
        <>
          <TableCell>
            <TextField
              control={control}
              defaultValue={
                selectedModel === ConsultantModelEnum.Dedicated
                  ? record.allocation?.toString()
                  : record.hours?.toString()
              }
              name={
                selectedModel === ConsultantModelEnum.Dedicated
                  ? `${inputNamePrefix}.${index}.${EFormFieldName.Allocation}`
                  : `${inputNamePrefix}.${index}.${EFormFieldName.Hours}`
              }
              textProps={{
                type: 'number',
              }}
              validate={validateRequiredNumber}
              variant={Variant.Standard}
              className={s.allocHoursCol}
            />
          </TableCell>

          <TableCell>
            <SelectFiled
              control={control as unknown as FieldControl}
              data={rateList[getRateKey(selectedModel)]}
              name={`${inputNamePrefix}.${index}.${EFormFieldName.Rate}`}
              validate={requiredStringValidator}
              variant={Variant.Standard}
            />
          </TableCell>

          <TableCell>
            <TextField
              className={s.feeCol}
              control={control as unknown as FieldControl}
              defaultValue={record.fee.toString()}
              disabled={isRateDisabled}
              name={`${inputNamePrefix}.${index}.${EFormFieldName.Fee}`}
              textProps={{
                type: 'number',
                disabled: !fieldWatcher(`${inputNamePrefix}.${index}.${EFormFieldName.Rate}`),
              }}
              validate={validateNumber}
              variant={Variant.Standard}
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <TextField
          control={control as unknown as FieldControl}
          defaultValue={record.notes}
          name={`${inputNamePrefix}.${index}.${EFormFieldName.Notes}`}
          variant={Variant.Standard}
        />
      </TableCell>
      <TableCell>
        <Stack direction='row' alignItems='center'>
          <IconButton aria-label='delete row' onClick={() => onDeleteSelectedRow(index)}>
            <DeleteIcon />
          </IconButton>
          {copyRowStatus === copyRowSubmitStatusEnum.Success && <CheckCircleIcon color='success' />}
          {copyRowStatus === copyRowSubmitStatusEnum.Error && (
            <Tooltip title={copyRowErrorMessage} placement='top' arrow>
              <ErrorIcon color='error' />
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default CopyBudgetingRowsFormTableRecord;
