import React, { FC } from 'react';

import TimesheetsUpload from 'src/pages/TimesheetSettings/TimesheetsUpload/TimesheetsUpload';

import TimesheetsettingsTable from './TimesheetSettingsTable/TimesheetSettingsTable';
import { useTimesheet } from './hooks/Timesheet.hook';

const TimesheetSettings: FC = () => {
  const timesheetHookData = useTimesheet();
  return (
    <>
      <TimesheetsUpload />
      <br />
      <TimesheetsettingsTable {...timesheetHookData} />
    </>
  );
};

export default TimesheetSettings;
