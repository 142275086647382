import { AnySchema, bool, number, string } from 'yup';

import moment from 'moment';

import { DateMask, Maybe } from '../types';
import { INPUT_MIN_LENGTH, INPUT_REGEXP, INPUT_SMALL_LENGTH, NUMBER_REGEXP } from './const';

interface IValidationErrors {
  required?: string;
  longStr?: string;
  shortStr?: string;
  invalid?: string;
  email?: string;
  min?: string;
  regexp?: RegExp;
  format?: DateMask;
  limit?: number;
}

type ISchemaValidator<T> = (message: IValidationErrors) => AnySchema<T | undefined>;

export const requiredBooleanSchema: ISchemaValidator<boolean> = ({ required }) =>
  bool().oneOf([true], required);

export const requiredStringSchema: ISchemaValidator<string> = ({ required }) =>
  string().required(required);

export const shortStrSchema: ISchemaValidator<string> = ({ shortStr }) =>
  string().min(INPUT_MIN_LENGTH, shortStr);

export const longStrSchema: ISchemaValidator<string> = ({ longStr, limit = INPUT_SMALL_LENGTH }) =>
  string().max(limit, longStr);

export const requiredNumberSchema: ISchemaValidator<string> = ({
  required,
  regexp = NUMBER_REGEXP,
  invalid,
}) => string().required(required).matches(regexp, invalid);

export const emailSchema: ISchemaValidator<string> = ({ email }) =>
  string().required(email).email(email);

export const validInputSymbolsStrSchema: ISchemaValidator<string> = ({
  invalid,
  regexp = INPUT_REGEXP,
}) =>
  string()
    .transform(str => (str === null ? '' : str))
    .matches(regexp, invalid);

export const validInputSymbolsDateObjectSchema: ISchemaValidator<string> = ({
  invalid,
  regexp = INPUT_REGEXP,
  format,
}) =>
  string()
    .transform(str => (str === null ? '' : str))
    // convert momentJs object to str with the right format
    .transform(str => moment(str).format(format))
    .matches(regexp, invalid);

export const validNumberStrSchema: ISchemaValidator<string> = ({
  invalid,
  regexp = NUMBER_REGEXP,
}) => string().matches(regexp, invalid);

export function validate<T>(schema: AnySchema<Maybe<T>>) {
  return async (value: T): Promise<Maybe<string>> => {
    try {
      await schema.validate(value, { abortEarly: true });
    } catch (err) {
      //eslint-disable-next-line
      //@ts-ignore
      return err.message;
    }
  };
}
