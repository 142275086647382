import React, { FC } from 'react';

import { StyledEngineProvider } from '@mui/material/styles';

interface IProps {
  children?: React.ReactNode;
}

const MuiCssProvider: FC<IProps> = ({ children }) => {
  return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
};

export default MuiCssProvider;
