export const ASSIGNMENT_FTL = 'budgeting-name-form-assignment';
export const EXPENSE_FTL = 'budgeting-name-form-expense';
export const VACANCY_FTL = 'budgeting-name-form-vacancy';
export const SUBMIT_BUDGET_FTL = 'submit-budget';
export const UNDO_SUBMIT = 'undo-submit';
export const CREATE_FORECAST_FTL = 'create-forecast';
export const SUBMIT_BUDGET_MESSAGE_FTL = 'submit-budget-message';
export const OPERATIONAL_FORECAST_FTL = 'operational-forecast';
export const OPERATIONAL_FORECAST_CAMEL_CASE_FTL = 'operational-forecast-camel-case';
export const COPY_BTN_FTL = 'copy-btn-title';
