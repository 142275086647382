import { WORKING_HOURS_PER_DAY } from 'common/constants';
import { ConsultantModelEnum } from 'common/types';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { IOvertime } from 'pages/SingleBilling/SingleBilling.types';

import { Overtime } from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

export type BillingHistoryItem = {
  id: number;
  financial_year: number;
  title: string;
  total?: number | undefined;
  month: number;
};

type CountSingleBillingTotalProps = {
  rate: number | null;
  model: string | undefined;
  hours: number | null;
  workingHoursPerMonth: number | undefined;
};

export const countSingleBillingTotal = (props: CountSingleBillingTotalProps): number | null => {
  const { rate, model, hours, workingHoursPerMonth } = props;
  if (!isNil(hours) && rate && workingHoursPerMonth) {
    const _rate = Number(rate);
    switch (model) {
      case ConsultantModelEnum.Dedicated:
        return round((hours / workingHoursPerMonth) * _rate, 6) ?? null;

      case ConsultantModelEnum.TimeAndMaterial:
        return round(_rate * hours, 6);

      default:
        return null;
    }
  } else return null;
};

export const getAssignmentOvertimesInfo = (
  assignmentId: number,
  resOvertimeInfo: Overtime[],
): [IOvertime[], number, number] => {
  const selectedResOvertimes = resOvertimeInfo.filter(
    o => o.billing_report_row_id === assignmentId,
  );
  let overtimesTotal = 0;
  let overtimesHours = 0;

  const overtimes: IOvertime[] = selectedResOvertimes.map(o => {
    overtimesTotal += o.total || 0;
    overtimesHours += o.type === 'bill' ? o.qty * o.coefficient : 0;

    return {
      hours: o.qty,
      multiplier: o.coefficient,
      type: o.type,
      comment: o.note,
      billing_report_row_id: o.billing_report_row_id,
      overtimeId: o.id,
      total: o.total,
    };
  });

  return [overtimes, overtimesTotal, overtimesHours];
};

export const getWorkingHoursPerMonth = (
  totalWorkingDays: { workingDays: number }[] | [],
  currentMonthNumber: Maybe<number>,
): Maybe<number> => {
  if (totalWorkingDays && currentMonthNumber) {
    const workingDaysPerMonth = totalWorkingDays?.[currentMonthNumber - 1]?.workingDays;
    return WORKING_HOURS_PER_DAY * workingDaysPerMonth;
  }
};
