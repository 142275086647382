import { useCallback } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import isNull from 'lodash/isNull';
import { MonthAllocation } from 'src/common/types';

import { useUpdateAllocationByIdMutation } from 'valtech-core/common/gql/generated';

import { calculateRate } from '../Budgeting.utils';

type UseUpdateAllocationByMonthReturn = {
  updateSingleAllocation: (allocation: Omit<MonthAllocation, 'isPartial'>) => void;
};

export const useUpdateAllocationByMonth = (): UseUpdateAllocationByMonthReturn => {
  const [updateAllocation] = useUpdateAllocationByIdMutation();
  const { financialYear, projectId } = useBudgetingState();

  const updateSingleAllocation = useCallback(
    (allocation: Omit<MonthAllocation, 'isPartial'>) => {
      const { assignementId, allocationId, value, monthIndex, rateValue } = allocation;

      if (!isNull(value) && rateValue && financialYear && projectId) {
        const allocationForecast = Number(value.toFixed(2));
        const cost = calculateRate(value, rateValue);

        updateAllocation({
          variables: {
            assignementId,
            allocationId,
            financialYear,
            projectId,
            cost,
            allocationForecast,
            month: monthIndex,
          },
          refetchQueries: ['getSingleBudgeting'],
        });
      }
    },
    [financialYear, projectId],
  );

  return {
    updateSingleAllocation,
  };
};
