import { HolidayFields } from '../Holiday.constants';

type defaultValuesType = {
  [HolidayFields.Title]: string;
  [HolidayFields.Date]: null | string;
};

export const defaultValues: defaultValuesType = {
  [HolidayFields.Title]: '',
  [HolidayFields.Date]: null,
};
