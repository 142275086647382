import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';

import * as routes from 'valtech-core/common/routes';

export function parseUrl(url: string, appTitle: string): string {
  if (!url || url?.includes(routes.LOGIN_ROUTE) || url?.includes(routes.LOGOUT_ROUTE))
    return appTitle;

  const pathsArray = url.split('/').filter(part => part !== '' && !Number(part));

  if (_isEmpty(pathsArray)) return appTitle;

  const updatedPathsArray = pathsArray.map(path => _startCase(path));

  return updatedPathsArray.splice(0, 0, appTitle) && updatedPathsArray.join(' - ');
}
