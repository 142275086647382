import React, { FC, Fragment } from 'react';

import cn from 'classnames';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { CurrencyEnum } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import NA from 'valtech-core/ui/NA';
import Typography from 'valtech-core/ui/Typography';

import { getMonthlyTotalPerEntity } from '../../Budgeting.utils';

import { AnnualTotalPerEntity, InvoicedTotal, MonthlyTotalPerEntity } from '../../Budgeting.types';
import styles from '../EditBudgetingTable.module.scss';

interface TableRecordsFooterProps {
  entities: string[];
  invoicedData: InvoicedTotal;
  monthlyTotalPerEntity: MonthlyTotalPerEntity;
  annualTotalPerEntity: AnnualTotalPerEntity;
  currency: CurrencyEnum;
}

const TableRecordsFooter: FC<TableRecordsFooterProps> = ({
  entities,
  invoicedData,
  monthlyTotalPerEntity,
  annualTotalPerEntity,
  currency,
}) => {
  return (
    <>
      {entities.map(entity => (
        <Fragment key={entity}>
          <TableRow className={cn(styles.stickyBottom, styles.footerAccordionTableRecords)}>
            <TableCell
              component='th'
              scope='row'
              className={cn(styles.stickyLeft, styles.stickyCell, styles.colored)}>
              <Typography variant='body2' transform='uppercase' weight='medium'>
                {entity}
              </Typography>
            </TableCell>
            {invoicedData?.months?.map(({ monthIndex, cost }) => (
              <TableCell
                key={`${monthIndex + cost} ${entity}`}
                align='right'
                className={cn(styles.colored, styles.footerColored)}>
                <NA
                  value={createNumberWithCurrency({
                    number: getMonthlyTotalPerEntity(monthlyTotalPerEntity, monthIndex, entity),
                    currencyDisplay: 'symbol',
                    currency,
                  })}
                />
              </TableCell>
            ))}
            <TableCell
              className={cn(styles.stickyCell, styles.totalCell, styles.stickToRight)}
              align='right'>
              {createNumberWithCurrency({
                number: annualTotalPerEntity[entity] || 0,
                currencyDisplay: 'symbol',
                currency,
              })}
            </TableCell>
          </TableRow>
        </Fragment>
      ))}
    </>
  );
};

export default TableRecordsFooter;
