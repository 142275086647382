import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import { useAuthState } from 'common/apolloState/auth';
import { showSystemError } from 'common/apolloState/system';

import { getLogoutUrl } from 'valtech-core/common/geLogoutUrl';
import { useLogoutMutation } from 'valtech-core/common/gql/generated';
import { HOME_ROUTE } from 'valtech-core/common/routes';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import AuthStorage from 'valtech-core/services/auth';
import { XKILLER_LAST_VISITED_URL_KEY } from 'valtech-core/services/auth/storage';

const Logout: React.FC = () => {
  const { setAuth } = useAuthState();
  const { userInfo } = useAuthState();

  const [logoutMutation] = useLogoutMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  useEffect(() => {
    userInfo?.email &&
      logoutMutation({
        variables: { email: userInfo.email },
      });
    AuthStorage.clear();
    AuthStorage.removeItem(XKILLER_LAST_VISITED_URL_KEY);
    setAuth(false);
    window.location.href = getLogoutUrl();
  }, []);

  return <Navigate to={HOME_ROUTE} />;
};

export default Logout;
