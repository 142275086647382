import React, { FC } from 'react';

import { Maybe } from 'valtech-core/common/types';
import Typography from 'valtech-core/ui/Typography';

import s from './TableFooter.module.scss';

interface IEditBillingTableFooterProps {
  title: string;
  total: Maybe<string>;
}

const TableFooter: FC<IEditBillingTableFooterProps> = ({ title, total }) => {
  return (
    <div className={s.wrapper}>
      <Typography weight='bold' component={'p'} className={s.text}>
        {title}
      </Typography>
      <Typography weight='bold' component={'p'} className={s.text}>
        {total}
      </Typography>
    </div>
  );
};

export default TableFooter;
