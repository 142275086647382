import React, { FC, useMemo } from 'react';

import { useLocalization } from '@fluent/react';
import { EFormFieldName } from 'src/components/_forms/form.types';
import AutocompleteField from 'src/form/AutocompleteField';
import { AutocompleteValue } from 'src/form/AutocompleteField/AutocompleteField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import { DELIVERY_MANAGER_FTL } from 'valtech-core/common/ftl';
import { AnyArray, FieldControl } from 'valtech-core/common/types';

interface IDeliveryManagerProps {
  control: FieldControl;
  allDeliveryManagers: AnyArray | undefined;
}

const DeliveryManager: FC<IDeliveryManagerProps> = ({ control, allDeliveryManagers }) => {
  const { l10n } = useLocalization();

  const deliveryManagers = useMemo((): AutocompleteValue[] => {
    if (!allDeliveryManagers?.length) return [];

    return allDeliveryManagers.map(manager => {
      return {
        label: manager.title,
        id: manager.id,
      };
    });
  }, [allDeliveryManagers]);

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(DELIVERY_MANAGER_FTL)}</Box>
      </Grid>

      <Grid item xs={6}>
        {!!deliveryManagers && (
          <AutocompleteField
            label={l10n.getString(DELIVERY_MANAGER_FTL)}
            name={EFormFieldName.DeliveryManagerId}
            data={deliveryManagers}
            control={control as unknown as FieldControl}
            validate={value => requiredStringValidator(value.label)}
            disabled={!deliveryManagers.length}
          />
        )}
      </Grid>
    </>
  );
};

export default DeliveryManager;
