import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import { useBillingState } from 'src/pages/SingleBilling/SingleBillingContextProvider';

import { TableContainer, TableFooter } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PERIOD_FTL, TOTAL_FTL } from 'valtech-core/common/ftl';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';

import { useBillingHistory } from './BillingHistory.hook';
import s from './BillingHistory.module.scss';

const BillingHistory: FC = () => {
  const { l10n } = useLocalization();
  const { billingHistoryList, allBillingsTotal } = useBillingHistory();
  const {
    state: { billingInfo },
  } = useBillingState();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={s.tableHead}>
            <TableCell>{l10n.getString(PERIOD_FTL)}</TableCell>
            <TableCell sx={{ textAlign: 'right' }}>{l10n.getString(TOTAL_FTL)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billingHistoryList?.map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell sx={{ textTransform: 'capitalize' }}>{row.billingsTitle}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {createNumberWithCurrency({ number: row.total, currency: billingInfo?.currency })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell
              sx={{
                textAlign: 'right',
                fontSize: 14,
                fontWeight: 500,
                color: '#000',
                letterSpacing: 1.25,
              }}>
              {createNumberWithCurrency({
                number: allBillingsTotal,
                currency: billingInfo?.currency,
              })}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BillingHistory;
