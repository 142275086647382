import React, { FC, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { ReactLocalization } from '@fluent/react';
import cn from 'classnames';
import {
  InvoiceCreateCheck,
  InvoicedTotal,
  TotalByEntities,
  TotalCosts,
} from 'pages/Budgeting/Budgeting.types';
import { useBudgetingState } from 'src/common/apolloState/budgeting';
import { showSystemError } from 'src/common/apolloState/system';

import { Table, TableBody } from '@mui/material';
import MuiLink from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';

import {
  ERROR_NO_ASSIGNMENTS_IN_BUDGETING,
  INVOICED_TOTAL_BY_ENTITIES_FLT,
} from 'valtech-core/common/ftl';
import { BillingStatus, BudgetType } from 'valtech-core/common/gql/generated';
import { BILLING_ROUTE } from 'valtech-core/common/routes';
import { CurrencyEnum } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import formatGrossMarginWithPercentage from 'valtech-core/common/utils/createNumberWithPercentage';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import Accordion from 'valtech-core/ui/Accordion';
import Button from 'valtech-core/ui/Button';
import NA from 'valtech-core/ui/NA';
import Typography from 'valtech-core/ui/Typography';

import { useSingleBudgeting } from '../../Budgeting.hooks';
import { useCreateBilling } from './BudgetingTableFooter.hooks';

import { COL_SPAN } from '../../Budgeting.utils';

import {
  ACTUAL_GM,
  FORECASTED_GM,
  FORECASTED_TOTAL_BY_ENTITIES_FTL,
  TOTAL_FORECASTED_FTL,
  TOTAL_INVOICED_FTL,
} from '../EditBudgetingTable.ftl';
import styles from '../EditBudgetingTable.module.scss';
import TableRecordsFooter from './TableRecordsFooter';

interface BudgetingTableFooterProps {
  l10n: ReactLocalization;
  forecastedData: TotalCosts;
  invoicedData: InvoicedTotal;
  currency: CurrencyEnum;
  accessLevel?: string;
  consultantsSectionTotals: TotalCosts;
  invoicedTotalsByEntities: TotalByEntities;
  forecastedTotalsByEntities: TotalByEntities;
}

const BudgetingTableFooter: FC<BudgetingTableFooterProps> = ({
  l10n,
  forecastedData,
  invoicedData,
  currency,
  accessLevel,
  consultantsSectionTotals,
  invoicedTotalsByEntities,
  forecastedTotalsByEntities,
}) => {
  const { forecastType } = useBudgetingState();
  const { createBilling } = useCreateBilling();
  const { actualGrossMargin, forecastGrossMargin } = useSingleBudgeting();

  const { entities, annualTotalPerEntity, monthlyTotalPerEntity } = invoicedTotalsByEntities;
  const {
    entities: forecastedEntities,
    annualTotalPerEntity: forecastedAnnualTotalPerEntity,
    monthlyTotalPerEntity: forecastedMonthlyTotalPerEntity,
  } = forecastedTotalsByEntities;

  const userAccessLevel = getAccessLevel(accessLevel, l10n);
  const isOperationForecast = forecastType?.type === BudgetType.OperationalForecast;

  const {
    grossMarginByMonths: averageForecastedGrossMargin,
    totalAverageGrossMarginPerYear: totalAverageForecastedGrossMargin,
  } = forecastGrossMargin;

  const {
    grossMarginByMonths: averageActualGrossMargin,
    totalAverageGrossMarginPerYear: totalAverageActualGrossMargin,
  } = actualGrossMargin;

  const onInvoiceCreateCheck = useCallback(
    (data: InvoiceCreateCheck) => {
      const { consultantsSectionTotals, index, invoiceData } = data;
      if (
        invoiceData?.monthIndex === consultantsSectionTotals?.months[index]?.monthIndex &&
        !!consultantsSectionTotals?.months[index]?.monthIndex &&
        !consultantsSectionTotals?.months[index]?.cost
      ) {
        showSystemError(ERROR_NO_ASSIGNMENTS_IN_BUDGETING);

        return;
      }

      createBilling(invoiceData);
    },
    [consultantsSectionTotals, invoicedData],
  );

  return (
    <TableFooter>
      <TableRow
        className={cn(styles.footer, {
          [styles.stickyRowSecond]: !isOperationForecast,
        })}>
        <TableCell
          component='th'
          scope='row'
          className={cn(
            styles.stickyCell,
            styles.overlapped,
            styles.stickyBottom,
            styles.stickyLeft,
            styles.shadow,
          )}>
          <Typography variant='body2' transform='uppercase' weight='medium'>
            {l10n.getString(TOTAL_FORECASTED_FTL)}
          </Typography>
        </TableCell>
        {forecastedData.months.map(({ monthIndex, cost }) => (
          <TableCell
            key={`${monthIndex}::${cost}`}
            className={cn(styles.stickyCell, styles.stickyBottom, styles.shadow)}
            align='right'>
            <NA
              value={createNumberWithCurrency({
                number: cost,
                currencyDisplay: 'symbol',
                currency,
              })}
            />
          </TableCell>
        ))}
        <TableCell
          className={cn(styles.stickyCell, styles.stickyBottom, styles.stickToRight, styles.shadow)}
          align='right'>
          {createNumberWithCurrency({
            number: forecastedData.total,
            currencyDisplay: 'symbol',
            currency,
          })}
        </TableCell>
      </TableRow>

      {averageForecastedGrossMargin &&
        !!forecastedEntities.length &&
        forecastedAnnualTotalPerEntity && (
          <TableRow>
            <TableCell colSpan={COL_SPAN} padding='none' className={styles.accordionTableFooter}>
              <Accordion
                title={l10n.getString(FORECASTED_TOTAL_BY_ENTITIES_FTL)}
                accordionProps={{ className: `${styles.accordion}` }}
                isOpened={false}>
                <Table className={cn(styles.table, styles.innerTable, styles.innerTableFooter)}>
                  <TableBody className={styles.footerTableRecords}>
                    <TableRecordsFooter
                      invoicedData={invoicedData}
                      entities={forecastedEntities}
                      monthlyTotalPerEntity={forecastedMonthlyTotalPerEntity}
                      annualTotalPerEntity={forecastedAnnualTotalPerEntity}
                      currency={currency}
                    />
                  </TableBody>
                </Table>
              </Accordion>
            </TableCell>
          </TableRow>
        )}

      {!!averageForecastedGrossMargin.length && (
        <TableRow className={cn(styles.footer)}>
          <TableCell
            component='th'
            scope='row'
            className={cn(
              styles.stickyCell,
              styles.overlapped,
              styles.stickyBottom,
              styles.stickyLeft,
              styles.shadow,
            )}>
            <Typography variant='body2' transform='uppercase' weight='medium'>
              {l10n.getString(FORECASTED_GM)}
            </Typography>
          </TableCell>
          {averageForecastedGrossMargin.map(({ month, averageGrossMarginPerMonth }) => (
            <TableCell
              key={`${month}::${averageGrossMarginPerMonth}`}
              className={cn(styles.stickyCell, styles.stickyBottom, styles.shadow)}
              align='right'>
              {formatGrossMarginWithPercentage({
                number: averageGrossMarginPerMonth,
              })}
            </TableCell>
          ))}
          <TableCell
            className={cn(
              styles.stickyCell,
              styles.stickyBottom,
              styles.stickToRight,
              styles.shadow,
            )}
            align='right'>
            {formatGrossMarginWithPercentage({
              number: totalAverageForecastedGrossMargin,
            })}
          </TableCell>
        </TableRow>
      )}

      {isOperationForecast && (
        <TableRow className={styles.footer}>
          <TableCell
            component='th'
            scope='row'
            className={cn(
              styles.stickyCell,
              styles.overlapped,
              styles.stickyBottom,
              styles.stickyLeft,
              styles.shadow,
            )}>
            <Typography variant='body2' transform='uppercase' weight='medium'>
              {l10n.getString(ACTUAL_GM)}
            </Typography>
          </TableCell>

          {averageActualGrossMargin.map(({ averageGrossMarginPerMonth, month }) => (
            <TableCell
              key={`${month}::${averageGrossMarginPerMonth}`}
              className={cn(styles.stickyCell, styles.stickyBottom, styles.shadow)}
              align='right'>
              {formatGrossMarginWithPercentage({
                number: averageGrossMarginPerMonth,
              })}
            </TableCell>
          ))}
          <TableCell
            className={cn(
              styles.stickyCell,
              styles.stickyBottom,
              styles.stickToRight,
              styles.shadow,
            )}
            align='right'>
            {formatGrossMarginWithPercentage({
              number: totalAverageActualGrossMargin,
            })}
          </TableCell>
        </TableRow>
      )}

      {isOperationForecast && (
        <TableRow className={cn(styles.footer, styles.stickyBottom)}>
          <TableCell
            component='th'
            scope='row'
            className={cn(
              styles.stickyCell,
              styles.overlapped,
              styles.stickyBottom,
              styles.stickyLeft,
              styles.shadow,
            )}>
            <Typography variant='body2' transform='uppercase' weight='medium'>
              {l10n.getString(TOTAL_INVOICED_FTL)}
            </Typography>
          </TableCell>
          {invoicedData.months.map(
            ({ cost, monthIndex, status, projectId, userId, hasZeroIndex, billingId }, index) => (
              <TableCell
                key={monthIndex + cost}
                className={cn(
                  styles.stickyCell,
                  styles.stickyBottom,
                  styles.invoicedCell,
                  styles.shadow,
                  {
                    [styles.textStatusDone]: status === BillingStatus.Invoiced,
                    [styles.textStatusDraft]: status === BillingStatus.Draft,
                    [styles.buttonCell]: !status,
                  },
                )}
                align='right'>
                {status ? (
                  <MuiLink
                    component={Link}
                    to={BILLING_ROUTE.replace(':id', `${billingId}`)}
                    color='inherit'
                    underline='none'>
                    <NA
                      value={createNumberWithCurrency({
                        number: cost,
                        currencyDisplay: 'symbol',
                        currency,
                      })}
                    />
                  </MuiLink>
                ) : userAccessLevel.full &&
                  forecastType?.type === BudgetType.OperationalForecast ? (
                  <Button
                    variant='text'
                    onClick={() => {
                      const invoiceData = { monthIndex, projectId, userId, hasZeroIndex };

                      onInvoiceCreateCheck({ consultantsSectionTotals, index, invoiceData });
                    }}>
                    {l10n.getString('create')}
                  </Button>
                ) : null}
              </TableCell>
            ),
          )}
          <TableCell
            className={cn(
              styles.stickyCell,
              styles.stickyBottom,
              styles.stickToRight,
              styles.shadow,
            )}
            align='right'>
            {createNumberWithCurrency({
              number: invoicedData.total,
              currencyDisplay: 'symbol',
              currency,
            })}
          </TableCell>
        </TableRow>
      )}

      {isOperationForecast && !!entities.length && annualTotalPerEntity && (
        <TableRow>
          <TableCell colSpan={COL_SPAN} padding='none' className={styles.accordionTableFooter}>
            <Accordion
              title={l10n.getString(INVOICED_TOTAL_BY_ENTITIES_FLT)}
              accordionProps={{ className: `${styles.accordion}` }}
              isOpened={false}>
              <Table className={cn(styles.table, styles.innerTable, styles.innerTableFooter)}>
                <TableBody className={styles.footerTableRecords}>
                  <TableRecordsFooter
                    invoicedData={invoicedData}
                    entities={entities}
                    monthlyTotalPerEntity={monthlyTotalPerEntity}
                    annualTotalPerEntity={annualTotalPerEntity}
                    currency={currency}
                  />
                </TableBody>
              </Table>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
    </TableFooter>
  );
};

export default BudgetingTableFooter;
