import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import DragAndDrop from 'src/components/DragAndDrop';

import { Button, Typography } from '@mui/material';

import {
  BROWSE_FILES,
  DRAG_AND_DROP_HERE,
  FORMAT_UPLOAD_MESSAGE_TIMESHEETS,
  OR,
  UPLOAD_FTL,
} from 'valtech-core/common/ftl';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { useUploadTimesheetFile } from '../hooks/UploadTimesheetFile.hook';
import { TIMESHEET_UPLOAD_FORMAT } from './TimesheetsUpload.constants';
import s from './TimesheetsUpload.module.scss';

const TimesheetsUpload: FC = () => {
  const { l10n } = useLocalization();
  const { uploadTimesheetFile, isLoading } = useUploadTimesheetFile();
  const uploadedFile = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const extensionsRegex = useMemo(() => /\.cvs/, []);

  const handleClearData = useCallback(
    (uploadedFile: React.RefObject<HTMLInputElement>) => {
      setTitle('');
      setOriginalFileName('');
      setFile(null);

      if (uploadedFile.current) {
        uploadedFile.current.value = '';
      }
    },
    [uploadedFile],
  );

  const editFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value ? e.target.value : originalFileName);
  };

  const sendFile = (replace = false) => {
    if (file) {
      uploadTimesheetFile({
        file,
        title,
        replace,
        date: new Date().toISOString(),
      });
    }
  };

  return (
    <>
      {isLoading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <form onSubmit={e => e.preventDefault()} id='form-file-upload'>
        <div className={cn(s.titleWrapper, { [s.active]: title })}>
          <input
            value={title || 'Title'}
            className={s.inputTitle}
            readOnly={!title}
            disabled={!title}
            type='text'
            onChange={editFileName}
          />
        </div>

        <DragAndDrop
          render={(dragActive, handleDrop, handleDrag, handleChange) => (
            <>
              <input
                onChange={e =>
                  handleChange(
                    e,
                    setTitle,
                    setFile,
                    TIMESHEET_UPLOAD_FORMAT,
                    extensionsRegex,
                    setOriginalFileName,
                  )
                }
                className={s.inputFile}
                id='input-file-upload'
                accept='.csv'
                type='file'
                ref={uploadedFile}
              />
              <label
                id='label-file-upload'
                htmlFor='input-file-upload'
                className={cn({ 'drag-active': dragActive })}>
                <div className={s.dragNDropWrapper} onDragEnter={handleDrag}>
                  <p>{l10n?.getString(DRAG_AND_DROP_HERE)}</p>
                  <p>{l10n?.getString(OR)}</p>
                  <p className={s.uploadFileText}>{l10n?.getString(BROWSE_FILES)}</p>
                </div>
                {dragActive && (
                  <div
                    className={s.dragNDrop}
                    id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={e =>
                      handleDrop(
                        e,
                        setTitle,
                        setFile,
                        TIMESHEET_UPLOAD_FORMAT,
                        extensionsRegex,
                        setOriginalFileName,
                      )
                    }></div>
                )}
                <Typography variant='h6' className={s.fileTypeWarning}>
                  {l10n.getString(FORMAT_UPLOAD_MESSAGE_TIMESHEETS)}
                </Typography>
              </label>
            </>
          )}
          handleClearData={() => handleClearData(uploadedFile)}
          message={FORMAT_UPLOAD_MESSAGE_TIMESHEETS}
        />
        <Button
          disabled={!file}
          type='button'
          size='medium'
          variant='contained'
          onClick={() => sendFile()}>
          {l10n?.getString(UPLOAD_FTL)}
        </Button>
      </form>
    </>
  );
};

export default TimesheetsUpload;
