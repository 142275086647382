import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { IWarningData } from 'common/types';
import isNil from 'lodash/isNil';
import TableFooter from 'pages/SingleBilling/EditSingleBillingTable/components/TableFooter';
import TableHeader from 'pages/SingleBilling/EditSingleBillingTable/components/TableHeader';
import MainTable from 'pages/SingleBilling/EditSingleBillingTable/tables/MainTable';
import { useUpdateSingleBilling } from 'pages/SingleBilling/SingleBilling.hook';
import {
  IBillingPageData,
  IClientsData,
  IConsultantsData,
} from 'pages/SingleBilling/SingleBilling.types';
import useGetTotalByEntities from 'src/common/hooks/useGetTotalByEntities';

import Box from '@mui/material/Box';

import {
  ACTUAL_GM,
  CLIENTS_EXPENSES_FTL,
  CONSULTANTS_CLIENTS_FTL,
  INVOICED_TOTAL_BY_ENTITIES_FLT,
  TOTAL_EXPENSES_FTL,
  TOTAL_FOR_INVOICING_FTL,
} from 'valtech-core/common/ftl';
import { Maybe } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import formatGrossMarginWithPercentage from 'valtech-core/common/utils/createNumberWithPercentage';
import Accordion from 'valtech-core/ui/Accordion';

import {
  countAverageGrossMarginPerBilling,
  makeFooterData,
} from 'pages/SingleBilling/EditSingleBillingTable/EditSingleBillingTable.utils';

import { useBillingState } from '../SingleBillingContextProvider';
import {
  clientsHeadData,
  clientsHeadDataByEntities,
  consultantsHeadData,
} from './EditSingleBillingTable.const';
import s from './EditSingleBillingTable.module.scss';
import TotalByEntitiesTable from './tables/TotalByEntitiesTable';

interface IEditSingleBillingTable {
  allClientsTotal: Maybe<number>;
  allConsultantsTotal: Maybe<number>;
  billingPageData: IBillingPageData | undefined;
  clientsData: IClientsData[] | undefined;
  consultantsData: IConsultantsData[] | undefined;
  globalTotal: Maybe<number>;
  globalTotalFE: Maybe<number>;
  singleBillingWarnings: IWarningData[];
}

const EditSingleBillingTable: FC<IEditSingleBillingTable> = ({
  allClientsTotal,
  allConsultantsTotal,
  globalTotal,
  globalTotalFE,
  consultantsData,
  clientsData,
  billingPageData,
  singleBillingWarnings,
}) => {
  const { l10n } = useLocalization();
  const { id: billingId } = useParams();
  const { updateSingleBillingTotal } = useUpdateSingleBilling();
  const [billingPageTotal, setBillingPageTotal] = useState<number | undefined>();
  const { setStateParam } = useBillingState();
  const { entitiesTotal } = useGetTotalByEntities([Number(billingId)]);

  const formattedConsultantsFooterData = makeFooterData(
    l10n.getString(TOTAL_EXPENSES_FTL),
    `${createNumberWithCurrency({
      number: allConsultantsTotal,
      currencyDisplay: 'symbol',
      currency: billingPageData?.currency,
    })}`,
  );

  const formattedClientsFooterData = makeFooterData(
    l10n.getString(TOTAL_EXPENSES_FTL),
    `${createNumberWithCurrency({
      number: allClientsTotal,
      currencyDisplay: 'symbol',
      currency: billingPageData?.currency,
    })}`,
  );

  const formattedActualGMData = formatGrossMarginWithPercentage({
    number: countAverageGrossMarginPerBilling(consultantsData),
    shouldDisplayStub: true,
  });

  useEffect(() => {
    setBillingPageTotal(globalTotal ? globalTotal : globalTotalFE);

    const shouldUpdate =
      billingId && globalTotalFE && !isNil(globalTotal) && Boolean(globalTotal !== globalTotalFE);

    if (shouldUpdate) {
      updateSingleBillingTotal(
        billingId,
        Number(globalTotalFE),
        countAverageGrossMarginPerBilling(consultantsData),
      ).then(() => setBillingPageTotal(globalTotalFE));
    }
    setStateParam({ singleBillingWarnings: singleBillingWarnings });
  }, [globalTotal, globalTotalFE]);

  return (
    <Box>
      <TableHeader title={billingPageData?.title} status={billingPageData?.status} />
      <Accordion
        title={l10n.getString(CONSULTANTS_CLIENTS_FTL)}
        accordionDetailsProps={{ sx: { padding: 0 } }}
        isOpened={true}
        boxProps={{ sx: { marginBottom: 2 } }}>
        <MainTable
          headData={consultantsHeadData}
          bodyData={consultantsData}
          footerData={formattedConsultantsFooterData}
          currencySign={billingPageData?.currencySign}
          singleBillingWarnings={singleBillingWarnings}
        />
      </Accordion>
      <Accordion
        title={l10n.getString(CLIENTS_EXPENSES_FTL)}
        accordionDetailsProps={{ sx: { padding: 0 } }}
        isOpened={true}>
        <MainTable
          headData={clientsHeadData}
          bodyData={clientsData}
          footerData={formattedClientsFooterData}
          currencySign={billingPageData?.currencySign}
        />
      </Accordion>
      <div className={s.container}>
        <Accordion
          title={l10n.getString(INVOICED_TOTAL_BY_ENTITIES_FLT)}
          accordionDetailsProps={{ sx: { padding: 0 } }}
          isOpened={true}>
          <TotalByEntitiesTable
            headData={clientsHeadDataByEntities}
            bodyData={entitiesTotal.monthlyTotalPerEntity[0]?.data}
            currency={billingPageData?.currency}
          />
        </Accordion>
      </div>
      <TableFooter
        title={l10n.getString(TOTAL_FOR_INVOICING_FTL)}
        total={`${createNumberWithCurrency({
          number: billingPageTotal,
          currencyDisplay: 'symbol',
          currency: billingPageData?.currency,
        })}`}
      />
      <TableFooter title={l10n.getString(ACTUAL_GM)} total={formattedActualGMData} />
    </Box>
  );
};
export default EditSingleBillingTable;
