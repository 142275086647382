import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import isNull from 'lodash/isNull';
import { Moment } from 'moment/moment';
import { DEFAULT_SKIP_QUANTITY, MAX_QUANTITY } from 'src/common/constants';
import useGetAccounts from 'src/common/hooks/useGetAccounts';
import SingleProjectFormWrapper from 'src/pages/ManageSettings/SingleProject/SingleProjectFormWrapper';
import { MSAType } from 'src/pages/ManageSettings/SingleProject/SingleProjectFormWrapper/SingleProjectContent/MSADetails/MSADetails';

import { ActivationStatus } from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';
import { AnyArray, CurrencyEnum } from 'valtech-core/common/types';

import { formattedArrayOfAllAccounts } from './SingleProject.utils';

import { useSingleProject } from './SinglePrject.hook';

export type Client = {
  id: number;
  name: string;
  projectId: number;
  clientId: number;
  clientManager: Maybe<string>;
  clientManagerEmail: Maybe<string>;
  status: ActivationStatus;
};
interface IUserCredentials {
  role: string;
  id: number;
  userInfo?: {
    id: number;
    name: string;
  };
}
export type SingleProjectFormProps = {
  businessUnitId: string;
  projectName: string;
  currency: CurrencyEnum;
  status: ActivationStatus;
  deliveryManagerId: number | string;
  msaNumber: string;
  msaDate?: Moment | string;
  msaType: MSAType;
  ICBO?: AnyArray;
  associatedClients?: { clients: Array<Client> };
  credentials?: AnyArray;
  arrayOfDeliveryManagers?: AnyArray;
  accounts?: AnyArray;
  accountId: number | string;
  userCredentials?: {
    credentials: IUserCredentials[];
  };
};

export type EditProjectVariables = SingleProjectFormProps & {
  id: number;
};

export type UpdatedSingleProjectFormProps = Omit<
  SingleProjectFormProps,
  'deliveryManagerId' | 'accountId'
> & {
  deliveryManagerId: { id: number | string | null; label: string };
  accountId: { id: number | string | null; label: string };
};

const SingleProject: FC = () => {
  const { id: projectId } = useParams();

  const { singleProject, loading } = useSingleProject(Number(projectId));
  const { accounts } = useGetAccounts({ limit: MAX_QUANTITY, skip: DEFAULT_SKIP_QUANTITY });

  const initialValues = !isNull(singleProject)
    ? {
        projectName: singleProject.projectName,
        currency: singleProject.currency,
        businessUnitId: singleProject.businessUnitId,
        status: singleProject.status,
        deliveryManagerId: singleProject.deliveryManagerId,
        accounts: formattedArrayOfAllAccounts(accounts),
        accountId: singleProject.accountId,
        msaNumber: singleProject.msaNumber,
        msaDate: singleProject.msaDate,
        msaType: singleProject.msaType,
        arrayOfDeliveryManagers: singleProject.arrayOfDeliveryManagers,
      }
    : {};

  const singleAccountFormInitialValues = initialValues;

  if (isNull(singleProject) && loading && projectId) return null;
  return <SingleProjectFormWrapper {...singleAccountFormInitialValues} />;
};

export default SingleProject;
