import { useMemo } from 'react';

import { useGetAllConsultantsQuery } from 'valtech-core/common/gql/generated';

export type ConsultantData = {
  id: number;
  label: string;
  email?: string;
  entity?: string;
};

type UseAllConsultantsReturn = {
  consultants: ConsultantData[];
  loading: boolean;
};

export const useAllConsultants = (): UseAllConsultantsReturn => {
  const { data, loading } = useGetAllConsultantsQuery();

  const consultants = useMemo((): ConsultantData[] => {
    if (!data) {
      return [];
    }

    return data.sendArrayOfConsultants.map(consultant => ({
      id: consultant.id,
      entity: consultant.bu_id,
      label: `${consultant.first_name} ${consultant.last_name}`,
      email: `${consultant.email}`,
    }));
  }, [data]);

  return {
    consultants,
    loading,
  };
};
