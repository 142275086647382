import { GENERIC_ERROR } from 'valtech-core/common/ftl';

import { systemVar } from './system';

export const showLoading = (): void => {
  const currentState = systemVar();
  systemVar({
    ...currentState,
    loading: true,
  });
};

export const hideLoading = (): void => {
  const currentState = systemVar();
  systemVar({
    ...currentState,
    loading: false,
  });
};

export const showSystemError = (error: string): void => {
  const currentState = systemVar();
  systemVar({
    ...currentState,
    error,
  });
};

export const hideSystemError = (): void => {
  const currentState = systemVar();
  systemVar({
    ...currentState,
    error: null,
  });
};

export const showGenericError = (): void => showSystemError(GENERIC_ERROR);
