import { useMemo } from 'react';

import isNil from 'lodash/isNil';
import { showGenericError } from 'src/common/apolloState/system';
import {
  TransformedEntitiesTotalData,
  transformEntitiesTotalData,
} from 'src/common/utils/transformEntitiesTotalData';

import { useGetTotalByEntitiesForBudgetPageQuery } from 'valtech-core/common/gql/generated';

type UseGetTotalByEntitiesForBudgetPageReturn = {
  entitiesTotal: TransformedEntitiesTotalData;
  loading?: boolean;
};

const useGetTotalByEntitiesForBudgetPage = (
  budgetReportId: number,
): UseGetTotalByEntitiesForBudgetPageReturn => {
  const { data, loading } = useGetTotalByEntitiesForBudgetPageQuery({
    variables: {
      budgetReportId,
    },
    skip: isNil(budgetReportId),
    fetchPolicy: 'cache-and-network',
    onError() {
      showGenericError();
    },
  });

  const entitiesTotal = useMemo((): TransformedEntitiesTotalData => {
    if (!data) {
      return {
        monthlyTotalPerEntity: [],
        entities: [],
      };
    }

    return {
      monthlyTotalPerEntity: transformEntitiesTotalData(data?.getTotalByEntitiesForBudgetPage),
      entities: data.getTotalByEntitiesForBudgetPage.map(item => item.entityName) || [],
    };
  }, [data]);

  return {
    entitiesTotal,
    loading,
  };
};

export default useGetTotalByEntitiesForBudgetPage;
