import React, { FC, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import { BoxProps } from '@mui/material/Box';
import { RadioGroupProps } from '@mui/material/RadioGroup';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import FormError from 'valtech-core/form/FormError';
import Radio from 'valtech-core/form/Radio';
import { RadioItem } from 'valtech-core/form/Radio/Radio';

export interface IRadioFieldProps {
  control: FieldControl;
  name: string;
  defaultValue?: string;
  boxProps?: BoxProps;
  radioGroupProps?: RadioGroupProps;
  data: RadioItem[];
  disabledList?: string[];
  validate?: (value: string) => Promise<Maybe<string>>;
  label?: string;
}

const RadioField: FC<IRadioFieldProps> = ({
  name,
  control,
  defaultValue = '',
  boxProps,
  radioGroupProps,
  disabledList,
  data,
  validate,
  label,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <Radio
          {...field}
          data={data}
          disabledList={disabledList}
          boxProps={boxProps}
          radioGroupProps={radioGroupProps}
          label={label}
          error={!!errors[name]}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </Radio>
      )}
      rules={{
        validate,
      }}
    />
  );
};

export default memo(RadioField);
