import React, { FC, ReactNode } from 'react';

import { Navigate, RouteProps } from 'react-router-dom';

import { useAuthState } from 'common/apolloState/auth';
import { ERoles } from 'src/common/types';

import * as routes from 'valtech-core/common/routes';
import { GuardTypesEnum } from 'valtech-core/common/types';

type CustomRouteProps = RouteProps & {
  type: GuardTypesEnum;
  redirectTo?: string;
  onlyForAdmin?: boolean;
  children?: ReactNode;
};

const CustomRoute: FC<CustomRouteProps> = ({
  type,
  onlyForAdmin = false,
  redirectTo,
  children,
}) => {
  const { isLoggedIn, userInfo } = useAuthState();
  const currentProfileRole = userInfo && userInfo.role ? userInfo.role : ERoles.User;

  if (isLoggedIn && type === GuardTypesEnum.Public) {
    return <Navigate to={redirectTo || routes.BUDGETINGS_ROUTE} replace={true} />;
  } else if (!isLoggedIn && type === GuardTypesEnum.Protected) {
    return <Navigate to={redirectTo || routes.LOGIN_ROUTE} replace={true} />;
  } else if (
    isLoggedIn &&
    type === GuardTypesEnum.Protected &&
    onlyForAdmin &&
    currentProfileRole !== ERoles.Administrator
  ) {
    return <Navigate to={routes.NOT_ALLOWED} />;
  }

  return <>{children}</>;
};

export default CustomRoute;
