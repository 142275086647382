import { makeVar } from '@apollo/client';

import authStorage from 'valtech-core/services/auth';

export type AuthState = {
  isLoggedIn: boolean;
  userInfo: UserInfo | null;
};

export type UserInfo = {
  email: string;
  name: string;
  role: string;
};

export const initialAuthState: AuthState = {
  userInfo: authStorage.getUserInfo(),
  isLoggedIn: !!authStorage.getToken(),
};

export const authVar = makeVar<AuthState>(initialAuthState);
