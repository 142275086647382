import { useCallback } from 'react';

import { showSystemError } from 'common/apolloState/system';

import { ERROR_USER_WITH_EMAIL_EXIST } from 'valtech-core/common/ftl';
import {
  EditSettingsUserMutationVariables,
  UserDataFragmentDoc,
  useAddSettingsUserMutation,
  useEditSettingsUserMutation,
} from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { mapToSubmitAddSettingsUser } from './SettingsUserForm.utils';

import { ISettingsUserFormProps } from './SettingsUserForm';

type UseHandleSettingsUserReturn = {
  createSettingsUser: (data: ISettingsUserFormProps) => Promise<boolean>;
  updateSettingsUser: (
    data: ISettingsUserFormProps,
    mutationData: EditSettingsUserMutationVariables,
  ) => Promise<boolean>;
  loading: boolean;
};

export const useHandleSettingsUser = (): UseHandleSettingsUserReturn => {
  const [addSettingsUser, { loading: adding }] = useAddSettingsUserMutation({
    onError(e) {
      if (e.message.includes('User with email') && e.message.includes('already exist')) {
        showSystemError(ERROR_USER_WITH_EMAIL_EXIST);
      } else {
        showSystemError(getErrorMessage(e.message));
      }
    },
  });

  const [editSettingsUsersMutation, { loading: updating }] = useEditSettingsUserMutation({
    onError(e) {
      if (e.message.includes('User with email') && e.message.includes('already exist')) {
        showSystemError(ERROR_USER_WITH_EMAIL_EXIST);
      } else {
        showSystemError(getErrorMessage(e.message));
      }
    },
  });

  const createSettingsUser = useCallback(async (data: ISettingsUserFormProps) => {
    const newSettingsUser = await addSettingsUser({
      variables: mapToSubmitAddSettingsUser({ data }),
      refetchQueries: ['getArrayOfUsers'],
    });

    return !!newSettingsUser.data?.createUser.id;
  }, []);

  const updateSettingsUser = useCallback(
    async (data: ISettingsUserFormProps, mutationData: EditSettingsUserMutationVariables) => {
      const editedSettingsUser = await editSettingsUsersMutation({
        variables: mutationData,

        update(cache, { data: apolloData }) {
          if (apolloData?.updateUserByID) {
            cache.writeFragment({
              id: `User:${data.userId}`,
              data: {
                __typename: 'User',
                status: data.status,
                role: data.role,
                name: data.name,
                id: data.userId,
                email: data.email,
              },
              fragment: UserDataFragmentDoc,
              fragmentName: 'UserData',
            });
          }
        },
      });

      return !!editedSettingsUser.data?.updateUserByID;
    },
    [],
  );

  return {
    createSettingsUser,
    updateSettingsUser,
    loading: adding || updating,
  };
};
