import React, { FC, memo } from 'react';

import MUIChip, { ChipProps } from '@mui/material/Chip';

import { ChipColor } from 'valtech-core/common/types';

interface IChipProps {
  chipProps?: ChipProps;
  variant: 'outlined' | 'filled' | undefined;
  label: string;
  size?: 'small' | 'medium' | undefined;
  className?: string;
  color?: ChipColor;
}

const Chip: FC<IChipProps> = ({
  chipProps,
  variant,
  label,
  size = 'medium',
  color = 'primary',
  className,
}) => {
  return (
    <MUIChip
      label={label}
      className={className}
      variant={variant}
      size={size}
      color={color}
      {...chipProps}
    />
  );
};

export default memo(Chip);
