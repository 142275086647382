import React, { FC, ReactNode } from 'react';

import Header from 'components/Header';

import styles from './Layout.module.scss';

interface ILayoutProps {
  children?: ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main className={styles.main}>{children}</main>
    </>
  );
};

export default Layout;
