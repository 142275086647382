import { Control, FieldValues } from 'react-hook-form';

import { ApolloError } from '@apollo/client';

import { OrderDirection } from 'valtech-core/common/gql/generated';

export type AnyObject = {
  // eslint-disable-next-line
  [key: string]: any;
};
export type Dictionary<V> = { [key: string]: V };

// eslint-disable-next-line
export type AnyArray = any[];

// eslint-disable-next-line
// @ts-ignore
export type FieldControl = Control<FieldValues, any>; // eslint-disable-line

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined | null;

export enum IconSize {
  Small = 'small',
  Middle = 'middle',
  Big = 'big',
}

export enum IconFontSize {
  Small = 20,
  Middle = 24,
  Big = 30,
}

export enum GuardTypesEnum {
  Protected = 'protected',
  Public = 'public',
  Regular = 'regular',
}

export enum BudgetingGroupsEnum {
  Assignment = 'assignment',
  Vacancy = 'vacancy',
  Expense = 'expense',
}

export enum BudgetingGroupsMultipleEnum {
  Assignments = 'assignments',
  Vacancies = 'vacancies',
  Expenses = 'expenses',
}

export type ChipColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined;

export enum CurrencyEnum {
  EUR = 'EUR',
  USD = 'USD',
}

export enum DateMask {
  DD_MM_YYYY_HH = 'DD-MM-YYYY - HH:mm',
  YYYY_MM_DD_HH = 'YYYY-MM-DD - HH:mm',
  DD_MM_YYYY = 'DD-MM-YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  MMMM_YYYY = 'MMMM YYYY',
}

export enum DrawerTypeEnum {
  Button = 'button',
  Link = 'link',
}

export enum TabsTypeEnum {
  Button = 'button',
  Link = 'link',
}

export enum AlertsSeverityEnum {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface IFilters {
  field: string;
  filterModel: string;
  query: string;
}

export enum RecordStatus {
  Active = 'Active',
  NotActive = 'Not active',
  Archived = 'Archived',
}

export enum ActivationStatus {
  Active = 'Active',
  NotActive = 'Not active',
}

export interface ISortingAndFiltering {
  loading: boolean;
  total: number;
  setPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  page: number;
  rowsPerPage: number;
  error: ApolloError | undefined;
  sortingType: OrderDirection;
  setSortingType: (value: OrderDirection) => void;
  setModel: (value: string) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
  setFilter: (value: IFilters[] | []) => void;
  filter: IFilters[];
  setProjectFilter: (value: IFilters) => void;
  setTableFilters: (value: any) => void;
  projectFilter: IFilters;
  tableFilters: IFilters[] | [];
  refetch?: () => void;
}
