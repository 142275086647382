import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import { SingleBillingTableTypeEnum } from 'common/types';
import { useGeneralTab } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';
import BillingHistory from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabForms/BillingHistory';
import Expenses from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabForms/Expenses';
import Overtime from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabForms/Overtime';
import ReportCorrection from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabForms/ReportCorrection';

// import VacationBalance from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabForms/VacationBalance';
// import {
//   GENERAL_FEEDBACK_ALERTS,
//   GENERAL_TREE_DATA,
// } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabMockData';
import {
  BILLING_HISTORY_FTL, // CONSULTANT_FTL,
  EXPENSES_FTL,
  OVERTIME_FTL,
  REPORT_CORRECTION_FTL,
  TIMESHEET_FTL, // VACATION_BALANCE_FTL,
} from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import Accordion from 'valtech-core/ui/Accordion';

// import Alert from 'valtech-core/ui/Alert';
// import Tree from 'valtech-core/ui/Tree';
import { useBillingState } from '../../SingleBillingContextProvider';
import TimesheetDetails from '../TimesheetDetails';
import s from './GeneralTab.module.scss';

const GeneralTab: FC = () => {
  const { l10n } = useLocalization();

  const {
    consultantsExpensesFormInitialValues,
    clientsExpensesFormInitialValues,
    reportCorrectionInitialValues,
    tableName,
    billingStatus,
    overtimeFormValues,
  } = useGeneralTab();
  const { state } = useBillingState();
  const { accessLevel } = state;
  const userAccessLevel = getAccessLevel(accessLevel, l10n);

  const disabledBlock =
    state.projectData?.locked || userAccessLevel.edit || userAccessLevel.viewAndComment;

  if (tableName === SingleBillingTableTypeEnum.Clients) {
    return (
      <div className={s.component__wrapper}>
        <Accordion
          title={l10n.getString(EXPENSES_FTL)}
          boxProps={{ sx: { marginBottom: 2 } }}
          accordionProps={{
            disabled: billingStatus === BillingStatus.Invoiced,
          }}
          isOpened={billingStatus !== BillingStatus.Invoiced}>
          <Expenses initialValues={clientsExpensesFormInitialValues} />
        </Accordion>
      </div>
    );
  }

  return (
    <div className={s.component__wrapper}>
      <Accordion
        title={l10n.getString(TIMESHEET_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: false }}
        isOpened={true}>
        <TimesheetDetails />
      </Accordion>

      <Accordion
        title={l10n.getString(REPORT_CORRECTION_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: billingStatus === BillingStatus.Invoiced || disabledBlock }}
        isOpened={billingStatus !== BillingStatus.Invoiced && !disabledBlock}>
        <ReportCorrection initialValues={reportCorrectionInitialValues} />
      </Accordion>

      <Accordion
        title={l10n.getString(OVERTIME_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: billingStatus === BillingStatus.Invoiced || disabledBlock }}
        isOpened={billingStatus !== BillingStatus.Invoiced && !disabledBlock}>
        <Overtime initialValues={overtimeFormValues} />
      </Accordion>

      <Accordion
        title={l10n.getString(EXPENSES_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: billingStatus === BillingStatus.Invoiced || disabledBlock }}
        isOpened={billingStatus !== BillingStatus.Invoiced && !disabledBlock}>
        <Expenses initialValues={consultantsExpensesFormInitialValues} />
      </Accordion>

      {/* <Accordion
        title={l10n.getString(VACATION_BALANCE_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: billingStatus === BillingStatus.Invoiced }}
        isOpened={billingStatus !== BillingStatus.Invoiced}>
        <VacationBalance />
      </Accordion> */}

      <Accordion
        title={l10n.getString(BILLING_HISTORY_FTL)}
        boxProps={{ sx: { marginBottom: 2 } }}
        accordionProps={{ disabled: billingStatus === BillingStatus.Invoiced }}
        isOpened={billingStatus !== BillingStatus.Invoiced}>
        <BillingHistory />
      </Accordion>
    </div>
  );
};

export default GeneralTab;
