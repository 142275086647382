import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { showGenericError } from 'common/apolloState/system';
import useHandleSorting from 'common/hooks/useHandleSorting';
import { handleSortingParams } from 'common/hooks/useHandleSorting/useHandleSorting';
import { DEFAULT_SKIP_QUANTITY, MAX_QUANTITY } from 'src/common/constants';

import {
  useDeleteUserCredentialsByIdMutation,
  useGetArrayOfUserCredentialsQuery,
  useUpdateUserCredentialsByIdMutation,
} from 'valtech-core/common/gql/generated';

import { IUserCredentialsTable } from './UserCredentialsTable.const';

interface IUserCredentials {
  role: string;
  id: number;
  userInfo?: { name: string };
}

interface IUsersList {
  id: number;
  label: string;
}
interface IUseUserCredentialsReturn {
  userCredentials: {
    loading: boolean;
    credentials: IUserCredentials[];
    usersList: IUsersList[];
    sorting: handleSortingParams;
  };
}
interface IDeleteUserCredentials {
  clientId: number;
}

export const useUserCredentials = (projectName: string | undefined): IUseUserCredentialsReturn => {
  const { id } = useParams();
  const credentialsId = Number(id);

  const { sortingType, activeColumn, handleSorting, sortBy } = useHandleSorting({
    modelState: '',
    sortByState: '',
    activeColumnState: '',
  });

  if (!credentialsId) {
    return {
      userCredentials: {
        loading: false,
        credentials: [],
        usersList: [],
        sorting: {
          sortingType,
          activeColumn,
          handleSorting,
        },
      },
    };
  }

  if (projectName) {
    const { data, loading } = useGetArrayOfUserCredentialsQuery({
      variables: {
        getUserCredentialsByProjectIdId: Number(credentialsId),
        sortBy: sortBy,
        sortDirection: sortingType,
        pagination: {
          skip: DEFAULT_SKIP_QUANTITY,
          limit: MAX_QUANTITY,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

    const credentials = useMemo(() => data?.getUserCredentialsByProjectID || [], [data]);
    const usersList: IUsersList[] = useMemo(() => {
      if (!data) {
        return [];
      }

      return (data?.sendArrayOfUsers?.data || []).map(item => {
        return {
          id: item?.id,
          label: item?.name,
        };
      });
    }, [data]);

    return {
      userCredentials: {
        loading,
        credentials,
        usersList,
        sorting: {
          sortingType,
          activeColumn,
          handleSorting,
        },
      },
    };
  }

  return {
    userCredentials: {
      loading: false,
      credentials: [],
      usersList: [],
      sorting: {
        sortingType,
        activeColumn,
        handleSorting,
      },
    },
  };
};

export const useUpdateUserCredentials = (): ((
  userCredentials: IUserCredentialsTable,
) => Promise<unknown>) => {
  const [updateUserCredentials] = useUpdateUserCredentialsByIdMutation();

  return (userCredentials: IUserCredentialsTable): Promise<unknown> => {
    const { id, role, name } = userCredentials;
    return updateUserCredentials({
      variables: {
        role: role,
        userId: name?.id as number,
        updateUserCredentialsByIdId: id as number,
      },
      refetchQueries: ['getArrayOfUserCredentials'],
    }).catch(() => {
      showGenericError();
    });
  };
};

export const useDeleteUserCredentials = (): ((
  userCredentials: IDeleteUserCredentials,
) => Promise<unknown>) => {
  const [deleteUserCredentials] = useDeleteUserCredentialsByIdMutation();

  return (userCredentials: IDeleteUserCredentials): Promise<unknown> => {
    const { clientId: clientId } = userCredentials;

    return deleteUserCredentials({
      variables: {
        deleteUserCredentialsByIdId: Number(clientId),
      },
      refetchQueries: ['getArrayOfUserCredentials'],
    }).catch(() => {
      showGenericError();
    });
  };
};
