import React, { FC, useMemo } from 'react';

import Modal from 'components/Modal';
import EntityForm from 'components/_forms/EntityForm/EntityForm';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useHandleAllEntitiesTableRowEdit } from './HandleAllEntitiesTableRowEdit.hooks';

import { Entity } from '../AllEntities.types';

interface HandleTableRowEditProps {
  rowRecord: Entity;
  order: number;
}

const HandleAllEntitiesTableRowEdit: FC<HandleTableRowEditProps> = ({ rowRecord, order }) => {
  const { isOpened, toggleForm, getEntityInitialValues } = useHandleAllEntitiesTableRowEdit({
    order,
    rowRecord,
  });

  const displayForm = useMemo(() => {
    return (
      <Box
        sx={{
          width: 600,
        }}>
        <EntityForm
          onClose={toggleForm}
          initialValues={getEntityInitialValues(rowRecord as Entity)}
          order={order}
          shortName={rowRecord.id}
        />
      </Box>
    );
  }, [rowRecord]);

  return (
    <>
      <IconButton
        aria-haspopup='true'
        onClick={toggleForm}
        sx={{
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}>
        <EditIcon color='primary' fontSize='small' />
      </IconButton>
      <Modal handleCloseModal={toggleForm} openModal={isOpened}>
        {displayForm}
      </Modal>
    </>
  );
};

export default HandleAllEntitiesTableRowEdit;
