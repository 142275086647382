import { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  clearBudgetingState,
  setBudgetingState,
  useBudgetingState,
} from 'common/apolloState/budgeting';
import { showGenericError } from 'common/apolloState/system';
import { COST_OF_OVERHEAD_DEFAULT, DEFAULT_GROSS_MARGIN, MONTHS_IN_A_YEAR } from 'common/constants';
import { useWorkingDaysForTheYear } from 'common/hooks/useWorkingDaysForTheYear';
import { AssignmentProject, ConsultantModelEnum, MonthSettings } from 'common/types';
import { getFormattedDate } from 'common/utils/formatDate';
import { sortByOrderWithKey } from 'common/utils/sortByOrderWithKey';
import round from 'lodash/round';
import useGetTotalByEntities from 'src/common/hooks/useGetTotalByEntities/useGetTotalByEntities';
import useGetTotalByEntitiesForBudgetPage from 'src/common/hooks/useGetTotalByEntitiesForBudgetPage';

import moment from 'moment';

import {
  BillingReportModel,
  useGetSingleBudgetingLazyQuery,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum, Maybe } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import getCurrencyCode from 'valtech-core/common/utils/getCurrencyCode';

import {
  allocationValueToPercentage,
  calculateEntityTotals,
  calculateTotalsForPeriod,
  countAverageGrossMarginPerMonth,
  countAverageGrossMarginPerYear,
  displayExpansesCostsPerMonth,
  generateGrossMarginArray, // getCurrencyCode,
  getTotalCost,
  mapToAllocationDisplay,
} from './Budgeting.utils';

import {
  AssignmentExpenses,
  AssignmentVacancy,
  BudgetingInfo,
  ConsultantSectionItem,
  ExpensesSectionItem,
  GrossMarginData,
  InvoicedTotal,
  MonthCost,
  TotalByEntities,
  TotalCosts,
  VacancySectionItem,
} from './Budgeting.types';

type UseBudgetingReturn = {
  loading: boolean;
  budgetingInfo: BudgetingInfo;
  consultantsSection: ConsultantSectionItem[];
  vacanciesSection: VacancySectionItem[];
  expensesSection: ExpensesSectionItem[];
  consultantsSectionTotals: TotalCosts;
  vacanciesSectionTotals: TotalCosts;
  expensesSectionTotals: TotalCosts;
  invoicedTotals: InvoicedTotal;
  invoicedTotalsByEntities: TotalByEntities;
  forecastedTotalsByEntities: TotalByEntities;
  getAssignmentIdsByMonth: (monthIndex: number) => number[];
  forecastGrossMargin: GrossMarginData;
  actualGrossMargin: GrossMarginData;
};

export const useSingleBudgeting = (): UseBudgetingReturn => {
  const { id: budgetReportId } = useParams();
  const { assignmentDefaults, forecastType, submitted } = useBudgetingState();
  const { getWorkingDays } = useWorkingDaysForTheYear();
  const [workingDaysCollection, setWorkingDaysCollection] = useState<Map<number, MonthSettings[]>>(
    new Map(),
  );

  const [getBudgeting, { data, loading }] = useGetSingleBudgetingLazyQuery({
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    onCompleted: newData => {
      const financialYear =
        newData.V2getBudgetReportByID.budgetInfo?.budgetingSettingInfo?.financial_year;
      const mergedAssignmentsAndVacancies = [
        ...(newData?.V2getBudgetReportByID.assignementConsultantInfo || []),
        ...(newData?.V2getBudgetReportByID.vacancyInfo || []),
      ];
      const holidayCalendarIds = mergedAssignmentsAndVacancies.reduce<number[]>((acc, item) => {
        if (!acc.includes(item.holiday_calendar_id)) {
          acc.push(item.holiday_calendar_id);
        }

        return acc;
      }, []);

      if (financialYear) {
        holidayCalendarIds?.forEach(calendarId => {
          getWorkingDays(calendarId, financialYear).then(calendar =>
            setWorkingDaysCollection(prev => {
              const newVal = new Map(prev);
              newVal.set(calendarId, calendar);
              return newVal;
            }),
          );
        });
      }
    },
    onError(_) {
      showGenericError();
    },
  });

  const billingReportIds = useMemo(() => {
    return data?.V2getBudgetReportByID?.billingInfo?.map(billingReport => billingReport?.id) || [];
  }, [data]);

  const budgetReportIdData = useMemo(() => {
    return forecastType?.id ? forecastType?.id : budgetReportId;
  }, [budgetReportId, forecastType]);

  const { entitiesTotal } = useGetTotalByEntities(billingReportIds);
  const { entitiesTotal: entitiesTotalForecasted } = useGetTotalByEntitiesForBudgetPage(
    Number(budgetReportIdData),
  );

  useEffect(() => {
    if (budgetReportId) {
      getBudgeting({
        variables: {
          id: Number(forecastType?.id ? forecastType?.id : budgetReportId),
        },
      });
    }
  }, [budgetReportId, forecastType, submitted]);

  useEffect(() => {
    return () => {
      clearBudgetingState();
    };
  }, []);

  const budgetingInfo = useMemo((): BudgetingInfo => {
    if (!data) {
      return {
        title: '',
        lastModified: '',
        modifiedBy: '',
        modifiedById: 0,
        financialYear: 0,
        budgetId: 0,
      };
    }

    const info = data.V2getBudgetReportByID.budgetInfo?.budgetingSettingInfo;

    return {
      title: `${data.V2getBudgetReportByID.budgetInfo?.projectInfo?.name} - ${info?.title}` || '',
      projectName: `${data.V2getBudgetReportByID.budgetInfo?.projectInfo?.name}`,
      financialYear: info?.financial_year || 0,
      status: data.V2getBudgetReportByID.budgetInfo?.status,
      lastModified: data.V2getBudgetReportByID.budgetInfo?.update_time || '',
      modifiedBy: `by ${data.V2getBudgetReportByID.budgetInfo?.managerInfo?.name}`,
      modifiedById: data.V2getBudgetReportByID.budgetInfo?.managerInfo?.id || 0,
      existingBudgets: data.V2getBudgetReportByID.existingBudgets,
      projectId: data.V2getBudgetReportByID.budgetInfo?.project_id,
      budgetingSettingId: data.V2getBudgetReportByID.budgetInfo?.budgetingSettingInfo?.id,
      budgetId: data.V2getBudgetReportByID.budgetInfo?.id || 0,
    };
  }, [data]);

  const getConsultantsSection = useCallback((): ConsultantSectionItem[] => {
    if (!data || !data.V2getBudgetReportByID.assignementConsultantInfo) {
      return [];
    }

    const consultantsInfo = data.V2getBudgetReportByID.assignementConsultantInfo;
    const currency = getCurrencyCode(data.V2getBudgetReportByID.budgetInfo?.projectInfo?.ccy);

    return consultantsInfo
      .reduce<Array<ConsultantSectionItem>>((acc, val) => {
        const consultantIndex = acc.findIndex(dev => dev?.id === `${val.consultantInfo?.id}`);
        const assignmentData = val.assignementInfo;
        const consultantRate = val.fee || 0;

        const allocationPerMonth = mapToAllocationDisplay({
          forecastApiData: data.V2getBudgetReportByID.forecastInfo,
          assignementId: assignmentData?.id,
          model: val.model as ConsultantModelEnum,
          fee: consultantRate,
          monthSettingsList: workingDaysCollection.get(val.holiday_calendar_id) || [],
          startDate: assignmentData?.start_date,
          endDate: assignmentData?.end_date,
        });

        const ConsultantProject: AssignmentProject = {
          id: `${assignmentData?.id}`,
          clientId: assignmentData?.clientInfo?.id || null,
          model: val.model,
          rate: {
            value: `${createNumberWithCurrency({
              number: consultantRate,
              currencyDisplay: 'symbol',
              currency,
            })}`,
            id: val.rateInfo?.id || 0,
            title: val.rateInfo?.title || '',
          },
          costOfOverhead: round(Number(val.cost_of_overhead), 6) || 0,
          holidayCalendarId: val.holiday_calendar_id,
          currency,
          comment: assignmentData?.notes,
          date: `${getFormattedDate(assignmentData?.start_date)} - ${getFormattedDate(
            assignmentData?.end_date,
          )}`,
          startDate: moment(assignmentData?.start_date),
          endDate: moment(assignmentData?.end_date),
          title: assignmentData?.clientInfo?.name || '',
          text: assignmentData?.text || '',
          allocation: allocationValueToPercentage(val.percentage),
          hours: val.hours_per_month,
          allocationPerMonth,
        };

        if (consultantIndex >= 0) {
          acc[consultantIndex]?.itemsList?.push(ConsultantProject);
          return acc;
        } else {
          const consultant: ConsultantSectionItem = {
            id: `${val.consultantInfo?.id}`,
            general: {
              name: `${val.consultantInfo?.first_name} ${val.consultantInfo?.last_name}`,
              id: `${val.consultantInfo?.id}`,
              position: val.consultantInfo?.role || '',
              businessUnit: val.consultantInfo?.bu_id || '',
              costs: {
                total: 0,
                months: [],
              },
            },
            itemsList: [ConsultantProject],
          };
          return [...acc, consultant];
        }
      }, [])
      .map(consultant => {
        const costsPerMonth: MonthCost[] = [];

        consultant.itemsList?.forEach(project => {
          project.allocationPerMonth.forEach(month => {
            costsPerMonth[month.monthIndex] = {
              monthIndex: month.monthIndex,
              cost: round((costsPerMonth[month.monthIndex]?.cost || 0) + (month?.cost || 0), 6),
            };
          });
        }, [] as MonthCost[]);

        if (consultant.general) {
          const costList = costsPerMonth.filter(cost => cost);

          consultant.general.costs = {
            months: costList,
            total: getTotalCost(costList),
          };
        }

        consultant.itemsList.sort(sortByOrderWithKey('title'));

        return consultant;
      })
      .sort(sortByOrderWithKey('general.name'));
  }, [Array.from(workingDaysCollection)]);

  const consultantsSectionTotals = useMemo((): TotalCosts => {
    const totalCostsPerMonth = calculateTotalsForPeriod(getConsultantsSection());

    return {
      months: totalCostsPerMonth,
      total: getTotalCost(totalCostsPerMonth),
    };
  }, [getConsultantsSection]);

  const getVacanciesSection = useCallback(() => {
    if (!data || !data.V2getBudgetReportByID.vacancyInfo) {
      return [];
    }

    const currency = getCurrencyCode(data.V2getBudgetReportByID.budgetInfo?.projectInfo?.ccy);

    return data.V2getBudgetReportByID.vacancyInfo
      .reduce<Array<VacancySectionItem>>((acc, val) => {
        const assignmentData = val.assignementInfo;
        const clientId = assignmentData?.clientInfo?.id || 0;
        const clientIndex = acc.findIndex(client => client?.id === `${clientId}`);

        const allocationPerMonth = mapToAllocationDisplay({
          forecastApiData: data.V2getBudgetReportByID.forecastInfo,
          assignementId: assignmentData?.id,
          model: val.model as ConsultantModelEnum,
          fee: val.fee,
          monthSettingsList: workingDaysCollection.get(val.holiday_calendar_id) || [],
          startDate: assignmentData?.start_date,
          endDate: assignmentData?.end_date,
        });

        const VacancyPosition: AssignmentVacancy = {
          id: `${assignmentData?.id}`,
          clientId,
          model: val.model,
          rate: {
            value: `${createNumberWithCurrency({
              number: val.fee,
              currencyDisplay: 'symbol',
              currency,
            })}`,
            id: val.rateInfo?.id || 0,
            title: val.rateInfo?.title || '',
          },
          holidayCalendarId: val.holiday_calendar_id,
          currency,
          comment: assignmentData?.notes,
          text: assignmentData?.text,
          role: val.role,
          date: `${getFormattedDate(assignmentData?.start_date)} - ${getFormattedDate(
            assignmentData?.end_date,
          )}`,
          startDate: moment(assignmentData?.start_date),
          endDate: moment(assignmentData?.end_date),
          title: val.role || '',
          allocation: allocationValueToPercentage(val.percentage),
          hours: val.hours_per_month,
          allocationPerMonth,
          costOfOverhead: val.cost_of_overhead || 0,
        };

        if (clientIndex >= 0) {
          acc[clientIndex]?.itemsList?.push(VacancyPosition);
          return acc;
        } else {
          const consultant: VacancySectionItem = {
            id: `${assignmentData?.clientInfo?.id}`,
            general: {
              id: `${assignmentData?.clientInfo?.id}`,
              name: `${assignmentData?.clientInfo?.name}`,
              costs: {
                total: 0,
                months: [],
              },
            },
            itemsList: [VacancyPosition],
          };
          return [...acc, consultant];
        }
      }, [])
      .map(client => {
        const costsPerMonth: MonthCost[] = [];

        client.itemsList?.forEach(position => {
          position.allocationPerMonth.forEach(month => {
            costsPerMonth[month.monthIndex] = {
              monthIndex: month.monthIndex,
              cost: round((costsPerMonth[month.monthIndex]?.cost || 0) + (month?.cost || 0), 6),
            };
          });
        }, [] as MonthCost[]);

        if (client.general) {
          const costList = costsPerMonth.filter(cost => cost);

          client.general.costs = {
            months: costList,
            total: getTotalCost(costList),
          };
        }

        client.itemsList.sort(sortByOrderWithKey('title'));
        return client;
      })
      .sort(sortByOrderWithKey('general.name'));
  }, [Array.from(workingDaysCollection)]);

  const vacanciesSectionTotals = useMemo((): TotalCosts => {
    const totalCostsPerMonth = calculateTotalsForPeriod(getVacanciesSection());

    return {
      months: totalCostsPerMonth,
      total: getTotalCost(totalCostsPerMonth),
    };
  }, [getVacanciesSection]);

  const getExpensesSection = useCallback(() => {
    if (!data || !data.V2getBudgetReportByID.expenseInfo) {
      return [];
    }

    const currency = getCurrencyCode(data.V2getBudgetReportByID.budgetInfo?.projectInfo?.ccy);

    return data.V2getBudgetReportByID.expenseInfo
      .reduce<Array<ExpensesSectionItem>>((acc, val) => {
        const assignmentData = val.assignementInfo;
        const clientId = assignmentData?.clientInfo?.id || 0;
        const clientIndex = acc.findIndex(client => client?.id === `${clientId}`);

        const costPerMonth = displayExpansesCostsPerMonth({
          startDate: assignmentData?.start_date,
          endDate: assignmentData?.end_date,
          cost: val.cost,
        });

        const ExpensesNote: AssignmentExpenses = {
          id: `${assignmentData?.id}`,
          clientId,
          currency,
          comment: assignmentData?.notes,
          date: `${getFormattedDate(assignmentData?.start_date)} - ${getFormattedDate(
            assignmentData?.end_date,
          )}`,
          startDate: moment(assignmentData?.start_date),
          endDate: moment(assignmentData?.end_date),
          title: `${assignmentData?.text}`,
          cost: val.cost,
          costPerMonth,
        };

        if (clientIndex >= 0) {
          acc[clientIndex]?.itemsList?.push(ExpensesNote);
          return acc;
        } else {
          const consultant: ExpensesSectionItem = {
            id: `${assignmentData?.clientInfo?.id}`,
            general: {
              id: `${assignmentData?.clientInfo?.id}`,
              name: assignmentData?.clientInfo?.name || 'No data From API',
              costs: {
                total: 0,
                months: [],
              },
            },
            itemsList: [ExpensesNote],
          };
          return [...acc, consultant];
        }
      }, [])
      .map(note => {
        const costsPerMonth: MonthCost[] = [];

        note.itemsList?.forEach(position => {
          position.costPerMonth.forEach(month => {
            costsPerMonth[month.monthIndex] = {
              monthIndex: month.monthIndex,
              cost: (costsPerMonth[month.monthIndex]?.cost || 0) + (month?.cost || 0),
            };
          });
        }, [] as MonthCost[]);

        if (note.general) {
          const costList = costsPerMonth.filter(cost => cost);

          note.general.costs = {
            months: costList,
            total: getTotalCost(costList),
          };
        }

        note.itemsList.sort(sortByOrderWithKey('title'));
        return note;
      })
      .sort(sortByOrderWithKey('general.name'));
  }, [data]);

  const expensesSectionTotals = useMemo((): TotalCosts => {
    const totalCostsPerMonth = calculateTotalsForPeriod(getExpensesSection());

    return {
      months: totalCostsPerMonth,
      total: getTotalCost(totalCostsPerMonth),
    };
  }, [getExpensesSection]);

  const invoicedTotals = useMemo((): InvoicedTotal => {
    let totalCostsPerMonth;
    const budgetInfo = data?.V2getBudgetReportByID.budgetInfo;

    if (!data?.V2getBudgetReportByID.billingInfo?.length) {
      totalCostsPerMonth = Array.from({ length: MONTHS_IN_A_YEAR }, (_, k) => ({
        monthIndex: k + 1, //add +1 cause monthIndex starts with 1 on the API level and with 0 on the application level;
        cost: 0,
        projectId: budgetInfo?.project_id,
        userId: budgetInfo?.managerInfo?.id,
      }));
    } else {
      totalCostsPerMonth =
        Array.from({ length: MONTHS_IN_A_YEAR }, (_, k) => {
          const hasZeroIndex = Boolean(
            data?.V2getBudgetReportByID.billingInfo?.find(DBMonth => DBMonth.month === 0),
          );
          const localMonthIndex = hasZeroIndex ? k : k + 1;
          const createdBilling = data?.V2getBudgetReportByID.billingInfo?.find(
            DBMonth => DBMonth.month === localMonthIndex,
          ) as Maybe<BillingReportModel>;

          if (!!createdBilling) {
            return {
              monthIndex: createdBilling.month,
              status: createdBilling.status,
              cost: createdBilling.total || 0,
              projectId: budgetInfo?.project_id,
              userId: budgetInfo?.managerInfo?.id,
              billingId: createdBilling.id,
            };
          } else {
            return {
              monthIndex: localMonthIndex,
              cost: 0,
              projectId: budgetInfo?.project_id,
              userId: budgetInfo?.managerInfo?.id,
              hasZeroIndex,
            };
          }
        }).sort(sortByOrderWithKey('monthIndex')) || [];
    }

    return {
      months: totalCostsPerMonth,
      total: getTotalCost(totalCostsPerMonth),
    };
  }, [data]);

  const invoicedTotalsByEntities = useMemo((): TotalByEntities => {
    const { monthlyTotalPerEntity, entities } = entitiesTotal;
    return {
      annualTotalPerEntity: calculateEntityTotals(monthlyTotalPerEntity),
      monthlyTotalPerEntity,
      entities: entities,
    };
  }, [data, entitiesTotal]);

  const forecastedTotalsByEntities = useMemo((): TotalByEntities => {
    const { monthlyTotalPerEntity, entities } = entitiesTotalForecasted;
    return {
      annualTotalPerEntity: calculateEntityTotals(monthlyTotalPerEntity),
      monthlyTotalPerEntity,
      entities: entities,
    };
  }, [data, entitiesTotalForecasted]);

  const getAssignmentIdsByMonth = useCallback(
    (monthIndex: number) => {
      const consultantsIds: number[] = [];
      const expensesIds: number[] = [];

      getConsultantsSection().forEach(client => {
        client.itemsList.forEach(project => {
          const currentMonthAllocation = project.allocationPerMonth.find(
            costItem => costItem.monthIndex === monthIndex && !!costItem.cost,
          );
          if (currentMonthAllocation) {
            consultantsIds.push(+project.id);
          }
        });
      });

      getExpensesSection().forEach(client => {
        client.itemsList.forEach(item => {
          const currentMonthAllocation = item.costPerMonth.find(
            costItem => costItem.monthIndex === monthIndex && !!costItem.cost,
          );
          if (currentMonthAllocation) {
            expensesIds.push(+item.id);
          }
        });
      });

      return [...consultantsIds, ...expensesIds];
    },
    [getConsultantsSection, getExpensesSection],
  );

  const actualGrossMargin = useMemo((): GrossMarginData => {
    const grossMarginByMonths = generateGrossMarginArray();

    if (!data?.V2getBudgetReportByID?.billingInfo?.length) {
      return { grossMarginByMonths, totalAverageGrossMarginPerYear: DEFAULT_GROSS_MARGIN };
    }

    const actualGrossMarginByMonths = grossMarginByMonths.map((actualGrossMargin, index) => {
      const createActualGrossMargin = data?.V2getBudgetReportByID?.billingInfo?.find(DBMonth => {
        return DBMonth?.month === index + 1;
      }) as Maybe<BillingReportModel>;

      if (createActualGrossMargin) {
        actualGrossMargin.averageGrossMarginPerMonth = createActualGrossMargin.gross_margin || null;
      }

      return actualGrossMargin;
    });

    return {
      grossMarginByMonths: actualGrossMarginByMonths,
      totalAverageGrossMarginPerYear: countAverageGrossMarginPerYear(actualGrossMarginByMonths),
    };
  }, [data]);

  const forecastedGrossMargin = useMemo((): GrossMarginData => {
    const grossMarginByMonths = generateGrossMarginArray();

    if (!data || !data?.V2getBudgetReportByID?.forecastInfo) {
      return {
        grossMarginByMonths,
        totalAverageGrossMarginPerYear: DEFAULT_GROSS_MARGIN,
      };
    }

    const forecastedMarginByMonths = countAverageGrossMarginPerMonth(
      data.V2getBudgetReportByID.forecastInfo,
    );

    return {
      grossMarginByMonths: forecastedMarginByMonths,
      totalAverageGrossMarginPerYear: countAverageGrossMarginPerYear(forecastedMarginByMonths),
    };
  }, [data?.V2getBudgetReportByID?.forecastInfo]);

  useEffect(() => {
    if (data) {
      const info = data.V2getBudgetReportByID.budgetInfo?.budgetingSettingInfo;
      setBudgetingState({
        accessLevel: data.V2getBudgetReportByID.access_level,
        projectId: data.V2getBudgetReportByID.budgetInfo?.project_id,
        projectName: data.V2getBudgetReportByID.budgetInfo?.projectInfo?.name,
        settingId: info?.id,
        financialYear: info?.financial_year,
        budgetReportId: data.V2getBudgetReportByID.budgetInfo?.id
          ? data.V2getBudgetReportByID.budgetInfo?.id
          : Number(budgetReportId),
        currency: data.V2getBudgetReportByID.budgetInfo?.projectInfo?.ccy as CurrencyEnum,
        assignmentDefaults: {
          ...assignmentDefaults,
          costOfOverhead: {
            EUR: info?.cost_of_overhead_eur || COST_OF_OVERHEAD_DEFAULT,
            USD: info?.cost_of_overhead_usd || COST_OF_OVERHEAD_DEFAULT,
          },
        },
        existingBudgets: data.V2getBudgetReportByID.existingBudgets,
        submitted: data.V2getBudgetReportByID.budgetInfo?.submitted,
      });
    }
  }, [data]);

  return {
    loading,
    budgetingInfo,
    consultantsSection: getConsultantsSection(),
    vacanciesSection: getVacanciesSection(),
    expensesSection: getExpensesSection(),
    consultantsSectionTotals,
    vacanciesSectionTotals,
    expensesSectionTotals,
    invoicedTotals,
    invoicedTotalsByEntities,
    forecastedTotalsByEntities,
    getAssignmentIdsByMonth,
    forecastGrossMargin: forecastedGrossMargin,
    actualGrossMargin: actualGrossMargin,
  };
};
