import { IFilters } from 'valtech-core/common/types';

type UpdateTableFiltersParams = {
  inputQuery: string;
  field: string;
  filterModel?: string;
  setTableFilters: (value: any) => void;
};

export const updateTableFilters = ({
  inputQuery,
  field,
  filterModel = '',
  setTableFilters,
}: UpdateTableFiltersParams): void => {
  setTableFilters(prevTableFilters => {
    const newTableFilters: IFilters[] = prevTableFilters.filter(f => f.query.length > 0);
    const foundFilterIndex = newTableFilters.findIndex(
      f => f.field === field && f.filterModel === filterModel,
    );

    if (foundFilterIndex > -1) {
      if (inputQuery !== '') {
        newTableFilters[foundFilterIndex] = {
          ...newTableFilters[foundFilterIndex],
          query: inputQuery,
        };
      } else {
        newTableFilters.splice(foundFilterIndex, 1);
      }
    } else {
      newTableFilters.push({
        query: inputQuery,
        field,
        filterModel,
      });
    }

    return newTableFilters;
  });
};
