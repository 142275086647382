import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';

import { ADD_FTL, SAVE_FTL } from 'valtech-core/common/ftl';

interface IHolidayCalendarHeaderProps {
  disabled?: boolean;
  callback: (event: Event | React.SyntheticEvent) => void;
}

const HolidayCalendarHeader: FC<IHolidayCalendarHeaderProps> = ({ disabled, callback }) => {
  const methods = useFormContext();
  const { l10n } = useLocalization();
  const { id: calendarID } = useParams();
  const { isEditing } = useEditingContext();

  const title = methods.getValues('calendar.data.title');

  return (
    <SettingsHeaderReusable
      title={title}
      callback={callback}
      disabled={disabled || isEditing}
      btnTitle={`${calendarID ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}`}
    />
  );
};

export default HolidayCalendarHeader;
