export interface IDataBudgetTypes {
  id: number;
  type: string;
}
export interface IFormattedForAutocompleteData {
  id: number;
  label: string;
}
export interface IFormattedForSelectData {
  id: number;
  title: string;
  value: string;
}

export type IUpdateBudgetData = {
  budgetId: number;
  columnName: string;
  value: boolean;
};

export const formattedForAutocompleteBudgetTypesData = (
  arr: IDataBudgetTypes[],
): Array<IFormattedForAutocompleteData> => {
  const newArrayData: Array<IFormattedForAutocompleteData> = arr.map(el => {
    return {
      id: el.id,
      label: el.type,
    };
  });
  return newArrayData;
};

export const formattedForSelectBudgetTypesData = (
  arr: IDataBudgetTypes[],
): Array<IFormattedForSelectData> => {
  const newArrayData: Array<IFormattedForSelectData> = arr.map(el => {
    return {
      id: el.id,
      title: el.type.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
      value: el.type.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
    };
  });
  return newArrayData;
};
