export const checkIsAllowedFileFormat = (files: FileList | null, formats: string[]): boolean => {
  if (!(files && files[0]) || !formats.length) {
    return false;
  }

  for (const format of formats) {
    if (format.includes(files[0].type) && !!files[0].type) {
      return true;
    }
  }

  return false;
};
