import React, { FC, useCallback, useMemo, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { getFormattedDate } from 'common/utils/formatDate';
import { updateTableFilters } from 'common/utils/updateTableFilters';
import SearchInput from 'components/SearchInput';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';
import { AllTableStatus, TableSearchFieldsEnum } from 'src/common/types';
import { chipColorPicker } from 'src/common/utils/chipColorPicker';
import { handleSearchInput } from 'src/common/utils/handleSearchModel';
import { splitString } from 'src/common/utils/splitAndCapitalize';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { ACTION_FTL, CLEAR_ALL_FTL, LABEL_FTL, NAME_FTL } from 'valtech-core/common/ftl';
import { OrderDirection } from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { IUseEntitiesReturn } from '../AllEntities.hook';
import HandleAllEntitiesTableRowEdit from '../HandleAllEntitiesTableRowEdit/HandleAllEntitiesTableRowEdit';
import { headData } from './AllEntitiesTable.const';
import s from './AllEntitiesTable.module.scss';

const AllEntitiesTable: FC<IUseEntitiesReturn> = ({
  loading,
  entities,
  sortingType,
  activeColumn,
  handleSorting,
  rowsPerPage,
  page,
  tableFilters,
  total,
  setRowsPerPage,
  setPage,
  setTableFilters,
  setSortingType,
  setModel,
  setSortBy,
  setActiveColumn,
  setFilter,
}) => {
  const { l10n } = useLocalization();
  const [isInputClear, setIsInputClear] = useState(true);

  const isData = useMemo(() => !loading && entities.length > 0, [loading, entities?.length]);

  const handleSearch = useCallback(data => {
    if (data.fieldName === LABEL_FTL) {
      updateTableFilters({
        inputQuery: data.query,
        field: TableSearchFieldsEnum.Label,
        filterModel: '',
        setTableFilters,
      });
    }

    return;
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilter([]);
    setTableFilters([]);
    setSortingType(OrderDirection.Desc);
    setModel('');
    setSortBy(TableSearchFieldsEnum.Label);
    setPage(0);
    setRowsPerPage(DEFAULT_REQUEST_LIMIT);
    setActiveColumn(NAME_FTL);
    setIsInputClear(false);
  }, []);

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead className={s.tableHead}>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />
          <TableRow>
            {headData.map(item => {
              const Searchable = item.l10n === LABEL_FTL;
              return (
                <TableCell key={item.l10n}>
                  {Searchable && (
                    <SearchInput
                      placeholder='Search'
                      fieldName={item.l10n}
                      onSearch={handleSearch}
                      boxProps={{ sx: { maxWidth: '350px' } }}
                      inputDefaultValue={handleSearchInput(tableFilters, item)}
                      size='small'
                      isInputClear={isInputClear}
                      onClear={() => setIsInputClear(true)}
                    />
                  )}
                  {item.l10n === ACTION_FTL && (
                    <Button
                      type='button'
                      size='small'
                      variant='contained'
                      onClick={handleClearFilters}>
                      {l10n.getString(CLEAR_ALL_FTL)}
                    </Button>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={s.tableBody}>
          {loading ? (
            <TableSkeleton
              cellsNumber={headData.length}
              rowsNumber={rowsPerPage}
              page={page}
              total={total}
            />
          ) : (
            entities?.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.label}</TableCell>
                <TableCell>
                  {getFormattedDate(row.updated_at as string, DateMask.DD_MM_YYYY_HH)}
                </TableCell>
                <TableCell>{row.userName}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    label={splitString(row.status)}
                    variant='outlined'
                    color={chipColorPicker(row.status as AllTableStatus)}
                    className='text-capitalized'
                  />
                </TableCell>
                <TableCell>
                  <HandleAllEntitiesTableRowEdit
                    order={row.order}
                    rowRecord={{
                      id: row.id,
                      label: row.label,
                      updatedAt: row.updated_at,
                      updatedBy: row.updated_by,
                      status: row.status,
                      order: row.order,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            count={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            isData={isData}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default AllEntitiesTable;
