import { useMemo } from 'react';

import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import { DEFAULT_SKIP_QUANTITY } from 'src/common/constants';

import { MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { OrderDirection, useGetBillingHistoryQuery } from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

type BillingHistoryListItem = {
  id: number;
  billingsTitle: string;
  total: Maybe<number>;
};

type UseBillingHistoryReturn = {
  billingHistoryList: BillingHistoryListItem[];
  allBillingsTotal: number;
};

export const useBillingHistory = (): UseBillingHistoryReturn => {
  const {
    state: { billingInfo },
  } = useBillingState();

  const { data } = useGetBillingHistoryQuery({
    variables: {
      pagination: {
        limit: MAX_REQUEST_LIMIT,
        skip: DEFAULT_SKIP_QUANTITY,
      },
      orderBy: {
        model: '',
        sortBy: 'month',
        sortDirection: OrderDirection.Asc,
      },
      filter: [
        {
          filterModel: '',
          field: 'title',
          query: String(billingInfo?.financialYear),
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  const billingHistoryData = useMemo(() => {
    if (!data || !data.sendAnArrayOfBillingReports.data)
      return {
        allBillingsTotal: 0,
        formattedBillings: [],
      };

    const currentMonthIndex = billingInfo?.billingMonth || 0;
    let allBillingsTotal = 0;
    const formattedBillings: BillingHistoryListItem[] = data.sendAnArrayOfBillingReports.data
      ?.filter(
        report => report.project_id === billingInfo?.project_id && report.month < currentMonthIndex,
      )
      .map(item => {
        allBillingsTotal += Number(item.total);
        return {
          id: item.id,
          billingsTitle: `${item.title.split(' ')[0].slice(0, 3)} ${item.title
            .split(' ')[1]
            .slice(2, 4)}`,
          total: Number(item.total),
        };
      });
    return {
      allBillingsTotal,
      formattedBillings,
    };
  }, [data, billingInfo]);

  return {
    billingHistoryList: billingHistoryData.formattedBillings,
    allBillingsTotal: billingHistoryData.allBillingsTotal,
  };
};
