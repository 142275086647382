import React, { FC, useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import AutocompleteField from 'form/AutocompleteField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import {
  ACCOUNT_FTL,
  ADD_FTL,
  BUDGET_FTL,
  CANCEL_FTL,
  EDIT_FTL,
  FINANCIAL_YEAR_FTL,
  SAVE_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useBudgetFormData } from './BudgetingForm.hooks';

import { IFormattedBudgetData } from './BudgetingForm.utils';

import { EFormFieldName } from '../form.types';

export interface IBudgetingFormProps {
  label: string;
  id: number;
}
export interface IEditBudgetingFormProps<T> {
  onClose: VoidFunction;
  initialValues?: {
    projectId: { id: number };
    budgetingSettingId: { id: number };
  };
  defaultValues?: Partial<T>;
  onSubmit?: (data: T) => void;
  budgetId?: number;
  hasDelete?: boolean;
}

const BudgetingForm: FC<IEditBudgetingFormProps<IBudgetingFormProps>> = ({
  onClose,
  initialValues,
}) => {
  const { l10n } = useLocalization();
  const { control, handleSubmit } = useForm<IFormattedBudgetData>({
    defaultValues: initialValues,
  });

  const { projectsList, budgetSettingsList, loading, createBudget } = useBudgetFormData();

  const formSubmit: SubmitHandler<IFormattedBudgetData> = useCallback(
    data => {
      createBudget(data).then(success => success && onClose());
    },

    [onClose],
  );

  const onCancel = () => {
    onClose();
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {initialValues
          ? `${l10n.getString(EDIT_FTL)} ${BUDGET_FTL}`
          : `${l10n.getString(ADD_FTL)} ${BUDGET_FTL}`}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AutocompleteField
            label={l10n.getString(FINANCIAL_YEAR_FTL)}
            name={EFormFieldName.BudgetingSettingId}
            control={control as unknown as FieldControl}
            data={budgetSettingsList}
            validate={value => requiredStringValidator(value.label)}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            label={l10n.getString(ACCOUNT_FTL)}
            name={EFormFieldName.ProjectId}
            control={control as unknown as FieldControl}
            data={projectsList}
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 1, mt: 6 }}>
        <Button onClick={handleSubmit(formSubmit)} variant='contained' sx={{ mx: 1 }}>
          {initialValues ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Box>
    </form>
  );
};

export default BudgetingForm;
