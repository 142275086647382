import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { FilterModelEnum, TableSearchFieldsEnum } from 'common/types';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import { getFormattedDate } from 'common/utils/formatDate';
import { updateTableFilters } from 'common/utils/updateTableFilters';
import SearchInput from 'components/SearchInput';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';
import { IUseBudgetingReturn } from 'pages/Budgetings/Budgetings.hook';
import { PROJECT_DEFAULT_OBJ, PageTypes } from 'src/common/apolloState/filters/filters';
import { clearFiltersState } from 'src/common/apolloState/filters/filters.actions';
import { handleSearchInput } from 'src/common/utils/handleSearchModel';

import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { BUDGETS_FTL, CLEAR_ALL_FTL, DELIVERY_MANAGER_FTL } from 'valtech-core/common/ftl';
import { OrderDirection } from 'valtech-core/common/gql/generated';
import { BUDGETING_ROUTE } from 'valtech-core/common/routes';
import { DateMask } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { headData } from './BudgetingTable.const';
import s from './BudgetingTable.module.scss';

interface IBudgetingTableProps extends IUseBudgetingReturn {
  drawerClearState: VoidFunction;
}

const BudgetingTable: FC<IBudgetingTableProps> = ({
  page,
  setPage,
  budgetings,
  setRowsPerPage,
  rowsPerPage,
  loading,
  total,
  sortingType,
  tableFilters,
  setSortingType,
  setModel,
  setSortBy,
  setFilter,
  setTableFilters,
  setProjectFilter,
  drawerClearState,
}) => {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const [activeColumn, setActiveColumn] = useState(BUDGETS_FTL);
  const [isInputClear, setIsInputClear] = useState(true);
  const isData = !loading && budgetings.length > 0;

  const handleSorting = useCallback(
    item => {
      setActiveColumn(item.l10n);
      setModel(item.model);
      setSortBy(item.sortBy);
      setSortingType(OrderDirection.Desc);

      if (item.l10n === activeColumn) {
        setSortingType(
          sortingType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        );
        return;
      }
    },
    [activeColumn, sortingType],
  );

  const navigateToPage = useCallback((id: number) => {
    navigate(BUDGETING_ROUTE.replace(':id', id.toString()));
  }, []);

  const handleSearch = useCallback(data => {
    if (data.fieldName === BUDGETS_FTL) {
      updateTableFilters({
        inputQuery: data.query,
        field: TableSearchFieldsEnum.Title,
        filterModel: FilterModelEnum.Budgets,
        setTableFilters,
      });
    }

    if (data.fieldName === DELIVERY_MANAGER_FTL) {
      updateTableFilters({
        inputQuery: data.query,
        field: TableSearchFieldsEnum.Name,
        filterModel: FilterModelEnum.DeliveryManager,
        setTableFilters,
      });
    }

    return;
  }, []);

  const handleClearFilters = () => {
    setFilter([]);
    setTableFilters([]);
    setProjectFilter(PROJECT_DEFAULT_OBJ);
    clearFiltersState(PageTypes.BUDGETING)();
    setSortingType(OrderDirection.Desc);
    setModel(FilterModelEnum.Manager);
    setSortBy(TableSearchFieldsEnum.Name);
    setActiveColumn(DELIVERY_MANAGER_FTL);
    setIsInputClear(false);
    drawerClearState();
  };

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead className={s.tableHead}>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />
          <TableRow>
            {headData.map(item => {
              const Searchable = item.l10n === BUDGETS_FTL || item.l10n === DELIVERY_MANAGER_FTL;
              return (
                <TableCell key={item.l10n}>
                  {Searchable && (
                    <SearchInput
                      placeholder='Search'
                      fieldName={item.l10n}
                      onSearch={handleSearch}
                      inputDefaultValue={handleSearchInput(tableFilters, item)}
                      boxProps={{ sx: { maxWidth: '350px' } }}
                      size='small'
                      isInputClear={isInputClear}
                      onClear={() => setIsInputClear(true)}
                    />
                  )}
                  {item.l10n === 'status' && (
                    <Button
                      type='button'
                      size='small'
                      variant='contained'
                      onClick={handleClearFilters}>
                      {l10n.getString(CLEAR_ALL_FTL)}
                    </Button>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody className={s.tableBody}>
          {loading && !budgetings ? (
            <TableSkeleton
              cellsNumber={headData.length}
              rowsNumber={rowsPerPage}
              page={page}
              total={total}
            />
          ) : (
            budgetings?.map(row => (
              <TableRow key={row.id} onClick={() => navigateToPage(row.id)}>
                <TableCell>{row.budgetsTitle}</TableCell>
                <TableCell>{row.project}</TableCell>
                <TableCell>{row.manager}</TableCell>
                <TableCell>{getFormattedDate(row.lastUpdated, DateMask.DD_MM_YYYY_HH)}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    label={row.status}
                    variant='outlined'
                    color={chipColorPicker(row.status)}
                    className='text-capitalized'
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            count={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            isData={isData}
          />
        </TableFooter>
      </Table>

      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          }}
        />
      )}
    </TableContainer>
  );
};
export default BudgetingTable;
