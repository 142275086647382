import React, { FC, useCallback, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { MAX_QUANTITY, PROJECT_FILTER_DEFAULT_OBJ } from 'common/constants';
import { useBillings } from 'pages/AllBillings/AllBillings.hook';
import AllBillingsTable from 'pages/AllBillings/AllBillingsTable';
import { PageTypes } from 'src/common/apolloState/filters/filters';
import { setProjectFilter } from 'src/common/apolloState/filters/filters.actions';
import useGetProjects, { defaultFilterByActiveProjects } from 'src/common/hooks/useGetProjects';
import { getProjectsTitle } from 'src/common/utils/handleProjectsData';

import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { ALL_FTL } from 'valtech-core/common/ftl';
import Drawer, { DrawerItemsList } from 'valtech-core/ui/Drawer';

const AllBillings: FC = () => {
  const [isClear, setClear] = useState<boolean>(false);

  const { l10n } = useLocalization();
  const { projects } = useGetProjects({
    paramLimit: MAX_QUANTITY,
    paramFilter: [defaultFilterByActiveProjects],
  });

  const billingsHookData = useBillings();

  const handleAccountFilter = useCallback(
    (title: string): void => {
      billingsHookData.setPage(0);
      billingsHookData.setRowsPerPage(DEFAULT_REQUEST_LIMIT);

      if (title.toLowerCase() === ALL_FTL) {
        billingsHookData.setProjectFilter({
          ...PROJECT_FILTER_DEFAULT_OBJ,
          query: l10n.getString(ALL_FTL),
        });

        setProjectFilter(PageTypes.BILLING)({
          ...PROJECT_FILTER_DEFAULT_OBJ,
          query: l10n.getString(ALL_FTL),
        });
        return;
      }

      billingsHookData.setProjectFilter({ ...PROJECT_FILTER_DEFAULT_OBJ, query: title });
      setProjectFilter(PageTypes.BILLING)({ ...PROJECT_FILTER_DEFAULT_OBJ, query: title });
    },
    [billingsHookData.setProjectFilter, billingsHookData.tableFilters],
  );

  const handleDrawerClear = useCallback(() => {
    setClear(true);

    setTimeout(() => {
      setClear(false);
    }, 100);
  }, []);

  const formattedProjects = projects.map((project): DrawerItemsList => {
    return {
      title: project.title,
      icon: <AutoAwesomeMosaicIcon />,
      onClick: () => handleAccountFilter(project.title),
    };
  });

  return (
    <Drawer
      drawerItemsList={formattedProjects}
      drawerWidth={256}
      activeItemOnLoad={getProjectsTitle(billingsHookData.projectFilter, projects, l10n)}
      clearState={isClear}>
      <AllBillingsTable {...billingsHookData} drawerClearState={handleDrawerClear} />
    </Drawer>
  );
};

export default AllBillings;
