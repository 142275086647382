import { Moment } from 'moment/moment';

import moment from 'moment';

import { DateMask, Maybe } from 'valtech-core/common/types';

type GetFirstAndLastDayOfMonth = (
  value: Maybe<string> | null,
  format: DateMask,
) => {
  startDay: Moment;
  endDay: Moment;
};

export const getFirstAndLastDayOfMonth: GetFirstAndLastDayOfMonth = (
  value: Maybe<string> | null,
  format = DateMask.MMMM_YYYY,
) => {
  return {
    startDay: moment(value, format).startOf('month'),
    endDay: moment(value, format).endOf('month'),
  };
};
