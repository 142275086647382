import { INTEGER_OR_DECIMAL_REGEXP } from 'valtech-core/common/form/const';
import { invalidNumberValidator } from 'valtech-core/common/form/validators';
import {
  ERROR_EXCHANGE_RATE_FORMAT_FTL,
  EUR_TO_USD_FTL,
  MONTH_FTL,
  USD_TO_EUR_FTL,
} from 'valtech-core/common/ftl';

export enum ExchangeRateParams {
  Month = 'month',
  UsdToEur = 'usd_to_eur',
  EurToUsd = 'eur_to_usd',
}

export const headData = [
  { l10n: MONTH_FTL, model: ExchangeRateParams.Month, sortBy: ExchangeRateParams.Month },
  { l10n: USD_TO_EUR_FTL, model: ExchangeRateParams.UsdToEur, sortBy: ExchangeRateParams.UsdToEur },
  { l10n: EUR_TO_USD_FTL, model: ExchangeRateParams.EurToUsd, sortBy: ExchangeRateParams.EurToUsd },
];

export const exchangeValidator = invalidNumberValidator({
  invalid: ERROR_EXCHANGE_RATE_FORMAT_FTL,
  regexp: INTEGER_OR_DECIMAL_REGEXP,
});

export const EXCHANGE_RATE_ID_PREFIX = 'local_';
