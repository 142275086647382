import { CLIENT_MANAGER_FTL, EMAIL_FTL, STATUS_FTL, TITLE_FTL } from 'valtech-core/common/ftl';
import { ActivationStatus } from 'valtech-core/common/gql/generated';

export enum AssociatedClients {
  ID = 'id',
  Name = 'name',
  ManagerName = 'manager_name',
  ManagerEmail = 'manager_email',
  Status = 'status',
}

export const headData = [
  { l10n: TITLE_FTL, model: '', sortBy: AssociatedClients.Name },
  { l10n: CLIENT_MANAGER_FTL, model: '', sortBy: AssociatedClients.ManagerName },
  { l10n: EMAIL_FTL, model: '', sortBy: AssociatedClients.ManagerEmail },
  { l10n: STATUS_FTL, model: '', sortBy: AssociatedClients.Status },
];

export interface IAssociatedClientsTable {
  id: number;
  name: string;
  manager_name: string;
  manager_email: string;
  status: ActivationStatus;
}

export const defaultValues: Omit<IAssociatedClientsTable, 'id'> = {
  name: '',
  manager_name: '',
  manager_email: '',
  status: ActivationStatus.Active,
};

export const tableData = [
  {
    id: '1',
    title: 'General',
    clientManager: 'Elvire Biscard',
    email: 'elvire.biscard@valtech.com',
    status: 'active',
  },
];
