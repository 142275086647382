import { ERoles } from 'src/common/types';

export const selectMockRoles = [
  { id: '1', title: ERoles.Administrator, value: ERoles.Administrator },
  { id: '2', title: ERoles.User, value: ERoles.User },
];

export const defaultInitialValues = {
  userId: 0,
  email: '',
  name: '',
  role: '',
  status: '',
};
