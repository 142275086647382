import { useCallback } from 'react';

import { InputMaybe, RecordStatus } from 'valtech-core/common/gql/generated';
import { useToggle } from 'valtech-core/utils/useToggle';

type UseHandleTableRowEditReturn = {
  isOpened: boolean;
  toggleForm: VoidFunction;
  getAccountInitialValues: (item: Account) => IAccountFormProps;
};

export type Account = {
  id: string;
  name: string;
  status: string;
  updatedAt: string;
  updatedBy: string | number;
};

type UseHandleTableRowEditParams = {
  rowRecord: Account;
};

export interface IAccountFormProps {
  name: string;
  status: InputMaybe<RecordStatus>;
  id: string;
  updatedBy: number | string;
}

export const useHandleAccountsTableRowEdit = ({
  rowRecord,
}: UseHandleTableRowEditParams): UseHandleTableRowEditReturn => {
  const [isOpened, toggleForm] = useToggle(false);

  const getAccountInitialValues = useCallback(
    (item: Account): IAccountFormProps => {
      return {
        name: item.name,
        status: item.status as InputMaybe<RecordStatus>,
        id: item.id,
        updatedBy: item.updatedBy,
      };
    },
    [rowRecord],
  );

  return {
    isOpened,
    toggleForm,
    getAccountInitialValues,
  };
};
