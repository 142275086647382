import { BUDGETS_FTL, LAST_UPDATED_FTL, STATUS_FTL, UPDATED_BY_FTL } from 'valtech-core/common/ftl';

export const headData = [
  { l10n: BUDGETS_FTL, model: '', sortBy: 'title' },
  { l10n: UPDATED_BY_FTL, model: '', sortBy: 'user_id' },
  { l10n: LAST_UPDATED_FTL, model: '', sortBy: 'modified_date' },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: 'status',
  },
];
