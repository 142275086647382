import React, { FC, useMemo } from 'react';

import { useLocalization } from '@fluent/react';
import { EFormFieldName } from 'src/components/_forms/form.types';
import AutocompleteField from 'src/form/AutocompleteField';
import { AutocompleteValue } from 'src/form/AutocompleteField/AutocompleteField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import { ACCOUNT_FTL, NAME_FTL, SELECT_ACCOUNT_FLT } from 'valtech-core/common/ftl';
import { AnyArray, FieldControl } from 'valtech-core/common/types';

type AddAccountManagerProps = {
  control: FieldControl;
  accounts?: AnyArray;
};

const AddAccount: FC<AddAccountManagerProps> = ({ control, accounts }) => {
  const { l10n } = useLocalization();

  const updatedAccounts = useMemo((): AutocompleteValue[] => {
    if (!accounts?.length) return [];

    return accounts.map(account => {
      return {
        label: account.title,
        id: account.id,
      };
    });
  }, [accounts]);

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ mt: 5, fontSize: 20 }}>{`${l10n.getString(ACCOUNT_FTL)} ${l10n.getString(
          NAME_FTL,
        )}`}</Box>
      </Grid>
      {!!updatedAccounts?.length && (
        <Grid item xs={6}>
          <AutocompleteField
            label={l10n.getString(SELECT_ACCOUNT_FLT)}
            name={EFormFieldName.AccountId}
            data={updatedAccounts}
            control={control as unknown as FieldControl}
            validate={value => requiredStringValidator(value.label)}
            disabled={!updatedAccounts.length}
          />
        </Grid>
      )}
    </>
  );
};

export default AddAccount;
