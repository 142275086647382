import React, { FC, memo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MUIAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface IAlertProps {
  btnName?: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  alertMsg: string;
  className?: string;
  onClose?: VoidFunction;
}

const Alert: FC<IAlertProps> = ({
  btnName = '',
  severity = 'warning',
  alertMsg,
  className,
  onClose,
}) => {
  return (
    <MUIAlert
      action={
        <Box alignItems={'center'} flexWrap={'nowrap'} display={'flex'}>
          {btnName && (
            <Button color='inherit' size='small'>
              {btnName}
            </Button>
          )}

          {onClose && (
            <IconButton size='small' color='inherit' onClick={onClose}>
              <CloseIcon style={{ width: 15, height: 15 }} />
            </IconButton>
          )}
        </Box>
      }
      severity={severity}
      className={className}
    >
      {alertMsg}
    </MUIAlert>
  );
};

export default memo(Alert);
