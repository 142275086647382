import { useReactiveVar } from '@apollo/client';

import { FiltersState, filtersVar } from './filters';

type UseFiltersStateReturn = FiltersState;

export const useFiltersState = (): UseFiltersStateReturn => {
  const state = useReactiveVar(filtersVar);

  return state;
};
