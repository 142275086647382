import React, { FC } from 'react';

import { FieldValues, UseFieldArrayAppend, useForm } from 'react-hook-form';

import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { Variant } from 'common/types';
import SelectFiled from 'form/SelectField/SelectField';
import TextField from 'form/TextField/TextField';
import { selectMockStatus } from 'src/common/constants';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {
  emailValidator,
  requiredLongStringValidator,
  requiredMidStringValidator,
  requiredStringValidator,
} from 'valtech-core/common/form/validators';
import { FieldControl } from 'valtech-core/common/types';

import { AssociatedClients, defaultValues } from '../AssociatedClientsTable.const';
import {
  IAddNewAssociatedClientParams,
  useAddNewAssociatedClient,
} from './AddNewAssociatedClient.hook';

import s from 'pages/ManageSettings/SingleBudgeting/SingleBudgetingContent/shared.module.scss';

export interface IAssociatedClientItem {
  id: number;
  name: string;
  manager_name: string;
  manager_email: string;
  status: string;
}

export interface IAddNewAssociatedClientProps {
  append: UseFieldArrayAppend<FieldValues, 'associatedClients.clients'>;
}

const AddNewAssociatedClient: FC<IAddNewAssociatedClientProps> = ({ append }) => {
  const methods = useForm({ defaultValues });
  const { control, setValue } = methods;
  const { addNewAssociatedClient, loading } = useAddNewAssociatedClient({
    append,
    methods,
  } as unknown as IAddNewAssociatedClientParams);
  const { isEditing } = useEditingContext();

  return (
    <TableRow>
      <TableCell>
        <TextField
          name={AssociatedClients.Name}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'text',
          }}
          variant={Variant.Filled}
          validate={requiredLongStringValidator}
          className={s.inputField}
          onBlurCallback={e => {
            setValue(`${AssociatedClients.Name}`, e.target.value.trim());
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          name={AssociatedClients.ManagerName}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'text',
          }}
          variant={Variant.Filled}
          validate={requiredMidStringValidator}
          className={s.inputField}
          onBlurCallback={e => {
            setValue(`${AssociatedClients.ManagerName}`, e.target.value.trim());
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          name={AssociatedClients.ManagerEmail}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'text',
          }}
          variant={Variant.Filled}
          validate={emailValidator}
          className={s.inputField}
          onBlurCallback={e => {
            setValue(`${AssociatedClients.ManagerEmail}`, e.target.value.trim());
          }}
        />
      </TableCell>
      <TableCell>
        <SelectFiled
          className={s.selectField}
          name={AssociatedClients.Status}
          data={selectMockStatus}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
          variant={Variant.Filled}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton
          aria-haspopup='true'
          onClick={addNewAssociatedClient}
          disabled={loading || isEditing}>
          <AddCircleOutlineIcon color='primary' fontSize='medium' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AddNewAssociatedClient;
