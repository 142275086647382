import { useCallback, useMemo } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showSystemError } from 'common/apolloState/system';
import { useClients } from 'common/hooks/useClients';

import {
  useAddExpenseMutation,
  useEditExpenseMutation,
  useUpdateBudgetMutation,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import { SelectedItem } from 'valtech-core/form/Select';

import { useDeleteAssignment } from 'components/_forms/forms.hooks';

import { mapToSubmitAddExpenses, mapToSubmitEditExpenses } from './ExpenseForm.utils';

import { IExpenseFormProps } from './ExpenseForm';

type UseHandleExpensesReturn = {
  createExpenses: (data: IExpenseFormProps) => Promise<boolean>;
  updateExpenses: (assignmentId: Maybe<number>, data: IExpenseFormProps) => Promise<boolean>;
  deleteExpenses: (expenseId: Maybe<number>) => Promise<boolean>;
  clientsList: SelectedItem[];
  loading: boolean;
};

export const useHandleExpenses = (): UseHandleExpensesReturn => {
  const { budgetReportId, projectId } = useBudgetingState();

  const { clients } = useClients();

  const [addExpensesMutation, { loading: adding }] = useAddExpenseMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [editExpensesMutation, { loading: updating }] = useEditExpenseMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const { loading: deleting, deleteAssignment: deleteExpenses } = useDeleteAssignment();

  const clientsList = useMemo(
    (): SelectedItem[] =>
      clients.map(client => ({ id: client.id, title: client.name, value: `${client.id}` })),
    [clients],
  );

  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const createExpenses = useCallback(async (data: IExpenseFormProps) => {
    if (budgetReportId && projectId) {
      const newExpenses = await addExpensesMutation({
        variables: mapToSubmitAddExpenses({ budgetReportId, projectId, data }),
        refetchQueries: ['getSingleBudgeting'],
      });

      await UpdateBudgetMutation({
        variables: {
          budgetId: Number(budgetReportId),
        },
      });

      return !!newExpenses.data?.addExpense.id;
    }

    return false;
  }, []);

  const updateExpenses = useCallback(
    async (assignmentId: Maybe<number>, data: IExpenseFormProps) => {
      if (assignmentId && projectId) {
        const editedExpenses = await editExpensesMutation({
          variables: mapToSubmitEditExpenses(projectId, assignmentId, data),
          refetchQueries: ['getSingleBudgeting'],
        });

        await UpdateBudgetMutation({
          variables: {
            budgetId: Number(budgetReportId),
          },
        });

        return !!editedExpenses.data?.editExpense;
      }

      return false;
    },
    [projectId],
  );

  return {
    createExpenses,
    updateExpenses,
    deleteExpenses,
    clientsList,
    loading: adding || updating || deleting,
  };
};
