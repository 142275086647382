import React, { FC, useMemo } from 'react';

import { useLocalization } from '@fluent/react';
import AlertCollapsable from 'components/AlertCollapsable';
import isNil from 'lodash/isNil';
import { ISingleBillingPageData } from 'pages/SingleBilling/SingleBilling.types';
import { useSingleBillingWarnings } from 'pages/SingleBilling/SingleBillingTableContainer/SingleBillingTableContainer.hook';

import Grid from '@mui/material/Grid';

import { ERROR_WARNINGS } from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';

import EditSingleBillingTable from '../EditSingleBillingTable';
import { useBillingState } from '../SingleBillingContextProvider';
import BillingTabsComponent from '../SingleBillingTabs';

interface ISingleBillingTableContainer {
  editBillingTableData: ISingleBillingPageData | undefined;
}

const SingleBillingTableContainer: FC<ISingleBillingTableContainer> = ({
  editBillingTableData,
}) => {
  const {
    state: { isTabOpened },
  } = useBillingState();
  const { l10n } = useLocalization();
  const { singleBillingWarnings, billingId } = useSingleBillingWarnings();

  const invoicedStatus = useMemo(() => {
    return (
      editBillingTableData?.billingPageData?.status !== BillingStatus.Invoiced &&
      !isNil(editBillingTableData?.billingPageData?.status)
    );
  }, [editBillingTableData?.billingPageData?.status]);

  return (
    <Grid container justifyContent='space-evenly'>
      <Grid item xs={isTabOpened ? 7 : 11}>
        {singleBillingWarnings.length > 0 && invoicedStatus && (
          <AlertCollapsable
            title={l10n.getString(ERROR_WARNINGS)}
            data={singleBillingWarnings}
            paperProps={{ sx: { marginBottom: 3, width: 670 } }}
            billingId={billingId || ''}
          />
        )}
        <EditSingleBillingTable
          billingPageData={editBillingTableData?.billingPageData}
          allClientsTotal={editBillingTableData?.allClientsTotal}
          allConsultantsTotal={editBillingTableData?.allConsultantsTotal}
          clientsData={editBillingTableData?.clientsData}
          consultantsData={editBillingTableData?.consultantsData}
          globalTotal={editBillingTableData?.globalTotal}
          globalTotalFE={editBillingTableData?.globalTotalFE}
          singleBillingWarnings={singleBillingWarnings}
        />
      </Grid>
      {isTabOpened && (
        <Grid item xs={4}>
          <BillingTabsComponent />
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBillingTableContainer;
