import { ExchangeRate } from 'common/types';

import { MONTH_LIST } from 'valtech-core/common/ftl';

export const getMonthsList = (arr: Omit<ExchangeRate, 'l10n'>[]): ExchangeRate[] =>
  arr.map(({ month, ...rest }) => ({
    l10n: MONTH_LIST[month - 1],
    month,
    ...rest,
  }));
