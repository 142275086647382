import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { chipColorPicker } from 'common/utils/chipColorPicker';
import { getFormattedDate } from 'common/utils/formatDate';
import TablePagination from 'components/TablePagination/TablePagination';
import TableRowReusable from 'components/TableRowReusable';
import TableSkeleton from 'components/TableSkeleton';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { RecordStatus } from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';
import Chip from 'valtech-core/ui/Chip';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { useSettingsBudgetings } from './AllBudgetings.hook';
import { headData } from './AllBudgetingsTable.const';

const AllBudgetingsTable: FC = () => {
  const navigate = useNavigate();
  const {
    loading,
    budgetings,
    sortingType,
    activeColumn,
    handleSorting,
    rowsPerPage,
    page,
    total,
    setRowsPerPage,
    setPage,
  } = useSettingsBudgetings();

  const isData = !loading && budgetings.length > 0;

  return (
    <TableContainer component={Paper} className='tableContainer-xKiller'>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Table aria-label='simple table'>
        <TableHead>
          <TableRowReusable
            headData={headData}
            sortingType={sortingType}
            activeColumn={activeColumn}
            handleSorting={handleSorting}
          />
        </TableHead>
        <TableBody>
          {loading ? (
            <TableSkeleton
              cellsNumber={headData.length}
              rowsNumber={rowsPerPage}
              page={page}
              total={total}
            />
          ) : (
            budgetings?.map(row => (
              <TableRow
                hover
                className='TableRow--hover'
                key={row.id}
                onClick={() => navigate(`${row.id}`)}>
                <TableCell>{row.budgets}</TableCell>
                <TableCell>{row.updatedBy}</TableCell>
                <TableCell>{getFormattedDate(row.lastUpdated, DateMask.DD_MM_YYYY_HH)}</TableCell>
                <TableCell>
                  <Chip
                    size={'medium'}
                    label={row.status === RecordStatus.NotActive ? 'Not Active' : row.status}
                    variant='outlined'
                    color={chipColorPicker(row.status as RecordStatus)}
                    className='text-capitalized'
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_REQUEST_LIMIT, MAX_REQUEST_LIMIT]}
            count={total}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            isData={isData}
          />
        </TableFooter>
      </Table>
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          }}
        />
      )}
    </TableContainer>
  );
};

export default AllBudgetingsTable;
