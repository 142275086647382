import React, { FC, memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

interface IProgressLinearProps {
  boxProps?: BoxProps;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
}

const ProgressLinear: FC<IProgressLinearProps> = ({ boxProps, color = 'primary' }) => {
  return (
    <Box sx={{ width: '100%' }} {...boxProps}>
      <LinearProgress color={color} />
    </Box>
  );
};

export default memo(ProgressLinear);
