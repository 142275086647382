import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import { FormProvider, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import EditingContextProvider from 'common/contexts/editingContext/editing.provider';
import usePrompt from 'common/hooks/usePrompt';

import {
  ARE_YOU_SURE_FTL,
  CANCEL_FTL,
  CONFIRM_FTL,
  UNSAVED_CHANGES_FTL,
} from 'valtech-core/common/ftl';
import ProgressLinear from 'valtech-core/ui/ProgressLinear/ProgressLinear';

import { useAddUpdHolidayCalendar } from './HolidayCalendar.hook';
import HolidayCalendarContent from './HolidayCalendarContent';
import HolidayCalendarHeader from './HolidayCalendarHeader';

const HolidayCalendar: FC = () => {
  const methods = useForm();
  const { id } = useParams();
  const { l10n } = useLocalization();
  const { loading, onSubmit } = useAddUpdHolidayCalendar(methods);

  usePrompt({
    title: l10n.getString(ARE_YOU_SURE_FTL),
    message: l10n.getString(UNSAVED_CHANGES_FTL),
    cancel: l10n.getString(CANCEL_FTL),
    confirm: l10n.getString(CONFIRM_FTL),
    when: Boolean(Object.keys(methods.formState.touchedFields).length && id),
  });

  return (
    <FormProvider {...methods}>
      <EditingContextProvider>
        <HolidayCalendarHeader disabled={loading} callback={() => onSubmit()} />
        {loading && <ProgressLinear />}
        <HolidayCalendarContent loading={loading} onProceed={() => onSubmit(true)} />
      </EditingContextProvider>
    </FormProvider>
  );
};

export default HolidayCalendar;
