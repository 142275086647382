import get from 'lodash/get';

export function sortByOrderWithKey(key: string) {
  return (a: unknown, b: unknown): number => {
    const prev = get(a, key);
    const curr = get(b, key);

    if (prev < curr) {
      return -1;
    }
    if (prev > curr) {
      return 1;
    }
    return 0;
  };
}
