import { invalidNumberValidator } from 'valtech-core/common/form/validators';
import { ERROR_OVERHEADS_FTL } from 'valtech-core/common/ftl';

export enum FinanceInformationFields {
  Status = 'status',
  Title = 'title',
  Year = 'year',
  CostOfOverheadUsd = 'costOfOverheadUsd',
  CostOfOverheadEur = 'costOfOverheadEur',
}

const regexp = /^\d+$/;
export const overheadsValidator = invalidNumberValidator({
  invalid: ERROR_OVERHEADS_FTL,
  regexp,
});
