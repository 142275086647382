import { useCallback } from 'react';

import { IEntityFormProps } from 'components/_forms/EntityForm';

import { useToggle } from 'valtech-core/utils/useToggle';

import { Entity } from '../AllEntities.types';

type UseHandleTableRowEditReturn = {
  isOpened: boolean;
  toggleForm: VoidFunction;
  getEntityInitialValues: (item: Entity) => IEntityFormProps;
};

type UseHandleTableRowEditParams = {
  order: number;
  rowRecord: Entity;
};

export const useHandleAllEntitiesTableRowEdit = ({
  rowRecord,
}: UseHandleTableRowEditParams): UseHandleTableRowEditReturn => {
  const [isOpened, toggleForm] = useToggle(false);

  const getEntityInitialValues = useCallback(
    (item: Entity): IEntityFormProps => {
      return {
        order: item.order,
        label: item.label,
        status: item.status,
        id: item.id,
        updatedBy: item.updatedBy,
      };
    },
    [rowRecord],
  );

  return {
    isOpened,
    toggleForm,
    getEntityInitialValues,
  };
};
