import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { FieldValues, UseFieldArrayAppend, UseFormReturn } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { showNotification } from 'common/apolloState/notifications';
import { showGenericError, showSystemError } from 'common/apolloState/system';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import { v4 as uuidv4 } from 'uuid';

import { ASSOCIATED_CLIENTS_FTL, ERROR_ASSOCIATED_CLIENT_EXIST } from 'valtech-core/common/ftl';
import { useCreateNewAssociatedClientMutation } from 'valtech-core/common/gql/generated';
import { AlertsSeverityEnum } from 'valtech-core/common/types';

import {
  AssociatedClients,
  IAssociatedClientsTable,
  defaultValues,
} from '../AssociatedClientsTable.const';

interface IAddNewAssociatedClientReturn {
  addNewAssociatedClient: () => void;
  loading: boolean;
}

export interface IAddNewAssociatedClientParams {
  append: UseFieldArrayAppend<FieldValues, 'associatedClients.clients'>;
  methods: UseFormReturn<Omit<IAssociatedClientsTable, 'id'>>;
}

export const useAddNewAssociatedClient = ({
  append,
  methods,
}: IAddNewAssociatedClientParams): IAddNewAssociatedClientReturn => {
  const { l10n } = useLocalization();
  const { trigger, getValues, reset } = methods;
  const [createNewAssociatedClient, { loading }] = useCreateNewAssociatedClientMutation();
  const { id: clientId } = useParams();

  const addNewAssociatedClient = useCallback(async () => {
    const values = getValues();

    if (
      !values[AssociatedClients.Name] &&
      !values[AssociatedClients.ID] &&
      !values[AssociatedClients.ManagerEmail] &&
      !values[AssociatedClients.Status]
    ) {
      showNotification({
        message: l10n.getString(ASSOCIATED_CLIENTS_FTL),
        hideDuration: secondsToMilliSeconds(3),
        severity: AlertsSeverityEnum.Error,
      });
      return;
    }

    try {
      const valid = await trigger();
      if (valid) {
        const { name, manager_name, manager_email, status } = values;
        const params = {
          name,
          manager_name,
          manager_email,
          status,
        };

        if (clientId) {
          const res = await createNewAssociatedClient({
            variables: {
              data: [
                {
                  project_id: Number(clientId),
                  name,
                  manager_name,
                  manager_email,
                  status,
                },
              ],
            },
          });
          const associatedClientId = res?.data?.createClients[0].id;
          associatedClientId &&
            append({
              id: associatedClientId,
              ...params,
            });
        } else {
          append({
            id: uuidv4(),
            ...params,
          });
        }
        reset({ ...defaultValues });
      }
    } catch (e: any) {
      if (e.message === 'Validation error') {
        showSystemError(ERROR_ASSOCIATED_CLIENT_EXIST);
      } else {
        showGenericError();
      }
    }
  }, [clientId]);

  return {
    loading,
    addNewAssociatedClient,
  };
};
