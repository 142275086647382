import React, { FC, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { showNotification } from 'common/apolloState/notifications';
import { showSystemError } from 'common/apolloState/system';
import { chipColorPicker } from 'common/utils/chipColorPicker';
import { getFormattedDate } from 'common/utils/formatDate';
import { secondsToMilliSeconds } from 'common/utils/secondsToMilliSeconds';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import { useSingleBillingHeader } from 'pages/SingleBilling/SingleBillingHeader/useSingleBillingHeader.hook';

import Box from '@mui/material/Box';

import { BILLING_SUCCESSFUL_MESSAGE_FTL } from 'valtech-core/common/ftl';
import { BillingStatus, useUpdateBudgetMutation } from 'valtech-core/common/gql/generated';
import { BILLINGS_ROUTE } from 'valtech-core/common/routes';
import { AlertsSeverityEnum, DateMask, Maybe } from 'valtech-core/common/types';
import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import Back from 'valtech-core/ui/Back';
import Button from 'valtech-core/ui/Button';
import Chip from 'valtech-core/ui/Chip';
import Typography from 'valtech-core/ui/Typography';

import { INVOICE_FTL } from './SingleBillingHeader.ftl';
import s from './SingleBillingHeader.module.scss';

interface ISingleBillingHeader {
  status: Maybe<BillingStatus>;
  title: Maybe<string>;
  modifiedBy: Maybe<number>;
  modifiedDate: Maybe<string>;
  projectName: Maybe<string>;
}

const SingleBillingHeader: FC<ISingleBillingHeader> = ({
  status,
  title,
  modifiedBy,
  modifiedDate,
  projectName,
}) => {
  const { l10n } = useLocalization();
  const {
    state: { accessLevel },
  } = useBillingState();
  const userAccessLevel = getAccessLevel(accessLevel, l10n);

  const navigate = useNavigate();
  const { budgetReportId } = useBudgetingState();
  const { id: billingId } = useParams();
  const [modifiedByName, setModifiedByName] = useState<string | null>(null);
  const { updateAssignmentStatus, getUserName } = useSingleBillingHeader();
  useEffect(() => {
    if (modifiedBy) {
      const fetchModifiedByName = async () => {
        const name = await getUserName(modifiedBy);
        setModifiedByName(name || null);
      };

      fetchModifiedByName();
    }
  }, [modifiedBy]);

  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const handleInvoiced = () => {
    if (billingId && status === BillingStatus.Draft) {
      updateAssignmentStatus(Number(billingId), BillingStatus.Invoiced).then(isSuccessful => {
        if (isSuccessful) {
          UpdateBudgetMutation({
            variables: {
              budgetId: Number(budgetReportId),
            },
          });
          showNotification({
            message: l10n.getString(BILLING_SUCCESSFUL_MESSAGE_FTL),
            hideDuration: secondsToMilliSeconds(10),
            severity: AlertsSeverityEnum.Success,
          });
          navigate(BILLINGS_ROUTE);
        }
      });
    }
  };

  return (
    <Box className={s.container + ' single-billing-header'}>
      <Box className={s.information}>
        <Back className={s.informationIcon} />
        <Box className={s.informationTitle}>
          <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
            {`${projectName} - ${title}`}
          </Typography>
        </Box>
        <Chip
          size={'medium'}
          label={status || ''}
          variant='outlined'
          color={chipColorPicker(status)}
        />
      </Box>
      <Box className={s.action}>
        <Typography variant='subtitle2' weight='regular' component={'p'} className={s.actionTitle}>
          {`last modified ${getFormattedDate(
            modifiedDate,
            DateMask.YYYY_MM_DD_HH,
          )} by ${modifiedByName}`}
        </Typography>
        {userAccessLevel.full && (
          <Button
            variant='contained'
            sx={{ mx: 1 }}
            type='button'
            onClick={handleInvoiced}
            disabled={status === BillingStatus.Invoiced}>
            {l10n.getString(INVOICE_FTL)}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SingleBillingHeader;
