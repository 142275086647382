import { useCallback, useEffect, useMemo } from 'react';

import { showGenericError } from 'common/apolloState/system';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';

import {
  useAddBillingReportCommentMutation,
  useDeleteBillingReportCommentMutation,
  useGetCommentsByIdLazyQuery,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

export type Comment = {
  id: number;
  date: string;
  comment: string;
  firstName: string;
  lastName: string;
};

type UseSingleBillingTabsReturn = {
  comments: Comment[];
  loading: boolean;
};

export const useSingleBillingTabs = (): UseSingleBillingTabsReturn => {
  const {
    state: { projectData },
  } = useBillingState();
  const [getCommentsByBRRId, { data, loading }] = useGetCommentsByIdLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (projectData?.id) {
      getCommentsByBRRId({
        variables: {
          billingReportRowId: projectData.id,
        },
      });
    }
  }, [projectData?.id]);

  const comments = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.sendAnArrayOfCommentsByBRRID
      .map(item => {
        const firstAndLastUserNameArr = item.userInfo.name.split(' ');
        return {
          id: item.id,
          comment: item.comment || '',
          date: item.date || '',
          firstName: firstAndLastUserNameArr[0] || '',
          lastName: firstAndLastUserNameArr[1] || '',
        };
      })
      .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
  }, [data]);

  return {
    loading,
    comments,
  };
};

type AddBillingReportComment = (
  billingReportRowId: Maybe<number>,
  comment: Maybe<string>,
  projectId: number | null,
) => Promise<boolean | undefined>;

type UseHandleSingleBillingTabs = {
  addBillingReportComment: AddBillingReportComment;
  loading: boolean;
};

type UseDeleteBillingReportComment = {
  deleteBillingReportComment: (commentId: number | null) => Promise<boolean | undefined>;
  loading: boolean;
};

export const useHandleSingleBillingTabs = (): UseHandleSingleBillingTabs => {
  const [addBillingReportCommentMutation, { loading }] = useAddBillingReportCommentMutation({
    onError() {
      showGenericError();
    },
  });

  const addBillingReportComment = useCallback(async (billingReportRowId, comment, projectId) => {
    try {
      if (billingReportRowId) {
        const response = await addBillingReportCommentMutation({
          variables: {
            billingReportRowId,
            comment,
            projectId,
            date: Date.now(),
          },
          refetchQueries: ['getCommentsById'],
        });

        return !!response.data;
      }
    } catch (_) {}
  }, []);

  return {
    addBillingReportComment,
    loading,
  };
};

export const useDeleteBillingReportComment = (): UseDeleteBillingReportComment => {
  const [deleteBillingReportCommentMutation, { loading }] = useDeleteBillingReportCommentMutation({
    onError() {
      showGenericError();
    },
  });

  const deleteBillingReportComment = useCallback(async (commentId: number | null) => {
    try {
      if (commentId) {
        const response = await deleteBillingReportCommentMutation({
          variables: {
            commentId,
          },
          refetchQueries: ['getCommentsById'],
        });

        return !!response.data;
      }
    } catch (_) {}
  }, []);

  return {
    deleteBillingReportComment,
    loading,
  };
};
