import { InMemoryCache } from '@apollo/client';

// import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import introspectionResult from 'valtech-core/common/gql/introspection';

import { typePolicies } from './typePolicies';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies,
  possibleTypes: introspectionResult.possibleTypes,

  /**
   * Customization fo IDs:
   * https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
   **/
  // dataIdFromObject(responseObject) {
  //   switch (responseObject.__typename) {
  //     case 'SomeType':
  //       return `SomeType:with:extra:data:to:make:custo:id:for:apollo:cache`;
  //     default:
  //       return defaultDataIdFromObject(responseObject);
  //   }
  // },
});
