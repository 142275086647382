import { TableSearchFieldsEnum } from 'src/common/types';

import {
  ACTION_FTL,
  LABEL_FTL,
  STATUS_FTL,
  UPDATED_AT_FTL,
  UPDATED_BY_FTL,
} from 'valtech-core/common/ftl';

export const headData = [
  { l10n: LABEL_FTL, model: '', sortBy: TableSearchFieldsEnum.Label },
  { l10n: UPDATED_AT_FTL, model: '', sortBy: TableSearchFieldsEnum.UpdateAt },
  { l10n: UPDATED_BY_FTL, model: '', sortBy: TableSearchFieldsEnum.UpdateBy },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: TableSearchFieldsEnum.Status,
  },
  { l10n: ACTION_FTL, model: '', sortBy: '' },
];
