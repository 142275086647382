import { useCallback, useEffect, useState } from 'react';

import noop from 'lodash/noop';
import { PROJECT_FILTER_DEFAULT_OBJ, TABLE_FILTER_DEFAULT_OBJ } from 'src/common/constants';

import { ALL_FTL } from 'valtech-core/common/ftl';
import { IFilters } from 'valtech-core/common/types';

type filterType = IFilters[] | [];

interface ISearchData {
  fieldName: string;
  query: string;
}

export type handlePageFilterSearchType = (data: ISearchData) => void;

export interface IUsePageFiltersReturn {
  filter: filterType;
  tableFilter?: IFilters;
  projectFilter?: IFilters;
  setFilter: (state: filterType) => void;
  handleSearch: handlePageFilterSearchType;
  setProjectsFilter: (state: IFilters) => void;
  setTableFilters: (state: filterType) => void;
}
interface IUsePageFiltersParams {
  filterState?: filterType;
  projectFilterState?: IFilters;
  tableFilter?: IFilters;
  setPage?: (pageNumber: number) => void;
  fieldMap?: {
    [key: string]: Omit<IFilters, 'query'>;
  };
  setTableFilters: (tableFilters: IFilters[]) => void;
}

const usePageFilters = ({
  setPage = noop,
  setTableFilters,
  fieldMap = {},
  filterState = [],
  projectFilterState = PROJECT_FILTER_DEFAULT_OBJ,
  tableFilter = TABLE_FILTER_DEFAULT_OBJ,
}: IUsePageFiltersParams): IUsePageFiltersReturn => {
  const [filter, setFilter] = useState<filterType>(filterState as filterType);
  const [projectFilter, setProjectsFilter] = useState<IFilters>(projectFilterState as IFilters);

  useEffect(() => {
    const filters: IFilters[] = [];

    if (projectFilter?.query) {
      filters.push(projectFilter);
    }

    if (tableFilter?.query) {
      filters.push(tableFilter);
    }

    setFilter(filters);

    if (tableFilter?.query) {
      setTableFilters([tableFilter]);
    }
  }, [projectFilter, tableFilter]);

  const handleSearch = useCallback(
    data => {
      if (!data.query) {
        if (projectFilter.query && projectFilter.query?.toLowerCase() !== ALL_FTL) {
          setFilter([projectFilter]);
        } else {
          setFilter([]);
        }

        setTableFilters([{ ...TABLE_FILTER_DEFAULT_OBJ }]);
      } else if (fieldMap && data.fieldName) {
        setTableFilters([
          {
            query: data.query,
            ...fieldMap[data.fieldName],
          },
        ]);
        setPage(0);
      }
    },
    [projectFilter],
  );

  return {
    filter,
    setFilter,
    tableFilter,
    setTableFilters,
    projectFilter,
    setProjectsFilter,
    handleSearch,
  };
};

export default usePageFilters;
