import React, { FC, useCallback, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { ConsultantModelEnum, IWarningData } from 'common/types';
import Modal from 'components/Modal';
import { useBudgetingState } from 'src/common/apolloState/budgeting';
import { useEditBillingReportErrorsByID } from 'src/pages/SingleBilling/EditBillingReportErrorsByID/EditBillingReportErrorsByID.hook';
import { useBillingState } from 'src/pages/SingleBilling/SingleBillingContextProvider';

import moment from 'moment';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Paper, { PaperProps } from '@mui/material/Paper';

import { COLLAPSE_FTL, IGNORE_FTL, INCLUDE_FTL, SHOW_ALL_FTL } from 'valtech-core/common/ftl';
import { CurrencyEnum } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';
import { useToggle } from 'valtech-core/utils/useToggle';

import AssignmentForm, { IAssignmentFormProps } from '../_forms/AssignmentForm';
import s from './AlertCollapsable.module.scss';

interface IAlertProps {
  title: string;
  data: IWarningData[];
  paperProps?: PaperProps;
  billingId: string;
}

interface IModal {
  assignmentFormModal: {
    open: boolean;
  };
}

interface IWarningDataExtended extends IWarningData {
  clientId: number;
  consultant: number;
  hours: number;
  model: ConsultantModelEnum;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const AlertCollapsable: FC<IAlertProps> = ({ title, data, paperProps, billingId }) => {
  const { l10n } = useLocalization();
  const [isExpanded, toggleExpanded] = useToggle(false);
  const { editBillingReportErrorsByID } = useEditBillingReportErrorsByID();
  const { assignmentDefaults, currency } = useBudgetingState();
  const {
    state: { billingInfo },
  } = useBillingState();

  const getDefaultCostOfOverhead = useCallback(() => {
    return currency === CurrencyEnum.EUR
      ? assignmentDefaults.costOfOverhead.EUR
      : assignmentDefaults.costOfOverhead.USD;
  }, [assignmentDefaults, currency]);

  const onIgnore = (errorId: number) => {
    editBillingReportErrorsByID(errorId, Number(billingId), true, false);
  };

  const [modalData, setModalData] = useState<IModal>({
    assignmentFormModal: {
      open: false,
    },
  });

  const toggleModal = (key: keyof IModal, id?: number) => {
    if (id) {
      const selectedData = data.find(item => item.id === id) as IWarningDataExtended;

      setModalData(prev => ({
        ...prev,
        [key]: {
          open: !prev[key]?.open,
          id: selectedData?.id,
          client: selectedData?.clientId,
          consultant: {
            id: selectedData?.consultant,
            label: selectedData?.title.split(' ').slice(0, 2).join(' '),
          },
          hours: selectedData?.hours,
          model: ConsultantModelEnum.TimeAndMaterial as ConsultantModelEnum,
          allocation: assignmentDefaults.allocation,
          costOfOverhead: getDefaultCostOfOverhead(),
          billingId: billingId,
          errorId: id,
          startDate: billingInfo?.billingStartDay,
          endDate: billingInfo?.billingEndDay,
          datePickerMinStartDate: billingInfo?.billingStartDay,
          datePickerMinEndDate: billingInfo?.billingEndDay,
          defaultCalendarDate: {
            month: billingInfo?.billingMonth,
            year: billingInfo?.financialYear,
          },
        },
      }));
    } else {
      setModalData(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          open: !prev[key]?.open,
        },
      }));
    }
  };

  return (
    <Paper elevation={3} {...paperProps} className={s.alertWrapper}>
      <Box className={s.alertHeader} onClick={toggleExpanded}>
        <Typography>{title}</Typography>
        <Badge badgeContent={data.length} color='error' className={s.badge} />
      </Box>
      <Modal
        handleCloseModal={() => toggleModal('assignmentFormModal')}
        openModal={modalData.assignmentFormModal.open}
        size='mid'
        closeBtn>
        <AssignmentForm
          onClose={() => toggleModal('assignmentFormModal')}
          defaultValues={modalData.assignmentFormModal as Partial<IAssignmentFormProps>}
          fromBilling={true}
          createFromWarning={true}
        />
      </Modal>
      <Collapse in={isExpanded}>
        <Box className={s.collapseContent}>
          {data.map(item => (
            <Paper key={item.id} elevation={3} className={s.alertItem}>
              <Typography>
                <p dangerouslySetInnerHTML={{ __html: item.title.replace(/\n/g, '<br />') }} />
              </Typography>
              <Box className={s.buttonGroup}>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={() => toggleModal('assignmentFormModal', item.id)}
                  className={s.includeButton}>
                  {l10n.getString(INCLUDE_FTL)}
                </Button>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={() => onIgnore(item.id)}
                  className={s.ignoreButton}>
                  {l10n.getString(IGNORE_FTL)}
                </Button>
              </Box>
            </Paper>
          ))}
        </Box>
      </Collapse>
      <Button className={s.collapseButton} variant='outlined' onClick={toggleExpanded}>
        {isExpanded ? l10n.getString(COLLAPSE_FTL) : l10n.getString(SHOW_ALL_FTL)}
      </Button>
    </Paper>
  );
};

export default AlertCollapsable;
