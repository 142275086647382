import React, { FC, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import AutocompleteField from 'form/AutocompleteField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { requiredStringValidator } from 'valtech-core/common/form/validators';
import { ADD_FTL, CANCEL_FTL, SAVE_FTL } from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useForecastFormData } from './ForecastForm.hooks';

import { IFormattedData, IFormattedForecastData } from './ForecastForm.utils';

import { EFormFieldName } from '../form.types';
import { CREATE_FORECAST_FTL, FORECAST_LABEL_FTL } from './ForecastForm.ftl';
import s from './ForecastForm.module.scss';

export interface IForecastFormProps {
  label: string;
  id: number;
}
export interface IEditForecastFormProps<T> {
  onClose: VoidFunction;
  initialValues?: IFormattedForecastData;
  defaultValues?: Partial<T>;
  onSubmit?: (data: T) => void;
  hasDelete?: boolean;
  budgetTypes: IFormattedData[];
  projectId?: number;
  budgetingSettingId?: number;
  loading: boolean;
}

const ForecastForm: FC<IEditForecastFormProps<IForecastFormProps>> = ({
  onClose,
  initialValues,
  budgetTypes,
  projectId,
  budgetingSettingId,
  loading,
}) => {
  const budgetId = Number(useParams().id);
  const { l10n } = useLocalization();
  const { control, handleSubmit } = useForm<IFormattedForecastData>({
    defaultValues: initialValues,
  });

  const { createBudget } = useForecastFormData();

  const forecastOptions = budgetTypes.map(item => {
    return { ...item, projectId, budgetId, budgetingSettingId };
  });

  const formSubmit: SubmitHandler<IFormattedForecastData> = useCallback(
    data => {
      createBudget(data).then(success => success && onClose());
    },

    [onClose],
  );

  const onCancel = () => {
    onClose();
  };

  return (
    <form onSubmit={e => e.preventDefault()} className={s.forecastForm}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>{l10n.getString(CREATE_FORECAST_FTL)}</Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AutocompleteField
            label={l10n.getString(FORECAST_LABEL_FTL)}
            name={EFormFieldName.ForecastToCreate}
            control={control as unknown as FieldControl}
            data={forecastOptions}
            validate={value => requiredStringValidator(value.label)}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button onClick={handleSubmit(formSubmit)} variant='contained'>
          {initialValues ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default ForecastForm;
