import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import { useGeneralTab } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTab.hook';

import Box from '@mui/material/Box';

import {
  ERROR_NO_TIMESHEETS_FOUND_FTL,
  ERROR_TIMESHEET_DO_NOT_MATCH_THE_FORECAST_FOUND_FTL,
} from 'valtech-core/common/ftl';
import Alert from 'valtech-core/ui/Alert';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { useBillingState } from '../../SingleBillingContextProvider';
import CollapsibleListItem from './CollapsibleListItem';
import { useTimesheetDetails } from './TimesheetDetails.hook';
import s from './TimesheetDetails.module.scss';

type GeneralFeedbackAlert = {
  id: string;
  title: string;
  severity: string;
};

const TimesheetDetails: FC = () => {
  const { l10n } = useLocalization();
  const [errorsList, setErrorsList] = useState<GeneralFeedbackAlert[]>([]);
  const [projectCollapsed, setProjectCollapsed] = useState<Record<string, boolean>>({});
  const [clientCollapsed, setClientCollapsed] = useState<Record<string, boolean>>({});
  const { state } = useBillingState();
  const { billingInfo } = useGeneralTab();
  const timesheetErrorWrapperRef = useRef<HTMLDivElement | null>(null);

  const billingWarnings = state.singleBillingWarnings;
  const month = billingInfo?.billingMonth;
  const year = billingInfo?.financialYear;
  const consultantId = state?.consultantId as number;
  const { id: billingId } = useParams();

  const { timesheetDetails, loading, consultantDetails } = useTimesheetDetails({
    consultantId,
    month,
    year,
    billingId: Number(billingId),
  });

  const toggleAccount = (projectIndex: number) => {
    const index = `${projectIndex}`;
    setProjectCollapsed(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleClient = (projectIndex: number, clientIndex: number) => {
    const index = `${projectIndex}_${clientIndex}`;
    setClientCollapsed(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const newErrorsList: GeneralFeedbackAlert[] = [];

    if (timesheetDetails.length === 0 && !loading) {
      newErrorsList.push({
        id: Date.now().toString(),
        title: l10n.getString(ERROR_NO_TIMESHEETS_FOUND_FTL),
        severity: 'warning',
      });
    }

    if (consultantDetails?.isNotMatchForecast) {
      newErrorsList.push({
        id: Date.now().toString(),
        title: l10n.getString(ERROR_TIMESHEET_DO_NOT_MATCH_THE_FORECAST_FOUND_FTL),
        severity: 'warning',
      });
    }

    setErrorsList(newErrorsList);
  }, [timesheetDetails, billingWarnings, loading, l10n]);

  const errorMessages = useMemo(
    () =>
      errorsList.map((notification, index) => (
        <React.Fragment key={`error_${index}`}>
          <Alert
            severity={'warning'}
            alertMsg={notification.title}
            onClose={() => setErrorsList(errorsList.filter(item => item.id !== notification.id))}
          />
          <br />
        </React.Fragment>
      )),
    [errorsList],
  );

  return (
    <>
      <div ref={timesheetErrorWrapperRef} />
      {timesheetErrorWrapperRef.current && errorsList.length > 0 && errorMessages}
      {loading && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <Box>
        <ul className={s.timesheetList}>
          <div className={cn(s.container, s.timesheetListItem, s.consultantDetails)}>
            <span>{consultantDetails.consultantName}</span>
            <span>{consultantDetails.totalHours}</span>
          </div>
          {timesheetDetails.map((project, projectIndex) => (
            <CollapsibleListItem
              key={`project_${projectIndex}`}
              name={project.projectName}
              totalHours={project.totalHours}
              collapsed={!!projectCollapsed[`${projectIndex}`]}
              onToggle={() => toggleAccount(projectIndex)}>
              <ul className={s.timesheetList}>
                {project.client.map((client, clientIndex) => (
                  <CollapsibleListItem
                    key={`client_${client.clientName}`}
                    name={client.clientName}
                    totalHours={client.totalHours}
                    collapsed={!!clientCollapsed[`${projectIndex}_${clientIndex}`]}
                    onToggle={() => toggleClient(projectIndex, clientIndex)}>
                    <ul className={s.timesheetList}>
                      {client.task.map(task => (
                        <li key={`task_${task.taskTitle}`} className={s.task}>
                          <div className={s.container}>
                            <span className={s.timesheetDetails}>{task.taskTitle}</span>
                            <span className={s.timesheetListHours}>{task.totalHours}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </CollapsibleListItem>
                ))}
              </ul>
            </CollapsibleListItem>
          ))}
        </ul>
      </Box>
    </>
  );
};

export default TimesheetDetails;
