import React, { FC, useEffect, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { ActiveTabsEnum, SingleBillingTableTypeEnum } from 'common/types';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import GeneralTab from 'pages/SingleBilling/SingleBillingTabs/GeneralTab';
import { useSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import getAccessLevel from 'valtech-core/common/utils/getAccessLevel';
import Tabs from 'valtech-core/ui/Tabs';

import Comments from './Comments';
import s from './SingleBillingTabs.module.scss';

const SingleBillingTabs: FC = () => {
  const { l10n } = useLocalization();
  const { state, setStateParam } = useBillingState();
  const { accessLevel } = state;
  const { comments } = useSingleBillingTabs();
  const links = [
    { title: 'GENERAL', value: '/general' },
    { title: 'COMMENTS', value: '/comments', badgeNotificationsQuantity: comments.length },
  ];
  const [shortVersion, setShortVersion] = useState(true);
  const userAccessLevel = getAccessLevel(accessLevel, l10n);
  useEffect(() => {
    const singleBillingHeader = document.querySelector('.single-billing-header');

    const observer = new IntersectionObserver(
      entries => {
        setShortVersion(entries[0].isIntersecting);
      },
      {
        rootMargin: '-10px',
      },
    );

    observer.observe(singleBillingHeader as Element);

    return () => observer.disconnect();
  }, []);

  const defaultTabValue =
    state.projectData?.locked || userAccessLevel.edit || userAccessLevel.viewAndComment
      ? links[1].value
      : links[0].value;

  const [tabValue, setTabValue] = React.useState<string>(defaultTabValue);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleCloseTabs = (): void => {
    setStateParam({ isTabOpened: false });
  };

  useEffect(() => {
    if (state.activeTabName === ActiveTabsEnum.Comments) setTabValue(links[1].value);
    else if (state.activeTabName === ActiveTabsEnum.General) setTabValue(links[0].value);
  }, [state]);

  return (
    <Box className={s.tabsPanel}>
      <IconButton className={s.closeTabsPanelBtn} onClick={handleCloseTabs}>
        <CloseIcon fontSize='large' />
      </IconButton>
      <Tabs
        tabs={links}
        colorSchema='blue'
        value={tabValue}
        onChange={handleTabsChange}
        boxProps={{ sx: { maxWidth: '600px' } }}
      />
      <Box
        className={`${s.tabsContainer} ${
          state.tableName === SingleBillingTableTypeEnum.Consultants && s.scroller
        } ${shortVersion ? s.scrollerShort : ''}`}>
        {tabValue === '/comments' && <Comments />}
        {tabValue === '/general' && <GeneralTab />}
      </Box>
    </Box>
  );
};
export default SingleBillingTabs;
