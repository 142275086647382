import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { showGenericError } from 'common/apolloState/system';
import { useAllBusinessUnitList } from 'common/hooks/useAllBusinessUnitList';
import { getYearsRangeSelect } from 'common/utils/getYearsRangeSelect';

import {
  DEC_SHORT_FTL,
  JAN_SHORT_FTL,
  STATUS_ACTIVE_FTL,
  STATUS_ARCHIVED_FTL,
  STATUS_NOT_ACTIVE_FTL,
} from 'valtech-core/common/ftl';
import {
  RecordStatus,
  useGetHolidayCalendarByIdLazyQuery,
} from 'valtech-core/common/gql/generated';
import { SelectedItem } from 'valtech-core/form/Select';

interface IUseHolidayCalendarSelectsReturn {
  years: SelectedItem[];
  statuses: SelectedItem[];
  businessUnits: SelectedItem[];
}

interface ICalendarData {
  title: string;
  year: number;
  modifiedBy?: number;
  status: RecordStatus;
  businessUnit: string;
}

interface IUseHolidayCalendarDescriptionReturn {
  calendar: {
    loading: boolean;
    data: ICalendarData;
  };
}

export const useHolidayCalendarDescription = (): IUseHolidayCalendarDescriptionReturn => {
  const { id } = useParams();
  const calendarID = Number(id);
  const { l10n } = useLocalization();
  const currentYear = new Date().getUTCFullYear();

  const [getHolidayCalendar, { loading, data }] = useGetHolidayCalendarByIdLazyQuery();

  useEffect(() => {
    if (id) {
      getHolidayCalendar({
        variables: {
          holidayCalendarId: calendarID,
        },
        fetchPolicy: 'cache-and-network',
        onError() {
          showGenericError();
        },
      });
    }
  }, [id]);

  const _data = useMemo(() => {
    if (!data || !calendarID) {
      return {
        year: new Date().getUTCFullYear(),
        title: `(${l10n.getString(JAN_SHORT_FTL)} ${currentYear} - ${l10n.getString(
          DEC_SHORT_FTL,
        )} ${currentYear})`,
        status: RecordStatus.NotActive,
        businessUnit: '',
      };
    } else {
      const {
        year,
        title,
        status,
        business_unit_id: businessUnit,
      } = data?.getHolidayCalendarByID || {};

      return {
        year,
        title,
        status,
        businessUnit,
      } as ICalendarData;
    }
  }, [data]);

  return {
    calendar: {
      data: _data,
      loading,
    },
  };
};

export const useHolidayCalendarSelects = (): IUseHolidayCalendarSelectsReturn => {
  const { l10n } = useLocalization();
  const years = getYearsRangeSelect();

  const { entities: businessUnitsData } = useAllBusinessUnitList();

  const statuses = [
    { id: 1, title: l10n.getString(STATUS_ACTIVE_FTL), value: RecordStatus.Active },
    { id: 2, title: l10n.getString(STATUS_NOT_ACTIVE_FTL), value: RecordStatus.NotActive },
    { id: 3, title: l10n.getString(STATUS_ARCHIVED_FTL), value: RecordStatus.Archived },
  ];

  const businessUnits = useMemo((): SelectedItem[] => {
    if (!businessUnitsData) {
      return [];
    }

    return businessUnitsData.map(businessUnit => ({
      id: businessUnit.id,
      title: businessUnit.title,
      value: `${businessUnit.id}`,
    }));
  }, [businessUnitsData]);

  return {
    years,
    statuses,
    businessUnits,
  };
};
