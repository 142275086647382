import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { FinanceInformationFields } from 'pages/ManageSettings/SingleBudgeting/SingleBudget.constants';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';

import { ADD_FTL, SAVE_FTL } from 'valtech-core/common/ftl';

interface ISingleBudgetingHeaderProps {
  disabled?: boolean;
  callback: (event: Event | React.SyntheticEvent) => void;
}

const SingleBudgetingHeader: FC<ISingleBudgetingHeaderProps> = ({ callback, disabled }) => {
  const { id } = useParams();
  const { l10n } = useLocalization();
  const { getValues } = useFormContext();
  const { isEditing } = useEditingContext();

  const title = getValues(`budgetData.data.${FinanceInformationFields.Title}`);

  return (
    <SettingsHeaderReusable
      title={title}
      callback={callback}
      disabled={disabled || isEditing}
      btnTitle={`${id ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}`}
    />
  );
};

export default SingleBudgetingHeader;
