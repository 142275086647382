import { useCallback, useMemo } from 'react';

import { showGenericError, showSystemError } from 'common/apolloState/system';
import {
  EditProjectVariables,
  SingleProjectFormProps,
} from 'pages/ManageSettings/SingleProject/SingleProject';
import { DEFAULT_SKIP_QUANTITY } from 'src/common/constants';
import { MSAType } from 'src/pages/ManageSettings/SingleProject/SingleProjectFormWrapper/SingleProjectContent/MSADetails/MSADetails';

import { MAX_REQUEST_LIMIT } from 'valtech-core/common/constants';
import { ERROR_PROJECT_NAME_EXIST } from 'valtech-core/common/ftl';
import {
  ActivationStatus,
  OrderDirection,
  useCreateUserProjectMutation,
  useEditUserProjectByIdMutation,
  useGetProjectDataQuery,
  useGetSingleProjectSelectDataQuery,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import {
  IDeliveryManager,
  formattedArrayOfDeliveryManagers,
  mapToSubmitAddProject,
  mapToSubmitEditProject,
} from 'src/pages/ManageSettings/SingleProject/SingleProject.utils';

type CreateProject = (data: SingleProjectFormProps) => Promise<number | undefined>;
type EditProject = (data: EditProjectVariables) => Promise<boolean | undefined>;

type useHandleSingleProjectReturn = {
  editProject: EditProject;
  createProject: CreateProject;
  loading: boolean;
};

type SingleProjectInfo = Omit<SingleProjectFormProps, 'associatedClients' | 'ICBO' | 'credentials'>;

type useSingleProjectReturn = {
  singleProject: SingleProjectInfo | null;
  loading: boolean;
};

export const useHandleSingleProject = (): useHandleSingleProjectReturn => {
  const [createUserProjectMutation, { loading: creating }] = useCreateUserProjectMutation({
    onError(e) {
      if (e.message.includes('Key (name)') && e.message.includes('already exists')) {
        showSystemError(ERROR_PROJECT_NAME_EXIST);
      } else {
        showSystemError(getErrorMessage(e.message));
      }
    },
  });

  const [editUserProjectByIdMutation, { loading: updating }] = useEditUserProjectByIdMutation({
    onError(e) {
      if (e.message.includes('Key (name)') && e.message.includes('already exists')) {
        showSystemError(ERROR_PROJECT_NAME_EXIST);
      } else {
        showSystemError(getErrorMessage(e.message));
      }
    },
  });

  const createProject = useCallback(async (data: SingleProjectFormProps) => {
    const newProject = await createUserProjectMutation({
      variables: mapToSubmitAddProject(data),
    });

    return newProject.data?.createProject.id;
  }, []);

  const editProject = useCallback(async (data: EditProjectVariables) => {
    if (data.id) {
      const editedProject = await editUserProjectByIdMutation({
        variables: mapToSubmitEditProject(data),
      });

      return !!editedProject.data?.updateProjectByID;
    }

    return false;
  }, []);

  return {
    createProject,
    editProject,
    loading: creating || updating,
  };
};

export const useSingleProject = (projectId?: number): useSingleProjectReturn => {
  if (projectId) {
    const { data, loading } = useGetProjectDataQuery({
      variables: {
        Id: projectId,
        pagination: {
          limit: MAX_REQUEST_LIMIT,
          skip: DEFAULT_SKIP_QUANTITY,
        },
        filter: [
          {
            field: 'name',
            filterModel: '',
            query: '',
          },
        ],
        orderBy: {
          model: '',
          sortBy: 'name',
          sortDirection: OrderDirection.Asc,
        },
      },
      fetchPolicy: 'cache-and-network',
      onError() {
        showGenericError();
      },
    });

    const singleProject = useMemo((): SingleProjectInfo | null => {
      if (!data) {
        return null;
      }
      const project = data.getProjectByID;
      const arrayOfDeliveryManagers = formattedArrayOfDeliveryManagers(
        data.sendArrayOfUsers.data as IDeliveryManager[],
      );

      return {
        accountId: project.account_id as number,
        businessUnitId: project.bu_id as string,
        projectName: project.name,
        currency: project.ccy as CurrencyEnum,
        status: project.status as ActivationStatus,
        deliveryManagerId: project.delivery_manager_id as number,
        msaNumber: project.msa_no || '',
        msaDate: project.msa_date,
        msaType: project.type as MSAType,
        arrayOfDeliveryManagers: arrayOfDeliveryManagers,
      };
    }, [data]);

    return {
      singleProject,
      loading,
    };
  } else {
    const { data, loading } = useGetSingleProjectSelectDataQuery({
      variables: {
        pagination: {
          limit: MAX_REQUEST_LIMIT,
          skip: DEFAULT_SKIP_QUANTITY,
        },
        filter: [
          {
            field: 'name',
            filterModel: '',
            query: '',
          },
        ],
        orderBy: {
          model: '',
          sortBy: 'name',
          sortDirection: OrderDirection.Asc,
        },
      },
      fetchPolicy: 'cache-and-network',
      onError() {
        showGenericError();
      },
    });

    const singleProject = useMemo((): SingleProjectInfo | null => {
      const arrayOfDeliveryManagers =
        data && formattedArrayOfDeliveryManagers(data.sendArrayOfUsers.data as IDeliveryManager[]);

      return {
        accountId: '',
        businessUnitId: '',
        projectName: '',
        currency: CurrencyEnum.EUR as CurrencyEnum,
        status: '' as unknown as any,
        deliveryManagerId: '',
        msaNumber: '',
        msaDate: '',
        msaType: '' as MSAType,
        arrayOfDeliveryManagers: arrayOfDeliveryManagers,
      };
    }, [data]);

    return {
      singleProject,
      loading,
    };
  }
};
