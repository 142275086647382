export const HOME_ROUTE = '/';
export const ADMIN_ROUTE = '/admin';

export const NOT_FOUND = '/404';
export const NOT_ALLOWED = '/not-allowed';
export const OTHER = '*';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const OAUTH_CALLBACK = '/oauth/callback';

export const BILLING_ROUTE = '/billing/:id';
export const BILLINGS_ROUTE = '/billings';
export const BUDGETING_ROUTE = '/budgeting/:id';
export const BUDGETINGS_ROUTE = '/budgetings';
export const ICBOS_ROUTE = '/icbos';
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_BUDGETINGS_ROUTE = `${SETTINGS_ROUTE}${BUDGETINGS_ROUTE}`;

export const MANAGE_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/*`;
