import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import { SETTINGS_ADD_ROUTE } from 'common/routes';
import AllBudgetingsTable from 'pages/ManageSettings/AllBudgetings/AllBudgetingsTable';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';

import { ADD_FTL, BUDGETING_FTL, BUDGET_FTL } from 'valtech-core/common/ftl';

const AllBudgetings: FC = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();

  const navigateToAddAccount = () => navigate(SETTINGS_ADD_ROUTE);

  return (
    <>
      <SettingsHeaderReusable
        title={l10n.getString(BUDGETING_FTL)}
        btnTitle={`${l10n.getString(ADD_FTL)} ${BUDGET_FTL}`}
        callback={navigateToAddAccount}
      />
      <AllBudgetingsTable />
    </>
  );
};

export default AllBudgetings;
