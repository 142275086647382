import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import SelectFiled from 'form/SelectField';
import TextField from 'form/TextField';
import { EFormFieldName } from 'src/components/_forms/form.types';
import {
  selectMockCurrency,
  selectMockStatus,
} from 'src/pages/ManageSettings/SingleProject/SingleProject.const';

import Grid from '@mui/material/Grid';

import {
  businessUnitOptionalValidator,
  requiredMidStringValidator,
  requiredStringValidator,
} from 'valtech-core/common/form/validators';
import {
  BUSINESS_UNIT_ID_FTL,
  CURRENCY_FTL,
  NAME_FTL,
  PROJECT_FTL,
  STATUS_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';

interface IGeneralInformationProps {
  control: FieldControl;
}

const GeneralInformation: FC<IGeneralInformationProps> = ({ control }) => {
  const { l10n } = useLocalization();

  return (
    <>
      <Grid item xs={4}>
        <TextField
          label={`${l10n.getString(PROJECT_FTL)} ${l10n.getString(NAME_FTL)}`}
          name={EFormFieldName.ProjectName}
          control={control as unknown as FieldControl}
          textProps={{
            size: 'medium',
            type: 'text',
          }}
          validate={requiredMidStringValidator}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label={l10n.getString(BUSINESS_UNIT_ID_FTL)}
          name='businessUnitId'
          control={control as unknown as FieldControl}
          textProps={{
            size: 'medium',
            type: 'text',
          }}
          validate={businessUnitOptionalValidator}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectFiled
          label={l10n.getString(CURRENCY_FTL)}
          name='currency'
          data={selectMockCurrency}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectFiled
          label={l10n.getString(STATUS_FTL)}
          name='status'
          data={selectMockStatus}
          control={control as unknown as FieldControl}
          validate={requiredStringValidator}
        />
      </Grid>
    </>
  );
};

export default GeneralInformation;
