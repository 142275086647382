import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import TableRowReusable from 'components/TableRowReusable';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { EXCHANGE_RATE_FTL } from 'valtech-core/common/ftl';
import ProgressLinear from 'valtech-core/ui/ProgressLinear/ProgressLinear';

import { exchangeRateHook, useUpdateExchangeRate } from './ExchangeRate.hook';
import ExchangeRateItem from './ExchangeRateItem';
import { EXCHANGE_RATE_ID_PREFIX, headData } from './ExchangeRateTable.constants';

const ExchangeRateTable: FC = () => {
  const [editingItem, setEditingItem] = useState({ id: '', field: '' });
  const { l10n } = useLocalization();
  const methods = useFormContext();
  const { id: budgetID } = useParams();
  const { control, setValue, getValues } = methods;
  const { isEditing, setState } = useEditingContext();

  const { exchangeRate } = exchangeRateHook();

  const {
    loading,
    sorting: { sortingType, activeColumn, handleSorting },
  } = exchangeRate;

  const { fields, replace } = useFieldArray({
    control,
    name: 'exchangeRate.exchangeRates',
  });

  const { onModify, loading: isUpdating } = useUpdateExchangeRate({ methods, replace });

  const onCancel = () => {
    setEditingItem({ id: '', field: '' });
    setState(false);
  };

  useEffect(() => {
    setValue('exchangeRate', exchangeRate);
    replace(exchangeRate.exchangeRates);
    onCancel();
  }, [loading]);

  const onSave = (index, field) => value => {
    onModify({ index, key: field, value }).then(() => {
      setValue(`exchangeRate.exchangeRates[${index}].[${field}]`, value);
    });
  };

  const onSelect = (id, field) => () => {
    if (isEditing) return;
    setEditingItem({ id, field });
    setState(true);
  };

  const checkIsActive = (id, field) => {
    const { id: currentID, field: currentField } = editingItem;
    return id === currentID && currentField === field;
  };

  const isDisabled =
    !budgetID ||
    isEditing ||
    Boolean(editingItem.id && editingItem.field) ||
    getValues('exchangeRate.exchangeRates[0]')?.id?.toString().includes(EXCHANGE_RATE_ID_PREFIX);

  return (
    <Box>
      <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(EXCHANGE_RATE_FTL)}</Box>
      <TableContainer sx={{ mt: 5 }}>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'relative',
                bottom: 0,
                left: 0,
              },
            }}
          />
        )}
        <Table aria-label='simple table'>
          <TableHead>
            <TableRowReusable
              headData={headData}
              sortingType={sortingType}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
              disableSorting={isDisabled}
            />
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <Typography>
                    {l10n.getString(getValues(`exchangeRate.exchangeRates[${index}].l10n`))}
                  </Typography>
                </TableCell>
                <ExchangeRateItem
                  isActive={checkIsActive(field.id, 'usd_to_eur')}
                  onSave={onSave(index, 'usd_to_eur')}
                  onSelect={onSelect(field.id, 'usd_to_eur')}
                  onCancel={onCancel}
                  value={getValues(`exchangeRate.exchangeRates[${index}].usd_to_eur`)}
                  disabled={isUpdating}
                />
                <ExchangeRateItem
                  isActive={checkIsActive(field.id, 'eur_to_usd')}
                  onSave={onSave(index, 'eur_to_usd')}
                  onSelect={onSelect(field.id, 'eur_to_usd')}
                  onCancel={onCancel}
                  value={getValues(`exchangeRate.exchangeRates[${index}].eur_to_usd`)}
                  disabled={isUpdating}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExchangeRateTable;
