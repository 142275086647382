import { useCallback } from 'react';

import { ISettingsUserFormProps } from 'components/_forms/SettingsUserForm';

import { useToggle } from 'valtech-core/utils/useToggle';

import { SettingsUser } from '../AllUsers.types';

type UseHandleTableRowEditReturn = {
  isOpened: boolean;
  toggleForm: VoidFunction;
  getSettingsUserInitialValues: (item: SettingsUser) => ISettingsUserFormProps;
};

type UseHandleTableRowEditParams = {
  rowRecord: SettingsUser;
  settingsUserId: number;
};

export const useHandleAllUsersTableRowEdit = ({
  rowRecord,
}: UseHandleTableRowEditParams): UseHandleTableRowEditReturn => {
  const [isOpened, toggleForm] = useToggle(false);

  const getSettingsUserInitialValues = useCallback(
    (item: SettingsUser): ISettingsUserFormProps => {
      return {
        userId: item.id,
        name: item.name,
        email: item.email,
        role: item.role,
        status: item.status,
        consultant: { id: Number(item.id), label: item.name, email: item.email },
      };
    },
    [rowRecord],
  );

  return {
    isOpened,
    toggleForm,
    getSettingsUserInitialValues,
  };
};
