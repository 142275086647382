import React, { FC } from 'react';

import { ReactLocalization } from '@fluent/react';
import cn from 'classnames';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { CurrencyEnum } from 'valtech-core/common/types';
import createNumberWithCurrency from 'valtech-core/common/utils/createNumberWithCurrency';
import NA from 'valtech-core/ui/NA';
import Typography from 'valtech-core/ui/Typography';

import { TotalCosts } from '../Budgeting.types';
import styles from './EditBudgetingTable.module.scss';

interface GroupTotalRowProps {
  title: string;
  data: TotalCosts;
  l10n: ReactLocalization;
  currency: CurrencyEnum;
}

const GroupTotalRow: FC<GroupTotalRowProps> = ({ title, l10n, data, currency }) => {
  if (!data.months.length) return null;

  return (
    <TableRow>
      <TableCell
        component='th'
        scope='row'
        className={cn(
          styles.stickyCell,
          styles.totalColored,
          styles.generalCellTitle,
          styles.cellSpacing,
        )}>
        <Typography variant='body2' weight='bold'>
          {l10n.getString(title)}
        </Typography>
      </TableCell>
      {data.months.map(totalCost => (
        <TableCell
          key={`${totalCost.monthIndex}::${totalCost.cost}`}
          align='right'
          className={styles.totalColoredCell}>
          <NA
            value={createNumberWithCurrency({
              number: totalCost.cost,
              currencyDisplay: 'symbol',
              currency,
            })}
          />
        </TableCell>
      ))}
      <TableCell
        align='right'
        className={cn(
          styles.totalColoredCell,
          styles.stickyCell,
          styles.stickToRight,
          styles.totalCell,
        )}>
        <NA
          value={createNumberWithCurrency({
            number: data.total,
            currencyDisplay: 'symbol',
            currency,
          })}
        />
      </TableCell>
    </TableRow>
  );
};

export default GroupTotalRow;
