import { useMemo } from 'react';

import { useGetTimesheetsByConsultantIdQuery } from 'valtech-core/common/gql/generated';

interface ITaskDetails {
  taskTitle: string;
  totalHours: number;
}

interface IClientDetails {
  clientName: string;
  isProcessedError: boolean;
  task: ITaskDetails[];
  totalHours: number;
}

interface ITimesheetDetails {
  projectName: string;
  client: IClientDetails[];
  totalHours: number;
}

export interface IUseTimesheetDetailsReturn {
  timesheetDetails: ITimesheetDetails[];
  loading: boolean;
  consultantDetails: {
    consultantName: string;
    totalHours: string | number;
    isNotMatchForecast: boolean;
  };
}

interface IUseTimesheetDetailsProps {
  consultantId: number;
  month: number;
  year: number;
  billingId: number;
}

export const useTimesheetDetails = ({
  consultantId,
  month,
  year,
  billingId,
}: IUseTimesheetDetailsProps): IUseTimesheetDetailsReturn => {
  const { data, loading } = useGetTimesheetsByConsultantIdQuery({
    variables: {
      consultantId,
      month,
      year,
      billingId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const consultantDetails = useMemo(() => {
    if (!data) {
      return {
        consultantName: '',
        totalHours: '',
        isNotMatchForecast: false,
      };
    }

    return {
      consultantName: data.getTimesheetsByConsultantID?.consultantName || '',
      totalHours: data.getTimesheetsByConsultantID?.totalHours || '',
      isNotMatchForecast: data.getTimesheetsByConsultantID?.isNotMatchForecast ?? false,
    };
  }, [data]);

  const timesheetDetails: ITimesheetDetails[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data.getTimesheetsByConsultantID?.data || []).map(item => {
      return {
        projectName: item.projectName,
        totalHours: item.totalHours,
        client: (item.client || []).map(clientItem => ({
          clientName: clientItem.clientName,
          isProcessedError: clientItem.isProcessedError,
          totalHours: clientItem.totalHours,
          task: (clientItem.task || []).map(taskItem => ({
            taskTitle: taskItem.taskTitle,
            totalHours: taskItem.totalHours,
          })),
        })),
      } as ITimesheetDetails;
    });
  }, [data]);

  return {
    timesheetDetails,
    loading,
    consultantDetails,
  };
};
