import { UseFormSetValue } from 'react-hook-form';

import { ConsultantModelEnum } from 'common/types';
import moment from 'moment/moment';

import {
  CopyAssignment,
  CopyExpenses,
  CopyRowsFromBudgetMutationVariables,
  CopyVacancy,
} from 'valtech-core/common/gql/generated';
import { BudgetingGroupsMultipleEnum, DateMask } from 'valtech-core/common/types';

import { AssignmentCopyRow, ExpenseCopyRow, VacancyCopyRow } from './CopyBudgetingRowsForm.hooks';

import { transformAllocation } from 'components/_forms/forms.utils';

import {
  CopyBudgetingRowsFormSubmitProps,
  copyRowSubmitStatusEnum,
  copyRowsStatusType,
} from './CopyBudgetingRowsForm';

type GetSelectedBudgetingRowsIdsParams = {
  selectedRowsIds: Set<string>;
};

type GetSelectedBudgetingRowsIdsReturn = {
  consultantsIds: number[];
  vacanciesIds: number[];
  expensesIds: number[];
};

export const getSelectedBudgetingRowsIds = ({
  selectedRowsIds,
}: GetSelectedBudgetingRowsIdsParams): GetSelectedBudgetingRowsIdsReturn => {
  const budgetingRowsIds: { assignment: number[]; vacancy: number[]; expense: number[] } = {
    assignment: [],
    vacancy: [],
    expense: [],
  };

  selectedRowsIds.forEach(selectedRow => {
    const rowTypeTitle = selectedRow.split('::')[0].split(':')[1];
    const rowTypeValue = parseInt(selectedRow.split('::')[1].split(':')[1]);

    budgetingRowsIds[rowTypeTitle].push(rowTypeValue);
  });

  return {
    consultantsIds: budgetingRowsIds.assignment,
    vacanciesIds: budgetingRowsIds.vacancy,
    expensesIds: budgetingRowsIds.expense,
  };
};

type mapToSubmitCopyBudgetingRowsProps = {
  data: CopyBudgetingRowsFormSubmitProps;
  projectId: number;
  budgetingCopyInSettingsId: number;
  currency: string;
};

export const mapToSubmitCopyBudgetingRows = ({
  data,
  projectId,
  budgetingCopyInSettingsId,
  currency,
}: mapToSubmitCopyBudgetingRowsProps): CopyRowsFromBudgetMutationVariables => {
  const assignments: CopyAssignment[] =
    data.assignments && data.assignments.length
      ? data.assignments.reduce(
          (assignmentsAcc: CopyAssignment[], assignment: AssignmentCopyRow) => {
            const percentageOrHoursKey =
              assignment.model === ConsultantModelEnum.TimeAndMaterial
                ? 'hours_per_month'
                : 'percentage';
            const percentageOrHours =
              assignment.model === ConsultantModelEnum.TimeAndMaterial
                ? Number(assignment.hours)
                : transformAllocation(Number(assignment.allocation));

            assignment.submitStatus !== copyRowSubmitStatusEnum.Success &&
              assignmentsAcc.push({
                ccy: currency,
                client_id: Number(assignment.clientId),
                consultant_id: assignment.consultant.id,
                cost_of_overhead: assignment.costOfOverhead,
                end_date: moment(assignment.endDate).format(DateMask.YYYY_MM_DD),
                fee: Number(assignment.fee),
                [percentageOrHoursKey]: percentageOrHours,
                holiday_calendar_id: Number(assignment.holidayCalendarId),
                model: assignment.model,
                notes: assignment.notes,
                icbo_template_id: 2,
                rate_id: Number(assignment.rate),
                start_date: moment(assignment.startDate).format(DateMask.YYYY_MM_DD),
                parent_assignment_id: assignment.assignmentId,
              });

            return assignmentsAcc;
          },
          [],
        )
      : [];

  const vacancies: CopyVacancy[] =
    data.vacancies && data.vacancies.length
      ? data.vacancies.reduce((vacanciesAcc: CopyVacancy[], vacancy: VacancyCopyRow) => {
          const percentageOrHoursKey =
            vacancy.model === ConsultantModelEnum.TimeAndMaterial
              ? 'hours_per_month'
              : 'percentage';
          const percentageOrHours =
            vacancy.model === ConsultantModelEnum.TimeAndMaterial
              ? Number(vacancy.hours)
              : transformAllocation(Number(vacancy.allocation));

          vacancy.submitStatus !== copyRowSubmitStatusEnum.Success &&
            vacanciesAcc.push({
              ccy: currency,
              client_id: Number(vacancy.clientId),
              end_date: moment(vacancy.endDate).format(DateMask.YYYY_MM_DD),
              fee: Number(vacancy.fee),
              [percentageOrHoursKey]: percentageOrHours,
              holiday_calendar_id: Number(vacancy.holidayCalendarId),
              model: vacancy.model,
              notes: vacancy.notes,
              rate_id: Number(vacancy.rate),
              role: vacancy.role,
              start_date: moment(vacancy.startDate).format(DateMask.YYYY_MM_DD),
              parent_assignment_id: vacancy.assignmentId,
            });

          return vacanciesAcc;
        }, [])
      : [];

  const expenses: CopyExpenses[] =
    data.expenses && data.expenses.length
      ? data.expenses.reduce((expensesAcc: CopyExpenses[], expense: ExpenseCopyRow) => {
          expense.submitStatus !== copyRowSubmitStatusEnum.Success &&
            expensesAcc.push({
              text: expense.title,
              client_id: Number(expense.clientId),
              cost: Number(expense.cost),
              end_date: moment(expense.endDate).format(DateMask.YYYY_MM_DD),
              notes: expense.notes,
              start_date: moment(expense.startDate).format(DateMask.YYYY_MM_DD),
              parent_assignment_id: expense.assignmentId,
            });

          return expensesAcc;
        }, [])
      : [];

  return {
    projectId,
    budgetingSettingId: budgetingCopyInSettingsId,
    assignments,
    vacancies,
    expenses,
  };
};

type changeCopyRowsSubmitStatusProps = {
  copyRowsStatus: copyRowsStatusType;
  fields: any;
  groupEnum: BudgetingGroupsMultipleEnum;
  setValue: UseFormSetValue<CopyBudgetingRowsFormSubmitProps>;
};

export const changeCopyRowsSubmitStatus = ({
  copyRowsStatus,
  fields,
  groupEnum,
  setValue,
}: changeCopyRowsSubmitStatusProps): void => {
  fields.forEach((field, index) => {
    if (copyRowsStatus[groupEnum]?.includes(field.assignmentId)) {
      setValue(`${groupEnum}.${index}.submitStatus`, copyRowSubmitStatusEnum.Success);
    }

    if (copyRowsStatus.errors?.find(e => e.id === field.assignmentId)) {
      setValue(`${groupEnum}.${index}.submitStatus`, copyRowSubmitStatusEnum.Error);
    }
  });
};

export const getCopyRowErrorMessage = (
  errors: { id: number; message: string }[],
  assignmentId: number,
): string => {
  const error = errors.find(e => e.id === assignmentId);

  return error ? error.message : '';
};
