import moment from 'moment';

import {
  AddExpenseMutationVariables,
  EditExpenseMutationVariables,
} from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';

import { IExpenseFormProps } from './ExpenseForm';

type SubmitAddExpensesParams = {
  budgetReportId: number;
  projectId: number;
  data: IExpenseFormProps;
};

export const mapToSubmitAddExpenses = ({
  budgetReportId,
  projectId,
  data,
}: SubmitAddExpensesParams): AddExpenseMutationVariables => {
  return {
    budgetReportId,
    projectId,
    text: data.title,
    clientId: Number(data.client),
    cost: Number(data.cost),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    notes: data.notes,
  };
};

export const mapToSubmitEditExpenses = (
  projectId: number,
  assignmentId: number,
  data: IExpenseFormProps,
): EditExpenseMutationVariables => {
  return {
    projectId,
    assignementId: Number(assignmentId),
    text: data.title,
    cost: Number(data.cost),
    clientId: Number(data.client),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    notes: data.notes,
  };
};
