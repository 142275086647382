import React, { FC, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import moment, { Moment } from 'moment';

import { BoxProps } from '@mui/material/Box';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import DatePicker from 'valtech-core/form/DataPicker';
import FormError from 'valtech-core/form/FormError';

export interface IDataPickerFieldProps {
  control: FieldControl;
  name: string;
  boxProps?: BoxProps;
  label?: string | undefined;
  validate?: (value: string) => Promise<Maybe<string>>;
  maxDate?: Moment;
  minDate?: Moment;
  disabled?: boolean;
  format?: string;
  defaultCalendarMonth?: string;
  defaultValue?: Moment | string;
  className?: string;
}

const DatePickerField: FC<IDataPickerFieldProps> = ({
  name,
  control,
  label,
  validate,
  disabled = false,
  defaultValue,
  defaultCalendarMonth,
  className,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <DatePicker
          label={label}
          error={!!errors[name]}
          onChange={value => {
            const formattedValue = moment(value);
            field.onChange(formattedValue);
          }}
          className={className}
          value={field.value}
          disabled={disabled}
          defaultCalendarMonth={moment(defaultCalendarMonth)}
          {...rest}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </DatePicker>
      )}
      rules={{ validate }}
    />
  );
};

export default memo(DatePickerField);
