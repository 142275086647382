import React, { FC, useEffect } from 'react';

import { FieldValues, UseFieldArrayAppend, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import DataPickerField from 'form/DataPickerField/DatePickerField';
import TextField from 'form/TextField/TextField';

import moment from 'moment';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ADD_FTL, DATE_FTL, HOLIDAY_FTL, TITLE_FTL } from 'valtech-core/common/ftl';
import { DateMask, FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';

import {
  HolidayFields,
  dateAddHolidayValidator,
  titleAddHolidayValidator,
} from '../Holiday.constants';
import { defaultValues } from './AddHoliday.constants';
import { IUseAddHolidayParams, useAddHolidayDate } from './AddHoliday.hook';

interface IAddHolidayProps {
  order: number;
  updOrder: (order: number) => void;
  currentYear: number | string | undefined;
  onCheckBeforeAdd: ({ date }: { date: string }) => boolean;
  append: UseFieldArrayAppend<FieldValues, 'holiday.holidays'>;
}

const AddHoliday: FC<IAddHolidayProps> = ({
  order,
  append,
  updOrder,
  currentYear,
  onCheckBeforeAdd,
}) => {
  const { l10n } = useLocalization();
  const methods = useForm({ defaultValues: defaultValues });
  const { control, resetField, getValues } = methods;
  const { isEditing } = useEditingContext();

  const { addHolidayDate, loading } = useAddHolidayDate({
    order,
    append,
    methods,
  } as unknown as IUseAddHolidayParams);

  const add = () => {
    if (onCheckBeforeAdd({ date: getValues(HolidayFields.Date) || '' })) return;

    addHolidayDate().then(() => updOrder(order + 1));
  };

  useEffect(() => {
    if (currentYear) {
      const newYear = new Date();
      newYear.setFullYear(Number(currentYear));
      resetField(HolidayFields.Date, {
        defaultValue: moment(newYear, DateMask.DD_MM_YYYY) as unknown as string,
      });
    }
  }, [updOrder]);

  return (
    <TableRow>
      <TableCell>
        <TextField
          label={l10n.getString(TITLE_FTL)}
          name={HolidayFields.Title}
          control={control as unknown as FieldControl}
          validate={titleAddHolidayValidator}
          onBlurCallback={e => {
            resetField(HolidayFields.Title, { defaultValue: e.target.value.trim() });
          }}
        />
      </TableCell>
      <TableCell>
        <DataPickerField
          label={l10n.getString(DATE_FTL)}
          name={HolidayFields.Date}
          control={control as unknown as FieldControl}
          validate={dateAddHolidayValidator}
          minDate={getCalendarRangeByYear(Number(currentYear)).firstDayOfYear}
          maxDate={getCalendarRangeByYear(Number(currentYear)).lastDayOfYear}
        />
      </TableCell>
      <TableCell>
        <Button
          disabled={loading || isEditing}
          onClick={add}
          variant='outlined'
          type='button'
          color='primary'
          sx={{ mx: 1 }}>
          {`${l10n.getString(ADD_FTL)} ${l10n.getString(HOLIDAY_FTL)}`}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AddHoliday;
