import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import s from './BlurContainer.module.scss';

interface IBlurContainerProps {
  disabled?: boolean;
  children?: ReactNode;
}

const BlurContainer: FC<IBlurContainerProps> = ({ disabled, children }) => {
  return (
    <div className={s.BlurContainer}>
      <div
        className={cn(s.BlurContainer__blur, {
          [s['BlurContainer__blur--active']]: disabled,
        })}
      />
      {children}
    </div>
  );
};

export default BlurContainer;
