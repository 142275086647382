import { useCallback, useMemo } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showSystemError } from 'common/apolloState/system';
import { useClients } from 'common/hooks/useClients';
import { useConsultantsRoles } from 'common/hooks/useConsultantsRoles';
import { useHolidayCalendarList } from 'common/hooks/useHolidayCalendarList';
import { RateItem, useRateList } from 'common/hooks/useRateList/useRateList';
import { ConsultantModelEnum } from 'common/types';

import {
  RecordStatus,
  useAddVacancyMutation,
  useEditVacancyMutation,
  useUpdateBudgetMutation,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import { SelectedItem } from 'valtech-core/form/Select';

import { useDeleteAssignment } from 'components/_forms/forms.hooks';

import { mapToSubmitAddVacancy, mapToSubmitEditVacancy } from './VacancyForm.utils';

import { IVacancyFormProps } from './VacancyForm';

type UseHandleVacancyReturn = {
  createVacancy: (data: IVacancyFormProps) => Promise<boolean>;
  updateVacancy: (assignmentId: Maybe<number>, data: IVacancyFormProps) => Promise<boolean>;
  deleteVacancy: (vacancyId: Maybe<number>) => Promise<boolean>;
  clientsList: SelectedItem[];
  rateList: { dedicated: SelectedItem[]; timeAndMaterial: SelectedItem[] };
  rates: { dedicated: RateItem[]; timeAndMaterial: RateItem[] };
  modelList: SelectedItem[];
  holidayCalendarList: SelectedItem[];
  roleList: SelectedItem[];
  loading: boolean;
};

export const useHandleVacancy = (): UseHandleVacancyReturn => {
  const {
    settingId: budgetingSettingId,
    budgetReportId,
    projectId,
    financialYear,
    currency,
  } = useBudgetingState();

  const { clients } = useClients();
  const { rates } = useRateList({ currency });
  const { roles } = useConsultantsRoles();
  const { holidayCalendar } = useHolidayCalendarList({
    filter: [
      { field: 'status', query: RecordStatus.Active },
      { field: 'year', query: `${financialYear}` },
    ],
  });

  const [addVacancyMutation, { loading: adding }] = useAddVacancyMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [editVacancyMutation, { loading: updating }] = useEditVacancyMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const { loading: deleting, deleteAssignment: deleteVacancy } = useDeleteAssignment();

  const clientsList = useMemo(
    (): SelectedItem[] =>
      clients.map(client => ({ id: client.id, title: client.name, value: `${client.id}` })),
    [clients],
  );

  const rateList = useMemo(
    (): { dedicated: SelectedItem[]; timeAndMaterial: SelectedItem[] } => ({
      dedicated: rates.dedicated.map(rate => ({
        id: rate.id,
        title: rate.label,
        value: `${rate.id}`,
      })),
      timeAndMaterial: rates.timeAndMaterial.map(rate => ({
        id: rate.id,
        title: rate.label,
        value: `${rate.id}`,
      })),
    }),

    [rates],
  );

  const roleList = useMemo(
    (): SelectedItem[] =>
      roles.map(rate => ({ id: rate.id, title: rate.label, value: `${rate.id}` })),
    [roles],
  );

  const holidayCalendarList = useMemo(
    (): SelectedItem[] =>
      holidayCalendar.map(calendar => ({
        id: calendar.id,
        title: calendar.label,
        value: `${calendar.id}`,
      })),
    [holidayCalendar],
  );

  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const createVacancy = useCallback(async (data: IVacancyFormProps) => {
    if (budgetReportId && budgetingSettingId && projectId) {
      const newVacancy = await addVacancyMutation({
        variables: mapToSubmitAddVacancy({
          budgetReportId,
          budgetingSettingId,
          data,
          projectId,
        }),
        refetchQueries: ['getSingleBudgeting'],
      });

      await UpdateBudgetMutation({
        variables: {
          budgetId: Number(budgetReportId),
        },
      });

      return !!newVacancy.data?.addVacancy.id;
    }

    return false;
  }, []);

  const updateVacancy = useCallback(
    async (assignmentId: Maybe<number>, data: IVacancyFormProps) => {
      if (assignmentId && projectId) {
        const editedVacancy = await editVacancyMutation({
          variables: mapToSubmitEditVacancy(projectId, assignmentId, data),
          refetchQueries: ['getSingleBudgeting'],
        });

        await UpdateBudgetMutation({
          variables: {
            budgetId: Number(budgetReportId),
          },
        });

        return !!editedVacancy.data?.editVacancy;
      }

      return false;
    },
    [projectId],
  );

  const modelList = [
    { id: '1', title: ConsultantModelEnum.Dedicated, value: ConsultantModelEnum.Dedicated },
    {
      id: '2',
      title: ConsultantModelEnum.TimeAndMaterial,
      value: ConsultantModelEnum.TimeAndMaterial,
    },
  ];

  return {
    createVacancy,
    updateVacancy,
    deleteVacancy,
    holidayCalendarList,
    clientsList,
    modelList,
    rateList,
    roleList,
    rates,
    loading: adding || updating || deleting,
  };
};
