import { CurrencyEnum, Maybe } from 'valtech-core/common/types';

interface IParams {
  currencyDisplay?: 'symbol' | 'code';
  number: Maybe<number>;
  currency?: CurrencyEnum;
}

const createNumberWithCurrency = ({
  number,
  currency = CurrencyEnum.EUR,
  currencyDisplay = 'code',
}: IParams): string => {
  if (typeof number !== 'number') {
    return '-';
  }

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    currencyDisplay,
  });
};

export default createNumberWithCurrency;
