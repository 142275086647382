import { FilterModelEnum, TableSearchFieldsEnum } from 'src/common/types';

import {
  BILL_FTL,
  DELIVERY_MANAGER_FTL,
  PROJECT_FTL,
  STATUS_FTL,
  TOTAL_FTL,
} from 'valtech-core/common/ftl';

export const headData = [
  { l10n: BILL_FTL, model: '', sortBy: TableSearchFieldsEnum.Title },
  { l10n: PROJECT_FTL, model: FilterModelEnum.Project, sortBy: TableSearchFieldsEnum.Name },
  {
    l10n: DELIVERY_MANAGER_FTL,
    model: FilterModelEnum.DeliveryManager,
    sortBy: TableSearchFieldsEnum.Name,
  },
  {
    l10n: STATUS_FTL,
    model: '',
    sortBy: 'status',
  },
  { l10n: TOTAL_FTL, model: '', sortBy: 'total' },
];
