import React, { FC } from 'react';

import cn from 'classnames';

import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

import styles from './SpinnerOverlay.module.scss';

interface ISpinnerOverlayProps extends CircularProgressProps {
  className?: string;
  visible: boolean;
}

const SpinnerOverlay: FC<ISpinnerOverlayProps> = ({ className, visible = false, ...rest }) => {
  if (!visible) {
    return null;
  }

  return (
    <Box className={cn(className, styles.overlay)}>
      <CircularProgress {...rest} />
    </Box>
  );
};

export default SpinnerOverlay;
