import { useEffect, useMemo } from 'react';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { ConsultantModelEnum } from 'common/types';
import { EFormFieldName } from 'src/components/_forms/form.types';

import {
  OrderDirection,
  RecordStatus,
  useGetRatesByBudgetingSettingIdLazyQuery,
} from 'valtech-core/common/gql/generated';
import { CurrencyEnum } from 'valtech-core/common/types';

export type RateItem = { id: number; label: string; fee: number; entity?: string };

type UseRateListReturn = {
  rates: {
    dedicated: RateItem[];
    timeAndMaterial: RateItem[];
  };
  loading: boolean;
};

type UseRateListParams = {
  budgetingSettingId?: number;
  model?: ConsultantModelEnum;
  currency?: CurrencyEnum;
};

export const useRateList = ({
  budgetingSettingId,
  model = ConsultantModelEnum.Dedicated,
  currency = CurrencyEnum.EUR,
}: UseRateListParams): UseRateListReturn => {
  const { settingId: stateSettingId, budgetingCopyInSettingsId } = useBudgetingState();
  const [getRatesById, { data, loading }] = useGetRatesByBudgetingSettingIdLazyQuery();

  const filterParams = [
    { field: EFormFieldName.Status, query: RecordStatus.Active },
    { field: EFormFieldName.CCY, query: currency },
  ];

  useEffect(() => {
    if (budgetingSettingId || budgetingCopyInSettingsId || stateSettingId) {
      getRatesById({
        variables: {
          budgetSettingsId:
            budgetingSettingId || budgetingCopyInSettingsId || (stateSettingId as number),
          orderBy: {
            sortBy: EFormFieldName.Title,
            sortDirection: OrderDirection.Asc,
          },
          filterDedicated: [
            { field: EFormFieldName.Model, query: ConsultantModelEnum.Dedicated },
            ...filterParams,
          ],
          filterTnM: [
            { field: EFormFieldName.Model, query: ConsultantModelEnum.TimeAndMaterial },
            ...filterParams,
          ],
        },
      });
    }
  }, [budgetingSettingId, stateSettingId, budgetingCopyInSettingsId, model]);

  const rates = useMemo((): {
    dedicated: RateItem[];
    timeAndMaterial: RateItem[];
  } => {
    if (!data) {
      return {
        dedicated: [],
        timeAndMaterial: [],
      };
    }

    return {
      dedicated: data.dedicated.map(rate => ({
        id: rate.id,
        label: rate.title,
        fee: rate.fee,
        entity: rate.entity,
      })),
      timeAndMaterial: data.timeAndMaterial.map(rate => ({
        id: rate.id,
        label: rate.title,
        fee: rate.fee,
        entity: rate.entity,
      })),
    };
  }, [data]);

  return {
    rates,
    loading,
  };
};
