import {
  CreateBudgetForProjectMutationVariables,
  RecordStatus,
} from 'valtech-core/common/gql/generated';

export interface IDataProjects {
  name: string;
  id: number;
  status: string;
}
export interface IDataBudgetSettings {
  title: string;
  id: number;
  status: string;
}

export interface IFormattedData {
  id: number;
  label: string;
}
export interface IFormattedBudgetData {
  projectId: { id: number };
  budgetingSettingId: { id: number };
}

export const formattedProjectsData = (arr: IDataProjects[]): Array<IFormattedData> => {
  const newArrayData: Array<IFormattedData> = [];
  arr
    .filter(el => el.status === RecordStatus.Active)
    .forEach(el => {
      newArrayData.push({
        id: el.id,
        label: el.name,
      });
    });
  return newArrayData;
};

export const formattedBudgetSettingsData = (arr: IDataBudgetSettings[]): Array<IFormattedData> => {
  const newArrayData: Array<IFormattedData> = [];
  arr
    .filter(el => el.status === RecordStatus.Active)
    .forEach(el => {
      newArrayData.push({
        id: el.id,
        label: el.title,
      });
    });
  return newArrayData;
};

export const mapToSubmitNewBudget = (
  data: IFormattedBudgetData,
): CreateBudgetForProjectMutationVariables => {
  return {
    projectId: data.projectId.id,
    budgetingSettingId: data.budgetingSettingId.id,
  };
};
