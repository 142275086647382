import React from 'react';

import { ApolloProvider } from '@apollo/client';

import App from 'pages/App';
import ReactDOM from 'react-dom/client';

import apolloClient from 'valtech-core/services/apollo';
import { AppLocalizationProvider } from 'valtech-core/services/l10n';

import './index.scss';
import { TanstackProvider } from './services/tanstackProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AppLocalizationProvider>
        <TanstackProvider>
          <App />
        </TanstackProvider>
      </AppLocalizationProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
