import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import TextInputWithButton from 'pages/SingleBilling/SingleBillingTabs/Comments/components/TextInputWithButton';
import { useSingleBillingTabs } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import Box from '@mui/material/Box';

import { WRITE_COMMENT_FTL } from 'valtech-core/common/ftl';

import Comment from '../Comment';
import s from './Comments.module.scss';

const Comments: FC = () => {
  const { l10n } = useLocalization();

  const { comments } = useSingleBillingTabs();

  return (
    <Box className={s.container}>
      <Box className={s.component}>
        {comments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </Box>
      <TextInputWithButton
        placeholder={l10n.getString(WRITE_COMMENT_FTL)}
        fieldName='comment'
        size='small'
      />
    </Box>
  );
};

export default Comments;
