import React, { FC, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';
import Skeleton from 'valtech-core/ui/Skeleton';

interface ITableSkeletonProps {
  rowsNumber: number;
  cellsNumber: number;
  total: number;
  page: number;
}

const TableSkeleton: FC<ITableSkeletonProps> = ({ rowsNumber, cellsNumber, total, page }) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsNumber);

  const getRowsNumber = () => {
    if (!total) {
      return;
    }

    const lastPageNumber = Math.floor(total / rowsNumber);
    if (lastPageNumber === page) {
      setRowsPerPage(total % rowsNumber);
    }
  };

  useEffect(() => {
    getRowsNumber();
  }, []);

  return (
    <>
      {new Array(rowsPerPage > 0 ? rowsPerPage : DEFAULT_REQUEST_LIMIT).fill(0).map(_row => {
        const rowId = uuidv4();
        return (
          <TableRow key={rowId}>
            {new Array(cellsNumber).fill(0).map(_cell => {
              const cellId = uuidv4();
              return (
                <TableCell key={cellId}>
                  <Skeleton animation='wave' />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};
export default TableSkeleton;
