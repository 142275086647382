import { useMemo } from 'react';

import { ApolloError, ApolloQueryResult } from '@apollo/client';

import {
  Exact,
  GetLatestUpdatedAtQuery,
  useGetLatestUpdatedAtQuery,
} from 'valtech-core/common/gql/generated';

interface IUseConsultantsLatestUpdatedFileReturn {
  consultantsLastUpdatedAt: string | null;
  error: ApolloError | undefined;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetLatestUpdatedAtQuery>>;
}

export const useConsultantsLatestUpdatedFile = (): IUseConsultantsLatestUpdatedFileReturn => {
  const { data, error, refetch } = useGetLatestUpdatedAtQuery({
    fetchPolicy: 'cache-and-network',
  });

  const consultantsLastUpdatedAt: string | null = useMemo(() => {
    if (!data?.getLatestUpdatedAt) {
      return null;
    }

    return new Date(data.getLatestUpdatedAt).toUTCString();
  }, [data]);

  return { consultantsLastUpdatedAt, refetch, error };
};
