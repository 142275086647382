import { useCallback, useState } from 'react';

import { IAssignmentFormProps } from 'components/_forms/AssignmentForm';
import { IExpenseFormProps, useHandleExpenses } from 'components/_forms/ExpenseForm';
import { IVacancyFormProps } from 'components/_forms/VacancyForm';
import { AssignmentProject } from 'src/common/types';

import { BudgetingGroupsEnum } from 'valtech-core/common/types';
import { useToggle } from 'valtech-core/utils/useToggle';

import { useDeleteAssignment } from 'components/_forms/forms.hooks';

import { AssignmentExpenses, AssignmentVacancy } from '../Budgeting.types';

type Consultant = { name: string; id: string };

type UseHandleTableRowEditReturn = {
  isOpened: boolean;
  toggleForm: VoidFunction;
  onDelete: VoidFunction;
  getAssignmentInitialValues: (
    consultant: Consultant,
    item: AssignmentProject,
  ) => IAssignmentFormProps;
  getVacanciesInitialValues: (consultant: Consultant, item: AssignmentVacancy) => IVacancyFormProps;
  getExpensesInitialValues: (item: AssignmentExpenses) => IExpenseFormProps;
  isLoading: boolean;
};

type UseHandleTableRowEditParams = {
  group: BudgetingGroupsEnum;
  rowRecord: AssignmentProject | AssignmentVacancy | AssignmentExpenses;
  assignmentId: number;
};

export const useHandleTableRowEdit = ({
  assignmentId,
  group,
  rowRecord,
}: UseHandleTableRowEditParams): UseHandleTableRowEditReturn => {
  const [isLoading, setLoading] = useState(false);
  const [isOpened, toggleForm] = useToggle(false);
  const { deleteAssignment } = useDeleteAssignment();
  const { deleteExpenses } = useHandleExpenses();

  const getAssignmentInitialValues = useCallback(
    (consultant: Consultant, item: AssignmentProject): IAssignmentFormProps => {
      return {
        consultant: {
          label: consultant.name,
          id: Number(consultant.id),
        },
        client: `${item.clientId}`,
        model: item.model,
        allocation: item.allocation,
        hours: item.hours,
        fee: getFeeInitialValue(item.rate.value),
        rate: `${item.rate.id}`,
        startDate: item.startDate,
        endDate: item.endDate,
        holidayCalendarId: item.holidayCalendarId,
        costOfOverhead: item.costOfOverhead,
        notes: item.comment || '',
      };
    },
    [rowRecord],
  );

  const getVacanciesInitialValues = useCallback(
    (consultant: Consultant, item: AssignmentVacancy): IVacancyFormProps => {
      return {
        client: `${item.clientId}`,
        model: item.model,
        role: item.role,
        fee: getFeeInitialValue(item.rate.value),
        rate: `${item.rate.id}`,
        startDate: item.startDate,
        endDate: item.endDate,
        notes: item.comment || '',
        addText: item.text || '',
        allocation: item.allocation,
        hours: item.hours,
        holidayCalendarId: item.holidayCalendarId,
        costOfOverhead: item.costOfOverhead || 0,
      };
    },
    [rowRecord],
  );

  const getExpensesInitialValues = useCallback(
    (item: AssignmentExpenses): IExpenseFormProps => {
      return {
        client: `${item.clientId}`,
        startDate: item.startDate,
        endDate: item.endDate,
        notes: item.comment || '',
        title: item.title || '',
        cost: item.cost,
      };
    },
    [rowRecord],
  );

  const onDelete = useCallback(async () => {
    try {
      setLoading(true);

      group === BudgetingGroupsEnum.Assignment || group === BudgetingGroupsEnum.Vacancy
        ? await deleteAssignment(assignmentId)
        : await deleteExpenses(assignmentId);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  }, [group]);

  return {
    isOpened,
    toggleForm,
    onDelete,
    isLoading,
    getExpensesInitialValues,
    getVacanciesInitialValues,
    getAssignmentInitialValues,
  };
};

export function getFeeInitialValue(fee: string): number {
  return fee ? Number(fee.slice(1).replace(',', '')) : 0;
}
