import { BudgetType, CreateForecastMutationVariables } from 'valtech-core/common/gql/generated';

export interface IFormattedData {
  id: number;
  label: string;
}
export interface IFormattedForecastData {
  forecastToCreate: {
    id: number;
    label: BudgetType;
    budgetId: number;
    projectId: number;
    budgetingSettingId: number;
  };
}

export const mapToSubmitNewBudget = (
  data: IFormattedForecastData,
): CreateForecastMutationVariables => {
  return {
    projectId: data.forecastToCreate.projectId,
    budgetingSettingId: data.forecastToCreate.budgetingSettingId,
    budgetType: data.forecastToCreate.label,
    parentBudgetReportId: data.forecastToCreate.budgetId,
  };
};
