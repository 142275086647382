import React, { FC } from 'react';

import { useAuthState } from 'common/apolloState/auth';
import { ERoles } from 'src/common/types';

import { ReactComponent as LogoIcon } from 'valtech-core/assets/logo.svg';
import { TabsTypeEnum } from 'valtech-core/common/types';
import Avatar from 'valtech-core/ui/Avatar';
import Tabs from 'valtech-core/ui/Tabs';

import { useCurrentTab } from './Header.hooks';

import { getFirstLastNames } from './Header.utils';

import { HEADER_NAV_LINKS, HEADER_NAV_LINKS_ADMIN } from './Header.const';
import s from './Header.module.scss';

export type navLink = {
  title: string;
  value: string;
};

const Header: FC = () => {
  const { userInfo } = useAuthState();
  const navLinks =
    userInfo && userInfo.role === ERoles.Administrator ? HEADER_NAV_LINKS_ADMIN : HEADER_NAV_LINKS;
  const currentTab = useCurrentTab(navLinks);
  const { firstName, lastName } = getFirstLastNames(userInfo?.name);

  return (
    <header className={s.header}>
      <LogoIcon className={s.logo} />
      <nav>
        <Tabs tabs={navLinks} colorSchema='white' value={currentTab} type={TabsTypeEnum.Link} />
      </nav>
      <Avatar
        withName
        isReversed
        nameOnly
        avatarData={{ url: '', firstName, lastName }}
        typographyProps={{
          sx: {
            font: 'inherit',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '143%',
            letterSpacing: '0,17px',
          },
        }}
      />
    </header>
  );
};

export default Header;
