import { makeVar } from '@apollo/client';

import { AlertsSeverityEnum } from 'valtech-core/common/types';

export type Notification = {
  id: string;
  message: string;
  hideDuration?: number;
  severity?: AlertsSeverityEnum;
};

export type NotificationsState = {
  messages: Notification[];
};

export const notificationsInitialState: NotificationsState = {
  messages: [],
};

export const notificationsVar = makeVar<NotificationsState>(notificationsInitialState);
