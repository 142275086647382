import { useEffect, useState } from 'react';

import { DEFAULT_REQUEST_LIMIT } from 'valtech-core/common/constants';

interface IUsePagePaginationParams {
  pageState?: number;
  totalState?: number;
  currentTotal?: number;
  rowsPerPageState?: number;
}

interface IUsePagePaginationReturn {
  page: number;
  total: number;
  rowsPerPage: number;
  setPage: (item: number) => void;
  setTotal: (item: number) => void;
  setRowsPerPage: (item: number) => void;
}

export type pagePaginationParams = IUsePagePaginationReturn;

const usePagePagination = ({
  pageState = 0,
  totalState = 0,
  currentTotal,
  rowsPerPageState = DEFAULT_REQUEST_LIMIT,
}: IUsePagePaginationParams = {}): IUsePagePaginationReturn => {
  const [page, setPage] = useState<number>(pageState);
  const [total, setTotal] = useState<number>(totalState);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageState);

  useEffect(() => {
    const isTotalChanged = total !== currentTotal;
    const isNumberValue = Number.isInteger(currentTotal);

    if (isTotalChanged && isNumberValue) {
      setTotal(currentTotal || total);
    }
  }, [currentTotal]);

  return {
    page,
    total,
    rowsPerPage,
    setPage,
    setTotal,
    setRowsPerPage,
  };
};

export default usePagePagination;
