import {
  CLIENT_FTL,
  COST_FTL,
  END_DATE_FTL,
  FEE_FTL,
  HOLIDAY_CALENDAR_FTL,
  MODEL_FTL,
  NAME_FTL,
  NOTES_FTL,
  ROLE_FTL,
  START_DATE_FTL,
  TIER_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';

import { ALLOCATION_OR_HOURS_FTL } from './CopyBudgetingRowsForm.ftl';

export const copyAssignmentsTableTitlesCol = [
  { id: 100, title_l10n: CLIENT_FTL },
  { id: 101, title_l10n: NAME_FTL },
  { id: 102, title_l10n: MODEL_FTL },
  { id: 103, title_l10n: HOLIDAY_CALENDAR_FTL },
  { id: 104, title_l10n: START_DATE_FTL },
  { id: 105, title_l10n: END_DATE_FTL },
  { id: 106, title_l10n: ALLOCATION_OR_HOURS_FTL },
  { id: 108, title_l10n: TIER_FTL },
  { id: 109, title_l10n: FEE_FTL },
  // { id: 10, title_l10n: VACATION_COEFFICIENT_FTL },
  // { id: 11, title_l10n: VACATION_BALANCE_FTL },
  { id: 112, title_l10n: NOTES_FTL },
  { id: 113, title_l10n: '' },
];

export const copyVacanciesTableTitlesCol = [
  { id: 200, title_l10n: CLIENT_FTL },
  { id: 201, title_l10n: ROLE_FTL },
  { id: 202, title_l10n: MODEL_FTL },
  { id: 203, title_l10n: HOLIDAY_CALENDAR_FTL },
  { id: 204, title_l10n: START_DATE_FTL },
  { id: 205, title_l10n: END_DATE_FTL },
  { id: 206, title_l10n: ALLOCATION_OR_HOURS_FTL },
  { id: 207, title_l10n: TIER_FTL },
  { id: 208, title_l10n: FEE_FTL },
  { id: 211, title_l10n: NOTES_FTL },
  { id: 212, title_l10n: '' },
];

export const copyExpensesTableTitlesCol = [
  { id: 300, title_l10n: TITLE_FTL },
  { id: 301, title_l10n: CLIENT_FTL },
  { id: 302, title_l10n: COST_FTL },
  { id: 303, title_l10n: START_DATE_FTL },
  { id: 304, title_l10n: END_DATE_FTL },
  { id: 305, title_l10n: NOTES_FTL },
  { id: 306, title_l10n: '' },
];
