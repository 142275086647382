import { GENERIC_ERROR } from 'valtech-core/common/ftl';

export type ErrorResponse = {
  response: {
    data: {
      response: {
        response: {
          error: string;
        };
      };
    };
  };
};

export const errorCatch = (error: ErrorResponse): string | undefined => {
  const message = error?.response?.data?.response?.response?.error || GENERIC_ERROR;
  return message;
};
