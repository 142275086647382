import { UserManagerSettings } from 'oidc-client-ts';

// Configuration of the UserManager client
const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '4007';
const apiUrlBase = isLocalhost
  ? process.env.REACT_APP_OIDC_CLIENT_REDIRECT_URI_BASE
  : window.location.origin;

const userManagerSettings: UserManagerSettings = {
  authority: 'https://login.microsoftonline.com/139f0669-cebb-42df-b9e3-415009ddb690/v2.0',
  client_id: isLocalhost
    ? (process.env.REACT_APP_SSO_AUTH_CLIENT_ID as string)
    : `${
        (window as Window & typeof globalThis & { env: { [key: string]: string } }).env
          .REACT_APP_SSO_AUTH_CLIENT_ID
      }`,
  redirect_uri: `${apiUrlBase}/oauth/callback`,
  response_type: 'code',
  response_mode: 'query',
  scope: 'openid offline_access https://graph.microsoft.com/mail.read',
  loadUserInfo: false,
};

const buildLoginUrl = (settings: UserManagerSettings): string => {
  const url =
    `${settings.authority}/authorize?` +
    `client_id=${encodeURIComponent(settings.client_id)}` +
    `&redirect_uri=${encodeURIComponent(settings.redirect_uri)}` +
    `&response_type=${encodeURIComponent('code')}` +
    `&response_mode=${encodeURIComponent('query')}` +
    `&scope=${encodeURIComponent('openid offline_access https://graph.microsoft.com/mail.read')}`;

  return url;
};

const removeCodeChallengeFromUrl = (url: string): string => {
  const urlObj = new URL(url);

  urlObj.searchParams.delete('code_challenge');
  urlObj.searchParams.delete('code_challenge_method');
  urlObj.pathname = urlObj.pathname.replace('/v2.0/', '/oauth2/v2.0/');
  return urlObj.toString();
};

export const handleSSOLogin = (): void => {
  const url = buildLoginUrl(userManagerSettings);

  // Remove unwanted URL parameters
  const urlWithoutCodeChallenge = removeCodeChallengeFromUrl(url);

  // Redirecting the user to a modified URL
  window.location.href = urlWithoutCodeChallenge;
};
