import { useCallback } from 'react';

import { showSystemError } from 'common/apolloState/system';

import {
  EditBusinessUnitMutationVariables,
  useAddBusinessUnitMutation,
  useEditBusinessUnitMutation,
} from 'valtech-core/common/gql/generated';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';

import { mapToSubmitAddEntity, mapToSubmitEditEntity } from './EntityForm.utils';

import { IEntityFormProps } from './EntityForm';

type UseHandleEntityReturn = {
  createEntity: (data: IEntityFormProps) => Promise<boolean>;
  updateEntity: (
    data: IEntityFormProps,
    mutationData: EditBusinessUnitMutationVariables,
  ) => Promise<boolean>;
  loading: boolean;
};

export const useHandleEntity = (): UseHandleEntityReturn => {
  const [addEntity, { loading: adding }] = useAddBusinessUnitMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [editEntityMutation, { loading: updating }] = useEditBusinessUnitMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const createEntity = useCallback(async (data: IEntityFormProps) => {
    const newEntity = await addEntity({
      variables: mapToSubmitAddEntity({ data }),
      refetchQueries: ['getArrayOfEtities'],
    });

    return !!newEntity.data?.addBusinessUnit.id;
  }, []);

  const updateEntity = useCallback(async (data: IEntityFormProps) => {
    const updateEntity = await editEntityMutation({
      variables: mapToSubmitEditEntity({ data }),
      refetchQueries: ['getArrayOfEtities'],
    });

    return !!updateEntity.data;
  }, []);

  return {
    createEntity,
    updateEntity,
    loading: adding || updating,
  };
};
