import React, { useContext } from 'react';

import noop from 'lodash/noop';

interface IEditingContext {
  isEditing: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditingContext = React.createContext<IEditingContext>({ isEditing: false, setState: noop });

export const useEditingContext = (): IEditingContext => useContext(EditingContext);

export default EditingContext;
