import { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showGenericError } from 'common/apolloState/system';
import { SingleBillingTableTypeEnum } from 'common/types';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { Overtimes } from 'pages/SingleBilling/SingleBilling.types';
import { IOvertime } from 'pages/SingleBilling/SingleBilling.types';
import { useBillingState } from 'pages/SingleBilling/SingleBillingContextProvider';
import { DEFAULT_OVERTIME } from 'pages/SingleBilling/SingleBillingTabs/GeneralTab/GeneralTabMockData';
import { COST_OF_OVERHEAD_DEFAULT } from 'src/common/constants';

import moment from 'moment';

import {
  BillingStatus,
  InputMaybe,
  useGetTmRateForConsultantLazyQuery,
  useUpdateBillingReportRowMutation,
  useUpdateBillingReportTotalMutation,
} from 'valtech-core/common/gql/generated';
import { AnyObject, CurrencyEnum, Maybe } from 'valtech-core/common/types';

type AssignmentReportCorrectionParams = {
  assignmentId: Maybe<number>;
  correctionNote: InputMaybe<string>;
  correctionQty: InputMaybe<number>;
  correctionTotal: InputMaybe<number>;
};

type UpdateAssignmentReportCorrection = (
  params: AssignmentReportCorrectionParams,
) => Promise<boolean | undefined>;

type UpdateAssignmentExpensesProps = {
  assignmentId: InputMaybe<number>;
  expenseNote: InputMaybe<string>;
  expenseTotal: InputMaybe<number>;
  projectTotal: InputMaybe<number>;
};

type UpdateAssignmentExpenses = (
  props: UpdateAssignmentExpensesProps,
) => Promise<boolean | undefined>;

type UpdateClientsExpensesProps = {
  id: number;
  correctionExpense: InputMaybe<number>;
  correctionQty: InputMaybe<number>;
  correctionTitle: InputMaybe<string>;
};

type UpdateClientsExpenses = (props: UpdateClientsExpensesProps) => Promise<boolean | undefined>;

interface IUseHandleUpdateAssignmentReturn {
  loading: boolean;
  updateAssignmentReportCorrection: UpdateAssignmentReportCorrection;
  updateAssignmentExpenses: UpdateAssignmentExpenses;
  updateClientsExpenses: UpdateClientsExpenses;
}

export const useHandleUpdateAssignment = (): IUseHandleUpdateAssignmentReturn => {
  const { projectId } = useBudgetingState();
  const [updateBillingReportRowMutation, { loading }] = useUpdateBillingReportRowMutation({
    onError() {
      showGenericError();
    },
  });
  const [updateBillingReportTotalMutation, { loading: updateBillingReportLoading }] =
    useUpdateBillingReportTotalMutation({
      onError() {
        showGenericError();
      },
    });

  const { id: billingId } = useParams();

  const updateAssignmentReportCorrection: UpdateAssignmentReportCorrection = useCallback(
    async ({ assignmentId, correctionNote, correctionQty, correctionTotal }) => {
      try {
        if (assignmentId && projectId) {
          const response = await updateBillingReportRowMutation({
            variables: {
              billingReportRowId: Number(assignmentId),
              correctionNote,
              correctionQty,
              projectId,
              correctionTotal,
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          const currentDate = moment().format();
          await updateBillingReportTotalMutation({
            variables: {
              billingReportId: Number(billingId),
              modifiedDate: currentDate,
              total: Number(correctionTotal),
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          return !!response.data?.updateBillingReportRow;
        }
      } catch (_) {}
    },
    [],
  );

  const updateAssignmentExpenses: UpdateAssignmentExpenses = useCallback(
    async ({ assignmentId, expenseNote, expenseTotal, projectTotal }) => {
      if (typeof expenseTotal === 'number') expenseTotal = round(expenseTotal, 6);
      if (typeof projectTotal === 'number') projectTotal = round(projectTotal, 6);

      try {
        if (assignmentId && projectId) {
          const response = await updateBillingReportRowMutation({
            variables: {
              billingReportRowId: Number(assignmentId),
              expenseNote,
              expenseTotal,
              projectId,
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          const currentDate = moment().format();
          await updateBillingReportTotalMutation({
            variables: {
              billingReportId: Number(billingId),
              modifiedDate: currentDate,
              total: Number(expenseTotal) || Number(projectTotal) || null,
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          return !!response.data?.updateBillingReportRow;
        }
      } catch (_) {}
    },
    [],
  );

  const updateClientsExpenses: UpdateClientsExpenses = useCallback(
    async ({ id, correctionExpense, correctionQty, correctionTitle }) => {
      if (typeof correctionExpense === 'number') correctionExpense = round(correctionExpense, 6);

      try {
        if (id && projectId) {
          const response = await updateBillingReportRowMutation({
            variables: {
              billingReportRowId: id,
              correctionExpense,
              correctionQty,
              correctionTitle,
              projectId,
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          const currentDate = moment().format();
          await updateBillingReportTotalMutation({
            variables: {
              billingReportId: Number(billingId),
              modifiedDate: currentDate,
              total: Number(correctionExpense),
            },
            refetchQueries: ['SingleBillingInfo'],
          });

          return !!response.data?.updateBillingReportRow;
        }
      } catch (_) {}
    },
    [],
  );

  return {
    updateAssignmentReportCorrection,
    updateAssignmentExpenses,
    updateClientsExpenses,
    loading: loading || updateBillingReportLoading,
  };
};

type ClientsExpensesFormValues = {
  amount: string;
  comment?: Maybe<string>;
};

type ReportCorrectionFormValues = {
  hours: string;
  comment?: Maybe<string>;
  isDisabled: boolean;
};

type ConsultantsExpensesFormValues = {
  amount: string;
  qty?: Maybe<string>;
  title?: Maybe<string>;
};

type UpdateStateConsultantsExpenses = (expensesAmount?: string, expensesComment?: string) => void;
type UpdateStateClientsExpenses = (props: {
  expensesClientsAmount?: string;
  expensesTitle?: string;
  expensesQty?: string;
}) => void;

type UpdateStateReportCorrection = (
  reportCorrectionQty?: InputMaybe<string>,
  reportCorrectionNote?: InputMaybe<string>,
) => void;

type UpdateStateOvertimes = (overtimes: IOvertime[]) => void;
type DeleteStateOvertimes = (id: number) => void;

export type UseGeneralTabReturn = {
  clientsExpensesFormInitialValues: ClientsExpensesFormValues;
  consultantsExpensesFormInitialValues: ConsultantsExpensesFormValues;
  reportCorrectionInitialValues: ReportCorrectionFormValues;
  overtimeFormValues: Overtimes;
  tableName: Maybe<SingleBillingTableTypeEnum>;
  billingStatus: Maybe<BillingStatus>;
  updateStateConsultantsExpenses: UpdateStateConsultantsExpenses;
  updateStateClientsExpenses: UpdateStateClientsExpenses;
  updateStateReportCorrection: UpdateStateReportCorrection;
  updateStateOvertimes: UpdateStateOvertimes;
  deleteStateOvertimes: DeleteStateOvertimes;
  projectData?: AnyObject;
  currencySign: Maybe<string>;
  workingHoursPerMonth: Maybe<number>;
  billingInfo?: AnyObject;
};
export const useGeneralTab = (): UseGeneralTabReturn => {
  const { state, setState } = useBillingState();
  const tabsValues = state.projectData?.tabsValues;

  const consultantsExpensesFormInitialValues = {
    amount:
      !tabsValues?.consultantsExpensesTabValues?.correctionExpense ||
      tabsValues?.consultantsExpensesTabValues?.correctionExpense === 0
        ? ''
        : tabsValues?.consultantsExpensesTabValues?.correctionExpense?.toString(),
    comment: tabsValues?.consultantsExpensesTabValues?.expenseNote?.toString() || '',
  };

  const clientsExpensesFormInitialValues = {
    amount:
      !tabsValues?.clientsExpensesTabValues?.correctionClientsExpense ||
      tabsValues?.clientsExpensesTabValues?.correctionClientsExpense === 0
        ? ''
        : tabsValues?.clientsExpensesTabValues?.correctionClientsExpense?.toString(),
    qty:
      !tabsValues?.clientsExpensesTabValues?.correctionQty ||
      tabsValues?.clientsExpensesTabValues?.correctionQty === 0
        ? ''
        : tabsValues?.clientsExpensesTabValues?.correctionQty?.toString(),
    title: tabsValues?.clientsExpensesTabValues?.correctionTitle?.toString() || '',
  };

  const reportCorrectionInitialValues = {
    hours: isNil(tabsValues?.reportCorrectionTabValues?.reportCorrectionQty)
      ? undefined
      : tabsValues?.reportCorrectionTabValues?.reportCorrectionQty?.toString(),
    comment: tabsValues?.reportCorrectionTabValues?.reportCorrectionNote?.toString() || '',
    isDisabled: tabsValues?.reportCorrectionTabValues?.isDisabled || false,
  };

  const overtimeFormValues = useMemo(() => {
    return {
      overtimes: tabsValues?.overtimesInitialTabValues && [
        ...tabsValues?.overtimesInitialTabValues,
        DEFAULT_OVERTIME,
      ],
    };
  }, [state]);

  const updateStateReportCorrection: UpdateStateReportCorrection = useCallback(
    (reportCorrectionQty, reportCorrectionNote): void => {
      setState(prev => ({
        ...prev,
        projectData: {
          ...prev.projectData,
          tabsValues: {
            ...prev.projectData?.tabsValues,
            reportCorrectionTabValues: {
              ...prev.projectData?.tabsValues.reportCorrectionTabValues,
              reportCorrectionQty,
              reportCorrectionNote,
            },
          },
        },
      }));
    },
    [state],
  );

  const updateStateConsultantsExpenses: UpdateStateConsultantsExpenses = useCallback(
    (expensesAmount, expensesComment): void => {
      setState(prev => ({
        ...prev,
        projectData: {
          ...prev.projectData,
          tabsValues: {
            ...prev.projectData?.tabsValues,
            consultantsExpensesTabValues: {
              ...prev.projectData?.tabsValues.consultantsExpensesTabValues,
              correctionExpense: expensesAmount,
              expenseNote: expensesComment,
            },
          },
        },
      }));
    },
    [state],
  );

  const updateStateClientsExpenses: UpdateStateClientsExpenses = useCallback(
    (props): void => {
      const { expensesClientsAmount, expensesTitle, expensesQty } = props;

      setState(prev => ({
        ...prev,
        projectData: {
          ...prev.projectData,
          tabsValues: {
            ...prev.projectData?.tabsValues,
            clientsExpensesTabValues: {
              ...prev.projectData?.tabsValues.clientsExpensesTabValues,
              correctionClientsExpense: expensesClientsAmount,
              correctionQty: expensesQty,
              correctionTitle: expensesTitle,
            },
          },
        },
      }));
    },
    [state],
  );

  const updateStateOvertimes: UpdateStateOvertimes = useCallback(
    (overtime): void => {
      setState(prev => ({
        ...prev,
        projectData: {
          ...prev.projectData,
          tabsValues: {
            ...prev.projectData?.tabsValues,
            overtimesInitialTabValues: [
              ...prev.projectData?.tabsValues.overtimesInitialTabValues,
              overtime,
            ],
          },
        },
      }));
    },
    [state],
  );

  const deleteStateOvertimes: DeleteStateOvertimes = useCallback(
    (id: number): void => {
      const IdList: number[] = [];
      if (id) IdList.push(id);

      setState(prev => ({
        ...prev,
        projectData: {
          ...prev.projectData,
          tabsValues: {
            ...prev.projectData?.tabsValues,
            overtimesInitialTabValues: [
              ...prev.projectData?.tabsValues.overtimesInitialTabValues.filter(
                el => !IdList.includes(el.overtimeId),
              ),
            ],
          },
        },
      }));
    },
    [state],
  );

  return {
    clientsExpensesFormInitialValues,
    consultantsExpensesFormInitialValues,
    reportCorrectionInitialValues,
    overtimeFormValues,
    tableName: state.tableName,
    billingStatus: state.billingInfo?.status,
    updateStateConsultantsExpenses,
    updateStateClientsExpenses,
    updateStateReportCorrection,
    updateStateOvertimes,
    deleteStateOvertimes,
    projectData: state.projectData,
    currencySign: state.billingInfo?.currencySign,
    workingHoursPerMonth: state.billingInfo?.workingHoursPerMonth,
    billingInfo: state.billingInfo,
  };
};

export const useTMRateForConsultant = (): number | null => {
  const {
    state: { projectData, consultantId },
  } = useBillingState();
  const [getTMRateForConsultant, { data }] = useGetTmRateForConsultantLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (projectData?.id) {
      getTMRateForConsultant({
        variables: {
          projectCcy: projectData?.projectCurrency || CurrencyEnum.EUR,
          budgetingSettingId: projectData.budgetingSettingId,
          consultantId: consultantId || 0,
          costOfOverhead: projectData?.costOfOverhead || COST_OF_OVERHEAD_DEFAULT,
        },
      });
    }
  }, [projectData?.id, consultantId]);

  const tmRateFee = useMemo(() => {
    if (!data?.getTMRateForConsultant) {
      return null;
    }

    const { fee } = data.getTMRateForConsultant;

    return fee;
  }, [data]);

  return tmRateFee;
};
