import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import TableRowReusable from 'components/TableRowReusable';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { USER_ACCESS_LEVEL_FTL } from 'valtech-core/common/ftl';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import AddNewUserCredentials from './AddNewUserCredentialsItem';
import UserCredentialsItem from './UserCredentialsItem';
import { headData } from './UserCredentialsTable.const';
import {
  useDeleteUserCredentials,
  useUpdateUserCredentials,
  useUserCredentials,
} from './UserCredentialsTable.hook';

interface IAssociatedClientsTableProps {
  projectName: string | undefined;
  deliveryManagerId: number | undefined;
}

const UserCredentialsTable: FC<IAssociatedClientsTableProps> = props => {
  const { projectName, deliveryManagerId } = props;

  const { id: clientId } = useParams();
  const [editingItemId, setEditItemId] = useState<string | number>('');
  const { l10n } = useLocalization();
  const methods = useFormContext();
  const { control, setValue, getValues } = methods;
  const { isEditing, setState } = useEditingContext();
  const { userCredentials } = useUserCredentials(projectName);
  const updateUserCredentials = useUpdateUserCredentials();
  const dleteUserCredentials = useDeleteUserCredentials();

  const {
    loading,
    sorting: { sortingType, activeColumn, handleSorting },
  } = userCredentials;

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'userCredentials.credentials',
  });

  useEffect(() => {
    setValue('credentials', userCredentials);
    replace(userCredentials.credentials);
  }, [userCredentials.loading]);

  const onSave = index => value => {
    if (clientId) {
      updateUserCredentials(value).then(data => {
        if (data) {
          setValue(`userCredentials.credentials[${index}]`, value);
        }
      });
    } else setValue(`userCredentials.credentials[${index}]`, value);
  };

  const onSelect = id => () => {
    if (isEditing) return;
    setEditItemId(id);
    setState(true);
  };

  const onCancel = () => {
    setEditItemId('');
    setState(false);
  };

  const onDelete = index => value => {
    if (clientId) {
      dleteUserCredentials(value).then(() => {
        setValue(`userCredentials.credentials[${index}]`, value);
        setState(false);
      });
    } else {
      setValue(`userCredentials.credentials[${index}]`, value);
      setState(false);
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 5, fontSize: 20 }}>{l10n.getString(USER_ACCESS_LEVEL_FTL)}</Box>
      <TableContainer component={Paper} sx={{ mt: 5, width: '65%' }}>
        {loading && (
          <ProgressLinear
            boxProps={{
              sx: {
                width: '100%',
                position: 'relative',
                bottom: 0,
                left: 0,
              },
            }}
          />
        )}
        <Table aria-label='simple table'>
          <TableHead>
            <TableRowReusable
              disableSorting={Boolean(editingItemId) || !clientId}
              headData={headData}
              sortingType={sortingType}
              activeColumn={activeColumn}
              handleSorting={handleSorting}
            />
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <UserCredentialsItem
                  key={field.id}
                  rowId={index}
                  onRemove={remove}
                  isActive={field.id === editingItemId && editingItemId !== ''}
                  onSave={onSave(index)}
                  onSelect={onSelect(field.id)}
                  onCancel={onCancel}
                  onDelete={onDelete(index)}
                  values={getValues(`userCredentials.credentials[${index}]`)}
                  isLoading={loading}
                  usersList={userCredentials.usersList}
                  deliveryManagerId={deliveryManagerId}
                />
              );
            })}
            <AddNewUserCredentials append={append} usersList={userCredentials.usersList} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default UserCredentialsTable;
