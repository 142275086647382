import React, { ReactNode, forwardRef, memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MUIRadio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

export type RadioItem = { id: string; label: string; value: string };

interface IRadioProps {
  boxProps?: BoxProps;
  radioGroupProps?: RadioGroupProps;
  data: RadioItem[];
  disabledList?: string[];
  children?: ReactNode;
  label?: string;
  error?: boolean;
}

const Radio = forwardRef<HTMLDivElement, IRadioProps>(
  ({ boxProps, radioGroupProps, data, disabledList, label, error, children, ...rest }, ref) => {
    return (
      <Box {...boxProps}>
        <FormControl>
          {label && <FormLabel error={error}>{label}</FormLabel>}
          <RadioGroup {...radioGroupProps} {...rest} ref={ref}>
            {data.map(({ id, label, value }) => {
              return (
                <FormControlLabel
                  key={id}
                  value={value}
                  control={<MUIRadio />}
                  label={label}
                  disabled={disabledList?.includes(id)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        {children}
      </Box>
    );
  },
);

Radio.displayName = 'Radio'; //name we would see in components toolkit

export default memo(Radio);
