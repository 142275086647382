import { StatusTitleEnum } from 'src/common/constants';

import { ActivationStatus } from 'valtech-core/common/gql/generated';
import { CurrencyEnum } from 'valtech-core/common/types';

export const selectMockCurrency = [
  { id: '1', title: CurrencyEnum.EUR, value: CurrencyEnum.EUR },
  { id: '2', title: CurrencyEnum.USD, value: CurrencyEnum.USD },
];

export const selectMockStatus = [
  { id: '1', title: StatusTitleEnum.Active, value: ActivationStatus.Active },
  { id: '2', title: StatusTitleEnum.NotActive, value: ActivationStatus.NotActive },
];
