import moment from 'moment';

import { DateMask, Maybe } from 'valtech-core/common/types';

export const getFormattedDate = (
  value: Maybe<string> | null,
  format = DateMask.YYYY_MM_DD,
): string => {
  return moment(value).format(format);
};
