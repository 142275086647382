import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { showGenericError } from 'common/apolloState/system';
import { IWarningData } from 'common/types';

import { useGetSingleBillingWarningsLazyQuery } from 'valtech-core/common/gql/generated';

type UseSingleBillingWarningsReturn = {
  singleBillingWarnings: IWarningData[];
  billingId?: string;
  loading: boolean;
};
export const useSingleBillingWarnings = (): UseSingleBillingWarningsReturn => {
  const { id: billingId } = useParams();

  const [getSingleBillingWarnings, { data, loading }] = useGetSingleBillingWarningsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onError() {
      showGenericError();
    },
  });

  useEffect(() => {
    if (billingId) {
      getSingleBillingWarnings({
        variables: {
          billingReportId: Number(billingId),
        },
      });
    }
  }, [billingId]);

  const singleBillingWarnings = useMemo(() => {
    if (!data) return [];

    return data.sendAnArrayOfBillingReportErrorsByBillingReportID.map(item => {
      const warningMessage = item.type || '';
      return {
        id: item.id,
        consultant: item.consultant_id,
        hours: item.hours,
        client: item.client,
        clientId: item.clientInfo?.id,
        title: warningMessage,
      };
    });
  }, [data]);

  return {
    loading,
    billingId,
    singleBillingWarnings,
  };
};
