import React, { FC } from 'react';

import { hideNotification, useNotifications } from 'common/apolloState/notifications';

import Stack from '@mui/material/Stack';

import Alert from 'valtech-core/ui/Alert';
import Snackbar from 'valtech-core/ui/Snackbar';

const Notifications: FC = () => {
  const notifications = useNotifications();

  notifications.forEach(notification => {
    if (notification.hideDuration) {
      setTimeout(() => {
        hideNotification(notification.id);
      }, notification.hideDuration);
    }
  });

  return (
    <Snackbar
      open={!!notifications.length}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <div>
        <Stack gap={2}>
          {notifications.map(notification => (
            <Alert
              key={notification.id}
              alertMsg={notification.message}
              severity={notification.severity}
              onClose={() => hideNotification(notification.id)}
            />
          ))}
        </Stack>
      </div>
    </Snackbar>
  );
};

export default Notifications;
