import React, { FC, useMemo } from 'react';

import { useLocalization } from '@fluent/react';
import Modal from 'components/Modal';
import AssignmentForm from 'components/_forms/AssignmentForm';
import ExpenseForm from 'components/_forms/ExpenseForm';
import VacancyForm from 'components/_forms/VacancyForm';
import { AssignmentProject } from 'src/common/types';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';

import {
  CANCEL_FTL,
  CONFIRMATION_MESSAGE_FTL,
  DELETE_FTL,
  EDIT_FTL,
} from 'valtech-core/common/ftl';
import { BudgetingGroupsEnum } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import Dropdown from 'valtech-core/ui/Dropdown';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';
import Typography from 'valtech-core/ui/Typography';
import { useToggle } from 'valtech-core/utils/useToggle';

import { useHandleTableRowEdit } from './HandleTableRowEdit.hooks';

import { AssignmentExpenses, AssignmentVacancy } from '../Budgeting.types';

interface HandleTableRowEditProps {
  group: BudgetingGroupsEnum;
  assignmentId: number;
  rowRecord: AssignmentProject | AssignmentVacancy | AssignmentExpenses;
  rowTitle: { name: string; id: string };
  hasDelete: boolean;
}

const HandleTableRowEdit: FC<HandleTableRowEditProps> = ({
  group,
  assignmentId,
  rowRecord,
  rowTitle,
  hasDelete,
}) => {
  const [isModalOpened, toggleDelete] = useToggle(false);
  const { l10n } = useLocalization();

  const {
    isLoading,
    isOpened,
    toggleForm,
    onDelete,
    getAssignmentInitialValues,
    getExpensesInitialValues,
    getVacanciesInitialValues,
  } = useHandleTableRowEdit({ group, assignmentId, rowRecord });

  const displayForm = useMemo(() => {
    switch (group) {
      case BudgetingGroupsEnum.Assignment:
        return (
          <AssignmentForm
            hasDelete={hasDelete}
            onClose={toggleForm}
            initialValues={getAssignmentInitialValues(rowTitle, rowRecord as AssignmentProject)}
            assignmentId={assignmentId}
          />
        );
      case BudgetingGroupsEnum.Vacancy:
        return (
          <VacancyForm
            hasDelete={hasDelete}
            onClose={toggleForm}
            initialValues={getVacanciesInitialValues(rowTitle, rowRecord as AssignmentVacancy)}
            assignmentId={assignmentId}
          />
        );
      case BudgetingGroupsEnum.Expense:
        return (
          <ExpenseForm
            hasDelete={hasDelete}
            onClose={toggleForm}
            initialValues={getExpensesInitialValues(rowRecord as AssignmentExpenses)}
            assignmentId={assignmentId}
            isDisabledEditOnBilling={!hasDelete}
          />
        );

      default:
        return null;
    }
  }, [group, rowRecord]);

  const menuList = [
    {
      id: '1',
      title: EDIT_FTL,
      callback: toggleForm,
      icon: <EditIcon fontSize='small' />,
    },
  ];

  return (
    <>
      <Dropdown
        bntTitle=''
        menuList={
          hasDelete
            ? [
                ...menuList,
                {
                  id: '2',
                  title: DELETE_FTL,
                  callback: toggleDelete,
                  icon: <DeleteIcon fontSize='small' />,
                },
              ]
            : menuList
        }
        buttonIcon={<MoreVertIcon />}
        menuPositionVertical='bottom'
        menuPositionHorizontal='right'
      />
      <Modal handleCloseModal={toggleForm} openModal={isOpened} size='mid' closeBtn>
        {displayForm}
      </Modal>
      <Modal handleCloseModal={toggleDelete} openModal={isModalOpened}>
        <SpinnerOverlay visible={isLoading} />
        <Stack gap={2} alignItems='center'>
          <Typography component='p' variant='h5'>
            {l10n.getString(CONFIRMATION_MESSAGE_FTL)}
          </Typography>
          <Stack direction='row' spacing={2}>
            <Button onClick={onDelete} variant='contained' color='error'>
              {l10n.getString(DELETE_FTL)}
            </Button>
            <Button onClick={toggleDelete}>{l10n.getString(CANCEL_FTL)}</Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default HandleTableRowEdit;
