import React, { FC } from 'react';

import { Maybe } from 'valtech-core/common/types';

interface NAProps {
  value: Maybe<string | number> | null;
  displayZeroValue?: boolean;
}

const NA: FC<NAProps> = ({ value, displayZeroValue = false }) => {
  return <>{displayZeroValue && value === 0 ? value : !!value ? value : '-'}</>;
};

export default NA;
