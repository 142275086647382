import { DEFAULT_GROSS_MARGIN, MONTHS_IN_A_YEAR, WORKING_HOURS_PER_DAY } from 'common/constants';
import { ConsultantModelEnum, MonthAllocation, MonthSettings } from 'common/types';
import { sortByOrderWithKey } from 'common/utils/sortByOrderWithKey';
import { isNil } from 'lodash';
import isNull from 'lodash/isNull';
import round from 'lodash/round';

import { MONTH_LIST } from 'valtech-core/common/ftl';
import { BillingStatus } from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';

import {
  AnnualTotalPerEntity,
  ConsultantSectionItem,
  ExpensesSectionItem,
  GrossMarginDataPerMonth,
  MonthCost,
  MonthlyTotalPerEntity,
  VacancySectionItem,
} from './Budgeting.types';

export const COL_SPAN = MONTH_LIST.length + 2;

export type DisplayAllocationParams = {
  startDate: Maybe<string>;
  endDate: Maybe<string>;
  allocation: number;
  fee: number;
  model: ConsultantModelEnum;
  monthSettingsList: MonthSettings[];
};

type GetPartialAllocationNumberParams = {
  monthSettings?: MonthSettings;
  oneYearAllocationPeriod: boolean;
  isStartPeriod: boolean;
  isEndPeriod: boolean;
  startYear: number | null;
  startMonthIndex: number | null;
  startPeriod: Date | null;
  endYear: number | null;
  endPeriod: Date | null;
  endMonthIndex: number | null;
};

export function getPartialAllocationNumber({
  monthSettings,
  oneYearAllocationPeriod,
  isEndPeriod,
  isStartPeriod,
  startYear,
  startMonthIndex,
  startPeriod,
  endYear,
  endMonthIndex,
  endPeriod,
}: GetPartialAllocationNumberParams): number {
  const hasMissedPeriodDates =
    isNull(startYear) ||
    isNull(startMonthIndex) ||
    isNull(startPeriod) ||
    isNull(endYear) ||
    isNull(endMonthIndex) ||
    isNull(endPeriod);

  if (hasMissedPeriodDates || !monthSettings) {
    return 0;
  }

  let workingDaysOfPeriod = 0;

  const lastDayOfThePeriod = endPeriod.getUTCDate();
  const isOneMonthAllocation = isStartPeriod && isEndPeriod;
  const notFullMonthAllocationEndPeriod = lastDayOfThePeriod !== monthSettings?.daysInMonth;

  if (isOneMonthAllocation) {
    workingDaysOfPeriod = workingDaysBetweenDates({
      startPeriod: startPeriod?.toISOString() as string,
      endPeriod: endPeriod?.toISOString() as string,
      holidays: monthSettings?.arrayOfNationalHolidays || [],
    });
  } else if (isStartPeriod) {
    workingDaysOfPeriod = workingDaysBetweenDates({
      startPeriod: startPeriod?.toISOString() as string,
      endPeriod: new Date(
        `${startYear}/${zeroPadDate(startMonthIndex + 1)}/${monthSettings?.daysInMonth}`,
      ).toISOString(),
      holidays: monthSettings?.arrayOfNationalHolidays || [],
    });
  } else if (isEndPeriod && oneYearAllocationPeriod && notFullMonthAllocationEndPeriod) {
    workingDaysOfPeriod = workingDaysBetweenDates({
      startPeriod: new Date(`${endYear}/${zeroPadDate(endMonthIndex + 1)}/01`).toISOString(),
      endPeriod: endPeriod?.toISOString() as string,
      holidays: monthSettings?.arrayOfNationalHolidays || [],
    });
  }

  const workingHoursInPeriod = workingDaysOfPeriod * WORKING_HOURS_PER_DAY;

  return workingHoursInPeriod;
}

function zeroPadDate(date: number) {
  return String(date).padStart(2, '0');
}

export type GetRateValueParams = {
  model: ConsultantModelEnum;
  fee: number;
  workingHoursInPeriod: number;
};

export function getRateValue({ model, fee, workingHoursInPeriod }: GetRateValueParams): number {
  return model === ConsultantModelEnum.TimeAndMaterial ? fee * workingHoursInPeriod : fee;
}

export function calculateRate(allocation: number, rate: number): number {
  return allocation * rate;
}

type CalculateCostParams = {
  hasDataToDisplay: boolean;
  allocation: number;
  rate: number;
};

export function calculateCost({ hasDataToDisplay, allocation, rate }: CalculateCostParams): number {
  if (!hasDataToDisplay) {
    return 0;
  }

  return calculateRate(allocation, rate);
}

export const getTotalCost = (costsList: MonthCost[]): number => {
  return round(
    costsList.reduce((prev, current) => prev + current.cost, 0),
    6,
  );
};

//https://stackoverflow.com/questions/37069186/calculate-working-days-between-two-dates-in-javascript-excepts-holidays
type WorkingDaysBetweenDatesParams = { startPeriod: string; endPeriod: string; holidays: string[] };
export function workingDaysBetweenDates({
  startPeriod,
  endPeriod,
  holidays,
}: WorkingDaysBetweenDatesParams): number {
  const startDate = parseDate(startPeriod);
  const endDate = parseDate(endPeriod);

  // Validate input
  if (endDate <= startDate) {
    return 0;
  }

  // Calculate days between dates
  const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  // eslint-disable-next-line
  //@ts-ignore
  const diff = endDate - startDate; // Milliseconds between datetime objects
  let days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  const weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
    days--;
  }
  /* Here is the code */
  holidays.forEach(day => {
    if (day >= startPeriod && day <= endPeriod) {
      /* If it is not saturday (6) or sunday (0), substract it */
      if (parseDate(day).getDay() % 6 != 0) {
        days--;
      }
    }
  });
  return days;
}

function parseDate(input) {
  return new Date(input); // months are 0-based
}

type mapToAllocationDisplayParams = {
  forecastApiData: Maybe<PartialAssignmentForecast[]>;
  assignementId: Maybe<number>;
  fee: number;
  model: ConsultantModelEnum;
  monthSettingsList: MonthSettings[];
  startDate: Maybe<string>;
  endDate: Maybe<string>;
};

export const mapToAllocationDisplay = ({
  forecastApiData,
  assignementId,
  fee,
  model,
  monthSettingsList,
  startDate,
  endDate,
}: mapToAllocationDisplayParams): MonthAllocation[] => {
  const forecastedAllocations = forecastApiData?.filter(
    forecast => forecast.assignementInfo.id === assignementId,
  );

  if (!forecastedAllocations) {
    return [];
  }

  const startPeriod = startDate ? new Date(startDate) : null;
  const startMonthIndex = startPeriod && startPeriod.getMonth();
  const startYear = startPeriod && startPeriod.getUTCFullYear();

  const endPeriod = endDate ? new Date(endDate) : null;
  const endMonthIndex = endPeriod && endPeriod.getUTCMonth();
  const endYear = endPeriod && endPeriod.getUTCFullYear();

  const oneYearAllocationPeriod = startYear === endYear;

  return forecastedAllocations
    ?.map(forecast => {
      const zeroIndexMonth = forecast.month - 1;

      const isStartPeriod = startMonthIndex === zeroIndexMonth;
      const isEndPeriod = endMonthIndex === zeroIndexMonth;

      const partialAllocationWorkingHours = getPartialAllocationNumber({
        monthSettings: monthSettingsList[zeroIndexMonth],
        oneYearAllocationPeriod,
        isEndPeriod,
        isStartPeriod,
        startYear,
        startMonthIndex,
        startPeriod,
        endYear,
        endMonthIndex,
        endPeriod,
      });

      return {
        assignementId,
        allocationId: forecast.id,
        isPartial: !!forecast.isPartial,
        cost: round(Number(forecast.cost), 6),
        gross_margin: forecast.gross_margin,
        month: forecast.month,
        monthIndex: forecast.month,
        value: forecast.allocation_forecast ? round(forecast.allocation_forecast, 6) : null,
        rateValue: round(
          getRateValue({
            model,
            fee,
            workingHoursInPeriod: !forecast.isPartial
              ? monthSettingsList[zeroIndexMonth]?.workingDays * WORKING_HOURS_PER_DAY
              : partialAllocationWorkingHours,
          }),
          6,
        ),
      };
    })
    .sort(sortByOrderWithKey('monthIndex'));
};

type PartialAssignmentForecast = {
  allocation_forecast?: number | undefined;
  cost?: number | undefined;
  id: number;
  month: number;
  gross_margin?: number;
  isPartial?: boolean;
  assignementInfo: {
    id: number;
  };
};

type DisplayExpansesCostsPerMonthParams = {
  startDate: Maybe<string>;
  endDate: Maybe<string>;
  cost: number;
};

export const displayExpansesCostsPerMonth = ({
  startDate,
  endDate,
  cost,
}: DisplayExpansesCostsPerMonthParams): MonthAllocation[] => {
  const startPeriod = startDate ? new Date(startDate) : null;
  const startMonthIndex = startPeriod && startPeriod.getMonth();
  const startYear = startPeriod && startPeriod.getUTCFullYear();

  const endPeriod = endDate ? new Date(endDate) : null;
  const endMonthIndex = endPeriod && endPeriod.getMonth();
  const endYear = endPeriod && endPeriod.getUTCFullYear();
  const hasAllocationPeriod = !isNull(startMonthIndex) && !isNull(endMonthIndex);
  const hasAllocationInNextYear = !isNull(startYear) && !isNull(endYear) && startYear < endYear;

  return Array.from({ length: MONTHS_IN_A_YEAR }, (_, k) => {
    const hasAllocation = (): boolean => {
      if (hasAllocationInNextYear) {
        return k >= Number(startMonthIndex);
      } else {
        return hasAllocationPeriod && k >= startMonthIndex && k <= endMonthIndex;
      }
    };

    const displayAllocation = hasAllocation();
    const monthIndex = k + 1;

    return {
      monthIndex,
      value: displayAllocation ? cost : null,
      cost: displayAllocation ? cost : 0,
    };
  });
};

export type BudgetingSection = ConsultantSectionItem | VacancySectionItem | ExpensesSectionItem;

export const calculateTotalsForPeriod = (section: Maybe<BudgetingSection[]>): MonthCost[] => {
  const totalCostsPerMonth: MonthCost[] = [];

  section?.forEach(consultant => {
    consultant.general?.costs?.months.forEach(month => {
      totalCostsPerMonth[month.monthIndex] = {
        monthIndex: month.monthIndex,
        cost: (totalCostsPerMonth[month.monthIndex]?.cost || 0) + (month?.cost || 0),
      };
    });
  });

  return totalCostsPerMonth.filter(cost => cost);
};

export const getMonthStatus = (
  status: Maybe<BillingStatus>,
  css: { invoiced: string; draft: string; new: string },
): string => {
  switch (status) {
    case BillingStatus.Invoiced:
      return css.invoiced;

    case BillingStatus.Draft:
      return css.draft;

    default:
      return css.new;
  }
};

export function allocationValueToPercentage(allocation: number | undefined): number | null {
  if (!allocation) return null;

  return round(allocation * 100, 6);
}

interface IGrossMarginAccumulator {
  [key: string]: { sum: number; count: number };
}

export const countAverageGrossMarginPerMonth = (
  forecastInfo: PartialAssignmentForecast[],
): GrossMarginDataPerMonth[] => {
  const result = forecastInfo
    .map(item => {
      return { month: item.month, grossMargin: item.gross_margin };
    })
    .reduce((acc: IGrossMarginAccumulator, object) => {
      const key = object.month;
      const value = object.grossMargin;

      if (!acc[key]) {
        acc[key] = { sum: 0, count: 0 };
      }

      if (!isNil(value)) {
        acc[key].sum += value;
        acc[key].count += 1;
      }
      return acc;
    }, {} as IGrossMarginAccumulator);

  return Object.entries(result).map(([key, value]) => ({
    month: Number(key),
    averageGrossMarginPerMonth: value.count > 0 ? value.sum / value.count : null,
  })) as GrossMarginDataPerMonth[];
};

export const countAverageGrossMarginPerYear = (data: GrossMarginDataPerMonth[]): number | null => {
  const { sum, count } = data.reduce(
    (acc, obj) => {
      if (!isNil(obj.averageGrossMarginPerMonth)) {
        acc.sum += obj.averageGrossMarginPerMonth;
        acc.count++;
      }

      return acc;
    },
    { sum: 0, count: 0 },
  );

  return count > 0 ? round(sum / count, 2) : null;
};

export const generateGrossMarginArray = (): GrossMarginDataPerMonth[] => {
  return Array.from({ length: MONTHS_IN_A_YEAR }, (_, index) => ({
    month: index++,
    averageGrossMarginPerMonth: DEFAULT_GROSS_MARGIN,
  }));
};

export const calculateEntityTotals = (data: MonthlyTotalPerEntity): AnnualTotalPerEntity | null => {
  if (!data.length) return null;

  return data.reduce((totals, monthData) => {
    monthData?.data.forEach(({ total, entityName }) => {
      totals[entityName] = (totals[entityName] || 0) + total;
    });
    return totals;
  }, {} as AnnualTotalPerEntity);
};

export const getMonthlyTotalPerEntity = (
  monthlyTotalPerEntity: MonthlyTotalPerEntity,
  monthIndex: number,
  entity: string,
): number | undefined => {
  return monthlyTotalPerEntity
    ?.find(item => item?.month === monthIndex)
    ?.data?.find(obj => obj?.entityName === entity)?.total;
};
