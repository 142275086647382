import moment from 'moment';

import {
  AddVacancyMutationVariables,
  EditVacancyMutationVariables,
} from 'valtech-core/common/gql/generated';
import { DateMask } from 'valtech-core/common/types';

import { transformAllocation } from '../forms.utils';

import { IVacancyFormProps } from './VacancyForm';

type SubmitAddVacancyParams = {
  budgetReportId: number;
  budgetingSettingId: number;
  projectId: number;
  data: IVacancyFormProps;
};

export const mapToSubmitAddVacancy = ({
  budgetReportId,
  budgetingSettingId,
  projectId,
  data,
}: SubmitAddVacancyParams): AddVacancyMutationVariables => {
  const percentageOrHoursKey = data.hours ? 'hoursPerMonth' : 'percentage';
  const percentageOrHours = data.hours
    ? Number(data.hours)
    : transformAllocation(Number(data.allocation));

  return {
    budgetReportId,
    budgetingSettingId,
    projectId,
    role: data.role,
    text: data.addText,
    holidayCalendarId: Number(data.holidayCalendarId),
    clientId: Number(data.client),
    model: data.model,
    rateId: Number(data.rate),
    fee: Number(data.fee),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    [percentageOrHoursKey]: percentageOrHours,
    notes: data.notes,
    costOfOverhead: Number(data.costOfOverhead),
  };
};

export const mapToSubmitEditVacancy = (
  projectId: number,
  assignmentId: number,
  data: IVacancyFormProps,
): EditVacancyMutationVariables => {
  const percentageOrHoursKey = data.hours ? 'hoursPerMonth' : 'percentage';
  const percentageOrHours = data.hours
    ? Number(data.hours)
    : transformAllocation(Number(data.allocation));

  return {
    projectId,
    assignementId: Number(assignmentId),
    role: data.role,
    text: data.addText,
    holidayCalendarId: Number(data.holidayCalendarId),
    clientId: Number(data.client),
    model: data.model,
    rateId: Number(data.rate),
    fee: Number(data.fee),
    startDate: moment(data.startDate).format(DateMask.YYYY_MM_DD),
    endDate: moment(data.endDate).format(DateMask.YYYY_MM_DD),
    [percentageOrHoursKey]: percentageOrHours,
    notes: data.notes,
    costOfOverhead: Number(data.costOfOverhead),
  };
};
