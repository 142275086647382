import { useMemo } from 'react';

import { showGenericError } from 'src/common/apolloState/system';
import {
  TransformedEntitiesTotalData,
  transformEntitiesTotalData,
} from 'src/common/utils/transformEntitiesTotalData';

import { useGetTotalByEntitiesQuery } from 'valtech-core/common/gql/generated';

type UseGetTotalByEntitiesReturn = {
  entitiesTotal: TransformedEntitiesTotalData;
  loading?: boolean;
};

const useGetTotalByEntities = (billingReportIds: number[]): UseGetTotalByEntitiesReturn => {
  const { data, loading } = useGetTotalByEntitiesQuery({
    variables: {
      billingReportIds,
    },
    skip: !billingReportIds.length,
    fetchPolicy: 'cache-and-network',
    onError() {
      showGenericError();
    },
  });

  const entitiesTotal = useMemo((): TransformedEntitiesTotalData => {
    if (!data) {
      return {
        monthlyTotalPerEntity: [],
        entities: [],
      };
    }

    return {
      monthlyTotalPerEntity: transformEntitiesTotalData(data?.getTotalByEntities),
      entities: data.getTotalByEntities.map(item => item.entityName) || [],
    };
  }, [data]);

  return {
    entitiesTotal,
    loading,
  };
};

export default useGetTotalByEntities;
