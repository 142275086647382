import React, { FC } from 'react';

import CommentIcon from '@mui/icons-material/CommentOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Typography from 'valtech-core/ui/Typography';

import styles from './Comment.module.scss';

interface CommentProps {
  text?: string;
  type?: string;
}

const Comment: FC<CommentProps> = ({ text, type = 'info' }) => {
  const handleIconButtonClick = e => {
    e.stopPropagation();
  };
  const icon =
    type === 'alert' ? (
      <svg
        width='22'
        height='19'
        viewBox='0 0 22 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={styles.icon}>
        <path
          d='M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z'
          fill='#FB930E'
        />
      </svg>
    ) : (
      <CommentIcon color={'primary'} />
    );

  return (
    <>
      <Tooltip
        title={<Typography className={styles.text}>{text || ''}</Typography>}
        placement='top'
        sx={{
          visibility: text ? 'visible' : 'hidden',
          pointerEvents: text ? 'auto' : 'none',
        }}
        arrow>
        <IconButton
          aria-haspopup='true'
          className={styles.icon}
          onClick={e => handleIconButtonClick(e)}>
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Comment;
