import React, { FC } from 'react';

import { Localized } from '@fluent/react';
import { handleSSOLogin } from 'src/common/oidc/oidcService';

import { Stack } from '@mui/material';

import { ReactComponent as LogoIcon } from 'valtech-core/assets/logo.svg';
import { LOGIN_FTL } from 'valtech-core/common/ftl';
import Button from 'valtech-core/ui/Button';

import s from './Login.module.scss';

const Login: FC = () => {
  const handleSSOButtonClick = () => {
    handleSSOLogin();
  };
  return (
    <div className={s.pageWrapper}>
      <Stack>
        <div className={s.logo}>
          <LogoIcon />
        </div>
        <Button onClick={handleSSOButtonClick} className={s.btn} variant='outlined'>
          <Localized id={LOGIN_FTL} />
        </Button>
      </Stack>
    </div>
  );
};

export default Login;
