import React, { ElementType, FC } from 'react';

import { useNavigate } from 'react-router-dom';

import cn from 'classnames';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { IconFontSize } from 'valtech-core/common/types';

import styles from './Back.module.scss';

export interface IBackProps extends ButtonProps {
  label?: string;
  fallbackTo?: string;
  arrowSize?: IconFontSize;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
}

const Back: FC<IBackProps> = ({
  label,
  className,
  fallbackTo = '/',
  arrowSize = IconFontSize.Middle,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <IconButton
      {...rest}
      className={cn(styles.label, className)}
      onClick={() => (window.history.length <= 2 ? navigate(fallbackTo) : navigate(-1))}
    >
      <KeyboardArrowLeftOutlinedIcon sx={{ fontSize: arrowSize }} />
      {label ? label : ''}
    </IconButton>
  );
};

export default Back;
