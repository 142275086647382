import { ReactLocalization } from '@fluent/react';

import { ALL_FTL } from 'valtech-core/common/ftl';
import { IFilters } from 'valtech-core/common/types';

import { Projects } from '../hooks/useGetProjects/useGetProjects';
import { FilterModelEnum } from '../types';

export const getProjectsTitle = (
  projectFilter: IFilters,
  projects: Projects[],
  l10n: ReactLocalization,
): string => {
  if (!!projects.length && projectFilter.query) {
    return (
      projects.find(project => project?.title === projectFilter.query)?.title ||
      l10n.getString(ALL_FTL)
    );
  }

  return l10n.getString(ALL_FTL);
};

export const removeProjectFromFilters = (filters: IFilters[]): IFilters[] => {
  return filters.filter(filter => filter.filterModel !== FilterModelEnum.Project);
};
