import moment, { Moment } from 'moment';

export const getCalendarRangeByYear = (
  year: number | null,
): { firstDayOfYear: Moment; lastDayOfYear: Moment } => {
  return {
    firstDayOfYear: moment(new Date(`${year}/01/01`)),
    lastDayOfYear: moment(new Date(`${year}/12/31`)),
  };
};
