import React, { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useEditingContext } from 'common/contexts/editingContext/editing.context';
import TextField from 'form/TextField/TextField';
import { FinanceInformationFields } from 'pages/ManageSettings/SingleBudgeting/SingleBudget.constants';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { invalidNumberFormatValidator } from 'valtech-core/common/form/validators';
import { BUDGET_CONST_OF_OVERHEADS_FTL } from 'valtech-core/common/ftl';
import { CurrencyEnum, FieldControl } from 'valtech-core/common/types';

const OverheadsCost: FC = () => {
  const { l10n } = useLocalization();
  const { control } = useFormContext();
  const { isEditing } = useEditingContext();

  return (
    <Grid item xs={4}>
      <Box sx={{ fontSize: 20, mb: 3 }}>{l10n.getString(BUDGET_CONST_OF_OVERHEADS_FTL)}</Box>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <TextField
          disabled={isEditing}
          label={CurrencyEnum.EUR}
          name={`budgetData.data.${FinanceInformationFields.CostOfOverheadEur}`}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'number',
          }}
          validate={invalidNumberFormatValidator}
        />
        <TextField
          disabled={isEditing}
          label={CurrencyEnum.USD}
          name={`budgetData.data.${FinanceInformationFields.CostOfOverheadUsd}`}
          control={control as unknown as FieldControl}
          textProps={{
            type: 'number',
          }}
          validate={invalidNumberFormatValidator}
        />
      </Box>
    </Grid>
  );
};

export default OverheadsCost;
