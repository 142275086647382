import React, { FC, useCallback } from 'react';

import { getFormattedDate } from 'common/utils/formatDate';
import { useDeleteBillingReportComment } from 'pages/SingleBilling/SingleBillingTabs/SingleBillingTabs.hook';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';

import { DateMask } from 'valtech-core/common/types';
import Avatar from 'valtech-core/ui/Avatar';
import Typography from 'valtech-core/ui/Typography';

import type { Comment as CommentType } from '../SingleBillingTabs.hook';
import s from './Comment.module.scss';

interface iCommentProps {
  comment: CommentType;
}

const Comment: FC<iCommentProps> = ({ comment }) => {
  const { deleteBillingReportComment } = useDeleteBillingReportComment();
  const avatarData = {
    firstName: comment.firstName,
    lastName: comment.lastName,
    url: '',
  };

  const handleDeleteComment = useCallback((data: number) => {
    deleteBillingReportComment(data);
  }, []);

  return (
    <Box className={s.containerAvatarInfo}>
      <Avatar
        withName={false}
        isReversed={false}
        avatarData={avatarData}
        avatarBgColor={'var(--custom-primary)'}
      />
      <Box className={s.info}>
        <Typography
          className={s.contactName}
          variant='h5'
          align='inherit'
          weight='regular'
          component={'p'}>
          {`${comment.firstName} ${comment.lastName}`}
        </Typography>
        <Typography variant='subtitle2' align='inherit' weight='regular' component={'p'}>
          {`${comment.comment}`}
        </Typography>
        <Typography
          className={s.dateInfo}
          variant='caption'
          align='inherit'
          weight='regular'
          component={'p'}>
          {`${getFormattedDate(comment.date, DateMask.DD_MM_YYYY_HH)}`}
          <DeleteOutlineIcon
            onClick={() => handleDeleteComment(comment.id)}
            className={s.deleteBtn}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default Comment;
