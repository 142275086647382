import {
  AddBusinessUnitMutationVariables,
  EditBusinessUnitMutationVariables,
} from 'valtech-core/common/gql/generated';

import { IEntityFormProps } from './EntityForm';

type SubmitAddEntityParams = {
  data: IEntityFormProps;
};

type SubmitEditEntityParams = {
  data: IEntityFormProps;
};

export const mapToSubmitAddEntity = ({
  data,
}: SubmitAddEntityParams): AddBusinessUnitMutationVariables => {
  return {
    addBusinessUnitId: data.id,
    label: data.label,
    status: data.status,
  };
};

export const mapToSubmitEditEntity = ({
  data,
}: SubmitEditEntityParams): EditBusinessUnitMutationVariables => {
  return {
    order: data.order,
    editBusinessUnitId: data.id,
    label: data.label,
    status: data.status,
  };
};
