import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLocalization } from '@fluent/react';
import SettingsHeaderReusable from 'pages/ManageSettings/components/SettingsHeaderReusable';

import Box from '@mui/material/Box';

import { ADD_PROJECT_FTL, PROJECTS_FTL } from 'valtech-core/common/ftl';

import AllProjectsTable from './AllProjectsTable';

const AllProjects: FC = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();

  const navigateToAddProject = () => {
    navigate('add');
  };

  return (
    <Box>
      <SettingsHeaderReusable
        title={l10n.getString(PROJECTS_FTL)}
        btnTitle={l10n.getString(ADD_PROJECT_FTL)}
        callback={navigateToAddProject}
      />
      <AllProjectsTable />
    </Box>
  );
};

export default AllProjects;
