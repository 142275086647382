import React, { ReactNode, forwardRef, memo } from 'react';

import cn from 'classnames';

import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

interface ICheckboxProps extends CheckboxProps {
  label?: string;
  children?: ReactNode;
  hasError?: boolean;
}

const Checkbox = forwardRef<HTMLDivElement, ICheckboxProps>(
  ({ label, children, hasError, ...rest }, ref) => {
    if (label) {
      return (
        <FormGroup>
          <FormControlLabel
            className={cn({ formError: hasError })}
            control={<MUICheckbox {...rest} />}
            label={label}
            ref={ref}
          />
          {children}
        </FormGroup>
      );
    }
    return (
      <>
        <MUICheckbox {...rest} />
        {children}
      </>
    );
  },
);

export default memo(Checkbox);
