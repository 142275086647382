import { IHoliday } from './Holiday.constants';

interface ICheckIfDateExisting {
  date: string;
  index?: number;
  list: IHoliday[];
}

export const checkIfDateExisting = ({ list, date, index }: ICheckIfDateExisting): boolean => {
  return Boolean(
    list.find((item, i) => item.date === date && (Number.isInteger(index) ? i !== index : true)),
  );
};
