import React, { FC } from 'react';

import { ReactLocalization } from '@fluent/react';
import cn from 'classnames';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { TOTAL_FTL } from 'valtech-core/common/ftl';

import styles from './EditBudgetingTable.module.scss';

interface BudgetingTableHeadProps {
  title: string;
  months: {
    title: string;
    status: string;
  }[];
  l10n: ReactLocalization;
}

const BudgetingTableHead: FC<BudgetingTableHeadProps> = ({ title, months, l10n }) => {
  return (
    <TableHead>
      <TableRow className={styles.tableHeading}>
        <TableCell className={cn(styles.stickyCell, styles.overlapped)}>{title}</TableCell>
        {months.map(month => (
          <TableCell
            key={month.title}
            align='right'
            className={cn('text-uppercase', styles.monthStatus, month.status)}>
            {l10n.getString(month.title).slice(0, 3)}
          </TableCell>
        ))}
        <TableCell
          align='right'
          className={cn(
            styles.stickyCell,
            styles.stickToRight,
            styles.overlapped,
            styles.totalCell,
          )}>
          {l10n.getString(TOTAL_FTL)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BudgetingTableHead;
