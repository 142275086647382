import React from 'react';

import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import CustomRoute from 'components/CustomRoute';
import GenericError from 'components/GenericError';
import Layout from 'components/Layout';
import LogoutTimer from 'components/LogoutTimer';
import Notifications from 'components/Notifications';
import AllBillings from 'pages/AllBillings';
import Budgeting from 'pages/Budgeting';
import Budgetings from 'pages/Budgetings';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import ManageSettings from 'pages/ManageSettings';
import OauthCallback from 'pages/OauthCallback';
import SingleBilling from 'pages/SingleBilling';
import { useAuthState } from 'src/common/apolloState/auth';
import PageTitle from 'src/components/PageTitle';

import * as routes from 'valtech-core/common/routes';
import { GuardTypesEnum } from 'valtech-core/common/types';
import MuiCssProvider from 'valtech-core/utils/MuiCssProvider';

function App(): JSX.Element {
  const { isLoggedIn } = useAuthState();

  return (
    <MuiCssProvider>
      <GenericError />
      <Notifications />
      {isLoggedIn && <LogoutTimer />}
      <Router>
        <PageTitle />
        <Routes>
          <Route path={routes.NOT_FOUND} element={'404 PAGE'} />
          <Route path={routes.LOGIN_ROUTE} element={<Login />} />
          <Route path={routes.LOGOUT_ROUTE} element={<Logout />} />
          <Route path={routes.OAUTH_CALLBACK} element={<OauthCallback />} />

          <Route
            path={routes.OTHER}
            element={
              <Layout>
                <Routes>
                  <Route
                    path={routes.HOME_ROUTE}
                    element={<Navigate to={routes.BUDGETINGS_ROUTE} replace={true} />}
                  />

                  <Route
                    path={routes.ADMIN_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected} onlyForAdmin={true}>
                        {'ADMIN PAGE'}
                      </CustomRoute>
                    }
                  />

                  <Route path={routes.NOT_ALLOWED} element={'NOT ALLOWED'} />

                  <Route
                    path={routes.BUDGETINGS_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected}>{<Budgetings />}</CustomRoute>
                    }
                  />
                  <Route
                    path={routes.BUDGETING_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected}>{<Budgeting />}</CustomRoute>
                    }
                  />
                  <Route
                    path={routes.BILLINGS_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected}>{<AllBillings />}</CustomRoute>
                    }
                  />
                  <Route
                    path={routes.BILLING_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected}>{<SingleBilling />}</CustomRoute>
                    }
                  />

                  <Route
                    path={routes.ICBOS_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected}>{'ICBOS PAGE'}</CustomRoute>
                    }
                  />
                  {/* Nested Routing for SETTINGS tab*/}
                  <Route
                    path={routes.MANAGE_SETTINGS_ROUTE}
                    element={
                      <CustomRoute type={GuardTypesEnum.Protected} onlyForAdmin={true}>
                        {<ManageSettings />}
                      </CustomRoute>
                    }
                  />
                  <Route
                    path={routes.OTHER}
                    element={<Navigate to={routes.NOT_FOUND} replace={true} />}
                  />
                </Routes>
              </Layout>
            }
          />
        </Routes>
      </Router>
    </MuiCssProvider>
  );
}

export default App;
