import React, { FC, useEffect, useState } from 'react';

import TableSkeleton from 'components/TableSkeleton';
import { useSingleBilling } from 'pages/SingleBilling/SingleBilling.hook';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import { SingleBillingContextProvider } from './SingleBillingContextProvider';
import SingleBillingHeader from './SingleBillingHeader';
import SingleBillingTableContainer from './SingleBillingTableContainer';

const SingleBilling: FC = () => {
  const { singleBilling, loading } = useSingleBilling();

  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && !mounted) {
      setMounted(true);
    }
  }, [loading, singleBilling]);

  if (!mounted) {
    return (
      <Table>
        <TableBody>
          <TableSkeleton rowsNumber={6} cellsNumber={1} total={12} page={1} />
        </TableBody>
      </Table>
    );
  }

  return (
    <>
      {loading && (
        <>
          <ProgressLinear />
        </>
      )}
      <SingleBillingHeader
        title={singleBilling?.billingPageData?.headerTitle}
        status={singleBilling?.billingPageData?.status}
        projectName={singleBilling?.billingPageData.projectName}
        modifiedBy={singleBilling?.billingPageData?.modifiedBy}
        modifiedDate={singleBilling?.billingPageData?.modified_date}
      />
      <SingleBillingTableContainer editBillingTableData={singleBilling} />
    </>
  );
};

const SingleBillingWithContext: FC = () => {
  return (
    <SingleBillingContextProvider>
      <SingleBilling />
    </SingleBillingContextProvider>
  );
};

export default SingleBillingWithContext;
