import { onError } from '@apollo/client/link/error';

import { clearLocalStorageExceptLastVisitedUrl } from './apollo.utils';

import { getLogoutUrl } from './../../common/geLogoutUrl';

export enum ErrorMessage {
  Forbidden = 'forbidden',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
}
interface GraphQLResponse {
  statusCode: number;
}

const manageForbiddenError = async (): Promise<void> => {
  console.warn('Forbidden, update token or smth.');
};

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.[0]?.message === ErrorMessage.Forbidden) {
    manageForbiddenError();
  }

  const response = graphQLErrors?.[0]?.extensions?.response as GraphQLResponse;

  if (response?.statusCode === 401) {
    clearLocalStorageExceptLastVisitedUrl();

    window.location.href = getLogoutUrl();
  }
});
