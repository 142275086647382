import React, { FC, memo } from 'react';

import { Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { Variant } from 'common/types';
import noop from 'lodash/noop';

import { BoxProps } from '@mui/material/Box';
import { FormControlProps } from '@mui/material/FormControl';

import { FieldControl, Maybe } from 'valtech-core/common/types';
import FormError from 'valtech-core/form/FormError';
import Select, { onChange } from 'valtech-core/form/Select';
import { SelectedItem } from 'valtech-core/form/Select/Select';

import styles from './SelectField.module.scss';

export interface ISelectFieldProps {
  control: FieldControl;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  boxProps?: BoxProps;
  formControlProps?: FormControlProps;
  data: SelectedItem[];
  label?: string;
  className?: string;
  validate?: (value: string) => Promise<Maybe<string>>;
  variant?: Variant;
  onChange?: onChange;
}

const SelectFiled: FC<ISelectFieldProps> = ({
  name,
  control,
  defaultValue = '',
  boxProps,
  formControlProps,
  data,
  label,
  className,
  validate,
  disabled = false,
  variant,
  onChange = noop,
}) => {
  function getNestedError(errors, path) {
    return path.split('.').reduce((obj, key) => obj && obj[key], errors);
  }
  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <Select
          field={field}
          boxProps={boxProps}
          formControlProps={formControlProps}
          data={data}
          label={label}
          error={!!getNestedError(errors, name)}
          className={cn(styles.selectError, className)}
          disabled={disabled}
          variant={variant}
          onChange={onChange}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError message={message} />}
          />
        </Select>
      )}
      rules={{ validate }}
    />
  );
};

export default memo(SelectFiled);
