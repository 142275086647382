import { useMemo } from 'react';

import { useGetConsultantRolesQuery } from 'valtech-core/common/gql/generated';

type ConsultantRole = { id: string; label: string };

type UseConsultantsRolesReturn = {
  roles: ConsultantRole[];
  loading: boolean;
};

export const useConsultantsRoles = (): UseConsultantsRolesReturn => {
  const { data, loading } = useGetConsultantRolesQuery();

  const roles = useMemo((): ConsultantRole[] => {
    if (!data) {
      return [];
    }

    return data.sendArrayOfConsultantRoles.map(role => ({
      id: role.name,
      label: role.name,
    }));
  }, [data]);

  return {
    roles,
    loading,
  };
};
