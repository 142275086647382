import { AvatarData } from './Avatar';

interface IReturnedData {
  initials: string;
  fullName: string;
}

export const makeUserData = (avatarData: AvatarData): IReturnedData => {
  const initials =
    avatarData?.firstName.substr(0, 1).toUpperCase() +
    avatarData?.lastName.substr(0, 1).toUpperCase();
  const fullName = avatarData?.firstName + ' ' + avatarData?.lastName;
  return { initials, fullName };
};
