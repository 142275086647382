import React, { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import BlurContainer from 'src/components/BlurContainer';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ASSOCIATED_CLIENTS_PROCEED_FTL, PROCEED_FTL } from 'valtech-core/common/ftl';

import { SingleProjectFormProps } from '../../SingleProject';
import AddAccount from './AddAccount';
//TODO: wait B.E.
// import MSADetailsTable from './MSADetails/MSADetailsTable';
import AssociatedClientsTable from './AssociatedClientsTable';
import DeliveryManager from './DeliveryManager';
import GeneralInformation from './GeneralInformation';
import MSADetails from './MSADetails';
import s from './SingleProjectContent.module.scss';
import UserCredentialsTable from './UserCredentialsTable';

type ISingleProjectFormWrapperType = Partial<SingleProjectFormProps>;
interface ISingleProjectFormWrapperProps extends ISingleProjectFormWrapperType {
  callback: () => void;
}

const SingleProjectContent: FC<ISingleProjectFormWrapperProps> = props => {
  const { arrayOfDeliveryManagers, accounts, projectName, deliveryManagerId, callback } = props;
  const { control } = useFormContext();
  const { l10n } = useLocalization();

  return (
    <Box sx={{ background: 'var(--grey-lighten-5)', padding: '30px' }}>
      <form
        onSubmit={e => e.preventDefault()}
        style={{ background: 'var(--white-color)', padding: '24px' }}>
        <Grid container spacing={3}>
          <GeneralInformation control={control} />
          <AddAccount control={control} accounts={accounts} />
          <DeliveryManager control={control} allDeliveryManagers={arrayOfDeliveryManagers} />
          <MSADetails control={control} />
        </Grid>

        {!projectName && (
          <Grid container sx={{ mt: 5 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                gap: '10px',
              }}>
              <Typography className={s.information__text}>
                {l10n.getString(ASSOCIATED_CLIENTS_PROCEED_FTL)}
              </Typography>
              <Button variant='contained' onClick={callback}>
                {l10n.getString(PROCEED_FTL)}
              </Button>
            </Box>
          </Grid>
        )}
        <div className={s.overlay}>
          <BlurContainer disabled={projectName ? false : true}>
            {/* <MSADetailsTable /> */}
            {<AssociatedClientsTable projectName={projectName} />}
            <UserCredentialsTable
              projectName={projectName}
              deliveryManagerId={Number(deliveryManagerId)}
            />
          </BlurContainer>
        </div>
      </form>
    </Box>
  );
};

export default SingleProjectContent;
