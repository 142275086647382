import React, { FC } from 'react';

import { SxProps } from '@mui/material';
import MUITablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import s from './TablePagination.module.scss';
import TablePaginationActions from './TablePaginationActions';

interface ITablePaginationProps {
  rowsPerPageOptions: Array<number | { value: number; label: string }>;
  count: number;
  rowsPerPage: number;
  page: number;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  isData: boolean;
  sx?: SxProps;
}

const TablePagination: FC<ITablePaginationProps> = ({
  rowsPerPageOptions,
  count,
  page,
  setRowsPerPage,
  setPage,
  rowsPerPage,
  sx,
  isData,
}) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableRow sx={sx}>
      <MUITablePagination
        rowsPerPageOptions={[...rowsPerPageOptions]}
        className={s.tablePagination}
        count={isData ? count : 0}
        rowsPerPage={rowsPerPage}
        page={isData ? page : 0}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
          disabled: !isData,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  );
};

export default TablePagination;
