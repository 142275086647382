import React, { FC } from 'react';

import Box from '@mui/material/Box';

import Back from 'valtech-core/ui/Back';
import Button from 'valtech-core/ui/Button';
import Typography from 'valtech-core/ui/Typography';

import s from './SettingsHeaderReusable.module.scss';

interface ISettingsHeaderReusableProps {
  title: string;
  btnTitle: string;
  disabled?: boolean;
  callback: (event: Event | React.SyntheticEvent) => void;
}

const SettingsHeaderReusable: FC<ISettingsHeaderReusableProps> = ({
  title,
  btnTitle,
  callback,
  disabled,
}) => {
  return (
    <Box className={s.container}>
      <Box className={s.information}>
        <Back className={s.informationIcon} />
        <Box className={s.informationTitle}>
          <Typography variant='h6' align='inherit' weight='regular' component={'p'}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box className={s.action}>
        <Button
          type='button'
          sx={{ mx: 1 }}
          onClick={callback}
          disabled={disabled}
          variant='contained'>
          {btnTitle}
        </Button>
      </Box>
    </Box>
  );
};

export default SettingsHeaderReusable;
